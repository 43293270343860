import styled from 'styled-components';

export const Container = styled.div`

  > div {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: start;
    text-align: left;
    flex-direction: column;
    padding: 32px;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 12px;
    text-decoration: none;
    background: ${props => props.theme.colors.bgBox};
    transition: all .2s ease-in-out;
    border: 2px solid ${props => props.theme.colors.bgBox};

    &:hover, &.active {
      border: 2px solid ${props => props.theme.colors.primary};
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 1rem;

      > h1 {
        line-height: 1;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.textColor};
      }

      > h5 {
        line-height: 1;
        font-size: .2rem;
        color: ${props => props.theme.colors.textColor};
      }

      > h2 {
        line-height: 1;
        font-size: 12px;
        color: ${props => props.theme.colors.primary};
        border-radius: 4px;
        height: 25px;

        &.pending {
          background: orange;
          color: #fff;
          padding: .4rem .8rem;
        }

        &.awaiting {
          background: orange;
          color: #fff;
          padding: .4rem .8rem;
        }

        &.closed {
          background: #03bb85;
          color: #fff;
          padding: .4rem .8rem;
        }

        &.canceled {
          background: red;
          color: #fff;
          padding: .4rem .8rem;
        }
      }

      > button {
        display: flex;
        align-items: center;
        background: none;
        text-decoration: none;
        color: #9aa4bf;
        font-size: .875rem;
        font-weight: 600;
        text-decoration: underline;

        > span {
          font-size: .65rem;
          margin-top: 3px;
        }
      }

      > p {
        color: #adafca;
        font-size: .75rem;
        font-weight: 500;
      }
    }


    > p {
      color: #9aa4bf;
      font-size: .875rem;
      font-weight: 500;
      margin-bottom: 1rem;
      line-height: 1.4285714286em;
      text-align: justify;
    }

    > h5 {
      color: #adafca;
      text-transform: uppercase;
    }

    > h6 {
      background: ${props => props.theme.colors.primary};
      color: #fff;
      border-radius: 4px;
      padding: 2px 6px;
    }
  }
`;
