import React, { useMemo, useEffect, useState } from 'react';

import api from '../../services/api';

import { useCredit } from '../../hooks/credit';

import CountUp from 'react-countup';
import { debounce } from 'lodash';

import BubbleMenuContext from './BubbleMenuContext';
import SectionBanner from '../../components/SectionBanner';
import BoxContainer from '../../components/BoxContainer';
import InputAnimate from '../../components/InputAnimate';
import SelectInput from '../../components/SelectInput';
import ThemeCard from './ThemeCard';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/sends-icon.png';

import { Container, ContentGrid1, SearchButton, SendStatiscs, TabsCrid, NotFound } from './styles';

interface IThemeCardProps {
  page: number;
  maxPages: number;
  records : {
    exam_name: string;
    difficulty: 'easy' | 'medium' | 'hard';
    id: string;
    thumbnail_url: string;
    student: {
      id: string;
      first_name: string;
      last_name: string;
      avatar: string;
      exp: number;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    };
    name: string;
    category: {
      name: string;
    }
  }[];
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
}

interface ICategoryProps {
  value: string | number;
  label: string | number;
}

const Temas: React.FC = () => {
  const { credits } = useCredit();

  const [themeSuggestCard, setThemeSuggestCard] = useState<IThemeCardProps | null>();

  const [categories, setCategories] = useState<ICategoryProps[]>([]);

  const [loading, setLoading] = useState(true);

  const [pageIndex, setPageIndex] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');

  const delayedSearch = useMemo(() => debounce(() => {
      if (searchTerm.length > 3 || searchTerm.length === 0) {
        setSearch(searchTerm);
        setPageIndex(0);
      }
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  useEffect(() => {
    async function loadData() {
      try {
        setThemeSuggestCard(null);
        window.scrollTo(0, 0);
        setLoading(true);

        const response = await api.get<IThemeCardProps>('/themes/active',
          {params: { pageIndex, pageSize: 8, exam_name: 'enem', name: search, category: category }}
        );

        console.log(response.data);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
        setThemeSuggestCard(response.data);

      } catch (error) {
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pageIndex, search, category]);

  // List CATEGORIES THEMES
  useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get('/themes/categories/list');

        console.log('LIST CATEGORIES >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setCategories(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, []);

  const canNextPage = (): boolean => {
    return pageIndex + 1 < maxPages;
  }

  const canPreviousPage = (): boolean => {
    return !!pageIndex;
  }

  const goNextPage = () => {
    if (canNextPage()) {
      setPageIndex(oldValue => oldValue + 1);
      console.log('pageindex>> ', pageIndex);
    }
    else {
      alert('Não há mais temas');
    }
  }

  const goPreviousPage = () => {
    if (canPreviousPage()) {
      setPageIndex(oldValue => oldValue - 1);
      console.log('pageindex>> ', pageIndex);
    }
    else {
      alert('Não há mais temas');
    }
  }

  return(
    <>
      <BubbleMenuContext exam_name={'enem'} />
      <Container>
        <SectionBanner icon={bannerindexIconImg} title="Temas disponíveis" subTitle="Você tem direito a 4 créditos de envio por mês e não acumulativos" />
        <BoxContainer margin="2rem 0 2rem 0" padding="28px" display="flex" position="relative">
        <SelectInput
            options={categories}
            onChange={event => {setCategory(event.target.value); setPageIndex(0);}}
            labelText="Eixo temático"
            placeHolder="Exibir todos"
            showLabel={true}
            showPlaceholder={true}
            selected={category}
          />
          <form onSubmit={e => e.preventDefault()}>
            <InputAnimate
              label="Pesquise por um tema"
              type="text"
              name="user"
              onChange={event => setSearchTerm(event.target.value)}
              autoComplete="off"
              margin="0"
              required
            />
            <SearchButton>
              <Icon icon="icon-icon-magnifying-glass" />
            </SearchButton>
          </form>
          <SendStatiscs>
            <p><CountUp end={credits.wordingsRemaining + credits.extraCredits || 0} duration={3} /></p>
            <h5>Créditos disponíveis</h5>
          </SendStatiscs>
        </BoxContainer>
        <TabsCrid>
          {(totalRecords === 0 && !loading) && <NotFound>Poxa, não encontramos nenhum tema para a sua pesquisa. 😢</NotFound>}
          <ContentGrid1>
            {themeSuggestCard?.records.map((theme, index) => (
              <ThemeCard
                key={index}
                id={theme.id}
                exp={30}
                title={theme.name}
                course={theme.exam_name}
                student={theme?.student}
                category={theme.category.name}
                setCategory={setCategory}
                thumbnail_url={theme.thumbnail_url}
                difficulty={theme.difficulty}
              />))}
            {loading && <Loader isFixed={false} />}
          </ContentGrid1>
        </TabsCrid>
        <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} temas disponíveis`}</p>
        <footer>
            {!loading &&
              <>
              {canPreviousPage() &&
                <Button color="primary" onClick={goPreviousPage}>
                  <Icon icon="icon-icon-small-arrow" size="9px" margin="0 4px 0 0" revert={true} />
                  Página anterior
                </Button>}
                {canNextPage() &&
                <Button color="primary" onClick={goNextPage}>
                  Próxima Página
                  <Icon icon="icon-icon-small-arrow" size="9px" margin="0 0 0 4px" />
                </Button>}
              </>
            }
        </footer>
        <Footer />
      </Container>
    </>
  );
}

export default Temas;
