import styled from 'styled-components';

export const AbsoluteHeader = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 500px;
  background: linear-gradient(120deg, #fa5d73, #ff9800);
  margin-top: -40px;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  margin: 1rem 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 784px;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {

    > h2 {
      margin-bottom: 1rem;
    }
  }

  > section:nth-child(2) {

    > main {
      position: relative;
      padding: 90px 100px 0px 100px;
      border-radius: 12px;
      background: ${props => props.theme.colors.bgBox};
      color: ${props => props.theme.colors.textColor};

      /* TABLET */
      @media (max-width: 768px) {
        width: 100%;
        padding: 32px 28px;
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        > aside {

          > h1 {
            font-size: 2.875rem;
            font-weight: 700;
            line-height: .9565217391em;
            margin-right: 1rem;
            color: ${props => props.theme.colors.textColor};

            /* MOBILE */
            @media (max-width: 480px) {
              font-size: 2rem;
            }
          }

          > h2 {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            color: #adafca;

            /* MOBILE */
            @media (max-width: 480px) {
              font-size: .75rem;
            }
          }

          > p {
            margin-top: 10px;
            color: #adafca;
            font-size: .75rem;
            font-weight: 500;
          }
        }

      }

      > main {
        position: relative;
        display: grid;
        flex-direction: column;
        grid-template-columns: 84px auto;
        grid-gap: 1rem;
        margin-top: 3rem;
        padding-bottom: 3rem;

        &:not(:last-child) {
          border-bottom: 1px solid #adafca35;
        }

        /* TABLET */
        @media (max-width: 768px) {
          display: flex;
        }

        > aside {

          > h6 {
            text-align: center;
            line-height: 1.1;
          }

          > h5 {
            color: #adafca;
            font-size: .75rem;
            font-weight: 500;
          }

          &:nth-child(1) {
            justify-content: start;
            display: flex;
            flex-direction: column;
            align-items: center;

            /* TABLET */
            @media (max-width: 768px) {
              display: flex;
              flex-direction: row;
            }
          }

          > div {

            /* TABLET */
            @media (max-width: 768px) {
              justify-content: start;
              margin-right: 1rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          > p {
            font-size: 1rem;
            font-weight: 500;
            line-height: 2em;
            color: ${props => props.theme.colors.textColor};

            &:not(:first-child) {
              margin-top: 36px;
            }

            > p {

              > img {
                height: auto;
                max-width: 100%;
                border-radius: 12px;
              }
            }

          }
        }
      }
    }

  }
`;

export const Status = styled.span`
  background: orange;
  color: #fff;
  padding: .4rem .8rem;
  border-radius: 4px;
  font-weight: 700;

  &.pending {
    background: orange;
    color: #fff;
    padding: .4rem .8rem;
  }

  &.awaiting {
    background: orange;
    color: #fff;
    padding: .4rem .8rem;
  }

  &.closed {
    background: #03bb85;
    color: #fff;
    padding: .4rem .8rem;
  }

  &.canceled {
    background: red;
    color: #fff;
    padding: .4rem .8rem;
  }
`;

export const NewTicket = styled.button`
  width: 100%;
  border: 2px dashed #fff;
  border-radius: 12px;
  background: none;
  padding: 68px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
  transition: all .3s ease-in-out;

  &:hover {
    background: #ffffff3d;
    color: ${props => props.theme.colors.textColor};
    color: #fff;
  }
`;

export const Showing = styled.p`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;

export const AnswersLoaderContent = styled.article`
  position: relative;
  min-height: 150px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    justify-content: center;
    width: 100% !important;
  }
`;

export const WysisygContainer = styled.div`
  position: relative;
`;

export const ModalFinishSupport = styled.section`
  position: relative;
  color: #3e3f5e;
  text-align: left;

  > h1 {
    font-size: 1.5rem;
    text-align: left;
  }

  > h2 {
    font-size: 1rem;
    text-align: left;
    margin: 1rem 0;
  }

  > ol {
    margin-left: 2rem;
  }

  > p {
    text-align: left;
    margin-top: 1rem;
    font-size: .875rem;

    > a {
      color: #665ab7;
      font-weight: bold;

      &:hover, &:focus, &:active, &:visited {
        color: #665ab7;
      }
    }
  }

`;
