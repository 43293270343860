import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface NewWordingFloatButtonProps {
  background?: string;
  size?: string;
}

export const LinkAction = styled(Link)`
  color: #fff;
`;

export const Container = styled.div<NewWordingFloatButtonProps>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.2rem;
  background: ${props => props.background ? props.background : '#9181fa'};
  width: ${props => props.size ? props.size : '60px'};
  height: ${props => props.size ? props.size : '60px'};
  bottom: 2rem;
  right: 3rem;
  animation: pulse 2s infinite;
  transition: all 0.3s ease-in-out 0s;
  z-index: 9999999999999999999999;

  &:after {
    position: absolute;
    z-index: 99999;
    content: attr(data-tooltip);
    border-radius: 1rem;
    text-align: center;
    box-sizing: border-box;
    box-shadow: ${props => props.theme.colors.shadowBox};
    padding: 0 12px;
    background: ${props => props.theme.colors.darkGray};
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    white-space: nowrap;
    line-height: 24px;
    margin-top: 0;
    margin-left: 0;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
  }

  &:hover {
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translateX(-5.7rem);
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(97,93,250, 0.50);
      box-shadow: 0 0 0 0 rgba(97,93,250, 0.50);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(255,255,255, 0);
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }
`;
