import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import SectionBanner from '../../components/SectionBanner';
import BadgeItemCard from './BadgeItemCard';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/badges-icon.png';

import { Container, ContentGrid1, TabsCrid } from './styles';

interface IAchievementsProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    icon: string;
    title: string;
    description: string;
    exp: number;
    key: string;
    requirement: string;
    is_hidden: boolean;
    created_at: string;
    updated_at: string;
    icon_url: string;
    users: {
      id: string;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    }[];
  }[];
}

const Conquistas: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [achievements, setAchievements] = useState<IAchievementsProps>({} as IAchievementsProps);

  // GET BADGES
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/achievements',
          {params: { pageIndex: 0, search: '', pageSize: 7777 }}
        );

        console.log('LIST ACHIVEMENTS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setAchievements(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return (
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Conquistas &amp; Gamificação" subTitle="Assista aos vídeos, envie redações, acumule pontos e desbloqueie conquistas!" />
      <TabsCrid>
        <ContentGrid1>
          {loading && <Loader isFixed={false} />}
          {achievements.records?.sort((a) => a.key === 'poxaluno' ? -1 : 0).map((achievement, index) => {



            return (
              <BadgeItemCard
                key={index}
                badge_icon={achievement.icon_url}
                title={achievement.title}
                description={achievement.requirement}
                exp_total_value={achievement.exp}
                status={achievement.users.length ? "concluded" : "pending"}
                is_hidden={achievement.is_hidden}
                users={achievement.users}
              />
            )
          })}
        </ContentGrid1>
        </TabsCrid>
        <Footer />
    </Container>
  );
}

export default Conquistas;
