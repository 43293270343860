import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {}

  /* MOBILE */
  @media (max-width: 480px) {}

  > div:nth-child(2) {

    > div:nth-child(1) {
      margin-right: 1rem;

      /* MOBILE */
      @media (max-width: 480px) {
        display: none;
      }

      > select {
        min-width: 170px;
        width: 100%;
        font-size: 1rem;
        border: 1px solid #adafca30;
        padding: 1rem;
        color: #a9a6c6;
        transition: border-color .2s ease-in-out;
        border-radius: 12px;
        font-weight: 600;
        cursor: pointer;
        font-size: 1rem;
      }
    }

  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    > button {
      display: flex;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      padding: 1rem 0rem;
      margin: 0 .5rem;
      font-size: .8rem;
      width: 190px;

      /* TABLET */
      @media (max-width: 768px) {}

      /* MOBILE */
      @media (max-width: 480px) {
        width: 50%;
        padding: 1rem 0rem;
        font-size: .7rem;
      }
    }
  }

  > p {
    text-align: center;
    margin-top: 2rem;
    color: #9aa4bf95;
  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  min-height: 350px;
  border-radius: 12px;
  overflow: hidden;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(4, 1fr);

  /* LAPTOP 1024 */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* TABLET */
  @media (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
  }

`;

export const SearchButton = styled.a`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 1;
  text-decoration: none;
  font-size: 1.2rem;
`;

export const SendStatiscs = styled.div`
  text-align: center;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    width: 1px;
    height: 40px;
    background-color: #eaeaf580;
    position: absolute;
    top: 9px;
    right: 0;
    display: block;
    box-sizing: border-box;
  }

  &:last-child::after {
    display: none;
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  }

  > h5 {
    color: #adafca;
    line-height: 1;
    font-size: .75rem;
    text-transform: uppercase;
  }

  /* TABLET */
  @media (max-width: 768px) {
    display: none;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    display: none;
  }
`;

export const TabsCrid = styled.div`
  margin: 2rem 0;

  ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;
  }

  li {
    font-size: .875rem;
    font-weight: 700;
    cursor: pointer;
    background: none;
    padding-right: 1rem;
    border-radius: 0px;
    color: ${props => props.theme.colors.textColor};

    > button {
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-weight: 700;
      color: ${props => props.theme.colors.textColor};
      background: none;
      opacity: .5;
      transition: opacity .3s ease-in-out 0s;

      &.active {
        opacity: 1;
        border-bottom: 4px solid #6e61c6;
      }
    }
  }
`;

export const NotFound = styled.p`
  margin-top: 2rem;
  text-align: center;
  padding: 2rem 0;
  border-radius: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.textColor};
  background: ${props => props.theme.colors.bgBox};
`;
