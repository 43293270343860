import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReportAnswer } from './styles';
import Swal from 'sweetalert2';
import LoaderPureCss from '../../../components/LoaderPureCss';

interface IModalProps {
  target_id: string;
}

interface ICategoriesAnswerReportProps {
  id: string;
  title: string;
  description: string;
  type: string;
  need_reason: boolean;
  created_at: string;
  updated_at: string;
}

interface IReportRegistrationForm {
  category_id: string;
  target_id: string;
  reason?: string;
}

const ModalReportAnswer: React.FC<IModalProps> = ({target_id}) => {

  const [loading, setLoading] = useState(false);

  const [needReason, setNeedReason] = useState(false);
  const [topicReportRadio, setAnswerReportRadio] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('');
  const [categoriesAnswerReport, setCategoriesAnswerReport] = useState<ICategoriesAnswerReportProps[]>([]);

  // List categories of reports ANWSER
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/reports/categories/list`,
          {params: { type: 'answer', }}
        );

        console.log('answer report categories>>>>> ', response.data);
        setCategoriesAnswerReport(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  const handleAnswerReportChangeOption = useCallback((id: string, need_reason: boolean) => {
    setAnswerReportRadio(id);
    setNeedReason(need_reason);
  }, []);

  const handleAnswerReportSubmit = useCallback((category_id: string, target_id: string, reason?: string) => {
    console.log('reason>>>', reason);

    async function loadData() {
      try {
        const data: IReportRegistrationForm = {
          category_id: category_id,
          target_id: target_id,
        };

        if (reason) data.reason = reason;

        const response = await api.post(`/reports`, data);

        console.log('report topic created >>>>> ', response.data);

        Swal.close();
        toast.success('💔 Denúncia enviada com suecesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } catch (error) {
        console.log(error);

        Swal.close();
        toast.error('😢 Poxa, algo deu errado! Tente novamente mais tarde.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        // set finally action
      }
    }

    loadData();

  }, []);

  return (
    <ReportAnswer>
      <form onSubmit={(event) => event.preventDefault()}>

        {/*
          <p>Reportar TOPIC ID: {topicReportRadio}</p>
          <p>Reportar TARGET ID: {target_id}</p>
        */}

        <h2>Por que você está denunciando essa resposta?</h2>
        {categoriesAnswerReport.map((category, index) => (
          <label key={index}>
            <input type="radio" name="report" defaultValue={category.id} onChange={() => {handleAnswerReportChangeOption(category.id, category.need_reason)}} />
            <aside>
              <div>
                <h5>{category.title}</h5>
                <p>{category.description}</p>
              </div>
            </aside>
          </label>
        ))}
        {needReason && <div>
          <h5>Motivo da intervenção:</h5>
          <textarea name="motivo" id="motivo" onChange={(e) => setTextAreaValue(e.target.value)} />
          {needReason && <p>Por favor, informe o motivo no campo acima.</p>}
        </div>}
        <div>
          <button onClick={() => handleAnswerReportSubmit(topicReportRadio, target_id, textAreaValue)} disabled={(!!!topicReportRadio && !needReason) || (needReason && !!!textAreaValue)}>Enviar denúncia</button>
          {!topicReportRadio && <p>Selecione uma das opções para realizar a denúncia.</p>}
        </div>
        {loading && <LoaderPureCss isFixed={false} />}
      </form>
    </ReportAnswer>
  );
}

export default ModalReportAnswer;
