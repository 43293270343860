import React from 'react';

import { Container } from './styles';

interface ISectionBannerProps {
  icon: string;
  title: string;
  subTitle: string;
  background?: string;
}

const SectionBanner: React.FC<ISectionBannerProps> = ({ icon, title, subTitle, background }) => {
  return(
    <Container title={title} subTitle={subTitle} background={background}>
      <img src={icon} alt={title} />
      <h1>{title}</h1>
      <p>{subTitle}</p>
    </Container>
  );
}

export default SectionBanner;
