import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {
  }

  /* MOBILE */
  @media (max-width: 480px) {
  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 4fr 3fr;
  position: relative;

  /* TABLET */
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  //SECTION 1
  > section:nth-child(1) {
    color: ${(props) => props.theme.colors.textColor};

    > div {
      position: sticky;
      top: calc(80px + 1rem);

      > aside {
        margin-bottom: 1rem;
      }

      > div {
        margin-bottom: 1rem;

        /* TABLET */
        @media (max-width: 1000px) {
          // display: none;
        }
      }
    }
  }

  //SECTION 2
  > section:nth-child(2) {
    .line {
      position: relative;
    }

    .line::before {
      position: absolute;
      width: 2px;
      height: 100%;
      background: ${(props) => props.theme.colors.timelineColor};
      content: "";
    }

    .line .content {
      position: relative;
      padding-left: 30px;
      flex-direction: column;
    }

    .line .content + .content {
      margin-top: 40px;
    }

    .line .content h1 {
      font-size: 1.5rem;
      line-height: 0.75;
      margin-bottom: 1rem;
      margin-top: 0;
      color: ${(props) => props.theme.colors.textColor};
    }

    .line .content p {
      font-size: 14px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.colors.textColor};

      > img {
        width: 14px;
        margin-right: 5px;
      }
    }

    .line .content::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: ${(props) => props.theme.colors.timelineColor};
      left: -6px;
      border-radius: 50%;
    }
  }
`;

export const Timeline = styled.div`
  display: flex;
  padding: 0 0 0 1rem;
  border-radius: 18px;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {
    padding: 0;
  }
  /* MOBILE */
  @media (max-width: 480px) {
  }
`;

export const TabsCrid = styled.div`
  margin: 2rem 0;

  ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;
  }

  li {
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
    background: none;
    padding-right: 1rem;
    border-radius: 0px;
    color: ${(props) => props.theme.colors.textColor};

    > button {
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-weight: 700;
      color: ${(props) => props.theme.colors.textColor};
      background: none;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out 0s;

      &.active {
        opacity: 1;
        border-bottom: 4px solid #6e61c6;
      }
    }
  }
`;

export const VisionModule = styled(Link)`
  position: relative;
  display: flex;
  padding: 1rem;
  text-decoration: none;

  > div {
    position: relative;
    background: linear-gradient(90deg,#5e195e,#000000);
    width: 100%;
    position: relative;
    display: grid;
    grid-gap: 1rem;
    margin: 1rem 0;
    grid-template-columns: 1.8fr 1.2fr;
    padding: 0 0 0 0;
    border-radius: 12px;
    box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
    background-size: contain;
    cursor: pointer;

    > aside {
      padding: 2rem 0 2rem 2rem;
      z-index: 9;

      > h1 {
        color: #fff !important;
        font-size: 1.5rem !important;
        margin-bottom: 0 !important;
        font-weight: 700 !important;
        line-height: 1 !important;

        > img {
          width: 16px;
        }
      }

      > h2 {
        margin-top: 4px !important;
        color: #fff !important;
        font-size: 0.875rem !important;
        font-weight: 500 !important;
        line-height: 1 !important;
        max-width: 215px !important;
      }

      > p {
        display: inline-block !important;
        height: 20px !important;
        margin-top: 26px !important;
        margin-bottom: 0px !important;
        margin-right: 5px !important;
        padding: 0 8px !important;
        border-radius: 200px !important;
        background-color: #fff !important;
        font-size: 0.75rem !important;
        font-weight: 700 !important;
        text-transform: uppercase !important;
        line-height: 20px !important;
        color: #4f8dff !important;
      }
    }

    > main {
      margin: -50px 3px -6px 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 0;

      > img {
        position: absolute;
        margin: 6px -27px;
      }
    }
  }
`;
