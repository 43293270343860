import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface IForumCategoryCardProps {
  title: string;
  description: string;
  order?: number;
  slug?: string;
  thumbnail_url: string;
  background: string;
  to: string;
}

const ForumCategoryCard: React.FC<IForumCategoryCardProps> = ({ description, thumbnail_url, title, background, to}) => {
  return (
    <Container background={background}>
      <header>
        <div>
          <img src={thumbnail_url} alt={title} />
        </div>
      </header>
      <main>
        <h4>Categoria</h4>
        <h1>{title}</h1>
        <p>{description}</p>
      </main>
      <footer>
        <Link to={to} color="outline">Acessar fórum</Link>
      </footer>
    </Container>
  );
}

export default ForumCategoryCard;
