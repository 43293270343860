import React from 'react';

import { BackgroundImage } from 'react-image-and-background-image-fade';

import UserAvatar from '../UserAvatar';
import Icon from '../Icon';
import ExpBadge from '../ExpBadge';

import poxaluluProfilePic from '../../assets/poxa-lulu-avatar.png';

import { Container } from './styles';
import BadgeDificulty from '../../Pages/Temas/ThemeCard/BadgeDificulty';

interface IThemeCardInstructionsProps {
  id: string;
  exp: number;
  themeSuggestion: boolean;
  title: string;
  course: string;
  link: string;
  thumbnail_url: string;
  is_photo?: boolean;
  student?: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  difficulty: 'easy' | 'medium' | 'hard';
}

const ThemeCardInstructions: React.FC<IThemeCardInstructionsProps> = ({ link, student, exp, themeSuggestion, title, thumbnail_url, course, is_photo, difficulty }) => {

  return (
    <Container thumbnail_url={thumbnail_url}>
      {thumbnail_url && <BackgroundImage
        element="figure"
        src={thumbnail_url}
        lazyLoad={true}
      />}
      <main>
        <ExpBadge icon="icon-icon-plus" exp={exp} />
        <div>
          <p>{themeSuggestion && 'Tema escolhido'} ({course})</p>
          <h2>{title}</h2>
        </div>
          <a href={link} target="_blank" rel="noreferrer">
            <Icon icon="icon-icon-forum" color="#ffffff" size="14px" margin="0 8px 0 0" />
            MATERIAL DE APOIO
          </a>
          {is_photo && <a href="https://poxalulu-api.s3.sa-east-1.amazonaws.com/materials/folha-de-redacao-enem-poxalulu.pdf" className="transparent" target="_blank" rel="noreferrer">
            <Icon icon="icon-icon-forum" color="#ffffff" size="14px" margin="0 8px 0 0" />
            FOLHA DE REDAÇÃO
          </a>}
      </main>
      <footer>
        <div>
          {
            student ?
            <>
              <UserAvatar size="sm" totalExp={student.exp} picture={student.avatar_url} />
              <div>
                <p><span>Sugerido por</span><br />{student.show_name}</p>
              </div>
            </>
            :
            <>
              <UserAvatar size="sm" totalExp={10000} picture={poxaluluProfilePic} />
              <div>
                <p><span>Publicado por</span><br />Poxalulu</p>
              </div>
            </>
          }
        </div>
        <div>
          <BadgeDificulty dificulty={difficulty} />
        </div>
      </footer>
    </Container>
  );
}

export default ThemeCardInstructions;
