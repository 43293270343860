import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import InputAnimateDropdown from '../../components/InputAnimateDropdown';
import SectionBanner from '../../components/SectionBanner';
import Icon, { IIconType } from '../../components/Icon';
import HeaderInfo from '../../components/HeaderInfo';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/accounthub-icon.png';

import { Container, ContentGrid1, ContentGrid2, SearchButton, } from './styles';
interface ISuportProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: IIconType;
  position: number;
}

const SuporteTecnico: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [suporteCategories, setSuportCategories] = useState<ISuportProps[]>([]);

  // List MATERIALS
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/sections');

        console.log('LIST SUPORT CATEGORIES >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setSuportCategories(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return(
    <Container>
        <SectionBanner icon={bannerindexIconImg} title="Suporte Técnico" subTitle="Preparamos uma dezena de artigos para solucionar qualquer probleminha da plataforma!" />
        <HeaderInfo supTitle="Visão geral" title="Central de ajuda" margin="2rem 0 1rem 0" showSendButton={false} />
        <ContentGrid1>
          <div>
            <form onSubmit={e => e.preventDefault()}>
              <InputAnimateDropdown
                label="Qual é a sua dúvida?"
                type="search"
                name="duvida"
                margin="0"
                required
              />
              <SearchButton>
                <Icon icon="icon-icon-magnifying-glass" />
              </SearchButton>
            </form>
          </div>
        </ContentGrid1>
        <ContentGrid2>
          {suporteCategories.map((category, index) => (
            <Link to={`/suporte/artigos/${category.slug}`} key={index}>
              <Icon icon={category.icon} size="32px" color="#9282fa" margin="0 0 1rem 0" />
              <h1>{category.name}</h1>
              <p>{category.description}</p>
            </Link>))}
          {loading && <Loader isFixed={false} />}
        </ContentGrid2>
      <Footer />
    </Container>
  );
}

export default SuporteTecnico;
