import styled from 'styled-components';

import SearchIcon from '../../assets/search-icon.svg';

interface ISearchResultProps {
  isActive: boolean;
}

export const SearchContainer = styled.main`
  position: relative;

  @media screen and (max-width: 680px) {
    position: static;
  }
`;

export const Container = styled.div`

  > input {
    width: 100%;
    padding: 1rem 3rem 1rem 1.5rem;
    border-radius: 12px;
    background: url('${SearchIcon}') no-repeat #6257b2;
    background-position: center right 1rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    transition: border-color .2s ease-in-out;

    ::placeholder {
      color: #ffffff40;
      font-weight: 500;
    }

    @media screen and (max-width: 680px) {
      height: 80px;
      border-radius: 0;

      ::placeholder {
        font-size: 11px
      }
    }
  }
`;

export const SearchResult = styled.div<ISearchResultProps>`
  position: absolute;
  width: 100%;
  top: 48px;
  padding: 1rem 1rem;
  border-radius: 0 0 12px 12px;
  z-index: 1;
  border: 1px solid #9aa4bf30;
  box-shadow: ${props => props.theme.colors.shadowBox};
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  opacity: ${({ isActive }) => isActive ? 1 : 0};
  visibility: ${({ isActive }) => isActive ? 'visible' : 'hidden'};
  transition: all .2s ease-in-out;

  @media screen and (max-width: 680px) {
    top: 80px;
    position: absolute;
    width: 100vw;
    left: 0;
    border-radius: 0px;
    border: none;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 30%);
    backdrop-filter: blur(8px);
  }

  > ul {
    list-style: none;

    > li {

      > a {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        transition: all .2s ease-in-out;

        &:hover {
          color: ${props => props.theme.colors.primary}c9;
        }
      }

      > p {
        color: #9aa4bf;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        transition: all .2s ease-in-out;
        margin-top: .3rem;
      }
    }

    > main {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);

      /* TABLET */
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }

      > div {

        > h5 {
          color: #adafca;
          font-size: .75rem;
          font-weight: 700;
          text-transform: uppercase;
          text-align: left;
        }

        > li {

          > a {
            color: ${props => props.theme.colors.primary};
            text-decoration: none;
            font-weight: bold;
            font-size: 12px;
            transition: all .2s ease-in-out;

            &:hover {
              color: ${props => props.theme.colors.primary}c9;
            }
          }

          > p {
            color: #9aa4bf;
            text-decoration: none;
            font-weight: bold;
            font-size: 12px;
            transition: all .2s ease-in-out;
            margin-top: .3rem;
          }
        }
      }
    }
  }
`;
