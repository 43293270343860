import styled, { css } from "styled-components";
import { Link } from 'react-router-dom';

import bg1 from '../../assets/bg-stats-card-1.png';
import bg2 from '../../assets/bg-stats-card-2.png';
import bg3 from '../../assets/bg-stats-card-3.png';
import bg4 from '../../assets/bg-stats-card-4.png';

interface IStatsBoxSmallProps {
  width: string;
  bg: 'bg1' | 'bg2' | 'bg3' | 'bg4';
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
}

const bgCardVariations = {
  bg1: css`
    background: url(${bg1});
  `,
  bg2: css`
    background: url(${bg2});
  `,
  bg3: css`
    background: url(${bg3});
  `,
  bg4: css`
    background: url(${bg4});
  `,
}

export const Container = styled(Link)<IStatsBoxSmallProps>`
  display: flex;
  width: ${props => props.width};
  ${props => bgCardVariations[props.bg]};
  background-color: ${props => props.theme.colors.bgBox};
  background-position: bottom right;
  background-position-y: 110%;
  background-position-x: right;
  background-repeat: no-repeat;
  height: 142px;
  padding: 1.1rem 1.2rem;
  border-radius: 12px;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  transition: all .3s ease-in-out;
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadowBox};

  &:hover {
    background-position-y: 100%;
    cursor: ${props => props.status === 'canceled' ? 'not-allowed' : 'pointer'};
  }

  &:hover footer {
    //margin-left: 10px;
  }

  > footer span {
    color: #fff;
  }

  &:hover div {
   // color: ${props => props.status === 'corrected' ? props.theme.colors.textColor : '#fff'};
  }

  > footer {
    transition: all .3s ease-in-out;
  }

  > footer span {
    background-color: ${props => props.status === 'in_correction' || 'in_confirmation' || 'pending' ? '#de8020' : null};
    background-color: ${props => props.status === 'canceled' ? '#ff0000' : null};

    border-radius: 4px;
    padding: 0 6px;
    font-size: 12px;
    font-weight: 600;

  }
`;

export const Points = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: normal;
  margin-top: -20px;

  > p {
    font-size: 2rem;
    font-weight: 700;
    line-height: .95;
    color: ${props => props.theme.colors.textColor};

    > span {
      font-size: .8rem;
      margin-left: 1px;
      display: inline-flex;
      align-items: center;

      color: #9aa4bf;
      font-weight: 700;
      text-transform: uppercase;

      > img {
        width: 15px;
        margin-left: 4px;
      }
    }
  }

  figure {
    margin-left: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #9aa4bf;
  }

  span:nth-child(2) {
    font-size: .75rem;
    font-weight: 700;
    position: relative;
    top: 1px;
  }

`;

export const Theme = styled.div`
  font-size: .9rem;
  font-weight: 700;
  min-height: 45px;
  max-height: 45px;
  line-height: 1.1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.textColor};
`;


