import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0 1rem 0;
  grid-template-columns: 1fr;

  border-radius: 12px;
  padding: 28px;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadow};

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 3fr;

    /* MOBILE */
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    > form {
      display: flex;
      width: 100%;
    }

    > div {
      > select {
        width: 100%;
        font-size: 1rem;
        background-color: ${props => props.theme.colors.bgBox};
        border: 1px solid #adafca30;;
        padding: 1rem;
        color: #a9a6c6;
        transition: border-color .2s ease-in-out;
        border-radius: 12px;
        font-weight: 600;
        cursor: pointer;
        font-size: 1rem;
      }
      > div, select, span {
        /* MOBILE */
        @media (max-width: 480px) {
          display: none;
        }
      }
      /* MOBILE */
      @media (max-width: 480px) {
          display: none;
      }
    }
  }
`;

export const ContentGrid2 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(3, 1fr);
  min-height: 200px;
  position: relative;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {}
  /* MOBILE */
  @media (max-width: 480px) {}
`;

export const SearchButton = styled.a`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 1;
  text-decoration: none;
  font-size: 1.2rem;
`;

export const Showing = styled.p`
  color: #9aa4bf95;
  padding: 2rem 0;
  margin-top: 2rem;
  text-align: center;
`;

export const TabsCrid = styled.div`
  margin: 2rem 0;

  > ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;

    > li {
      font-size: .875rem;
      font-weight: 700;
      cursor: pointer;
      background: none;
      padding-right: 1rem;
      border-radius: 0px;
      color: ${props => props.theme.colors.textColor};

      > button {
        padding: 1rem 2rem;
        text-transform: uppercase;
        font-weight: 700;
        color: ${props => props.theme.colors.textColor};
        background: none;
        opacity: .5;
        transition: opacity .3s ease-in-out 0s;

        &.active {
          opacity: 1;
          border-bottom: 4px solid #6e61c6;
        }
      }
    }
  }
`;

export const NotFound = styled.p`
  margin-top: 2rem;
  text-align: center;
  padding: 2rem;
  border-radius: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.textColor};
  background: ${props => props.theme.colors.bgBox};
`;
