import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

type ButtonProps = {
  id: string;
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
  is_photo: boolean;
  cancel_reason?: string;
};

const ButtonStatus: React.FC<ButtonProps> = ({ status, id, is_photo, cancel_reason }) => {
  return (
    <Container status={status} cancel_reason={cancel_reason}>
      {status === 'canceled' && <Link to="#">
        {status === 'canceled' && 'Cancelada'}
      </Link>}
      {status === 'pending' && <Link to={`/visualizar-redacao/${is_photo ? 'foto' : 'texto'}/${id}`}>
        {status === 'pending' && 'Aguardando Correção'}
      </Link>}
      {status === 'in_correction' && <Link to={`/visualizar-redacao/${is_photo ? 'foto' : 'texto'}/${id}`}>
        {status === 'in_correction' && 'Aguardando Correção'}
      </Link>}
      {status === 'in_confirmation' && <Link to={`/visualizar-redacao/${is_photo ? 'foto' : 'texto'}/${id}`}>
        {status === 'in_confirmation' && 'Aguardando Correção'}
      </Link>}
      {status === 'corrected' && <Link to={`/visualizar-redacao/${is_photo ? 'foto' : 'texto'}/${id}`}>
        {status === 'corrected' && 'Visualizar Redação'}
      </Link>}
    </Container>
  );
};

export default ButtonStatus;
