import styled from "styled-components";

interface FakeThumbnailProps {
  thumbnail: string;
  video: string;
}

export const Container = styled.div``;

export const FakeThumbnail = styled.div<FakeThumbnailProps>`
  position: relative;
  background: url(${(props) => props.thumbnail}) center center / cover no-repeat;
  border-radius: 12px;
  overflow: hidden;
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s ease-in-out 0s;
  padding: 1.5rem;
  cursor: pointer;


  &::before {
    content: "";
    position: absolute;
    inset: 0px;
    background-image: linear-gradient(transparent, rgb(2, 22, 20));
    opacity: 0.4;
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background: url(${(props) => `https://b-vz-e7274b3b-4ac.tv.pandavideo.com.br/${props.video}/preview.webp`}) center center / cover no-repeat;

    &::before {
      opacity: 1;
    }
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.primary};
    border: 6px solid #ffffff20;
    opacity: 0.85;
    padding: 2rem;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
`;
