import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

import AchievementBox from '../../../components/AchievementBox';

import firstAchievements from '../../../assets/enviar-redacao.png';
import nextAchievements from '../../../assets/badge2.png';

interface IAchievementsProps {
  achievements?: {
    id: string;
    icon: string;
    title: string;
    exp: number;
    description: string;
    requirement: string;
    acquired_at: string;
    icon_url: string;
  }[];
  total: number;
}

const LastAchievementsCards: React.FC = () => {
  const [achievements, setAchievements] = useState<IAchievementsProps>({} as IAchievementsProps);

    // GET LAST ACHIVEMENTS BADGES
    useEffect(() => {
      async function loadData() {
        try {
          //setLoading(true);
          const response = await api.get('/achievements/last', {
            params: { quantity: 1 }
          });

          console.log('LIST ACHIVEMENTS >>>>>>>>>>>>>>>>>>>');
          console.log(response.data);

          setAchievements(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          //setLoading(false);
        }
      }

      loadData();
    }, []);

  return (
    <>
        {achievements?.achievements && <AchievementBox
          background="bg1"
          icon={achievements?.achievements[0]?.icon_url || firstAchievements}
          title={achievements?.achievements[0]?.title ? 'Última Conquista Desbloqueada' : 'Desbloqueie conquistas' }
          description={achievements?.achievements[0]?.title || 'Que tal enviar sua primeira redação?' }
          buttonTextValue={achievements?.achievements[0]?.title ? 'Visualizar perfil' : 'Escrever redação'}
          href={achievements?.achievements[0]?.title ? '/minhas-conquistas' : '/temas'}
        />}
        <AchievementBox
          background="bg2"
          icon={nextAchievements}
          title="Próximas Conquistas"
          description="Realize os desafios acumule pontos!"
          buttonTextValue="Ver todas"
          href="/conquistas"
        />
    </>
  );
}

export default LastAchievementsCards;
