import React, { useState, useCallback, useEffect, useRef } from "react";

import api from "../../services/api";

import { useHistory } from "react-router-dom";
import { useCredit } from "../../hooks/credit";

import htmlParser from "react-html-parser";
import confetti from "canvas-confetti";
import Countdown, { CountdownRenderProps } from "react-countdown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TinySlider from "tiny-slider-react";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import SectionBanner from "../../components/SectionBanner";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import Icon from "../../components/Icon";
import LottieAnimation from "../../components/LottieAnimation";

import bannerindexIconImg from "../../assets/simulados-icon.png";

import clockAnimation from "../../assets/lottie-animations/clock.json";
import batteryAnimation from "../../assets/lottie-animations/battery.json";
import wifiAnimation from "../../assets/lottie-animations/wifi.json";
import checkAnimation from "../../assets/lottie-animations/check.json";
import poxa from "../../assets/lottie-animations/connection.json";

import {
  Container,
  ContentGrid1,
  FooterMenu,
  Question,
  InputGroup,
  SliderInnerAlert,
  CardQuestion,
  ModalFinishForTime,
} from "./styles";

import addZeroBefore from "../../utils/addZeroBefore";
import toHHMMSS from "../../utils/toHHMMSS";

interface IQuestionsProps {
  id: number;
  enunciado: string;
  opcoes: Opcoes;
  alternativa_correta: string;
  info_prova: {
    ano: string;
    material: string;
  };
  titulo: string;
  total: string;
  total_certas: string;
  total_erradas: string;
  corrected?: boolean;
  blank?: boolean;
  status?: "corrected" | "wrong" | "blank";
}

interface ITemplateAnswers {
  id: number;
  index: number;
  answer_checked: string;
  answer_correct: string;
  status: string;
}

interface Opcoes {
  [key: number]: {
    opcao: string;
    descricao: string;
  };
}

const Simulados: React.FC = () => {
  const history = useHistory();
  const { credits } = useCredit();

  const countDownRef = useRef<any>(null);

  const answerList = useRef<HTMLDivElement>(null);
  const carousel = useRef<any>(null);

  const [checkedAnswer, setCheckedAnswer] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [loadNextQuestion, setLoadNextQuestion] = useState(true);

  const [dataQuestions, setDataQuestions] = useState<IQuestionsProps[]>([]);
  const [checkedQuestions, setCheckedQuestions] = useState<ITemplateAnswers[]>(
    []
  );

  const [totalTestTimeFormated, setTotalTestTimeFormated] = useState("");
  const [loading, setLoading] = useState(false);
  const [countCorrects, setCountCorrects] = useState(0);

  const [questionIndex, setQuestionIndex] = useState(0);

  const [startTime, setStartTime] =
    useState<number>(999999999999999999999999999);

  //initialize simulado
  const handleInitialize = useCallback(() => {
    Swal.close();
    setStartTime(Date.now() + 3600000 * 2 + 1800000); // 2h30 de simulado
  }, []);

  // Regras slider modal
  const showModalRules = useCallback(() => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <SliderInnerAlert>
          <TinySlider
            ref={carousel}
            settings={{
              lazyload: true,
              nav: false,
              mouseDrag: false,
              loop: false,
              gutter: 1,
              autoplay: false,
              controlsContainer: "#customize-controls",
            }}
          >
            <div>
              <LottieAnimation
                options={{ animationData: clockAnimation }}
                height={200}
                width={200}
                scale="scale(0.8)"
              />
              <h1>4 simulados por mês</h1>
              <p>
                Você tem direito a realizar 4 simulados por mês. <br />
                Todo dia{" "}
                {credits.initialPeriod &&
                  format(new Date(credits.initialPeriod), "dd", {
                    locale: ptBR,
                  })}
                , seus créditos são renovados automaticamente.
              </p>
              <footer>
                <p>
                  <button
                    onClick={() => {
                      history.goBack();
                      setLoading(true);
                      Swal.close();
                    }}
                  >
                    fazer em outro momento
                  </button>
                </p>
                {/* @ts-ignore */}
                <p>
                  <button
                    onClick={() => {
                      carousel?.current?.slider.goTo("last");
                    }}
                  >
                    pular
                  </button>
                </p>
              </footer>
            </div>
            <div>
              <LottieAnimation
                options={{ animationData: batteryAnimation }}
                height={200}
                width={200}
              />
              <h1>Todo cuidado é pouco</h1>
              <p>
                Só inicie o simulado se tiver certeza que poderá termina-lo. Se
                por algum motivo você fechar o site (Plataforma), seus acertos
                não serão contabilizados e você perderá 1 simulado.
              </p>
              <footer>
                <p>
                  <button
                    onClick={() => {
                      history.push(`/dashboard`);
                      setLoading(true);
                      Swal.close();
                    }}
                  >
                    fazer em outro momento
                  </button>
                </p>
                {/* @ts-ignore */}
                <p>
                  <button
                    onClick={() => {
                      carousel?.current?.slider.goTo("last");
                    }}
                  >
                    pular
                  </button>
                </p>
              </footer>
            </div>

            <div>
              <LottieAnimation
                options={{ animationData: wifiAnimation }}
                height={200}
                width={200}
                scale="scale(2)"
              />
              <h1>Conexão com a internet</h1>
              <p>
                Certifique-se de ter uma boa conexão com a internet. Você não
                terá outra chance para refazer o mesmo simulado.
              </p>
              <footer>
                <p>
                  <button
                    onClick={() => {
                      history.push(`/dashboard`);
                      setLoading(true);
                      Swal.close();
                    }}
                  >
                    fazer em outro momento
                  </button>
                </p>
                {/* @ts-ignore */}
                <p>
                  <button
                    onClick={() => {
                      carousel?.current?.slider.goTo("last");
                    }}
                  >
                    pular
                  </button>
                </p>
              </footer>
            </div>

            <div>
              <LottieAnimation
                options={{ animationData: checkAnimation }}
                height={200}
                width={200}
              />
              <p style={{ justifyContent: "center" }}>
                <button className="outline" onClick={handleInitialize}>
                  Iniciar Simulado
                </button>
              </p>
              <aside>
                <p>
                  <button
                    style={{
                      background: "none",
                      color: "#fff",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      history.push(`/dashboard`);
                      setLoading(true);
                      Swal.close();
                    }}
                  >
                    fazer em outro momento
                  </button>
                </p>
              </aside>
            </div>
          </TinySlider>
          <ul
            className="controls"
            id="customize-controls"
            aria-label="Carousel Navigation"
          >
            <li className="prev" data-controls="prev" aria-controls="customize">
              <Icon
                icon="icon-icon-small-arrow"
                size="1rem"
                margin="0 5px"
                color="#3e3f5e"
              />
            </li>
            <li className="next" data-controls="next" aria-controls="customize">
              <Icon
                icon="icon-icon-small-arrow"
                size="1rem"
                margin="0 5px"
                color="#3e3f5e"
              />
            </li>
          </ul>
        </SliderInnerAlert>
      ),
      title: "REGRAS DO SIMULADO",
      background: "#d14d4d",
      backdrop: "#6e61c6",
      showConfirmButton: false,
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      padding: 32,
    });
  }, [history, credits.initialPeriod, handleInitialize]);

  const showModalBlock = useCallback(() => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <SliderInnerAlert>
          <div>
            <LottieAnimation
              options={{ animationData: poxa }}
              height={200}
              width={200}
              scale="scale(1)"
            />
            <h2>Você já realizou 4 simualdos esse mês.</h2>
            <p>
              Você poderá fazer simulados a partir do dia{" "}
              {credits.initialPeriod &&
                format(new Date(credits.initialPeriod), "dd", { locale: ptBR })}
              .
            </p>
            <footer>
              <p>
                <button
                  onClick={() => {
                    history.goBack();
                    setLoading(true);
                    Swal.close();
                  }}
                >
                  Fazer em outro momento
                </button>
              </p>
            </footer>
          </div>
        </SliderInnerAlert>
      ),
      background: "#d14d4d",
      backdrop: "#6e61c6",
      showConfirmButton: false,
      showCloseButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      padding: 32,
    });
  }, [history, credits.initialPeriod]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        //setLoading(true);
        const response = await api.get("/questions/random");

        setDataQuestions(response.data);
        showModalRules();
      } catch (error) {
        console.log(error);
        showModalBlock();
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [showModalRules, showModalBlock]);

  const handleSumbitResult = useCallback(async () => {
    try {
      setLoading(true);

      //setLoading(true);
      await api.post("/practice-tests", {
        total: countCorrects,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [countCorrects]);

  //finalizar simulado por tempo
  const handleFinishForTimer = useCallback(() => {
    setReadOnly(true);

    //submit result user
    handleSumbitResult();

    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        <ModalFinishForTime>
          <p>
            Poxinha, você acertou {countCorrects} de {dataQuestions.length}{" "}
            questões!
          </p>
        </ModalFinishForTime>
      ),
      title: "TEMPO ESGOTADO!",
      background: "#6e61c6",
      backdrop: "#4b3cad",
      showConfirmButton: true,
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      padding: 32,
      confirmButtonText: "Voltar para Dashboard",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
        history.push("/dashboard");
      }
    });
  }, [countCorrects, dataQuestions.length, history, handleSumbitResult]);

  const renderer = useCallback(
    ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
      if (completed) {
        // Render a completed state
        return "";
      }

      // Render a countdown
      return (
        <span>
          {`0${hours}`.slice(-2)}:{`0${minutes}`.slice(-2)}:
          {`0${seconds}`.slice(-2)}
        </span>
      );
    },
    []
  );

  const handleChangeAnswer = useCallback(
    (answer: string) => {
      if (!readOnly) {
        setCheckedAnswer(answer);
      }
    },
    [readOnly]
  );

  const loadConfetti = useCallback(() => {
    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2,
        },
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2,
        },
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2,
        },
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2,
        },
      });
    }, 0);

    setTimeout(() => {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2,
        },
      });
    }, 0);
  }, []);

  const handleSaveCheckedAnswer = useCallback(() => {
    const currentQuestion = {
      id: dataQuestions[questionIndex].id,
      index: questionIndex,
      answer_checked: checkedAnswer,
      answer_correct: dataQuestions[questionIndex].alternativa_correta,
      status: "answered",
    };

    // check if question index contain in array
    if (
      !checkedQuestions.find(
        (question) => question.index === currentQuestion.index
      )
    ) {
      setCheckedQuestions((oldQuestions) => [...oldQuestions, currentQuestion]);
    }
  }, [checkedAnswer, checkedQuestions, dataQuestions, questionIndex]);

  const navigationToQuestion = useCallback(
    (index: number) => {
      setQuestionIndex(index);

      if (checkedQuestions.find((question) => question.index === index)) {
        const questionChecked = checkedQuestions.find(
          (question) => question.index === index
        );

        if (!questionChecked) {
          return;
        }

        setReadOnly(true);
        setCheckedAnswer(questionChecked?.answer_checked);
      } else {
        setReadOnly(false);
        setCheckedAnswer("");
      }
    },
    [checkedQuestions]
  );

  // Get next question
  const getNextQuestion = useCallback(() => {
    if (checkedAnswer === "") {
      return;
    }

    handleSaveCheckedAnswer();

    if (checkedQuestions.length === dataQuestions.length) {
      return;
    }

    if (questionIndex + 1 === dataQuestions.length) {
      const isChecked = dataQuestions.findIndex((questions) => {
        if (checkedQuestions.find((question) => question.id === questions.id)) {
          return false;
        }
        return true;
      });

      setQuestionIndex(isChecked);
      setReadOnly(false);
      setCheckedAnswer("");

      return;
    }

    window.scrollTo(0, 0);
    setLoadNextQuestion(false);
    setTimeout(() => setLoadNextQuestion(true), 800);
    navigationToQuestion(questionIndex + 1);
  }, [
    checkedAnswer,
    handleSaveCheckedAnswer,
    navigationToQuestion,
    questionIndex,
    checkedQuestions,
    dataQuestions,
  ]);

  const checkCorrectQuestion = useCallback(() => {
    // Check if inputs are empty
    if (!checkedAnswer) {
      let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <h2 style={{ lineHeight: 1 }}>
            Poxinha, você esqueceu de assinalar uma resposta!
          </h2>
        ),
        icon: "error",
        background: "#6e61c6",
        backdrop: "rgba(110, 97, 198, .1)",
        confirmButtonColor: "#03bb85",
        showConfirmButton: true,
        confirmButtonText: "Ops!",
      });

      return;
    }

    handleSaveCheckedAnswer();

    // Check if answr is correct
    const correctAnswerValue = dataQuestions[questionIndex].alternativa_correta;
    if (checkedAnswer === correctAnswerValue) {
      setCountCorrects((oldValue) => oldValue + 1);
      loadConfetti();
      dataQuestions[questionIndex].corrected = true;
      dataQuestions[questionIndex].status = "corrected";
    } else {
      dataQuestions[questionIndex].corrected = false;
      dataQuestions[questionIndex].status = "wrong";
    }

    setReadOnly(true);

    // verifica se ja terminou
    if (checkedQuestions.length + 1 === dataQuestions.length) {
      const timeInMileseconds =
        3600000 * 2 + 1800000 - countDownRef.current.state.timeDelta.total;
      setTotalTestTimeFormated(toHHMMSS(timeInMileseconds));

      //submit result user
      handleSumbitResult();

      let MySwal = withReactContent(Swal);
      MySwal.fire({
        html: (
          <h2 style={{ lineHeight: 1 }}>
            Poxinha, você acertou {countCorrects} de {dataQuestions.length}{" "}
            questões!
          </h2>
        ),
        title: "RESULTADO FINAL!",
        background: "#6e61c6",
        backdrop: "rgba(110, 97, 198, .1)",
        confirmButtonColor: "#03bb85",
        showConfirmButton: true,
        showCloseButton: true,
        allowEscapeKey: true,
        confirmButtonText: "Revisar simulado!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
        }
      });

      return;
    }
  }, [
    checkedAnswer,
    dataQuestions,
    loadConfetti,
    questionIndex,
    handleSaveCheckedAnswer,
    checkedQuestions,
    countCorrects,
    handleSumbitResult,
  ]);

  return (
    <>
      {loading && <Loader />}
      <Container>
        <SectionBanner
          icon={bannerindexIconImg}
          title="Simulado ENEM"
          subTitle="Você pode fazer 4 simulados por mês. Aproveite!"
        />
        <ContentGrid1>
          <section
            style={
              !loadNextQuestion
                ? { height: "400px", overflow: "hidden" }
                : { height: "auto" }
            }
          >
            {!loadNextQuestion && <Loader isFixed={false} />}
            <Question>
              <header>
                <div>
                  <h1>
                    Questão {questionIndex + 1}
                    <span>/{dataQuestions.length}</span>
                  </h1>
                </div>
                <div>
                  <p>{dataQuestions[questionIndex]?.info_prova.material}</p>
                </div>
              </header>
              <main>
                <div>{htmlParser(dataQuestions[questionIndex]?.enunciado)}</div>
              </main>
              <h1>Alternativas</h1>
              <footer
                ref={answerList}
                style={
                  readOnly ? { cursor: "not-allowed" } : { cursor: "default" }
                }
              >
                <InputGroup
                  readOnly={readOnly}
                  value="A"
                  correctAnswer={
                    dataQuestions[questionIndex]?.alternativa_correta
                  }
                  checkedAnswer={checkedAnswer}
                >
                  <input
                    id="radio1"
                    name="radio-1"
                    type="radio"
                    checked={checkedAnswer === "A"}
                    onChange={() => {
                      handleChangeAnswer("A");
                    }}
                  />
                  <label htmlFor="radio1">
                    {htmlParser(
                      dataQuestions[questionIndex]?.opcoes[1].descricao
                    )}
                  </label>
                </InputGroup>
                <InputGroup
                  readOnly={readOnly}
                  value="B"
                  correctAnswer={
                    dataQuestions[questionIndex]?.alternativa_correta
                  }
                  checkedAnswer={checkedAnswer}
                >
                  <input
                    id="radio2"
                    name="radio-1"
                    type="radio"
                    checked={checkedAnswer === "B"}
                    onChange={() => {
                      handleChangeAnswer("B");
                    }}
                  />
                  <label htmlFor="radio2">
                    {htmlParser(
                      dataQuestions[questionIndex]?.opcoes[2].descricao
                    )}
                  </label>
                </InputGroup>
                <InputGroup
                  readOnly={readOnly}
                  value="C"
                  correctAnswer={
                    dataQuestions[questionIndex]?.alternativa_correta
                  }
                  checkedAnswer={checkedAnswer}
                >
                  <input
                    id="radio3"
                    name="radio-1"
                    type="radio"
                    checked={checkedAnswer === "C"}
                    onChange={() => {
                      handleChangeAnswer("C");
                    }}
                  />
                  <label htmlFor="radio3">
                    {htmlParser(
                      dataQuestions[questionIndex]?.opcoes[3].descricao
                    )}
                  </label>
                </InputGroup>
                <InputGroup
                  readOnly={readOnly}
                  value="D"
                  correctAnswer={
                    dataQuestions[questionIndex]?.alternativa_correta
                  }
                  checkedAnswer={checkedAnswer}
                >
                  <input
                    id="radio4"
                    name="radio-1"
                    type="radio"
                    checked={checkedAnswer === "D"}
                    onChange={() => {
                      handleChangeAnswer("D");
                    }}
                  />
                  <label htmlFor="radio4">
                    {htmlParser(
                      dataQuestions[questionIndex]?.opcoes[4].descricao
                    )}
                  </label>
                </InputGroup>
                <InputGroup
                  readOnly={readOnly}
                  value="E"
                  correctAnswer={
                    dataQuestions[questionIndex]?.alternativa_correta
                  }
                  checkedAnswer={checkedAnswer}
                >
                  <input
                    id="radio5"
                    name="radio-1"
                    type="radio"
                    checked={checkedAnswer === "E"}
                    onChange={() => {
                      handleChangeAnswer("E");
                    }}
                  />
                  <label htmlFor="radio5">
                    {htmlParser(
                      dataQuestions[questionIndex]?.opcoes[5].descricao
                    )}
                  </label>
                </InputGroup>
              </footer>
            </Question>
          </section>
          <section>
            <main>
              {dataQuestions.map((question, index) => {
                return (
                  <CardQuestion
                    key={question.id}
                    current={
                      dataQuestions[index].id ===
                      dataQuestions[questionIndex].id
                        ? true
                        : false
                    }
                    status={dataQuestions[index].status || "blank"}
                    blank={dataQuestions[index].corrected ? false : true}
                    onClick={() => navigationToQuestion(index)}
                  >
                    <p>{index + 1}</p>
                  </CardQuestion>
                );
              })}
            </main>
          </section>
        </ContentGrid1>
        <Footer />
      </Container>
      {!loading && (
        <FooterMenu>
          <section>
            <p>
              {checkedQuestions.length === dataQuestions.length
                ? "Tempo de prova"
                : "Tempo restante"}
            </p>
            {checkedQuestions.length !== dataQuestions.length ? (
              <Countdown
                ref={countDownRef}
                controlled={false}
                date={startTime}
                renderer={renderer}
                onComplete={handleFinishForTimer}
              />
            ) : (
              <span>{totalTestTimeFormated}</span>
            )}
          </section>
          <section>
            <p>Acertos</p>
            <span>{addZeroBefore(countCorrects)}</span>
          </section>
          {checkedQuestions.length !== dataQuestions.length && (
            <section>
              {readOnly ? (
                <Button color="outline" onClick={getNextQuestion}>
                  PRÓXIMA QUESTÃO{" "}
                  <Icon
                    icon="icon-icon-small-arrow"
                    margin="0 0 0 5px"
                    size="8px"
                  />
                </Button>
              ) : (
                <Button color="success" onClick={checkCorrectQuestion}>
                  RESPONDER QUESTÃO
                </Button>
              )}
            </section>
          )}
        </FooterMenu>
      )}
    </>
  );
};

export default Simulados;
