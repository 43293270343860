import React from 'react';

import { format, getDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

import { Container } from './styles';

interface ISingleEventCardProps {
  start: Date;
  end: Date;
  title: string;
  description: string;
  link?: string;
  cover_url?: string;
  onClick(): void;
}

const SingleEventCard: React.FC<ISingleEventCardProps> = ({ start, end, title, description, link, cover_url, onClick}) => {
  return (
    <Container cover_url={cover_url}>
      <header>
        {
          start === end ?
          <div>
            <aside>{getDate(new Date(start))}</aside>
            <aside>{format(new Date(start), 'MMM', { locale: ptBR })}</aside>
          </div>
          :
          <>
            <div>
              <aside>{getDate(new Date(start))}</aside>
              <aside>{format(new Date(start), 'MMM', { locale: ptBR })}</aside>
            </div>
            <div>
              <aside>{getDate(new Date(end))}</aside>
              <aside>{format(new Date(end), 'MMM', { locale: ptBR })}</aside>
            </div>
          </>
        }
      </header>
      <main>
        <h1>{title}</h1>
        {
          start === end ?
            <h4>{format(new Date(start), "dd/MM 'às' HH:mm")}</h4>
          :
            <h4>{format(new Date(start), "dd/MM 'às' HH:mm")} até {format(new Date(end), "dd/MM 'às' HH:mm")}</h4>
        }
        <p>{description}</p>
        <div>
          {link &&
            <a href={link.substr(0,4) === 'http' ? link : `https://${link}`} target="_blank" rel="noreferrer">
              <Icon icon="icon-icon-share" margin="0 .5rem 0 0" />
               {link}
            </a>
          }
        </div>
      </main>
      <footer>
        <Button color="outline" onClick={onClick}>+ Ver mais detalhes</Button>
      </footer>
    </Container>
  );
}

export default SingleEventCard;
