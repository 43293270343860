import styled from 'styled-components';

export const Container = styled.div`

`;


export const Live = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;
  margin-top: -25px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding: 1rem;
    width: 100%;

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff26;
      padding: 5px 10px;
      backdrop-filter: blur(5px);
      border-radius: 15px;

      > input {
        margin-right: 8px;
        margin-top: 2px;
      }
    }

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      font-size: 27px;
      background: rgb(106 85 157 / 22%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transition: all .4s;

      &::before {
        position: absolute;
        content: '×';
        top: 7px;
      }
    }
  }

  > img {
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
  }
`;


export const ModalPlan = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;
  margin-top: -25px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    padding: 1rem;
    width: 100%;

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff3b;
      padding: 5px 10px;
      backdrop-filter: blur(5px);
      border-radius: 15px;

      > input {
        margin-right: 8px;
      }
    }

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      font-size: 27px;
      background: rgb(106 85 157 / 22%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transition: all .4s;

      &::before {
        position: absolute;
        content: '×';
        top: 7px;
      }
    }
  }

  > a {
    text-decoration: none;

    > img {
      width: 100%;
      border-radius: 12px;
      cursor: pointer;
    }
  }

`;

export const FloatValidation = styled.div`
  position: absolute;
  z-index: 99999;
  top: 1rem;
  right: 1rem;
  padding: 7px 12px;
  backdrop-filter: blur(5px);
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
`;
