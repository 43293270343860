import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import Icon, { IIconType } from '../Icon';

import { useField } from '@unform/core';

import InputMask from "react-input-mask";

import { Container, Label, Error } from './styles';

interface IInputValidateMaskProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
  autoFocus?: boolean;
  icon?: IIconType;
  mask: string;
  name: string;
}

const InputValidateMask: React.FC<IInputValidateMaskProps> = ({label, name, padding, margin, onlyWhite, autoFocus, icon, mask, ...rest}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <>
      <Container padding={padding} margin={margin}>
        {icon && <Icon icon={icon} />}
        <InputMask name={name} type="text" mask={mask} ref={inputRef} defaultValue={defaultValue} {...rest} />
        <Label onlyWhite={onlyWhite}>{label}</Label>
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
}

export default InputValidateMask;
