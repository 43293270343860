import styled from 'styled-components';
import { Link } from 'react-router-dom';

import bgCategoty1 from '../../assets/bg-categories-1.png';

interface IVideoCategoryBoxProps {
  background: string;
  bgImg: string;
  isLocked?: boolean;
}

export const LinkVideo = styled(Link)`
  text-decoration: none;
`;

export const Container = styled.div<IVideoCategoryBoxProps>`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 1.8fr 1.2fr;
  padding: 0 0 0 0;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);

  //background: url(${props => props.bgImg ? props.bgImg : bgCategoty1}) no-repeat 100% 0, ${props => props.background};
  background: ${props => props.background};
  background-size: contain;
  cursor: ${props => props.isLocked ? 'not-allowed' : 'pointer'};

  > aside {
    padding: 2rem 0 2rem 2rem;
    z-index: 9;

    > h1 {
      color: #fff !important;
      font-size: 1.5rem !important;
      margin-bottom: 0 !important;
      font-weight: 700 !important;
      line-height: 1 !important;

      > img {
        width: 16px;
        display: ${props => props.isLocked ? 'unset' : 'none'};
      }
    }

    > h2 {
      margin-top: 4px !important;
      color: #fff !important;
      font-size: .875rem !important;
      font-weight: 500 !important;
      line-height: 1 !important;
      max-width: 215px !important;
    }

    > p {
      display: inline-block !important;
      height: 20px !important;
      margin-top: 26px !important;
      margin-bottom: 0px !important;
      margin-right: 5px !important;
      padding: 0 8px !important;
      border-radius: 200px !important;
      background-color: #fff !important;
      font-size: .75rem !important;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      line-height: 20px !important;
      color: #4f8dff !important;
    }
  }

  > main {
    margin: -50px 3px -6px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 0;

    > img {
      position: absolute;
      margin: 6px -27px;
    }
  }

`;
