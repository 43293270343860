import React, { useEffect, useState } from 'react';

import ReactStars from 'react-stars';
import 'react-toastify/dist/ReactToastify.css';

import NoSignalPlayer from './NoSignalPlayer';
import Footer from '../../components/Footer';

import viewIcon from '../../assets/view.svg';

import { Container, ContentGrid1, YoutubeVideoContainer } from './styles';
import axios from 'axios';
import NextVideoTumbnailsButton from '../../components/NextVideoTumbnailsButton';
import badgeAfiliates from '../../assets/badge-conquistas.png';
import AchievementBox from '../../components/AchievementBox';

interface YoutubeApiRequest {
  kind: string;
  etag: string;
  items?: (ItemsEntity)[];
  pageInfo: PageInfo;
}
interface ItemsEntity {
  kind: string;
  etag: string;
  id: string;
  snippet: Snippet;
}
interface Snippet {
  publishedAt: string;
  channelId: string;
  title: string;
  description: string;
  thumbnails: Thumbnails;
  channelTitle: string;
  playlistId: string;
  position: number;
  resourceId: ResourceId;
  videoOwnerChannelTitle: string;
  videoOwnerChannelId: string;
}
interface Thumbnails {
  default: ThumbSizes;
  medium: ThumbSizes;
  high: ThumbSizes;
  standard: ThumbSizes;
  maxres: ThumbSizes;
}
interface ThumbSizes {
  url: string;
  width: number;
  height: number;
}
interface ResourceId {
  kind: string;
  videoId: string;
}
interface PageInfo {
  totalResults: number;
  resultsPerPage: number;
}



const Afiliados: React.FC = () => {

  const [signal, setSignal] = useState(true);
  const [youtubeVideos, setYoutubeVideos] = useState({} as YoutubeApiRequest);
  const [currentVideo, setCurrentVideo] = useState(0);

  const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100';
  const PLAYLIST_ID = 'PL778FK0pRijObPjNOp44-8hOaeYOS0MiM'

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get<YoutubeApiRequest>(`${YOUTUBE_API_URL}&playlistId=${PLAYLIST_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
        console.log(response.data);
        setYoutubeVideos(response.data);
        setCurrentVideo(0);
      } catch (error) {
        console.log(error)
      }
    })();
  }, []);

  const cleanTitle = (value: string) => {
    return value.replaceAll('CURSO GRATUITO:', '');
  }
  const cleanTitle2 = (value: string) => {
    return value.replaceAll('l CURSO GRATUITO REDAÇÃO EM MOVIMENTO', '');
  }

  return (
    <Container>
      <h1>{youtubeVideos.items && youtubeVideos?.items[currentVideo].snippet.title}</h1>
      <ContentGrid1>
        <aside>
          <main>
            {signal ?
              <YoutubeVideoContainer>
                {youtubeVideos.items &&
                <iframe
                  src={`https://www.youtube.com/embed/${youtubeVideos.items[currentVideo].snippet.resourceId.videoId}?autoplay=1`}
                  frameBorder="0"
                  title="Live"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />}
              </YoutubeVideoContainer>
              :
              <YoutubeVideoContainer>
                <NoSignalPlayer />
              </YoutubeVideoContainer>
            }
          </main>
          <footer>
            <aside>
              {signal ?
                <div>
                  <img src={viewIcon} alt="Visualizações" />
                  <span>Comentários no Youtube</span>
                </div>
                :
                <div>
                  <img src={viewIcon} alt="Visualizações" />
                  <span>Poxinha, verifique em seu calendário a data da próxima live</span>
                </div>
              }
            </aside>
            <aside>
              <main />
              <ReactStars
                key={1}
                count={5}
                size={20}
                color1="#adafca"
                color2="#b9993a"
                half={false}
                value={5} //puxar do banco aqui
                onChange={(new_rating: number) => {
                  //
                }}
              />
            </aside>
          </footer>
        </aside>
        <aside>
          {youtubeVideos.items && youtubeVideos.items.map((video, index) => (
            <NextVideoTumbnailsButton
              key={index}
              lessonOrder={index + 1}
              thumbnailUrl={video.snippet.thumbnails.medium.url}
              lessonTitle={cleanTitle(cleanTitle2(video.snippet.title))}
              lessonDuration={'01:11:56'}
              progress={100}
              current={index === currentVideo}
              onClick={() => setCurrentVideo(index)}
            />
          ))}
        </aside>
        <AchievementBox
          background="bg4"
          icon={badgeAfiliates}
          title="Programa de afiliados"
          description="Preencha com seus dados e enviaremos um e-mail com a confirmação do cadastro."
          buttonTextValue="Inscreva-se"
          href="/cadastro"
          pulseAnimation={false}
        />
      </ContentGrid1>
      <Footer />
    </Container>
  );
}

export default Afiliados;
