import React, { useCallback, useState } from 'react';

import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { convertToRaw, EditorState, ContentState } from 'draft-js';

// @ts-ignore
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from '../../../components/Button';

import { Container } from './styles';

interface IWysiwygSupportProps {
  title?: string;
  placeholder?: string;
  maxLength: number;
  ticket_id: string;
  setAnswers: React.Dispatch<React.SetStateAction<any[]>>;
}

const WysiwygSupport: React.FC<IWysiwygSupportProps> = ({ title, placeholder, maxLength, ticket_id, setAnswers }) => {
  const { user } = useAuth();

  const [editor, setEditor] = useState<EditorState>();

  async function uploadImageCallBack(file: File) {
    try {
      if(file.size > 1024 * 1024 * 3) {
        throw new Error('exceeded-size-limit');
      }
      const data = new FormData();
      data.append("image", file);

      const response = await api.patch('/tickets', data);

      return { data: { link: response.data } };
    } catch(err) {
      console.log(err);

      if(err.message === 'exceeded-size-limit') {
        toast.error('😢 Tamanho da imagem excedido. Máximo de 3MB!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const handleEditorStateChange = (editorState: EditorState) => {
    if (editor) {
      const contentState = editorState.getCurrentContent();
      const oldContent = editor.getCurrentContent();

      if(contentState === oldContent || contentState.getPlainText().length <= maxLength) {
          setEditor(editorState);
      } else {
          const editorState = EditorState.moveFocusToEnd(
              EditorState.push(
                  editor,
                  ContentState.createFromText(oldContent.getPlainText()),
                  'undo',
              )
          );
        setEditor(editorState);
      }
    } else {
      setEditor(editorState);
    }
  }

  const handleSubmitReply = useCallback(async (value: EditorState | any) => {
    const content = convertToRaw(value.getCurrentContent());
      console.log(draftToMarkdown(content));

    try {
      const response = await api.post(`/tickets/${ticket_id}/answer`, {
        text: draftToMarkdown(content),
      });

      console.log(response.data);

      const answer = {
        ...response.data,
        user: {
          role: user.role,
          exp: user.exp,
          role_name: user.role_name,
          show_name: user.show_name,
          level: user.level,
          current_exp: user.current_exp,
          avatar_url: user.avatar_url,
          career: user.career,
        },
      }

      setAnswers(oldAnswers => [...oldAnswers, answer]);

      setEditor(EditorState.createEmpty());
      let modal = withReactContent(Swal);
      modal.fire({
        html: (
          <div>
            <h1>Sua resposta foi enviada com sucesso!</h1>
            <h4>Enviaremos um e-mail quando o seu chamado for respondido 😊</h4>
          </div>
        ),
        text: 'Em breve você será respondido.',
        icon: 'success',
        background: '#6e61c6',
        backdrop: 'rgba(110, 97, 198, .1)',
        confirmButtonColor: '#03bb85',
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.scrollTo(0, 0);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [ticket_id, setAnswers, user]);

  return (
    <Container>
      <header>
        <p>{title ? title : 'Escreva uma resposta'}</p>
      </header>
      <Editor
        editorState={editor}
        onEditorStateChange={(editorState: EditorState) => {handleEditorStateChange(editorState)}}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        handlePastedText={() => false}
        localization={{
          locale: 'pt',
        }}
        placeholder={placeholder ? placeholder : ''}
        stripPastedStyles={true}
        toolbar={{
          options: ['inline', 'list', 'emoji', 'image'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            linkCallback: undefined
          },
          emoji: {
            className: 'add',
            component: undefined,
            popupClassName: undefined,
            emojis: [
              '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
              '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
              '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
              '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
              '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
              '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
              '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
              '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
              '✅', '❎', '💯',
            ],
          },
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              width: '100%',
            },
          },
        }}
      />
      <footer>
        <div>
          <p>{editor ? editor?.getCurrentContent().getPlainText().length : 0}/{maxLength}</p>
        </div>
        <div>
          <Button
            color={editor && editor?.getCurrentContent().getPlainText().length > 0 ? 'primary' : 'none'}
            disabled={editor && editor?.getCurrentContent().getPlainText().length > 0 ? false : true}
            onClick={() => handleSubmitReply(editor)}>
            Responder
          </Button>
        </div>
      </footer>
    </Container>
  );
}

export default WysiwygSupport;
