import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Mark, MarkPhotoProvider } from '../../../hooks/mark-photo';

import Icon from '../../../components/Icon';
import WordingContent from '../WordingContent';

import { Container, Modal } from './styles';

interface iModalOpenCorrectionProps {
  photo_url: string;
  initialMarks: Mark[];
  status: string;
}

const ModalOpenCorrection: React.FC<iModalOpenCorrectionProps> = ({ photo_url, initialMarks, status }) => {
  const mainRef = useRef<HTMLElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenCorrection = useCallback(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    const root = document.querySelector('html');

    if(!root) return;

    root.style.overflow = isOpen ? 'hidden' : 'scroll';
  }, [isOpen]);

  return (
    <Container>
      <Modal isOpen={isOpen} ref={mainRef} status={status}>
        <div onClick={() => setIsOpen(false)}>
          <Icon icon="icon-icon-cross-thin" color="#3e3f5e" />
        </div>

        <main>
          <MarkPhotoProvider>
            <WordingContent photo_url={photo_url} initialMarks={initialMarks} parent={mainRef} />
          </MarkPhotoProvider>
        </main>
        <p>Para melhor experiência, recomendamos a ulitilização de um computador para visualizar a correção.</p>
      </Modal>
      <div onClick={() => status === 'corrected' && handleOpenCorrection()}>
        <button>{status === 'corrected' ? 'Visualizar Correção' : 'Aguardando Correção'}</button>
      </div>
      <img src={photo_url} alt="Foto da redação" />
    </Container>
  );
}

export default ModalOpenCorrection;
