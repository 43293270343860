import React, { useRef, useEffect, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core'

import Icon from '../Icon';

import { Container, Label, Input, Erro } from './styles';

interface IInputAnimateProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
}

const InputAnimate2: React.FC<IInputAnimateProps> = ({
  name,
  label,
  padding,
  margin,
  onlyWhite,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    })
  }, [fieldName, registerField]);

  return (
    <Container padding={padding} margin={margin}>
      <Input ref={inputRef} onlyWhite={onlyWhite} defaultValue={defaultValue} {...rest} />
      <Label onlyWhite={onlyWhite}>{label}</Label>
      {error && <Erro><Icon icon="icon-icon-info" />  {error}</Erro>}
    </Container>
  );
}

export default InputAnimate2;
