import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* TABLET */
  @media (max-width: 768px) {}

  /* MOBILE */
  @media (max-width: 480px) {}

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      display: flex;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      padding: 1rem .4rem;
      font-size: .8rem;
      width: 200px;
    }
  }

`;

export const SearchButton = styled.button`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 1;
  text-decoration: none;
  font-size: 1.2rem;
`;

export const SendStatiscs = styled.div`
  text-align: center;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    width: 1px;
    height: 40px;
    background-color: #eaeaf580;
    position: absolute;
    top: 9px;
    right: 0;
    display: block;
    box-sizing: border-box;
  }

  &:last-child::after {
    display: none;
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  }

  > h5 {
    color: #adafca;
    line-height: 1;
    font-size: .75rem;
    text-transform: uppercase;
  }

  /* TABLET */
  @media (max-width: 768px) {
    display: none;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    display: none;
  }
`;


export const RenoveBox = styled.div`
  padding: 0px 12px !important;
  border-radius: 6px !important;
  right: 19px !important;
  background: ${props => props.theme.colors.bgBox} !important;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  line-height: 44px;
  position: absolute;
  top: -8px;
  right: 70px;
  border-radius: 6px;
  font-weight: 700;
  line-height: 32px;
  background-color: #ffffff;
  box-shadow: 3px 5px 20px 0 rgba(94,92,154,0.07);
  > span {
    font-size: .65rem;
    color: #bfc1d6;
    text-transform: uppercase;
  }
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE 2 */
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {}
  /* MOBILE */
  @media (max-width: 480px) {}
`;

export const TabsCrid = styled.div`
  margin: 2rem 0;

  > section {
    position: relative;
    min-height: 200px;
  }

  > ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 2rem 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;

    > li {
      font-size: .875rem;
      font-weight: 700;
      cursor: pointer;
      background: none;
      padding-right: 1rem;
      border-radius: 0px;
      color: ${props => props.theme.colors.textColor};

      > button {
        padding: 1rem 2rem;
        text-transform: uppercase;
        font-weight: 700;
        color: ${props => props.theme.colors.textColor};
        background: none;
        opacity: .5;
        transition: opacity .3s ease-in-out 0s;

        &.active {
          opacity: 1;
          border-bottom: 4px solid #6e61c6;
        }
      }
    }
  }
`;

export const NotFound = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.textColor};
  background: ${props => props.theme.colors.bgBox};

  > a {
    background: #6e61c6;
    display: flex;
    height: 48px;
    padding: 0 1.2rem;
    margin-top: 1rem;
    border-radius: 8px;
    color: #ffffff;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    align-items: center;
    justify-content: center;
  }
`;

export const Showing = styled.div`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;
