import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import InputAnimate from '../../components/InputAnimate';

import { Container, Logo, LogoMobile, Form, LandingInfo, LandingForm, ContentIntro, FormBox, ContentGrid1 } from './styles';

import logoPoxalulu from '../../assets/logo-poxalulu.svg';
import rocketImg from '../../assets/rocket.png';

const RedefinirSenha: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [loading, setLoading] = useState(false);

  const handleResetPassword = useCallback((e) => {
    e.preventDefault();
    if(password.length < 6 || password2.length < 6) {
      toast.error('🔒 Poxinha, sua senha deve ter no mínimo 6 caracteres!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if((password !== password2)) {
      toast.error('🤷‍♂️ Poxinha, as senhas escolhidas não conferem!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      loadData();
    }

    async function loadData() {
      try {
        setLoading(true);
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('token');
        console.log(token)

        if (!token) {
          history.push('/');
          return;
        }

        const response = await api.post(`/password/reset`, {
          "token": token,
          "password": password,
          "password_confirmation": password2
        });

        console.log('senha alterada com sucesso >>>>> ', response.data);

        toast.success('👌 Senha alterada com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        history.push('/');

      } catch (err) {
        console.log(err);
        const { error } = err.response?.data;
        console.log(error);

        let message = '';

        switch(error) {
          case 'token-not-found':
            message = 'Token inválido!'
            break;

          case 'token-expired':
            message = 'Token expirado. Por favor, refaça o processo!';
            break;

          case 'user-not-found':
            message = 'Usuário não encontrado!';
            break;

          default:
            message = '😢 Poxa, algo deu errado! Tente novamente mais tarde.';
        }

        toast.error(message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false);
      }
    }


  }, [password, password2, location.search, history]);

    return(
        <Container>
          <ContentGrid1>
            <LandingInfo>
              <ContentIntro>
                <Logo>
                  <img src={logoPoxalulu} alt="Logo Poxalulu"/>
                </Logo>
                <h1>Alterar senha</h1>
                <p>Poxinha, caso você ainda esteja com dificuldade em redefinir sua senha, por favor, entre em contato com <a href="mailto:suporte@poxalulu.com.br">suporte@poxalulu.com.br</a></p>
              </ContentIntro>
            </LandingInfo>

            <LandingForm>
              <FormBox className="frmm">
                {loading && <Loader isFixed={false} />}
                <img className="rocket" src={rocketImg} alt="Voa, poxinha!"/>
                <LogoMobile>
                  <h2>Redefina a sua senha</h2>
                  <img src={logoPoxalulu} alt="Logo Poxalulu"/>
                </LogoMobile>
                <Form onSubmit={e => handleResetPassword(e)}>
                  <InputAnimate
                    label="Nova senha"
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onlyWhite={true}
                    autoComplete="off"
                    icon="icon-icon-private"
                    minLength={6}
                    required
                  />
                  <InputAnimate
                    label="Repita a nova senha"
                    type="password"
                    name="password2"
                    onChange={(e) => setPassword2(e.target.value)}
                    onlyWhite={true}
                    autoComplete="off"
                    icon="icon-icon-private"
                    minLength={6}
                    required
                  />
                  <div className="access-info">
                    <Button type="submit" color="success">Alterar senha!</Button>
                    <p className="lined-text">Plataforma Poxaululu v2.0 - Todos os direitos reservados</p>
                  </div>
                </Form>
              </FormBox>
            </LandingForm>
          </ContentGrid1>
        </Container>
    );
}

export default RedefinirSenha;
