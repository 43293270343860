import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import ReactStars from 'react-stars';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isPast } from 'date-fns';
import { parseISO } from 'date-fns/esm';

import NextVideoTumbnails from '../../components/NextVideoTumbnails';
import VideoCategoryBox from '../../components/VideoCategoryBox';
import Loader from '../../components/Loader';

import Footer from '../../components/Footer';

import { Container, ContentGrid1, ContentGrid2, ConfirmDiv } from './styles';

import viewIcon from '../../assets/view.svg';
import fileIcon from '../../assets/file.svg';
import PlayerAdapter from '../../components/PlayerAdapter';

interface ICurrentCluster {
  order: number;
  id: string;
  slug: string;
  cluster: {
    title: string;
  }
  cluster_id: string;
  download_url?: string;
  duration: number;
  formatted_duration: string;
  thumbnail_url: string;
  thumbnail: string;
  title: string;
  video: string;
  video_url: string;
  progress?: {
    position: number;
  }
}

interface IClusterProps {
  id: string;
  slug: string;
  course: {
    slug: string;
  }
  course_id: string;
  order: number;
  title: string;
  description: string;
  thumbnail_url: string;
  total_lessons: number;
  background: string;
}

const Player: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const { lesson_slug, cluster_slug, course_slug } = useParams<{
    lesson_slug?: string,
    cluster_slug: string,
    course_slug: string,
  }>();

  const [loading, setLoading] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const [currentCluster, setCurrentCluster] = useState<ICurrentCluster[]>();
  const [clusters, setClusters] = useState<IClusterProps[]>();

  const [currentVideo, setCurrentVideo] = useState<ICurrentCluster>({} as ICurrentCluster);

  const [currentRating, setCurrentRating] = useState(0);
  const [currentViews, setCurrentViews] = useState(0);

  // List clusters
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get<IClusterProps[]>(`/courses/${course_slug}/clusters`);

        console.log(response.data);

        setClusters(response.data);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }

    loadData();
  }, [course_slug]);

  // List videos modules
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<ICurrentCluster[]>(`/clusters/${cluster_slug}/lessons`);

        setLoading(true);
        console.log(response.data);

        setCurrentCluster(response.data);

        let video = response.data[0];

        if (lesson_slug) {
          const lesson = response.data.find(findLesson => findLesson.slug === lesson_slug);
          console.log('leson???????????????????', lesson)

          if (lesson) {
            video = lesson;
          }
          else {
            toast.error('😢 Poxa, parece que o vídeo que você tentou acessar não existe!', {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            history.push('/videos');
          }
        }

        setCurrentVideo(video);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [cluster_slug, lesson_slug, history]);

  useEffect(() => {
    async function loadData() {
      if (!currentVideo.id) {
        return;
      }

      try {
        const ratingResponse = await api.get(`/lessons/${currentVideo.id}/rating`);

        setCurrentRating(ratingResponse.data.formatted_rating);
      } catch (error) {
        setCurrentRating(0);
      }

      try {
        const viewsResponse = await api.patch(`/lessons/${currentVideo.id}/views`);

        setCurrentViews(Number(viewsResponse.data));
      } catch (error) {
        setCurrentViews(0);
      }
    }

    loadData();
  }, [currentVideo]);

  const fakeLoading = useCallback(() => {
    setLoadingVideo(true);
    setTimeout(() => {
      setLoadingVideo(false);
    }, 1);
  }, []);


  const ratingChanged = useCallback((new_rating: number, id: string) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      html: (
        <ConfirmDiv>
          <h2>Confirmar avaliação?</h2>
          <p>Você está avaliando o vídeo com {new_rating} estrelas!</p>
          <ReactStars
            key={0}
            color1="#adafca"
            color2="#ffbf00"
            value={new_rating}
            count={5}
            size={50}
            half={false}
            edit={false}
            onChange={(new_rating: number) => {
              console.log('voto alterado: ' + (new_rating * 10));
            }}
          />
        </ConfirmDiv>
      ),
      confirmButtonText: 'Confirmar',
      showCloseButton: true,
    }).then((result) => {

      async function loadData() {
        try {
          const response = await api.post(`/lessons/${id}/rating`, {
            rating: new_rating * 10
          });

          console.log(response.data);
        } catch (error) {
          console.log(error);
        } finally {

        }
      }
      loadData();

      if (result.isConfirmed) {
        Swal.fire({
          title: 'Pronto!',
          text: 'Sua avaliação foi computada com sucesso!',
          icon: 'success',
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          confirmButtonColor: '#03bb85',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 2500,
          timerProgressBar: true,
        });
        console.log('voto confirmado: ' + (new_rating * 10));
      }
      else {
        console.log('opration canceled');
      }
    });
  }, []);

  const onReady = useCallback((ref: any | null) => {
    console.log('onReady', ref);
  }, []);

  const onPlay = useCallback((ref: any | null) => {
    console.log('onPlay', ref);
  }, []);

  const onPause = useCallback((ref: any | null) => {
    console.log('onPause', ref);
  }, []);

  const onEnded = useCallback((id: string) => {
    console.log('onEnded', id);

    async function loadData() {
      try {
        const response = await api.put(`/lessons/${id}/progress`, { position: currentVideo.duration });

        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }
    loadData();
  }, [currentVideo.duration]);

  const onProgress = useCallback((
    played: number,
    playedSeconds: number,
    loaded: number,
    loadedSeconds: number,
  ) => {
    console.log('onProgress', played, playedSeconds, loaded, loadedSeconds);
  }, []);

  return (
    <Container>
      <h6>{currentVideo?.cluster?.title}</h6>
      {currentVideo.order && <h1>Aula {currentVideo.order < 10 ? '0' + currentVideo.order : currentVideo.order} - {currentVideo.title}</h1>}
      <ContentGrid1>
        <aside>
          {currentVideo.video && !loadingVideo && (
            <PlayerAdapter
              provider="panda"
              video_url={currentVideo.video}
              thumbnail={currentVideo.thumbnail}
              video={currentVideo.video}
              onReady={onReady}
              onPlay={onPlay}
              onPause={onPause}
              onEnded={() => onEnded(currentVideo.id)}
              onProgress={onProgress}
            />
          )}
          {loadingVideo && <main>
            <Loader isFixed={false} />
          </main>}
          <footer>
            <aside>
              <div>
                <img src={viewIcon} alt="Visualizações" />
                <span>{currentViews}</span>
              </div>
              <div>
                <ul>
                  {currentVideo &&
                    <li>
                      {currentVideo.download_url &&
                        <>
                          <img src={fileIcon} alt="" />
                          <a href={currentVideo.download_url} target="_blank" rel="noreferrer">Material de Apoio</a>
                        </>
                      }
                    </li>
                  }
                </ul>
              </div>
            </aside>
            <aside>
              {!!currentRating && <main />}
              <ReactStars
                key={1}
                count={5}
                size={20}
                color1="#adafca"
                color2="#b9993a"
                half={false}
                value={currentRating} //puxar do banco aqui
                onChange={(new_rating: number) => {
                  if (currentVideo) ratingChanged(new_rating, currentVideo?.id);
                }}
              />
            </aside>
          </footer>
        </aside>
        <aside>
          {currentCluster && currentCluster.map((lesson, index) => {
            return (
              <NextVideoTumbnails
                key={index}
                lessonOrder={lesson.order}
                thumbnailUrl={lesson.thumbnail_url ? `https://thumbs.tv.pandavideo.com.br/vz-e7274b3b-4ac/${lesson.thumbnail}.png` : `https://b-vz-e7274b3b-4ac.tv.pandavideo.com.br/${lesson.video}/thumbnail.jpg `}
                lessonTitle={lesson.title}
                lessonDuration={lesson.formatted_duration.slice(0, 2) === '00' ? lesson.formatted_duration.slice(3, 8) : lesson.formatted_duration}
                progress={lesson.progress?.position ? lesson.progress?.position * 100 / lesson.duration : 0}
                current={currentVideo.slug === lesson.slug ? true : false}
                onClick={() => {
                  setCurrentVideo(lesson);
                  window.history.pushState(null, '', `/player/${course_slug}/${cluster_slug}/${lesson.slug}`);
                  fakeLoading();
                }}
              />
            )
          })}
        </aside>
        {loading && <Loader isFixed={false} />}
      </ContentGrid1>
      <ContentGrid2>
        {clusters && clusters.map((cluster, index) => {
          const course = user.courses?.find(course => course.id === cluster.course_id);
          const inWarranty = !isPast(parseISO(String(course?.warranty_date)));

          return (
            <VideoCategoryBox
              key={index}
              href={`/player/${cluster.course.slug}/${cluster.slug}`}
              background={cluster.background}
              bgImg={cluster.thumbnail_url}
              title={cluster.title}
              subTitle={cluster.description}
              videoQuantity={cluster.total_lessons}
              isLocked={index >= 1 && inWarranty}
            />
          )
        })}
      </ContentGrid2>
      <Footer />
    </Container>
  );
}

export default Player;
