import React from 'react';

import { useCredit } from '../../hooks/credit';

import { format, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container } from './styles';

const FooterAlert: React.FC = () => {
  const { credits } = useCredit();

  return (
    <Container>
      <p>Poxa, você não tem créditos disponíveis. {credits.finalPeriod && format(addDays(new Date(credits.finalPeriod), 1), "'Data da renovação' dd'/'MM'/'Y", {locale: ptBR})}</p>
    </Container>
  );
}

export default FooterAlert;
