import styled from "styled-components";

export const SingleEvent = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  > section:nth-child(1) {

      > h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 2rem;
        text-align: left;
      }

      > div {
        position: relative;
        margin-bottom: 1.1rem;
        display: flex;
        justify-content: center;

        > input[type="text"], textarea {
          background-color: transparent;
          border: 1px solid #3f485f;
          width: 100%;
          color: #fff;
          border-radius: 12px;
          font-size: .875rem;
          font-weight: 700;
          padding: .8rem 1.5rem;
          resize: none;
          transition: all .3s ease-in-out;

          ::placeholder {
            color: #8f91ac;
          }

          &:focus {
            border-color: #6e61c6;
          }

          &:focus + label {
            font-size: .75rem;
            transform:translateY(-1.5rem);
          }
          &:valid + label {
            font-size: .75rem;
            transform: translateY(-1.5rem);
          }

          &:invalid + label {
            transform: translateY(0);
          }
        }

        > label {
          color: #adafca;
          font-size: .875rem;
          font-weight: 600;
          position: absolute;
          top: 12.5px;
          left: .75rem;
          padding: 0 5px;
          pointer-events: none;
          background: #1d2333; //bgColor label = bgColor content
          transition: .3s ease;
          transform: translateY(0);
        }

      }

      > div {
        width: 100%;
        position: relative;
        display: flex;

        > label {
          color: #adafca;
          font-size: .75rem;
          font-weight: 600;
          position: absolute;
          top: 12.5px;
          left: .75rem;
          padding: 0 5px;
          pointer-events: none;
          background: #1d2333; //bgColor label = bgColor content
          transition: .3s ease;
          transform: translateY(-1.5rem);
        }

        div {

          div {

            > input[type="text"] {
              background-color: transparent;
              border: 1px solid #3f485f;
              width: 100%;
              color: #fff;
              border-radius: 12px;
              font-size: .875rem;
              font-weight: 700;
              padding: .8rem 1.5rem;
              resize: none;
              transition: all .3s ease-in-out;

              ::placeholder {
                color: #8f91ac;
              }

              &:focus {
                border-color: #6e61c6;
              }

              &:focus + label {
                font-size: .75rem;
                transform:translateY(-1.5rem);
              }
              &:valid + label {
                font-size: .75rem;
                transform: translateY(-1.5rem);
              }

              &:invalid + label {
                transform: translateY(0);
              }
          }
        }
      }
    }

    > footer {

      > p {
        margin: 1rem 0;
        color: #f96969;
        transition: all .3s ease-in-out;
      }

      > button {
        background: #03bb85;
        color: #fff;
        border: none;
        border-radius: 7px;
        padding: .9rem 0;
        width: 155px;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        font-size: .75rem;
        box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
`;
