import styled from 'styled-components';

export const ConfirmDiv = styled.div`

  > footer {
    display: flex;
    justify-content: center;
  }

  > button {
    margin-top: 1rem;
    border: none;
    border-radius: 10px;
    padding: 1.15rem .4rem;
    font-weight: bold;
    align-items: center;
    box-shadow: 4px 7px 12px 0 rgb(97 93 250 / 20%);
    font-size: 1rem;
    transition: all .3s ease-in-out;
    background: #03bb85;
    color: #fff;
    height: 48px;
    width: 100%;
    padding: 11px 1.2rem;
    font-size: .875rem;
    font-weight: 700;
    text-transform: uppercase;

    &:disabled {
      background: #f7f7f7;
      border: 1px solid #d2d7e3;
      color: #b3bbcf;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
`;
