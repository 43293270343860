import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 2rem 0;
  min-height: 150px;
  grid-template-columns: repeat(4, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TabsCrid = styled.div`
  margin: 1rem 0;
`;
