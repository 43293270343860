import React, { useCallback } from 'react';
import Draggable from 'react-draggable';

interface IPositionProps {
  x: number;
  y: number;
}

interface ICropPointProps {
  cropPoints: {
    'left-top': {
      x: number;
      y: number;
    }
    'right-top': {
      x: number;
      y: number;
    }
    'right-bottom': {
      x: number;
      y: number;
    }
    'left-bottom': {
      x: number;
      y: number;
    }
  };
  pointArea: 'left-top' | 'right-top' | 'right-bottom' | 'left-bottom';
  defaultPosition: {
    x: number;
    y: number;
  };
  pointSize: number;
  pointBgColor: string;
  pointBorder: string;
  bounds: any;
  onStop(value: any, pointArea: any, cropPoints: any): void;
  onDrag(value: any, pointArea: any): void;
}

const buildCropPointStyle = (size: number, pointBgColor: string, pointBorder: string) => ({
  width: size,
  height: size,
  backgroundColor: pointBgColor,
  border: pointBorder,
  borderRadius: '100%',
  position: 'absolute',
  zIndex: 1001
})

// import { Container } from './styles';

const CropPoint: React.FC<ICropPointProps> = ({
  cropPoints,
  pointArea,
  defaultPosition,
  pointSize,
  pointBgColor = 'transparent',
  pointBorder = '4px solid #3cabe2',
  onStop: externalOnStop,
  onDrag: externalOnDrag,
  bounds,
}) => {

  const onDrag = useCallback((_, position: IPositionProps) => {
      externalOnDrag({
          ...position,
          x: position.x + pointSize / 2,
          y: position.y + pointSize / 2
        },
        pointArea
      )}, [externalOnDrag, pointArea, pointSize]);

  const onStop = useCallback((_, position) => {
    externalOnStop({
        ...position,
        x: position.x + pointSize / 2,
        y: position.y + pointSize / 2
      },
      pointArea,
      cropPoints
  )}, [cropPoints, externalOnStop, pointArea, pointSize]);

  return (
    <Draggable
      bounds={bounds}
      defaultPosition={defaultPosition}
      position={{
        x: cropPoints[pointArea].x - pointSize / 2,
        y: cropPoints[pointArea].y - pointSize / 2
      }}
      onDrag={onDrag}
      onStop={onStop}
    >
      {/* @ts-ignore */}
      <div style={buildCropPointStyle(pointSize, pointBgColor, pointBorder)} />
    </Draggable>
  );
}

export default CropPoint;
