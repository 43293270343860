import styled, { css } from 'styled-components';
import bg1 from '../../assets/bg-achievement-1.jpg';
import bg2 from '../../assets/bg-achievement-2.jpg';
import bg3 from '../../assets/bg-achievement-3.jpg';
import bg4 from '../../assets/bg-achievement-4.jpg';

interface IAchievementBoxProps {
  background: 'bg1' | 'bg2' | 'bg3' | 'bg4';
  pulseAnimation?: boolean;
}

const bgVariations = {
  bg1: css`
    background: url(${bg1});
  `,
  bg2: css`
    background: url(${bg2});
  `,
  bg3: css`
    background: url(${bg3});
  `,
  bg4: css`
    background: url(${bg4});
  `,
}


export const Container = styled.div<IAchievementBoxProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 142px;
  padding: 0 28px;
  border-radius: 12px;
  width: 100%;
  color: #fff;
  ${props => bgVariations[props.background]};
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  box-shadow: ${props => props.theme.colors.boxShadow};
  animation: ${props => props.pulseAnimation ? 'pulseAnimationCss 2s infinite' : 'unset' };

  @keyframes pulseAnimationCss {
    0% {
      -moz-box-shadow: 0 0 0 0 #ff000050;
      box-shadow: 0 0 0 0 #ff000050;
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(255,255,255, 0);
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }

  > aside {
    display: flex;
    align-items: center;
    justify-content: start;

    > figure {
      margin-right: 16px;

      /* TABLET */
      @media (max-width: 768px) {
        margin: 0;
      }

    }

    > div {

      > h3 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
      }

      > p {
        margin-top: 8px;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1;
      }
    }
  }

  > a {
    width: 140px;
    background: #fff;
    color: #3e3f5e;

    display: inline-block;
    height: 48px;
    border-radius: 10px;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    box-shadow: 3px 5px 10px 0 rgba(62,63,94,.2);
  }

  /* TABLET */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;

    > aside {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      text-align: center;

      > div > h3 {
        max-width: 250px;
        margin-top: 1rem;

        /* TABLET */
        @media (max-width: 768px) {
          max-width: unset;
        }
      }
    }
  }
  /* MOBILE */
  @media (max-width: 480px) {

  }
`;
