import React from 'react';

import { Container, Points, Theme } from './styles';

import graphUpImg  from "../../assets/graph-up.svg";
import graphDownImg  from "../../assets/graph-down.svg";

interface IStatsBoxSmallProps {
  id: string;
  width: string;
  levelFlag: 'up' | 'down' | 'none';
  nota?: number;
  theme: string;
  is_photo: boolean;
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
  bg: 'bg1' | 'bg2' | 'bg3' | 'bg4';
}

const StatsBoxSmall: React.FC<IStatsBoxSmallProps> = ({ id, levelFlag, width, nota, theme, is_photo, status, bg }) => {
  return (
    <Container to={status === 'canceled' ? '#' : `/visualizar-redacao/${is_photo ? 'foto' : 'texto'}/${id}`} width={width} bg={bg} status={status}>
      <Theme>{theme}</Theme>
      {status === 'corrected' ?
      <Points>
        <p>
          <span>nota
            <img src={levelFlag === 'up' ? graphUpImg : graphDownImg} alt=""/>
          </span>
          <br />
          {nota}
        </p>
      </Points>
      :
      <footer>
        {status === 'canceled' && <span>Cancelada</span>}
        {status === 'pending' && <span>Aguardando correção</span>}
        {status === 'in_correction' && <span>Aguardando correção</span>}
        {status === 'in_confirmation' && <span>Aguardando correção</span>}
      </footer>
    }
    </Container>
  );
}

export default StatsBoxSmall;
