import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 3fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid2 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid3 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
`;

export const WidgetBoxActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 .55rem 2rem 0;
  z-index: 999;

  > h4 {
    color: ${props => props.theme.colors.textColor};
    line-height: 1em;
    margin-left: .55rem;

    > img {
      width: 18px;
      border-radius: 2px;
    }
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  position: relative;

  .apexcharts-tooltip {
    background: ${props => props.theme.colors.textColor}40;
    color: #fff;
    box-shadow: none;
    font-weight: 600;
    backdrop-filter: blur(11px);
    border: 2px solid #e3e3e317;

    .apexcharts-tooltip-title {
      background: #3e3f5e;
      color: #fff;
    }
  }

  .apexcharts-menu {
    background: ${props => props.theme.colors.bgBox}40;
    color: ${props => props.theme.colors.textColor};
    backdrop-filter: blur(11px);
    border: 2px solid #e3e3e317;
    text-align: center;
    top: 30px;
    margin-bottom: 3px;
    padding: 6px 7px;
    font-weight: 700;
  }

  .apexcharts-theme-light .apexcharts-menu-item:hover {
    background: #ffffff2e;
    border-radius: 4px;
    margin-bottom: 3px;
    padding: 6px 7px;
  }

  > main {
    position: absolute;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    z-index: 0;

    > h1 {
      font-size: 2.5rem;
      line-height: 1;
    }

    > p {
      margin-top: 2px;
      color: #adafca;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
    }
  }
`;

export const TabsCrid = styled.div`
  margin: 1rem 0;

  > ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 2rem 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;

    > li {
      font-size: .875rem;
      font-weight: 700;
      cursor: pointer;
      background: none;
      padding-right: 1rem;
      border-radius: 0px;
      color: ${props => props.theme.colors.textColor};

      > button {
        padding: 1rem 2rem;
        text-transform: uppercase;
        font-weight: 700;
        color: ${props => props.theme.colors.textColor};
        background: none;
        opacity: .5;
        transition: opacity .3s ease-in-out 0s;

        &.active {
          opacity: 1;
          border-bottom: 4px solid #6e61c6;
        }
      }
    }
  }
`;

export const OverlaySoon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  transition: all .3s ease-in-out;
  cursor: pointer;
  background-image: linear-gradient(to bottom, transparent, #302575);
  opacity: 1;
  cursor: not-allowed;

  > div {
    border: 2px solid #ffffff;
    color: #ffffff;
    border-radius: 4px;
    padding: 10px 18px;
    background: #7b6fca;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    opacity: 1;
  }
`;
