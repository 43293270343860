import styled from 'styled-components';

interface IUserAvatarProps {
  showOnlyHehaxon?: boolean;
  zIndex?: number;
}
export const Container = styled.div<IUserAvatarProps>`
  display: flex;
  justify-content: center;
  z-index: ${props => props.zIndex ? props.zIndex : props.zIndex};

  .user-avatar {
    display: block;
    width: 100px;
    height: 110px;
    position: relative;

    &.online,
    &.offline,
    &.away {
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        border: 3px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: -2px;
        z-index: 10;
      }
    }

    &.online {
      &::before {
        background-color: #1df377;
      }
    }

    &.offline {
      &::before {
        background-color: #3b3b3b;
      }
    }

    &.away {
      &::before {
        background-color: #e07144;
      }
    }

    &.big {
      width: 148px;
      height: 164px;

      .user-avatar-content {
        top: 27px;
        left: 24px;
      }

      .user-avatar-progress,
      .user-avatar-progress-border {
        top: 14px;
        left: 12px;
      }

      .user-avatar-badge {
        width: 40px;
        height: 44px;
        top: 102px;
        right: 8px;

        .user-avatar-badge-content {
          top: 5px;
          left: 4px;
        }

        .user-avatar-badge-text {
          font-size: 16px;
        }
      }
    }

    &.medium {
      width: 120px;
      height: 132px;

      .user-avatar-content {
        top: 21px;
        left: 19px;
      }

      .user-avatar-progress,
      .user-avatar-progress-border {
        top: 11px;
        left: 10px;
      }

      .user-avatar-badge {
        width: 32px;
        height: 36px;
        top: 82px;
        right: 7px;

        .user-avatar-badge-content {
          top: 4px;
          left: 3px;
        }

        .user-avatar-badge-text {
          font-size: 14px;
          line-height: 1;
        }
      }
    }

    &.small {
      width: 50px;
      height: 56px;

      .user-avatar-content {
        top: 12px;
        left: 10px;
      }

      .user-avatar-progress,
      .user-avatar-progress-border {
        top: 6px;
        left: 5px;
      }

      .user-avatar-badge {
        width: 22px;
        height: 24px;
        top: 27px;
        right: 2px;

        .user-avatar-badge-content {
          top: 3px;
          left: 3px;

        }

        .user-avatar-badge-text {
          font-size: 8px;

        }
      }
    }

    &.smaller {
      .user-avatar-overlay {
        width: 30px;
        height: 32px;
      }

      .user-avatar-overlay-content {
        .user-avatar-overlay-content-text {
          font-size: 11px;
        }
      }
    }

    &.no-stats {
      width: 100px;
      height: 108px;

      .user-avatar-content {
        top: 8px;
        left: 8px;
      }

      &.big {
        width: 148px;
        height: 164px;

        .user-avatar-content {
          top: 14px;
          left: 12px;
        }
      }

      &.medium {
        width: 120px;
        height: 130px;

        .user-avatar-content {
          top: 10px;
          left: 10px;
        }
      }

      &.small {
        width: 50px;
        height: 56px;

        .user-avatar-content {
          top: 6px;
          left: 5px;
        }
      }

      &.smaller {
        width: 34px;
        height: 36px;

        .user-avatar-content {
          top: 2px;
          left: 2px;
        }
      }

      &.micro {
        width: 22px;
        height: 24px;

        .user-avatar-content {
          top: 2px;
          left: 2px;
        }
      }
    }

    &.no-border {
      width: 84px;
      height: 92px;

      .user-avatar-content {
        top: 0;
        left: 0;
      }

      &.big {
        width: 124px;
        height: 136px;
      }

      &.medium {
        width: 100px;
        height: 110px;
      }

      &.small {
        width: 40px;
        height: 44px;
      }

      &.tiny {
        width: 24px;
        height: 26px;
      }

      &.micro {
        width: 18px;
        height: 20px;
      }
    }

    &.no-outline {
      width: 84px;
      height: 92px;

      .user-avatar-progress,
      .user-avatar-progress-border {
        top: 0;
        left: 0;
      }

      .user-avatar-content {
        top: 9px;
        left: 8px;
      }

      .user-avatar-badge {
        top: 60px;
        right: -3px;
      }

      &.small {
        width: 40px;
        height: 44px;

        .user-avatar-content {
          top: 6px;
          left: 5px;
        }

        .user-avatar-badge {
          top: 21px;
          right: -3px;
        }
      }
    }

    .user-avatar-border {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      //${props => props.showOnlyHehaxon ? 'transform: scale(0.5)' : 'none'};

      .hexagon-content {
        background-color: ${props => props.theme.colors.white};
      }
    }

    .user-avatar-content {
      position: absolute;
      top: 18px;
      left: 16px;
      z-index: 3;
      //${props => props.showOnlyHehaxon ? 'transform: scale(0.6)' : 'none'};
    }

    .user-avatar-progress,
    .user-avatar-progress-border {
      position: absolute;
      top: 9px;
      left: 8px;
      z-index: 2;
    }

    .user-avatar-progress {
      z-index: 3;
      display: ${props => props.showOnlyHehaxon ? 'none' : 'block'};
    }

    .user-avatar-progress-border {
      z-index: 2;
      display: ${props => props.showOnlyHehaxon ? 'none' : 'block'};
    }

    .user-avatar-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 32px;
      position: absolute;
      top: 68px;
      right: 5px;
      z-index: 4;
      /* AQUI */
      /* display: ${props => props.showOnlyHehaxon ? 'none' : 'block'}; */

      .user-avatar-badge-border {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        display: ${props => props.showOnlyHehaxon ? 'none' : 'block'};

        .hexagon-content {
          background-color: ${props => props.theme.colors.white};
        }
      }

      .user-avatar-badge-content {
        position: absolute;
        top: 4px;
        left: 3px;
        z-index: 5;
        display: ${props => props.showOnlyHehaxon ? 'none' : 'block'};
      }

      .user-avatar-badge-text {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        pointer-events: none;
        position: relative;
        z-index: 6;
        display: ${props => props.showOnlyHehaxon ? 'none' : 'block'};
      }
    }

    .user-avatar-overlay {
      position: absolute;
      top: 2px;
      left: 2px;
      z-index: 11;
    }

    .user-avatar-overlay-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 12;

      .user-avatar-overlay-content-text {
        color: ${props => props.theme.colors.white};
        font-weight: 600;
        pointer-events: none;
        position: relative;
        top: 1px;
      }
    }
  }
`;
