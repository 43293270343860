import styled from 'styled-components';

interface ICropperPerpectiveProps {
  padding?: string;
}

export const Container = styled.div<ICropperPerpectiveProps>`

  .react-draggable {
    //background: pink !important;
    animation: pulseAnimation 2s infinite;
    cursor: move;
  }

  @keyframes pulseAnimation {
    0% {
      -moz-box-shadow: 0 0 0 0 #ff000050;
      box-shadow: 0 0 0 0 #ff000050;
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(255,255,255, 0);
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }

  > main {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 420px;
    padding: 1rem;
    border-style: dashed;
    color: #adafca;
    border-color: #adafca;
    border-width: 2px;
    border-radius: 8px;
    margin-bottom: 1rem;
    transition: all .3s ease-in-out;

    &:hover {
      border-color: ${props => props.theme.colors.textColor};
      color: ${props => props.theme.colors.textColor};
      background: ${props => props.theme.colors.bgContent};
    }

    > div {
      display: flex;
      justify-content: center;
      z-index: 999999;
    }

    > input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 99999;
      opacity: 0;
      cursor: pointer;
    }

    > footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  }

  > button {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 0 1.2rem;
    border-radius: 8px;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s ease-in-out;
    align-items: center;
    justify-content: center;
  }

`;

  export const BtnReset = styled.p`
    z-index: 9999999999999999;
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin: 1rem 4px 0 4px;

    > span {
      background: #ea457d;
      padding: 1px 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 17px;
      color: #fff;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {

      }
    }
  `;

  export const BtnCancel = styled.p`
    z-index: 9999999999999999;
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin: 1rem 4px 0 4px;

    > span {
      background: red;
      padding: 1px 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 17px;
      color: #fff;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {

      }
    }
    `;

    export const CropInstructionsContentModal = styled.div`

      > h1 {
        text-align: left;
        font-size: 22px;
      }

      > p {
        text-align: justify;
      }
    `;
