import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  > footer {

    > button {
      width: 100%;
      height: 48px;
      padding: 0 1.2rem;
      border-radius: 8px;
      font-size: .875rem;
      font-weight: 700;
      text-align: center;
      line-height: 48px;
      cursor: pointer;
      text-decoration: none;
      transition: all .2s ease-in-out;
      align-items: center;
      justify-content: center;

      &:disabled {
        background: transparent;
        border: 1px solid #d2d7e3;
        color: #b3bbcf;
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: repeat(1, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    //
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //
  }

  > section:nth-child(1) {
    display: flex;
    position: relative;

    > main {
      position: sticky;
      top: calc(80px + 1rem);
      width: 100%;

      > div {
        height: unset;

        > main {

          > div {

            > h2 {
              min-height: unset !important;
            }
          }
        }

        > footer {
          visibility: hidden;
          height: 12px;
        }

        > div {

          > figure {
            height: 150px;
          }
        }
      }
    }
  }

  > section:nth-child(2) {
    display: flex;
    justify-content: center;
    background: blue;
    border-radius: 12px;
    background: ${props => props.theme.colors.bgBox};
    box-shadow: ${props => props.theme.colors.shadowBox};
    color: ${props => props.theme.colors.textColor};

    > main:nth-child(1) {
      width: 100%;
      display: flex;
      flex-direction: column;

      > h1 {
        margin-bottom: 1rem;
      }

      > footer {

        > button {
          width: 100%;
          height: 48px;
          padding: 0 1.2rem;
          border-radius: 8px;
          color: #ffffff;
          font-size: .875rem;
          font-weight: 700;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
          text-decoration: none;
          transition: all .2s ease-in-out;
          align-items: center;
          justify-content: center;

          &:hover {
            padding: 0 1.2rem;
          }
        }

        > p {
            text-align: center;
            margin-top: 2rem;
            color: #8f91ac;
            font-weight: 600;
            font-size: .75rem;
        }
      }
    }
  }
`;
