import styled from 'styled-components';

export const WidgetBoxActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 .55rem 2rem 0;

  > h4 {
    color: ${props => props.theme.colors.textColor};
    line-height: 1em;
    margin-left: .55rem;
  }

  > div {
    display: flex;
    justify-content: end;
    margin-right: .55rem;

    > p {
      font-size: .7rem;
      font-weight: 700;
      color: #ffffff;
      margin: 0 0.75rem;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 0;
      color: ${props => props.theme.colors.textColor};

      /* TABLET */
      @media (max-width: 768px) {

      }

      /* MOBILE */
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
`;

export const TinySliderContent = styled.div`
  position: relative;
`;

export const SlideItem = styled.div`
  text-align: center;

  > a {
    text-decoration: none;

    > h6 {
      margin-top: 10px;
      font-size: 1.375rem;
      font-weight: 700;
      line-height: 1;
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      margin-top: 4px;
      color: #9aa4bf;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
    }
  }
`;
