import React from 'react';

import { Container } from './styles';

interface IBoxContainerProps {
  position?: string;
  margin?: string;
  padding?: string;
  height?: string;
  maxHeight?: string;
  textAlign?: 'center' | 'left' | 'right';
  display?: 'block' | 'flex' | 'grid';
  justifyContent?: 'center' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  overflow?: string;
  borderRadius?: string;
}

const BoxContainer: React.FC<IBoxContainerProps> = ({ children, position, margin, padding, display, textAlign, flexDirection, justifyContent, height, maxHeight, overflow, borderRadius }) => {
  return (
    <Container
    position={position}
    margin={margin}
    padding={padding}
    display={display}
    textAlign={textAlign}
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    height={height}
    maxHeight={maxHeight}
    overflow={overflow}
    borderRadius={borderRadius}
  >
      {children}
    </Container>
  );
}

export default BoxContainer;
