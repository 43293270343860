const menuLinks = [
  {
    "tooltip": 'Dashboard',
    "location": 'dashboard',
    "icon": "icon-icon-newsfeed"
  },
  {
    "tooltip": 'Minhas Redações',
    "location": 'minhas-redacoes',
    "icon": "icon-icon-send-message"
  },
  {
    "tooltip": 'Estatísticas',
    "location": 'estatisticas',
    "icon": "icon-icon-overview"
  },
  {
    "tooltip": 'Vídeos Exclusivos',
    "location": 'videos',
    "icon": "icon-icon-streams"
  },
  {
    "tooltip": 'Curso Análises de Redações 1000',
    "location": 'analise-redacoes-nota-1000',
    "icon": "icon-icon-youtube",
  },
  {
    "tooltip": 'Redação Espacial',
    "location": 'redacao-espacial',
    "icon": "icon-icon-play",
    customIcon: '<svg version="1.1" viewBox="0 0 20 20" xml:space="preserve"><path d="M19.5,7.6c-0.3-0.7-0.8-1.2-1.4-1.7c-0.6-0.4-1.3-0.7-2.2-0.9c-0.8-0.2-1.7-0.3-2.7-0.4c-1-0.1-2-0.1-3.1-0.1s-2.1,0-3.1,0.1S5,4.8,4.1,5C3.3,5.2,2.6,5.5,1.9,5.9c-0.6,0.4-1.1,1-1.4,1.7C0.2,8.3,0,9.1,0,10.2c0,1,0.2,2,0.6,2.8s0.9,1.5,1.6,1.9s1.5,0.7,2.3,0.7c0.5,0,1-0.1,1.5-0.2c0.4-0.2,0.8-0.3,1.2-0.6c0.4-0.2,0.7-0.5,1-0.7c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2c0.3,0.2,0.6,0.3,0.9,0.6c0.3,0.2,0.6,0.5,1,0.7c0.4,0.2,0.8,0.4,1.2,0.6c0.4,0.2,0.9,0.2,1.5,0.2c0.9,0,1.7-0.2,2.3-0.7s1.2-1.1,1.6-1.9c0.4-0.8,0.6-1.7,0.6-2.8S19.8,8.3,19.5,7.6L19.5,7.6z M18.2,12.3c-0.3,0.6-0.6,1.1-1.1,1.4c-0.5,0.3-1,0.5-1.6,0.5c-0.5,0-0.9-0.1-1.3-0.2c-0.4-0.2-0.7-0.3-1-0.6c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.2-0.7-0.4-1-0.6c-0.4-0.2-0.8-0.2-1.2-0.2S9.1,12,8.8,12.1c-0.4,0.2-0.7,0.3-1,0.6s-0.6,0.4-0.9,0.7c-0.3,0.2-0.7,0.4-1,0.6c-0.4,0.2-0.8,0.2-1.3,0.2c-0.6,0-1.2-0.2-1.6-0.5s-0.8-0.8-1.1-1.4c-0.3-0.6-0.4-1.3-0.4-2.1c0-1,0.2-1.7,0.6-2.3s0.9-1,1.7-1.3c0.7-0.3,1.6-0.5,2.7-0.5S8.7,5.9,10,5.9s2.5,0,3.6,0.1c1.1,0.1,2,0.3,2.7,0.5C17,6.9,17.6,7.3,18,7.9c0.4,0.6,0.6,1.3,0.6,2.3C18.5,11,18.4,11.7,18.2,12.3L18.2,12.3z"/></svg>',
    "badge": "New"
  },
  {
    "tooltip": 'Materiais',
    "location": 'materiais',
    "icon": "icon-icon-forum"
  },
  {
    "tooltip": 'Simulados',
    "location": 'simulados',
    "icon": "icon-icon-quests"
  },
  {
    "tooltip": 'Conquistas',
    "location": 'conquistas',
    "icon": "icon-icon-trophy"
  },
  {
    "tooltip": 'Fórum',
    "location": 'forum',
    "icon": "icon-icon-messages-1"
  },
  {
    "tooltip": 'Calendário',
    "location": 'calendario',
    "icon": "icon-icon-events-monthly"
  },
  {
    "tooltip": 'Suporte Técnico',
    "location": 'suporte',
    "icon": "icon-icon-comment"
  }
]

export default menuLinks;
