import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CadastroAfiliados from '../Pages/CadastroAfiliados';

import RedefinirSenha from '../Pages/RedefinirSenha';
import SignIn from '../Pages/SigIn';

const AuthRoutes: React.FC = () => (
  <Switch>
    <Route path="/cadastro" exact component={CadastroAfiliados} />
    <Route path="/resetar-senha" exact component={RedefinirSenha} />
    <Route path="/" component={SignIn} />
  </Switch>
);

export default AuthRoutes;
