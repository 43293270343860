import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import Chart, { Props as ChartProps } from "react-apexcharts";

import SectionBanner from '../../components/SectionBanner';
import HeaderInfo from '../../components/HeaderInfo';
import ProfileStats from '../../components/ProfileStats';
import BoxContainer from '../../components/BoxContainer';
import SmallStatisticsBox from '../../components/SmallStatisticsBox';
import BrazilMap from '../../components/BrazilMap';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';

import { Container, ContentGrid0, ContentGrid1, ContentGrid2, ContentGrid3, WidgetBoxActions, TabsCrid, ChartContainer } from './styles';

import bannerindexIconImg from '../../assets/overview-icon.png';
import brazilFlag from '../../assets/brazil-flag.svg';

interface IPerformanceGraphProps {
  categories?: string[];
  series: any[];
}

interface IProfileStatisticsProps {
  submitted_wordings: number;
  average_grade: number;
  highest_grade: number;
  times_in_hall: number;
  topics_count: number;
  tickets_count: number;
  achievements_count: number;
}

interface IBrazilStates {
  [key: string]: number;
}

const Estatisticas: React.FC = () => {
  const { user } = useAuth();
  const [loadingPergormanceGraph, setLoadingPergormanceGraph] = useState(true);
  const [loadingCompetencesGraph, setLoadingCompetencesGraph] = useState(true);
  const [loadingBrazilStates, setLoadingBrazilStates] = useState(true);
  const [loadingSimulatedData, setLoadingSimulatedData] = useState(true);

  const [brazilStates, setBrazilStates] = useState<IBrazilStates>({} as IBrazilStates);
  const [performanceGraph, setPerformanceGraph] = useState<IPerformanceGraphProps>({} as IPerformanceGraphProps);
  const [areaChartSimulatedData, setAreaChartSimulatedData] = useState<IPerformanceGraphProps>({} as IPerformanceGraphProps);

  const [personalStatistics, setPersonalStatistics] = useState<IProfileStatisticsProps>({} as IProfileStatisticsProps);
  const [competencesGraph, setCompetencesGraph] = useState([]);

  // List PERSONAL WORDING STATISTICS
  useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get<IProfileStatisticsProps>(`/profile/wordings/statistics/${user.id}`,
          { params: { exam_name: 'enem' }}
        );

        console.log('LIST PERSONAL WORDINGS STATISTICS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setPersonalStatistics(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoadingPergormanceGraph(true);
        const response = await api.get('/wordings/statistics');

        console.log('LIST PERFORMANCE GRAPH STATISTCS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setPerformanceGraph(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingPergormanceGraph(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoadingBrazilStates(true);
        const response = await api.get('/wordings/statistics/states');

        setBrazilStates(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingBrazilStates(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoadingSimulatedData(true);
        const response = await api.get('/practice-tests/statistics');

        setAreaChartSimulatedData(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSimulatedData(false);
      }
    }

    loadData();
  }, []);

  // GET COMPETENCES AVG
  useEffect(() => {
    async function loadData() {
      try {
        setLoadingCompetencesGraph(true);
        const response = await api.get(`/corrections/statistics/${user.id}/competences`,
        {
          params: { exam_name: 'enem' }
        });

        console.log('LIST COMPETENCES AVG >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setCompetencesGraph(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingCompetencesGraph(false);
      }
    }

    loadData();
  }, [user.id]);

  const barChartPerformanceOptions = useMemo<ChartProps>(() => ({
    chart: {
      id: "grafico-de-desempenho",
      foreColor: '#adafca',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          customIcons: []
        },
      },
    },
    xaxis: {
      categories: performanceGraph ? performanceGraph.categories : [],
      axisBorder: {
        show: false,
        color: '#adafca50',
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      //max: 1000,
      //min: minDataValues - 200,
      tickAmount: 4,
      forceNiceScale: true,
      axisBorder: {
        show: false,
      },
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 6,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
    },
    tooltip: {
      enabled: true,
      theme: 'light',
      marker: {
        show: true,
      },
    },
    colors: ['#6e61c6', '#45437f'],
    fill: {
      type: 'solid',
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: "#00000020",
      clipMarkers: true,
      yaxis: {
        lines: {
          show: true
        },
      },
      xaxis: {
        lines: {
          show: true
        }
      },
    },
  }), [performanceGraph]);

  const donutPerformanceOptions = {
    chart: {
      type: 'donut',
      id: "grafico-de-desempenho",
      foreColor: '#adafca',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          customIcons: []
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '55%',
          labels: {
            show: false,
          }
        }
      }
    },
    stroke:{
      show: false,
    },
    colors: [
      '#ffce56',
      '#36a2eb',
      '#4bc0c0',
      '#e86d6c',
      '#9966ff',
    ],
    dataLabels: {
      enabled: false,
    },
    labels: ['Competência 1', 'Competência 2', 'Competência 3', 'Competência 4', 'Competência 5']
  };

  const areaChartSimulatedOptions: ChartProps = {
    chart: {
      id: "grafico-acertos-nos-simulado",
      foreColor: '#adafca',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          //zoom: `<img src=${zoomIcon} width='20' />`, // if custum icon
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          customIcons: [],
        },
      },
    },
    xaxis: {
      categories: areaChartSimulatedData ? areaChartSimulatedData.categories : [],
      axisBorder: {
        show: false,
        color: '#adafca50',
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      //max: 1000,
      //min: minDataValues - 200,
      tickAmount: 4,
      forceNiceScale: true,
      axisBorder: {
        show: false,
      },
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 6,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
    },
    tooltip: {
      enabled: true,
      theme: 'light',
      marker: {
        show: true,
      },
    },
    colors: ['#6e61c6', '#45437f'],
    fill: {
      type: 'solid',
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: "#00000020",
      clipMarkers: true,
      yaxis: {
        lines: {
          show: true
        },
      },
      xaxis: {
        lines: {
          show: true
        }
      },
    },
  }

  return (
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Estatísticas" subTitle="Gráficos, métricas, estatísticas e muito mais!" />
      <HeaderInfo supTitle="Visão geral" title="Estatísticas" margin="2rem 0 1rem 0" />
      <TabsCrid>
        <ContentGrid0>
          <SmallStatisticsBox icon="icon-icon-send-message" title="Redações Enviadas" bgColorIcon="#9181fa" value={personalStatistics?.submitted_wordings} />
          <SmallStatisticsBox icon="icon-icon-overview" title="Nota Média" bgColorIcon="#9181fa" value={personalStatistics?.average_grade} />
          <SmallStatisticsBox icon="icon-icon-trophy" title="Vezes no Hall da Fama" bgColorIcon="#9181fa" value={personalStatistics?.times_in_hall} />
          <SmallStatisticsBox icon="icon-icon-messages-1" title="Posts no Fórum" bgColorIcon="#9181fa" value={personalStatistics?.topics_count} />
        </ContentGrid0>
        <ContentGrid1>
          <ProfileStats submitted_wordings={personalStatistics.submitted_wordings} highest_grade={personalStatistics.highest_grade} />
          <BoxContainer padding="24px" display="flex" flexDirection="column" position="relative" overflow="hidden" borderRadius="12px">
            <ChartContainer>
              <h4 style={{marginBottom: '-2rem'}}>Gráfico de Desempenho</h4>
              {loadingPergormanceGraph && (<div style={{marginTop: '36%'}}><Loader isFixed={false} /></div>)}
              {performanceGraph.series && <Chart options={barChartPerformanceOptions} series={performanceGraph.series} type="bar" height={350} />}
            </ChartContainer>
          </BoxContainer>
        </ContentGrid1>
        <ContentGrid2>
          <BoxContainer justifyContent="center" textAlign="center" padding="28px" flexDirection="column" position="relative" overflow="hidden" borderRadius="12px">
            <WidgetBoxActions>
              <h4><img src={brazilFlag} alt="Brasil" /> Média por Estado</h4>
            </WidgetBoxActions>
            <BrazilMap states={brazilStates} />
          </BoxContainer>
          <BoxContainer display="flex" justifyContent="center" padding="28px" flexDirection="column" borderRadius="12px">
            <ChartContainer>
              {loadingCompetencesGraph && <Loader isFixed={false} />}
              {personalStatistics?.average_grade ? <main>
                <h1>{personalStatistics?.average_grade.toFixed(0)}</h1>
                <p>Nota média</p>
              </main>
              :
                <main>
                  <h4>Você ainda não possui métricas para serem exibidas 😢</h4>
                </main>
              }
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem'}}>
                <h4>Gráfico de Competências</h4>
              </div>
              {/* @ts-ignore */}
              {competencesGraph && <Chart options={donutPerformanceOptions} series={competencesGraph} type="donut" height={580} />}
            </ChartContainer>
          </BoxContainer>
        </ContentGrid2>
        <ContentGrid3>
          <BoxContainer justifyContent="center" padding="28px" flexDirection="column" position="relative" overflow="hidden" borderRadius="12px">
            <ChartContainer>
              <h4>Acertos nos simulados</h4>
              {areaChartSimulatedData.series && <Chart options={areaChartSimulatedOptions} series={areaChartSimulatedData.series} type="area" height={400} />}
            </ChartContainer>
          </BoxContainer>
        </ContentGrid3>
      </TabsCrid>
      <Footer />
    </Container>
  );
}

export default Estatisticas;
