import styled from "styled-components";

export const SingleEvent = styled.div`

  > section {
    position: relative;

    h1 {
      text-align: left;
      color: #40485f;
      margin-bottom: 1rem;
      padding-left: .45rem;
    }
  }

  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    > p {
      margin: 1rem 0;
      color: #f96969;
      transition: all .3s ease-in-out;
    }

    > button {
      display: flex;
      background: #03bb85;
      color: #fff;
      border: none;
      border-radius: 7px;
      padding: .9rem 0;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      font-size: .75rem;
      box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    }
  }
`;
