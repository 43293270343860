import styled from 'styled-components';

/*
  interface IAVGBrazilMapProps {
    avgValue: number[];
  }

  - 600 ~ 699 : #edc196
  - 700 ~ 799 : #f2997d
  - 800 ~ 899 : #f47c69
  - 900 ~ 999 : #f06d5a
*/

export const Container = styled.div`
  //position: relative;
  display: flex;
  font-size: 12px;
  justify-content: center;

  > svg {
    width: 460px;
    height: 465px;

    /* TABLET */
    @media (max-width: 768px) {
      width: 460px;
      height: 465px;
    }

    /* MOBILE IPHONE 6/7/8 PLUS */
    @media (max-width: 480px) {
      zoom: .7;
      //transform: scale(.65);
    }
    /* MOBILE IPHONE 5 */
    @media (max-width: 376px) {
      zoom: .5;
      //transform: scale(.65);
    }
  }

  g {
    cursor: pointer;

    > path {
      fill: #6e61c6;
      color: #fff;
      transition: all 350ms linear;
      &:hover {
        fill: #45437f;
        /* transform: scale(1.008); */
        z-index: 999;
      }
    }
    > circle {
      fill: #5b50a3;
    }
    > text {
      fill: #fff;
      > div {
        background: yellow;
        width: 50px;
        height: 25px;
        display: block;
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  text-align: center;

  > footer {

    > p {
    font-size: 11px;
    text-align: center;
    width: 100%;
    color: #adafca;
    }
  }
`;
