import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import { Image } from 'react-image-and-background-image-fade';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ProfileHeader from '../../components/ProfileHeader';
import Accordion from '../../components/Accordion';
import HeaderInfo from '../../components/HeaderInfo';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import Icon from '../../components/Icon';

import { Container, ContentGrid1, ContentGrid2, Card, NotFound } from './styles';

interface IAchievementsProps {
  achievements: {
    id: string;
    icon: string;
    title: string;
    exp: number;
    description: string;
    requirement: string;
    acquired_at: string;
    icon_url: string;
  }[];
  total: number;
}

const MinhasConquistas: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [achievements, setAchievements] = useState<IAchievementsProps>({} as IAchievementsProps);

  // GET BADGES
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/achievements/last',
          {params: { quantity: 7777 }}
        );

        console.log('LIST ACHIVEMENTS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setAchievements(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return(
    <Container>
        <ProfileHeader />
        <ContentGrid1>
          <section>
            <div>
              <Accordion />
              <footer>
                {/* <Button color="success" onClick={() => handleSaveChanges()} >Salvar mudanças!</Button> */}
              </footer>
            </div>
          </section>
          <section>
            <HeaderInfo supTitle="Some pontos para o ranking" title="Minhas Conquistas" showSendButton={false} margin="0 0 1.5rem 0" />
            <section>
              {achievements.achievements && achievements.achievements.length === 0 && <NotFound>Poxa, você ainda não desbloqueou nenhuma conquista 😢</NotFound>}
              <ContentGrid2>
                {loading && <Loader isFixed={false} />}
                {achievements.achievements && achievements.achievements.length > 0 && achievements.achievements?.map((achievement, index) => (
                  <Card key={index}>
                    <div>
                      <Image src={achievement.icon_url} lazyLoad={true} />
                    </div>
                    <aside>
                      <h4>{achievement.title}</h4>
                      <p>{achievement.requirement}</p>
                      <footer>Desbloquada em {format(parseISO(achievement.acquired_at), "dd'/'MM'/'yyyy", { locale: ptBR })}</footer>
                    </aside>
                  </Card>
                ))}
              </ContentGrid2>
            </section>
          </section>
        </ContentGrid1>
        <Footer />
    </Container>
  );
}

export default MinhasConquistas;
