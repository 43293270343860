import React from 'react';

import { Container } from './styles';

const SmallLoader: React.FC = () => {
  return (
    <Container>
      <main>
        <div>
          <span />
        </div>
      </main>
    </Container>
  );
}

export default SmallLoader;
