import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

import { Container } from './styles';

interface INextVideoTumbnailsProps {
  thumbnailUrl: string;
  lessonOrder: number;
  lessonTitle: string;
  lessonDuration: string;
  progress: number;
  current?: boolean;
  onClick: (e:  React.MouseEvent) => void;
}

const NextVideoTumbnails: React.FC<INextVideoTumbnailsProps> = ({thumbnailUrl, lessonOrder, lessonTitle, lessonDuration, progress, current, onClick}) => {
  return (
    <Container onClick={onClick} current={current}>
      <div>
        <figure>
          <div>assistindo</div>
          <img src={thumbnailUrl} alt={lessonTitle} />
        </figure>
        <main>
          <p>Aula {lessonOrder}</p>
          <h3>{lessonTitle}</h3>
          <ProgressBar completed={progress} height="3px" margin="7px 0 0 0" isLabelVisible={false} baseBgColor="rgba(204, 204, 204, 0.3)" bgColor="#6e61c6" />
        </main>
        <aside>
          <p>{lessonDuration}</p>
        </aside>
      </div>
    </Container>
  );
}

export default NextVideoTumbnails;
