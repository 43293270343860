const dateMask = (value: any) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  value = value.replace(/(\d{2})(\d{2})$/, "$1$2");
  value = value.slice(0, 10)
  return value;
}

export default dateMask;
