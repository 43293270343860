import styled from 'styled-components';

interface IBoxContainerProps {
  position?: string;
  margin?: string;
  padding?: string;
  height?: string;
  maxHeight?: string;
  textAlign?: 'center' | 'left' | 'right';
  display?: 'block' | 'flex' | 'grid';
  justifyContent?: 'center' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  overflow?: string;
  borderRadius?: string;
}

export const Container = styled.div<IBoxContainerProps>`
  width: 100%;
  position: ${props => props.position ? props.position : null };
  height: ${props => props.height ? props.height : null };
  max-height: ${props => props.maxHeight ? props.maxHeight : null };
  align-items: center;
  min-height: 50px;
  box-sizing: border-box;
  text-align: ${props => props.textAlign ? 'center' : 'unset'};
  display: ${props => props.display ? props.display : 'block' };
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row' };
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'unset' };
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadowBox};
  margin: ${props => props.margin ? props.margin : '0' };
  padding: ${props => props.padding ? props.padding : '0 28px'};
  overflow: ${props => props.overflow ? props.overflow : 'unset'};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};

  > form {
    display: flex;
    width: 100%;
  }
`;
