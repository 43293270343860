import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseISO } from 'date-fns/esm';

import ProfileHeader from '../../components/ProfileHeader';
import Accordion from '../../components/Accordion';
import HeaderInfo from '../../components/HeaderInfo';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import Footer from '../../components/Footer';

import { Container, ContentGrid1, Showing } from './styles';

interface IHistoricoLoginProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    user_id: string;
    ip: string;
    browser_name: string;
    browser_version: string;
    os_name: string;
    os_version: string;
    city: string;
    state: string;
    country: string;
    created_at: string;
    updated_at: string;
    device: string;
    os: string;
    browser: string;
    location: string;
    device_model?: string;
    device_type?: string;
    device_vendor?: string;
  }[];
}

const HistoricoLogin: React.FC = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(false);

  const [loginLogs, setLoginLogs] = useState({} as IHistoricoLoginProps);

  useEffect(() => {
    async function loadData() {
      try {
        window.scrollTo(0, 0);
        setLoading(true);

        const response = await api.get('/profile/login-logs',
          {params: { pageIndex: currentPage - 1, pageSize: 4, }}
        );

        console.log('LIST LOGIN LOGS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);
        setLoginLogs(response.data);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

      } catch (error) {
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    }

    loadData();

  }, [currentPage]);

  return(
    <Container>
        <ProfileHeader />
        <ContentGrid1>
          <section>
            <div>
              <Accordion />
              <footer>
               {/*  <Button color="success" onClick={() => handleSaveChanges()} >Salvar mudanças!</Button> */}
              </footer>
            </div>
          </section>
          <section>
            <HeaderInfo supTitle="Segurança" title="Histórico de acessos" showSendButton={false} margin="0 0 1.5rem 0" />
            <section>
              {loading && <Loader isFixed={false} />}
              <ul>
                <li>
                  A conta do “Usuário” é de uso individual e não deve ser compartilhada. Caso isso seja identificado,
                  poderá ocorrer o cancelamento do cadastro do “Usuário” e em hipótese nenhuma ocorrerá a devolução de
                  qualquer valor pago, conforme disposto nos <a href="poxalulu.com.br/termos-de-uso.html" target="_blank" rel="nofollow">Termos de Uso</a>,
                  aceito por você no ato da compra.
                </li>
              </ul>
              <main>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <td>Data</td>
                        <td>IP</td>
                        <td>Dispositivo</td>
                        <td>Local</td>
                      </tr>
                    </thead>
                    <tbody>
                      {loginLogs.records?.map((log, index) => (
                        <tr key={index}>
                          <td data-label="Data">{format(parseISO(log.created_at), "d 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR })}</td>
                          <td data-label="IP">{log.ip}</td>
                          <td data-label="IP">{log.device}</td>
                          <td data-label="Local">{log.location}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </main>
            </section>
            <footer>
              <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} registros de acesso`}</Showing>
              {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
            </footer>
          </section>
        </ContentGrid1>
        <Footer />
    </Container>
  );
}

export default HistoricoLogin;
