import React from 'react';

import { Container } from './styles';

import loveReactIcon from '../../../assets/reaction-icons/love.png';
import likeReactIcon from '../../../assets/reaction-icons/like.png';
import funnyReactIcon from '../../../assets/reaction-icons/funny.png';
import happyReactIcon from '../../../assets/reaction-icons/happy.png';
import sadReactIcon from '../../../assets/reaction-icons/sad.png';

interface IInlineReactionProps {
  love?: number;
  like?: number;
  funny?: number;
  happy?: number;
  sad?: number;
}

const InlineReaction: React.FC<IInlineReactionProps> = ({love = 0, like = 0, funny = 0, happy = 0, sad = 0})  => {
  const reactionTypes = {
    love: loveReactIcon,
    like: likeReactIcon,
    funny: funnyReactIcon,
    happy: happyReactIcon,
    sad: sadReactIcon,
  };

  const totalReactions = love + like + funny + happy + sad;

  return (
    <Container>
      <aside>
        <div>{totalReactions}</div>
        <span data-count={sad}><img src={reactionTypes['sad']} alt="sad"/></span>
        <span data-count={happy}><img src={reactionTypes['happy']} alt="happy"/></span>
        <span data-count={funny}><img src={reactionTypes['funny']} alt="funny"/></span>
        <span data-count={love}><img src={reactionTypes['love']} alt="love"/></span>
        <span data-count={like}><img src={reactionTypes['like']} alt="like"/></span>
      </aside>
    </Container>
  );
}

export default InlineReaction;
