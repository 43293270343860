import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  padding: 28px 32px;
  border-radius: 12px;
  grid-template-columns: repeat(1, 1fr);
  background: ${props => props.theme.colors.bgBox};

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > div {

    > form {
      display: flex;
      position: relative;

      > div:first-child {
        z-index: 9;
      }
    }
  }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(2, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > a {
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: start;
    text-align: left;
    flex-direction: column;
    padding: 32px;
    border-radius: 12px;
    text-decoration: none;
    background: ${props => props.theme.colors.bgBox};
    transition: all .2s ease-in-out;
    border: 2px solid ${props => props.theme.colors.bgBox};

    &:hover {
      border: 2px solid ${props => props.theme.colors.primary};
    }

    > h1 {
      line-height: 1;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.textColor};
    }

    > h2 {
      line-height: 1;
      font-size: 32px;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.primary};
    }

    > p {
      color: #9aa4bf;
      font-size: .875rem;
      font-weight: 500;
      margin-bottom: 1rem;
      line-height: 1.4285714286em;
    }

    > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #9aa4bf;
      font-size: .875rem;
      font-weight: 600;

      > span {
        font-size: .65rem;
        margin-top: 3px;
      }
    }
  }

`;

export const SearchButton = styled.a`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 9;
  text-decoration: none;
  font-size: 1.2rem;
`;

export const NotFound = styled.div`
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadowBox};
  position: absolute;
  z-index: 0;
  width: 100%;
  text-align: center;
  padding: 28px;
  border-radius: 12px;
  font-weight: bold;
`;

export const FkLoadee = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadowBox};
  z-index: 0;
  width: 100%;
  text-align: center;
  padding: 28px;
  min-height: 200px;
  border-radius: 12px;
`;

export const Breadcrumbs = styled.div`
  margin-top: 1rem;

  > ol {

    > li {
      color: ${props => props.theme.colors.textColor};
      display: inline;
      font-weight: 500;
      font-size: 13px;
      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #adafca;

      > a {
        text-decoration: none;
        transition: all .3s ease-in-out;
        color: #adafca;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }

      & + li::before {
        content: "/";
        margin: 0 6px;
      }
    }
  }
`;
