import React, { useState, useCallback, createContext, useContext } from 'react';
import { uuid } from 'uuidv4';

import MarkPhotoContainer from '../components/MarkContainer';

export interface Mark {
  id: string;
  mark_id?: string;
  type: 'negative' | 'positive';
  background: string;
  color: string;
  title: string;
  description: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  offsetX?: number;
  offsetY?: number;
  isNew: boolean;
  isActive: boolean;
  isDeleted?: boolean;
}

interface IShowMark {
  id: string;
  offsetX: number;
  offsetY: number;
}

interface ICoordinate {
  left: string;
  top: string;
  width: string;
  height: string;
}

interface IMoveMark {
  id: string;
  x: number;
  y: number;
}

interface MarkPhotoContextState {
  marks: Mark[];
  deletedMarks: string[];
  addMark(mark: Omit<Mark, 'id' | 'isNew' | 'isActive'>): void;
  removeMark(id: string): void;
  showMark(data: IShowMark): void;
  hideMark(id: string): void;
  moveMark(data: IMoveMark): void;
  isActive(id: string): boolean;
  setInitialMarks(marks: Omit<Mark, 'isNew' | 'isActive'>[]): void;
  getCoordinates(id: string): ICoordinate;
}

const MarkPhotoContext = createContext<MarkPhotoContextState>(
  {} as MarkPhotoContextState,
);

const MarkPhotoProvider: React.FC = ({ children }) => {
  const [marks, setMarks] = useState<Mark[]>([]);
  const [deletedMarks, setDeletedMarks] = useState<string[]>([]);

  const setInitialMarks = useCallback(
    (initialMarks: Omit<Mark, 'isNew' | 'isActive'>[]) => {
      const newInitialMarks = initialMarks.map(mark => ({
        ...mark,
        isNew: false,
        isActive: false,
      }));

      setMarks(newInitialMarks);
    },
    [],
  );

  const getCoordinates = useCallback(
    (id: string): ICoordinate => {
      const mark = marks.find(findMark => findMark.id === id);

      if (!mark) {
        return {
          left: '0px',
          top: '0px',
          width: '0px',
          height: '0px',
        };
      }

      const x3 = Math.min(mark.x1, mark.x2);
      const y3 = Math.min(mark.y1, mark.y2);
      const x4 = Math.max(mark.x1, mark.x2);
      const y4 = Math.max(mark.y1, mark.y2);

      const left = `${x3}px`;
      const top = `${y3}px`;
      const width = `${x4 - x3}px`;
      const height = `${y4 - y3}px`;

      return {
        left,
        top,
        width,
        height,
      };
    },
    [marks],
  );

  const addMark = useCallback((mark: Omit<Mark, 'id' | 'isNew'>) => {
    const newMark = {
      ...mark,
      id: uuid(),
      isNew: true,
      isActive: false,
    };

    setMarks(oldMarks => [...oldMarks, newMark]);
  }, []);

  const removeMark = useCallback((id: string) => {
    setMarks(oldMarks => {
      const oldMarkIndex = oldMarks.findIndex(oldMark => oldMark.id === id);

      if (!oldMarks[oldMarkIndex].isNew) {
        setDeletedMarks(oldDeletedMarks => [...oldDeletedMarks, id]);
      }

      return oldMarks.filter(oldMark => oldMark.id !== id);
    });
  }, []);

  const showMark = useCallback(
    ({ id, offsetX, offsetY }: IShowMark) =>
      setMarks(oldMarks =>
        oldMarks.map(oldMark =>
          oldMark.id === id
            ? { ...oldMark, offsetX, offsetY, isActive: true }
            : { ...oldMark, isActive: false },
        ),
      ),
    [],
  );

  const moveMark = useCallback(({ id, x, y }) => {
    setMarks(oldMarks =>
      oldMarks.map(oldMark =>
        oldMark.id === id
          ? {
              ...oldMark,
              x1: oldMark.x1 + x,
              x2: oldMark.x2 + x,
              y1: oldMark.y1 + y,
              y2: oldMark.y2 + y,
            }
          : oldMark,
      ),
    );
  }, []);

  const hideMark = useCallback(
    (id: string) =>
      setMarks(oldMarks =>
        oldMarks.map(oldMark =>
          oldMark.id === id ? { ...oldMark, isActive: false } : oldMark,
        ),
      ),
    [],
  );

  const isActive = useCallback(
    (id: string) => {
      const mark = marks.find(findMark => findMark.id === id);

      if (!mark) {
        return false;
      }

      return mark.isActive;
    },
    [marks],
  );

  const activeMarks = useCallback(() => {
    return marks.filter(mark => mark.isActive === true);
  }, [marks]);

  return (
    <MarkPhotoContext.Provider
      value={{
        marks,
        deletedMarks,
        addMark,
        removeMark,
        showMark,
        hideMark,
        moveMark,
        isActive,
        setInitialMarks,
        getCoordinates,
      }}
    >
      <MarkPhotoContainer marks={activeMarks()}>{children}</MarkPhotoContainer>
    </MarkPhotoContext.Provider>
  );
};

function useMark(): MarkPhotoContextState {
  const context = useContext(MarkPhotoContext);

  if (!context) {
    throw new Error('useMark must be user within an MarkPhotoProvider');
  }

  return context;
}

export { MarkPhotoProvider, useMark };
