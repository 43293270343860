import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../services/api';


import VideoTumbnail from '../../../components/VideoTumbnail';

interface ILastVideoSuggest {
  id: string;
  slug: string;
  cluster_id: string;
  title: string;
  description: string;
  download: string;
  thumbnail: string;
  video: string;
  duration: number;
  order: number;
  views: number;
  created_at: string;
  updated_at: string;
  cluster: {
    id: string;
    course_id: string;
    title: string;
    description: string;
    thumbnail: string;
    slug: string;
    order: number;
    background: string;
    created_at: string;
    updated_at: string;
    course: {
      id: string;
      exam_name: string;
      title: string;
      description: string;
      active: boolean;
      created_at: string;
      updated_at: string;
      slug: string;
    };
    thumbnail_url: string;
  };
  formatted_duration: string;
  download_url: string;
  thumbnail_url: string;
  progress: {
    id: string;
    user_id: string;
    lesson_id: string;
    position: number;
    created_at: string;
    updated_at: string;
  };
}

const PlayerLastVideo: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [lastVideo, setLastVideo] = useState<ILastVideoSuggest>();

  // List last Video
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<ILastVideoSuggest>('/lessons/last');

        setLoading(true);
        console.log(response.data);

        setLastVideo(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return (
    <>
      <Link to={`/player/${lastVideo?.cluster.course.slug}/${lastVideo?.cluster.slug}/${lastVideo?.slug}`}>
        {lastVideo &&
        <VideoTumbnail
          videoTumbnailUrl={lastVideo.thumbnail ? `https://thumbs.tv.pandavideo.com.br/vz-e7274b3b-4ac/${lastVideo.thumbnail}.png` : `https://b-vz-e7274b3b-4ac.tv.pandavideo.com.br/${lastVideo.video}/thumbnail.jpg `}
          dateView={lastVideo.progress && lastVideo.progress.updated_at}
          videoTitle={lastVideo.title}
          percentView={lastVideo.progress && lastVideo.progress.position * 100 / lastVideo.duration}
          minHeight="461px"
        />}
      </Link>
    </>
  );
}

export default PlayerLastVideo;
