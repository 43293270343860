import styled from 'styled-components';
interface ContainerProps {
  isOpen: boolean;
  width?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: inline-block !important;

  > div {
    position: absolute;
    border-radius: 10px;
    right: -8px;
    top: 2rem;
    width: ${props => (props.width ? props.width : '385px')};
    background: ${props => props.theme.colors.bgBox};
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transform: ${props => (props.isOpen ? 'translate(0px, 10px)' : 'translate(0px, -40px)')};
    transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
    z-index: 999;
  }
`;
