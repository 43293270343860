import styled from "styled-components";

export const ReportAnswer = styled.div`
  color: #3e3f5e;
  text-align: left;

  > form {

    > h2 {
      margin-bottom: 1rem;
    }

    > div {
      text-align: left;
      display: flex;
      flex-direction: column;

      > h5 {
        font-size: 12px;
        margin-top: 1rem;
      }

      > button {
        margin-top: 1rem;
        border: none;
        border-radius: 10px;
        padding: 1.15rem .4rem;
        font-weight: bold;
        align-items: center;
        box-shadow: 4px 7px 12px 0 rgb(97 93 250 / 20%);
        font-size: 1rem;
        transition: all .3s ease-in-out;
        background: #6e61c6;
        color: #fff;
        height: 48px;
        padding: 11px 1.2rem;
        font-size: .875rem;
        font-weight: 700;
        text-transform: uppercase;

        &:disabled {
          background: #f7f7f7;
          border: 1px solid #d2d7e3;
          color: #b3bbcf;
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      > p {
        text-align: center;
        color: #ea5353;
        font-size: 11px;
        margin-top: .5rem;
        font-weight: bold;
      }

      > textarea {
        border: 1px solid #9aa4bf;
        min-height: 60px;
        margin-top: .2rem;
        padding:  .25rem .5rem;
        resize: none;
      }
    }


    label {
      display: flex;
      cursor: pointer;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      margin-bottom: 0.375em;

      input {
        position: absolute;
        left: -9999px;

        &:checked + aside {
          background-color: mix(#fff, #6e61c6, 84%);

          &:before {
            box-shadow: inset 0 0 0 0.4375em #6e61c6;
          }
        }
      }

      aside {
        display: flex;
        align-items: center;
        padding: 0.375em 0.75em 0.375em 0;
        border-radius: 50%;
        transition: 0.25s ease;

        &:hover {
          background-color: mix(#fff, #6e61c6, 84%);
        }

        &:before {
          display: flex;
          flex-shrink: 0;
          content: "";
          background-color: #fff;
          width: 1.2em;
          height: 1.2em;
          border-radius: 50%;
          margin-right: 1rem;
          transition: 0.25s ease;
          box-shadow: inset 0 0 0 0.125em #6e61c6;
        }

        > div {
          line-height: 1;

          > h5 {
            color: #3e3f5e;
            margin-bottom: .2rem;
            font-size: 12px;
          }

          > p {
            color: #9aa4bf;
            font-size: 11px;
          }
        }
      }
    }
  }

`;

