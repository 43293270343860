import React, { useState, useEffect, useCallback } from 'react';

import api from '../../services/api';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Calendar, dateFnsLocalizer, Event, Messages } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { format, parse, startOfWeek, getDay, startOfMonth, endOfMonth, endOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import SectionBanner from '../../components/SectionBanner';
import HeaderInfo from '../../components/HeaderInfo';
import SingleEventCard from './SingleEventCard';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import ConnectionFailed from '../../components/ConnectionFailed';
import Button from '../../components/Button';

import CreateEvent, {IAddEventDTO} from './CreateEvent';

import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/events-icon.png';
import defaultCoverEvent from '../../assets/default-event.png';

import { Container, ContentGrid1, ContentGrid2, Header, SingleEvent2 } from './styles';

interface ICalendarioProps {
  id: number;
  course_id?: string;
  user_id?: string;
  start: Date;
  end: Date;
  title: string;
  description: string;
  link?: string;
  created_at: Date;
  updated_at: Date;
  cover_url?: string;
}

const Calendario: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const [startDate, setStartDate] = useState(startOfWeek(startOfMonth(new Date())));
  const [endDate, setEndDate] = useState(endOfWeek(endOfMonth(new Date())));

  const [events, setEvents] = useState<ICalendarioProps[]>([]);
  const [nextEvents, setNextEvents] = useState<ICalendarioProps[]>([]);

 // Get window dimensions
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  // Get events per mouth
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/schedule',
          {params: { from: startDate, to: endDate, }}
        );

        console.log('CALENDAR EVENTS >>>>>>>>>>>>');
        console.log(response.data);
        const addCover = response.data.map((eventData: any) => {
          const cover_url =  eventData.id ? eventData.cover_url: 'https://poxalulu-api.s3.sa-east-1.amazonaws.com/renovacao-credits.png';

          return {...eventData, cover_url}
        })
        setEvents(addCover);
      } catch (error) {
        console.log(error);
        setConnectionFailed(true)
      }
      finally {
        setLoading(false);
      }
    }

    loadData();
  }, [startDate, endDate]);


  // Get next events
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get('/schedule/next',
          {params: { count: 4, }}
        );

        console.log('NEXT EVENTS >>>>>>>>>>>>');
        console.log(response.data);
        setNextEvents(response.data);
      } catch (error) {
        console.log(error);
      }
      finally {

      }
    }

    loadData();
  }, []);

  const locales = { 'pt-BR': ptBR };
  const localizer = dateFnsLocalizer({format, parse, startOfWeek, getDay, locales });
  const messages: Messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    showMore: ((total) => `+ ${total} evento${total > 1 && 's'}`),
  }

  const handleAddEvent = useCallback(
    async ({ start, title, description, link }: IAddEventDTO) => {
      try {
        console.log(start);
        console.log('new Date(start)', new Date(start));
        console.log('parseISO(start)', format(start, 'yyyy-MM-dd HH:mm', { locale: ptBR }));

        const response = await api.post('/schedule',
          {
            start: start,
            end: start,
            title,
            description,
            link,
          }
        );

        console.log(response.data);

        setEvents(oldEvents => [...oldEvents,  response.data]);
      } catch (error) {
        console.log(error);
      }
      finally {

      }
    },
    []
  );

  // Modal Create single Event
  const handleCreateEvent = useCallback(() => {
    let MySwal = withReactContent(Swal);

    MySwal.fire({
      html: <CreateEvent addEvent={handleAddEvent} />,
      background: '#1d2333',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, [handleAddEvent]);


  // Remove Event from calendar
  const handleRemoveFromCalendar = useCallback(async (event: any) => {
    try {
      await api.delete(`/schedule/${event.id}`);
      Swal.close();
      toast.warning('🤷‍♂️ Lembrete excluído com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setEvents(oldEvents =>
        oldEvents.filter(events => events.id !== event.id),
      );

    } catch (error) {
      console.log(error);
      Swal.close();
      toast.error('😢 Não foi possível excluir o lembrete!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    finally{

    }
  }, []);

  // Modal open single event
  const handleOpenEvent = useCallback((event) => {
    let MySwal = withReactContent(Swal);
    MySwal.fire({
      html: (
        // id, course_id, user_id, start, end, title, description, link, created_at, updated_at, cover_url
        <SingleEvent2>
          <header style={
            event?.cover_url && {
              background: `url(${event?.cover_url}) center center`,
              height: '222px',
          }} />
          <main>
            <div>
              <h1>{event.title}</h1>
            </div>
            <div>
              <Icon icon="icon-icon-events" margin="0 .85rem 0 0" size="20px" />
              {
                event.start === event.end ?
                  <main>
                    <h4>Data e hora</h4>
                    <p>{format(new Date(event.start), "EEEE, dd 'de' MMMM 'às' HH:mm", {locale: ptBR})}</p>
                  </main>
                :
                  <>
                    <h4>Data e hora</h4>
                    <h4>{format(new Date(event.start), "EEEE, dd 'de' MMMM 'às' HH:mm", {locale: ptBR})} até {format(new Date(event.end), "EEEE, dd 'de' MMMM 'às' HH:mm", {locale: ptBR})}</h4>
                  </>
              }
            </div>
            {event.link && <div>
              <Icon icon="icon-icon-send-message" margin="0 .85rem 0 0" size="20px" />
              <main>
                <h4>Link</h4>
                <p><a href={event?.link} target="_blank" rel="noreferrer">{event?.link}</a></p>
              </main>
            </div>}
            {event.description && <div style={{margin: '2rem 0 1rem 0'}}>
              <main>
                <h4>Descrição:</h4>
                <p style={{margin: '.5rem 0 0 0'}}>{event.description}</p>
              </main>
            </div>}

            {(event.user_id && event.id) && <footer>
              <button type="button" onClick={() => handleRemoveFromCalendar(event)}>Remover do calendário</button>
            </footer>}
          </main>
        </SingleEvent2>
        ),
      background: '#1d2333',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, [handleRemoveFromCalendar]);

  return(
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Calendário" subTitle="Gerencie e crie facilmente eventos ou lembretes!" />
      <Header>
        <div>
          <p>Monte seu plano</p>
          <h1>Visão geral</h1>
        </div>
        <div>
          <Button color="primary" onClick={handleCreateEvent}>
            <Icon icon="icon-icon-events-monthly" color="#ffffff" size="14px" margin="0 8px 0 0" />Criar lembrete
          </Button>
        </div>
      </Header>
      <ContentGrid1>
      <section>
        <Calendar
          popup={true}
          culture={'pt-BR'}
          defaultView={'month'}
          events={events}
          messages={messages}
          localizer={localizer}
          startAccessor="start"
          onNavigate={(date, view, action) => {
            if (view === 'month') {
              setStartDate(startOfWeek(startOfMonth(date)));
              setEndDate(endOfWeek(endOfMonth(date)));
            }
          }}
          view="month"
          views={["month"]}
          endAccessor="end"
          onSelectEvent={(event: Event) => handleOpenEvent(event)}
          formats={{
            weekdayFormat: (date) => {
              const formattedDate =  windowDimensions.width < 780 ? format(date, 'EEEEEE', { locale: ptBR }) : format(date, 'EEEE', { locale: ptBR });
              return formattedDate;
            }
          }}

          style={{height: 777,}}
          eventPropGetter={(event)  => {
            // @ts-ignore
            if (event.title === 'Data do fim do seu plano') {
              return { style: { backgroundColor: '#00c3ff' } }
            }
            // @ts-ignore
            if (!event.id) {
              return { style: { backgroundColor: '#f09c54' } }
            }
              //@ts-ignore
              return { style: { backgroundColor: event.user_id === null ? '#f82735' : '#6e61c6' } }
            }
          }
        />
      </section>
      {loading && <Loader isFixed={false}  />}
      {connectionFailed && <ConnectionFailed />}
    </ContentGrid1>
    {nextEvents.length > 0 && <HeaderInfo supTitle="Veja o que está por vir" title="Próximos eventos" margin="2rem 0 1rem 0" showSendButton={false} />}
    <ContentGrid2>
      <section>
        {
          nextEvents.slice(0, 4).map((event, index) => {

            return (
              <SingleEventCard
                onClick={() => handleOpenEvent(event)}
                key={index}
                start={event.start}
                end={event.end}
                title={event.title}
                description={event.description}
                link={event?.link}
                cover_url={event.cover_url ? event.cover_url : `${defaultCoverEvent}`}
              />
            )
          })}
      </section>
    </ContentGrid2>
    <Footer />
    </Container>
  );
}

export default Calendario;
