import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';

import ReactMarkdown from 'react-markdown';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Icon from '../../components/Icon';
import UserAvatar from '../../components/UserAvatar';
import TicketCard from './TicketCard';
import Pagination from '../../components/Pagination';
import Footer from '../../components/Footer';
import WysiwygSupport from './WysiwygSupport';

import { Container, ContentGrid2, WysisygContainer, AbsoluteHeader, Status, NewTicket, Showing, AnswersLoaderContent, ModalFinishSupport } from './styles';

import truncateStrings from '../../utils/truncateStrings';
import Loader from '../../components/Loader';

import supportProfilePic from '../../assets/support_avatar.png';

export interface ISuportListProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    student_id: string;
    category_id: string;
    title: string;
    description: string;
    rating?: number;
    status: 'pending' | 'awaiting' | 'closed' | 'canceled';
    created_at: string;
    updated_at: string;
    student: {
      id: string;
      first_name: string;
      last_name: string;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    };
    category: {
      id: string;
      name: string;
    };
  }[];
}

interface ITicketProps {
  id: string;
  student_id: string;
  category_id: string;
  category: {
    id: string;
    name: string;
  }
  title: string;
  description: string;
  rating?: number;
  status: 'pending' | 'awaiting' | 'closed' | 'canceled';
  created_at: string;
  updated_at: string;
  student: {
    id: string;
    first_name: string;
    last_name: string;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
}

export interface IAnswersProps {
  id: string;
  ticket_id: string;
  user_id: string;
  text: string;
  created_at: string;
  updated_at: string;
  user: {
    id: string;
    university_id?: null;
    career_id?: null;
    first_name: string;
    last_name: string;
    nickname?: null;
    phone_number?: null;
    cpf: string;
    email: string;
    birthday: string;
    gender: string;
    avatar: string;
    cover?: null;
    about?: null;
    role: string;
    price_per_wording?: null;
    bank: string;
    agency: string;
    account: string;
    account_type: string;
    active: boolean;
    exp: number;
    cep?: null;
    street?: null;
    number?: null;
    complement?: null;
    neighborhood?: null;
    city?: null;
    state?: null;
    is_public: boolean;
    last_privacy_update?: null;
    created_at: string;
    updated_at: string;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
}


const SuportTicket: React.FC = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingAnswers, setLoadingAnswers] = useState(false);

  const [supportList, setSupportList] = useState<ISuportListProps>({} as ISuportListProps);

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [currentTicket, setCurrentTicket] = useState<ITicketProps>({} as ITicketProps);
  const [answers, setAnswers] = useState<IAnswersProps[]>([]);

  //LIST ANSWERS
  const handleListAnswers = useCallback((id) => {
    async function loadData() {
      try {
        setLoadingAnswers(true);
        const response = await api.get(`/tickets/${id}`);

        console.log('LIST ANSWERS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setAnswers(response.data.answers);

      } catch (error) {
        console.log(error);
      } finally {
        setLoadingAnswers(false);
      }
    }

    loadData();
  }, []);

  // List tickets
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/profile/tickets',
          { params: { pageIndex: currentPage - 1, pageSize: 3 } }
        );

        console.log('LIST USER TICKETS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setSupportList(response.data);
        setCurrentTicket(response.data.records[0]);

        handleListAnswers(response.data.records[0].id);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [currentPage, handleListAnswers]);

  const handleOpenFinishSupport = useCallback(() => {
    let reportModal = withReactContent(Swal);

    reportModal.fire({
      html: (
        <ModalFinishSupport>
          <h1>Atualização Importante Sobre o Suporte</h1>
          <p>{user.first_name},</p>
          <p>Esperamos que esteja aproveitando a experiência com a plataforma Poxalulu. Estamos sempre procurando maneiras de melhorar nosso suporte e serviços para garantir que suas necessidades sejam atendidas da forma mais eficiente e eficaz possível.</p>
          <p>Com isso em mente, gostaríamos de informar que, a partir do dia 02/08/2023, todos os chamados de suporte à nossa plataforma serão gerenciados através do e-mail.</p>
          <h2>Como isso afeta você?</h2>
          <ol>
            <li>Facilidade de comunicação: Ao invés de precisar navegar pela nossa plataforma para registrar um chamado, agora você pode simplesmente nos enviar um e-mail com sua questão ou preocupação.</li>
            <li>Tempo de resposta mais rápido: Estamos comprometidos em responder a todos os e-mails o mais rápido possível, garantindo que suas questões sejam resolvidas em tempo hábil.</li>
          </ol>
          <p>Por favor, para registrar um chamado ou fazer uma pergunta, envie um e-mail para <a href="mailto:suporte@poxalulu.com.br" target="_blank" rel="noreferrer">suporte@poxalulu.com.br</a> incluindo o máximo de detalhes possíveis. Assim que recebermos seu e-mail, um membro da nossa equipe de suporte entrará em contato com você para ajudá-lo.</p>
          <p>Atenciosamente,<br/> <strong>Equipe de Suporte da Poxalulu</strong></p>
        </ModalFinishSupport>
      ),
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: 600,
    });
  }, [user.first_name]);

  return (
    <>
      <AbsoluteHeader />
      <Container>
        {loading && <Loader isFixed={false} />}
        <ContentGrid2>
          <section>
            <NewTicket onClick={handleOpenFinishSupport}>
              <Icon icon="icon-icon-comment" size="40px" />
              <h1>Criar novo chamado</h1>
              <p>Clique aqui para enviar uma solicitação</p>
            </NewTicket>
            {supportList?.records && supportList?.records?.length > 0 && <h2>Seus chamados</h2>}
            {supportList?.records && supportList?.records.map((ticket, index) => (
              <TicketCard
                key={index}
                title={ticket.title}
                status={ticket.status}
                category={ticket.category.name}
                description={truncateStrings(ticket.description, 277)}
                created_at={ticket.created_at}
                onClick={() => { setCurrentTicket(ticket); handleListAnswers(ticket.id) }}
                className={currentTicket?.id === ticket.id ? 'active' : ''}
              />
            ))}
            <footer>
              <Showing>{totalRecords !== 0 && `Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} tickets abertos`}</Showing>
              {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
            </footer>
          </section>
          {currentTicket && <section>
            <main>
              <div>
                <aside>
                  <h2>CENTRAL DE AJUDA / {currentTicket?.category?.name}</h2>
                  <h1>{currentTicket?.title}</h1>
                  {currentTicket?.created_at && <p>{format(parseISO(currentTicket?.created_at), "dd'/'MM'/'yyyy 'às' HH:mm", { locale: ptBR })}</p>}
                </aside>
                {(currentTicket?.status === 'pending' || currentTicket?.status === 'awaiting') && <Status className={currentTicket?.status}>Pendente</Status>}
                {currentTicket?.status === 'closed' && <Status className={currentTicket?.status}>Finalizado</Status>}
                {currentTicket?.status === 'canceled' && <Status className={currentTicket?.status}>Cancelado</Status>}
              </div>
              <main>
                <aside>
                  <UserAvatar size="sm" totalExp={user.exp} picture={user.avatar_url} showOnlyHehaxon={false} />
                  <h6>{user.show_name}</h6>
                </aside>
                <aside>
                  <p><ReactMarkdown>{currentTicket?.description}</ReactMarkdown></p>
                  {currentTicket?.created_at && <h5>{format(parseISO(currentTicket?.created_at), "dd'/'MM'/'yyyy 'às' HH:mm", { locale: ptBR })}</h5>}
                </aside>
              </main>
              {answers && answers?.map((answer, index) => (
                <main key={index}>
                  {answer.user.role === 'student' ?
                    <aside>
                      <UserAvatar size="sm" totalExp={answer.user.exp} picture={answer.user.avatar_url} showOnlyHehaxon={false} />
                      <h6>{answer.user.show_name}</h6>
                    </aside>
                    :
                    <aside>
                      <UserAvatar size="sm" totalExp={10000} picture={supportProfilePic} showOnlyHehaxon={false} />
                      <h6>Suporte <br />Técnico</h6>
                    </aside>
                  }
                  <aside>
                    <p><ReactMarkdown>{answer.text}</ReactMarkdown></p>
                    {answer && <h5>{format(parseISO(answer?.created_at), "dd'/'MM'/'yyyy 'às' HH:mm", { locale: ptBR })}</h5>}
                  </aside>
                </main>
              ))}
              {loadingAnswers && (
                <AnswersLoaderContent>
                  <Loader isFixed={false} />
                </AnswersLoaderContent>
              )}
            </main>
            {currentTicket?.status !== 'closed' && (
              <WysisygContainer>
                <WysiwygSupport placeholder="Escreva sua resposta aqui!" ticket_id={currentTicket?.id} maxLength={500} setAnswers={setAnswers} />
              </WysisygContainer>
            )}
          </section>}
        </ContentGrid2>
        <Footer />
      </Container>
    </>
  );
}

export default SuportTicket;
