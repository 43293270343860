import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuid_v4  } from 'uuid';

import { useTheme } from '../../hooks/theme';

import { Container } from './styles';
interface IUserAvatarProps {
  picture: string;
  totalExp: number;
  showOnlyHehaxon?: boolean;
  zIndex?: number;
  size: 'sm' | 'md' | 'lg';
}

interface IDimension {
  width: number;
  height: number;
  radius?: number;
  lineWidth?: number;
}

interface ISizes {
  sm: {
    name: string;
    main: IDimension;
    image: IDimension;
    border: IDimension;
    progress: IDimension;
    hexagon: IDimension;
    dark: IDimension;
  }
  md: {
    name: string;
    main: IDimension;
    image: IDimension;
    border: IDimension;
    progress: IDimension;
    hexagon: IDimension;
    dark: IDimension;
  }
  lg: {
    name: string;
    main: IDimension;
    image: IDimension;
    border: IDimension;
    progress: IDimension;
    hexagon: IDimension;
    dark: IDimension;
  }
}

const UserAvatar: React.FC<IUserAvatarProps> = ({
  picture,
  totalExp = 0,
  size,
  showOnlyHehaxon,
  zIndex
}) => {
  const { theme } = useTheme();
  const mainRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const borderRef = useRef<HTMLDivElement>(null);
  const hexagonRef = useRef<HTMLDivElement>(null);
  const darkRef = useRef<HTMLDivElement>(null);

  const [id] = useState(uuid_v4());
  const [sizes] = useState<ISizes>({
    sm: {
      name: 'small',
      main: {
        width: 50,
        height: 56,
      },
      image: {
        width: 30,
        height: 32,
        radius: 1,
      },
      border: {
        width: 40,
        height: 44,
        radius: 1,
        lineWidth: 3,
      },
      progress: {
        width: 40,
        height: 44,
        radius: 1,
        lineWidth: 3,
      },
      hexagon: {
        width: 22,
        height: 24,
        radius: 1,
      },
      dark: {
        width: 16,
        height: 18,
      },
    },
    md: {
      name: 'medium',
      main: {
        width: 120,
        height: 132,
      },
      image: {
        width: 82,
        height: 90,
        radius: 3,
      },
      border: {
        width: 100,
        height: 110,
        radius: 3,
        lineWidth: 5,
      },
      progress: {
        width: 100,
        height: 110,
        radius: 3,
        lineWidth: 5,
      },
      hexagon: {
        width: 32,
        height: 36,
        radius: 1,
      },
      dark: {
        width: 26,
        height: 28,
      },
    },
    lg: {
      name: 'big',
      main: {
        width: 148,
        height: 164,
      },
      image: {
        width: 100,
        height: 110,
        radius: 3,
      },
      border: {
        width: 124,
        height: 136,
        radius: 3,
        lineWidth: 8,
      },
      progress: {
        width: 124,
        height: 136,
        radius: 3,
        lineWidth: 8,
      },
      hexagon: {
        width: 40,
        height: 44,
        radius: 1,
      },
      dark: {
        width: 32,
        height: 34,
      },
    },
  });

  const [levelInfo, setLevelInfo] = useState({
    level: 0,
    currentExp: 0,
  });

  useEffect(() => {
    const level = totalExp / 100;
    const currentExp = totalExp % 100;

    setLevelInfo({
      level: Math.trunc(level),
      currentExp,
    })

  }, [totalExp]);

  useEffect(() => {
    if (mainRef.current) mainRef.current.innerHTML = '';
    if (imageRef.current) imageRef.current.innerHTML = '';
    if (progressRef.current) progressRef.current.innerHTML = '';
    if (borderRef.current) borderRef.current.innerHTML = '';
    if (hexagonRef.current) hexagonRef.current.innerHTML = '';
    if (darkRef.current) darkRef.current.innerHTML = '';

    // @ts-ignore
    new XM_Hexagon({
      container: `.hexagon-main-${id}`,
      width: sizes[size].main && sizes[size].main.width,
      height: sizes[size].main && sizes[size].main.height,
      roundedCorners: true,
      fill: true,
      roundedCornerRadius: size === 'lg' ? 5 : 2,
      lineColor: theme.title === 'dark' ? '#1d2333' : '#ffffff',
    });

    // @ts-ignore
    new XM_Hexagon({
      container: `.hexagon-image-${id}`,
      width: sizes[size].image.width, // 30
      height: sizes[size].image.height, // 32
      roundedCorners: true,
      roundedCornerRadius: sizes[size].image.radius || 1,
      clip: true
    })

    // @ts-ignore
    new XM_Hexagon({
      container: `.hexagon-border-${id}`,
      width: sizes[size].border.width, // 40
      height: sizes[size].border.height, // 44
      lineWidth: sizes[size].border.lineWidth || 3,
      roundedCorners: true,
      roundedCornerRadius: sizes[size].border.radius || 1,
      lineColor: '#e7e8ee'
    })

    // @ts-ignore
    new XM_Hexagon({
      container: `.hexagon-progress-${id}`,
      width: sizes[size].progress.width,
      height: sizes[size].progress.height,
      lineWidth: sizes[size].progress.lineWidth || 3,
      roundedCorners: true,
      roundedCornerRadius: sizes[size].progress.radius || 1,
      gradient: {
        colors: ['#f577ea', '#6e61c6']
      },
      scale: {
        start: 0,
        end: 1,
        stop: levelInfo.currentExp ? levelInfo.currentExp / 100 : 0,
      }
    })

    // @ts-ignore
    new XM_Hexagon({
      container: `.hexagon-${id}`,
      width: sizes[size].hexagon.width,
      height: sizes[size].hexagon.height,
      roundedCorners: true,
      roundedCornerRadius: sizes[size].hexagon.radius || 1,
      lineColor: theme.title === 'dark' ? '#1d2333' : '#ffffff',
      fill: true
    })

    // @ts-ignore
    new XM_Hexagon({
      container: `.hexagon-dark-${id}`,
      width: sizes[size].dark.width,
      height: sizes[size].dark.height,
      roundedCorners: true,
      roundedCornerRadius: 1,
      lineColor: '#45437f',
      fill: true
    })
  }, [id, sizes, size, levelInfo.currentExp, theme.title, picture]);

  return (
    <Container showOnlyHehaxon={showOnlyHehaxon} zIndex={zIndex}>
      <div className={`user-avatar ${sizes[size].name}`}>
        <div className="user-avatar-border">
          <div ref={mainRef} className={`hexagon-main-${id}`}></div>
        </div>

        <div className="user-avatar-content">
          <div ref={imageRef} className={`hexagon-image-${id}`} data-src={picture}></div>
        </div>

        <div className="user-avatar-progress">
          <div ref={progressRef} className={`hexagon-progress-${id}`}></div>
        </div>

        <div className="user-avatar-progress-border">
          <div ref={borderRef} className={`hexagon-border-${id}`}></div>
        </div>

        <div className="user-avatar-badge">
          <div className="user-avatar-badge-border">
            <div ref={hexagonRef} className={`hexagon-${id}`}></div>
          </div>

          <div className="user-avatar-badge-content">
            <div ref={darkRef} className={`hexagon-dark-${id}`}></div>
          </div>

          <p className="user-avatar-badge-text">{levelInfo.level}</p>
        </div>
      </div>
    </Container>
  )
}

export default UserAvatar;
