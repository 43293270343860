import React, { useCallback, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { convertToRaw, EditorState, ContentState } from 'draft-js';

// @ts-ignore
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from '../Button';

import { Container } from './styles';
import { useParams } from 'react-router-dom';
interface IWysiwygProps {
  title?: string;
  placeholder?: string;
  maxLength: number;
  setAnswers: React.Dispatch<React.SetStateAction<any[]>>;
}

const Wysiwyg: React.FC<IWysiwygProps> = ({ title, placeholder, maxLength, setAnswers }) => {
  const { user } = useAuth();

  const { slug, category, id } = useParams<{ slug: string, category: string, id: string, }>();

  const [editor, setEditor] = useState<EditorState>();
  const [loadingReply, setLoadingReply] = useState(false);

  async function uploadImageCallBack(file: File) {
    try {
      if(file.size > 1024 * 1024 * 3) {
        throw new Error('exceeded-size-limit');
      }
      const data = new FormData();
      data.append("image", file);

      const response = await api.patch('/tickets', data);

      return { data: { link: response.data } };
    } catch(err) {
      console.log(err);

      if(err.message === 'exceeded-size-limit') {
        toast.error('😢 Tamanho da imagem excedido. Máximo de 3MB!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const handleEditorStateChange = (editorState: EditorState) => {
    if (editor) {
      const contentState = editorState.getCurrentContent();
      const oldContent = editor.getCurrentContent();

      if(contentState === oldContent || contentState.getPlainText().length <= maxLength) {
          setEditor(editorState);
      } else {
          const editorState = EditorState.moveFocusToEnd(
              EditorState.push(
                  editor,
                  ContentState.createFromText(oldContent.getPlainText()),
                  'undo',
              )
          );
        setEditor(editorState);
      }
    } else {
      setEditor(editorState);
    }
  }

  const handleSubmitReply = useCallback(async (value: EditorState | any) => {
    const content = convertToRaw(value.getCurrentContent());
      console.log(draftToMarkdown(content));

    try {
      setLoadingReply(true);
      const response = await api.post(`forums/${slug}/${category}/topics/${id}/answers`, {
        content: draftToMarkdown(content),
      });

      console.log(response.data);

      const answer = {
        ...response.data,
        reactions: [],
        author: {
          id: user.id,
          role: user.role,
          exp: user.exp,
          role_name: user.role_name,
          show_name: user.show_name,
          level: user.level,
          current_exp: user.current_exp,
          avatar_url: user.avatar_url,
          career: user.career,
        },
      }

      setAnswers(oldAnswers => [...oldAnswers, answer]);

      setEditor(EditorState.createEmpty());
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingReply(false);
    }
  }, [category, slug, id, setAnswers, user]);

  return (
    <Container>
      <header>
        <p>{title ? title : 'Escreva uma resposta'}</p>
      </header>
      <Editor
        editorState={editor}
        onEditorStateChange={(editorState: EditorState) => {handleEditorStateChange(editorState)}}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        handlePastedText={() => false}
        localization={{
          locale: 'pt',
        }}
        placeholder={placeholder ? placeholder : ''}
        stripPastedStyles={true}
        toolbar={{
          options: ['inline', 'list', 'emoji', 'image'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            linkCallback: undefined
          },
          emoji: {
            className: 'add',
            component: undefined,
            popupClassName: undefined,
            emojis: [
              '💜','😀','😃','😄','😁','😆','😅','😂','🤣','😊','😇','🙂','🙃','😉','😌','😍','🥰','😘',
              '😗','😙','😚','😋','😛','😝','😜','🤪','🤨','🧐','🤓','😎','🤩','🥳','😏',
              '😱','🤗','🤭','😬','😲','🤠','👋','🤚','🖐','✋','🖖','👌','✌️','🤞','🤟','🤘','🤙',
              '👈','👉','👆','👇','☝️','👍','👎','👊','🤛','🤜','👏','🙌','🤝','🙏',
              '✍️','💅','💪','🧠','👀','🏆','🥇','🥈','🥉','🏅','🖊','🖋','✒️','🖌','🖍','📝','✏️',
            ],
          },
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              width: '100%',
            },
          },
        }}
      />
      <footer>
        <div>
          <p>{editor ? editor?.getCurrentContent().getPlainText().length : 0}/{maxLength}</p>
        </div>
        <div>
          <Button
            color={editor && editor?.getCurrentContent().getPlainText().length > 0 ? 'primary' : 'none'}
            disabled={!loadingReply && editor && editor?.getCurrentContent().getPlainText().length > 0 ? false : true}
            onClick={() => handleSubmitReply(editor)}
            >
            Responder
          </Button>
        </div>
      </footer>
    </Container>
  );
}

export default Wysiwyg;
