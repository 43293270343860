import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;

  > h1 {
    color: #3e3f5e;
  }

  > p {
    color: #9aa4bf;
    font-size: 12px;
    text-align: justify;
  }

  > div {
    margin: 2rem 0 0 0;
    position: relative;

    > form {

      > input {
        width: 100%;
        padding: 1rem 1.5rem;
        border-radius: 12px;
        border: 1px solid #adafca30;
        color: #3e3f5e;
        background: #ffffff;
        font-size: 1rem;
        font-weight: 700;
        transition: .3s ease-in-out;

        &:focus {
          border-color: #615dfa;
        }
      }

      > label {
        color: #adafca;
        font-weight: 600;
        position: absolute;
        top: 1rem;
        left: 1rem;
        padding: 0 5px;
        pointer-events: none;
        background: #fff;
        transition: .3s ease;
        font-size: .8rem;
        transform: translateY(-1.7rem);
      }

      > button {
        border: none;
        border-radius: 10px;
        padding: 1.15rem .4rem;
        font-weight: bold;
        align-items: center;
        box-shadow: 4px 7px 12px 0 rgb(97 93 250 / 20%);
        font-size: .8rem;
        transition: all .3s ease-in-out;
        background: #6e61c6;
        border: 1px solid #6e61c6;
        color: #fff;
        margin-top: 1rem;
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: .8rem .4rem;

        &:disabled {
          background: #f7f7f7;
          border: 1px solid #d2d7e3;
          color: #b3bbcf;
          box-shadow: none;
          cursor: default;
        }
      }

      > p {
        color: #e66969;
        font-size: 14px;
        text-align: center;
        margin-top: 1rem;
        font-weight: bold;
      }
    }
  }
`;

