import styled from 'styled-components';

import bgLogin from '../../assets/login-bg.jpg';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: url(${bgLogin}) no-repeat 0 ${props => props.theme.colors.primary};
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  overflow: hidden;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #3e3f5e !important;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    background: ${props => props.theme.colors.primary};
  }
`;

export const ContentGrid1 = styled.div`
  display: flex;
  justify-content: center;

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }
`;

export const Logo = styled.div`
  margin-bottom: 30px;
  text-align: center;

  > img {
    width: 240px;
  }
`;

export const LogoMobile = styled.div`
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;

  > h2 {
    color: #3e3f5e;
    font-weight: 700;
    line-height: 1em;
    text-align: center;

    /* TABLET */
    @media (max-width: 1000px) {
      display: none;
    }
  }

  > img {
    width: 200px;
    display: none;

    /* TABLET */
    @media (max-width: 1000px) {
      display: block;
    }
  }
`;

export const Form = styled.form`
  padding: 1rem 0;
  margin-top: 1rem;
  border-radius: 7px;

  > Input {
    margin: 1.9rem 0 0 0;
  }

  > .forget {
    margin: 1rem 0 2rem 0;
    display: flex;
    justify-content: space-between;

    /* TABLET */
    @media (max-width: 477px) {
      > div {
        display: none;
      }
    }
  }

  > .forget p {
    cursor: pointer;
    color: #d86060;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    width: 100%;
    text-decoration: none;
    text-align: center;
    transition: color .3s;

    > span {
      color: #7777d4;
    transition: color .3s;

    &:hover {
        color: #202996;
      }
    }
  }


`;

/*  */
export const LandingInfo = styled.div`
  display: flex;
  width: 40%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: -7rem;

  /* TABLET */
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const LandingForm = styled.div`
  display: flex;
  width: 40%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  /* TABLET */
  @media (max-width: 1000px) {
    width: 90%;

    > div {

      > img {
        display: none;
      }
    }
  }

  .access-info {
    color: #fff;
    font-size: 10px;
    text-align: center;
  }

  .access-info > Button {
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 56px;
    max-height: 56px;
    padding: 4px .4rem;
  }

  .lined-text {
    margin-top: 2rem;
    font-weight: 700;
    text-align: center;
    position: relative;

    text-align: center;
    font-size: 10px;

    > a {
      color: #9aa4bf80;
      text-decoration: none;
    }
  }
`;

export const ContentIntro = styled.div`
  position: relative;
  text-align: center;
  padding: 1rem 4rem;

  > h2 {
    margin-top: 36px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
  }

  > h1 {
    font-size: 5rem;
    line-height: .8;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
  }

  > p {
    max-width: 480px;
    margin: 2rem auto 0;
    font-size: .8rem;
    line-height: 1.3333333333em;
    font-weight: 500;
    color: #fff;
  }
`;

export const FormBox = styled.div`
  max-width: 484px;
  padding: 3rem 4rem;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
  position: relative;

  > .rocket {
    position: absolute;
    top: -40px;
    left: -90px;
  }

  > h2 {
    color: #3e3f5e;
    font-weight: 700;
    line-height: 1em;
    text-align: center;
  }
`;

export const ModalMaintenance = styled.div`
  text-align: left;
  > h1 {
    color: #fff;
  }

  > p {
    margin-top: .5rem;
  }
`;

export const ModalChromeAndW7Detect = styled.div`
  color: #3e3f5e;
  > h1 {
    margin-top: 1rem;
    text-align: center;
  }
  > p {
    text-align: justify;
  }
  > a {
    &.download {
      width: 100%;
      background: #615dfa;
      border-radius: 6px;
      padding: 12px 0;
      z-index: 9999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      transition: all .2s ease-in-out;
      margin: 1rem 0 .5rem 0;
      text-decoration: none;
    }
    color: #ced3e1;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    width: 100%;
    text-decoration: none;
    text-align: center;
    transition: color 0.3s ease 0s;
    font-weight: 600;
  }
`;
