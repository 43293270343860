// @ts-nocheck
import React from 'react';
import CropPoint from './CropPoint';

// import { Container } from './styles';

interface ICropPointsProps {
  previewDims: {
    ratio: number;
    width: number;
    height: number;
  }
  pointSize: any;
  pointBgColor: any;
  pointBorder: any;
  cropPoints: any;
  bounds: any;
  onDrag(position: any, area: any): void;
  onStop(position: any, area: any, cropPoints: any): void;
}

const CropPoints: React.FC<ICropPointsProps> = ({ previewDims, ...rest }) => {
  return (
    <>
      <CropPoint
        pointArea='left-top'
        defaultPosition={{ x: 0, y: 0 }}
        {...rest}
      />
      <CropPoint
        pointArea='right-top'
        defaultPosition={{ x: previewDims.width, y: 0 }}
        {...rest}
      />
      <CropPoint
        pointArea='right-bottom'
        defaultPosition={{ x: 0, y: previewDims.height }}
        {...rest}
      />
      <CropPoint
        pointArea='left-bottom'
        defaultPosition={{
          x: previewDims.width,
          y: previewDims.height
        }}
        {...rest}
      />
    </>
  );
}

export default CropPoints;
