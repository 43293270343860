import styled from 'styled-components';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 44px;
  border-radius: 12px;
  cursor: pointer;
  color: #adafca;
  background-color: ${props => props.theme.colors.bgBoxMenu};
  box-shadow: 3px 5px 20px 0 rgba(94,92,154,.1);
  transition: box-shadow .2s ease-in-out,background-color .2s ease-in-out;

  > span {
    display: flex;

    > img {
      height: 25px;
      width: 25px;
      margin-right: .5rem;
    }
  }

  > p {
    display: inline;
    font-size: .875rem;
    font-weight: 700;
    transition: color .2s ease-in-out;
    text-transform: capitalize;
    color: ${props => props.theme.colors.textColor};
  }

  > div {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 40px;
    padding: 12px 1rem;
    right: 0;
    top: -4.5rem;
    width: 277px;
    background: ${props => props.theme.colors.bgContent};
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transform: ${props => (props.isOpen ? 'translate(0px, 0px)' : 'translate(0px, 10px)')};
    transition: all .4s ease-in-out;
    z-index: 99999999;

    > span {
      position: relative;
      display: flex;

      &:after {
        content: attr(data-reaction);
        box-shadow: 0 0 40px 0 rgb(94 92 154 / 45%);
        background: ${props => props.theme.colors.bgBox};
        color: ${props => props.theme.colors.textColor};
        position: absolute;
        z-index: 99999;
        border-radius: 1rem;
        text-align: center;
        box-sizing: border-box;
        padding: 0px 12px;
        margin: 0 -7px;
        text-transform: capitalize;
        font-size: .75rem;
        font-weight: 700;
        white-space: nowrap;
        line-height: 24px;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
      }

      &:hover::after {
        opacity: 1;
        visibility: visible;
        transform: translateY(-2.5rem);
      }

      &.active {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
      }

      > img {
        transition: all .2s ease-in-out;
        position: relative;

        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }
`;
