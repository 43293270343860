import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #ff0000;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  z-index: 9999999;

  > p {
    text-align: center;
  }
`;
