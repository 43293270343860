import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 #8d7aff;
      box-shadow: 0 0 0 0 #8d7aff;
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(255,255,255, 0);
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid1 = styled.div`
  overflow: hidden;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 890px 1fr; // FOLHA = 890px / GRID-GAP = 16px / TOTAL 1260px

  /* TABLET */
  @media (max-width: 1260px) {
    display: flex;
    flex-direction: column;
  }
  /* MOBILE */
  @media (max-width: 480px) {

  }

  > main {
    overflow: hidden;
    border-radius: 12px;
    background: #fff;
    box-shadow: ${props => props.theme.colors.shadowBox};
  }

  > section:nth-child(2) {
    position: relative;
    display: flex;
    flex-direction: column;

    > main {
      position: relative;
      width: 100%;

      > button {
        width: 100%;
        height: 48px;
        padding: 0 1.2rem;
        border-radius: 8px;
        color: #ffffff;
        font-size: .875rem;
        font-weight: 700;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        text-decoration: none;
        transition: all .2s ease-in-out;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
      }
    }

    > footer {
      position: relative;
      box-shadow: ${props => props.theme.colors.shadowBox};
      background: ${props => props.theme.colors.bgBox};
      padding: 28px;
      border-radius: 12px;
      margin-top: .5rem;

      /* TABLET */
      @media (max-width: 1260px) {
        margin-top: .5rem;
      }

      > h4 {
        margin-bottom: 2rem;
        font-size: 1.2rem;
        line-height: 1;
        color: ${props => props.theme.colors.textColor};
      }

      > div {
        margin-bottom: 1rem;

        > header {
          color: #adafca;
          display: flex;
          justify-content: space-between;
          margin-bottom: .5rem;

          > h6 {
            color: #ffffff;
            text-transform: uppercase;
            color: ${props => props.theme.colors.textColor};
          }
        }
      }

      > footer {

        > h4 {
          color: ${props => props.theme.colors.textColor};
        }
      }
    }
  }
`;

export const Modal900Content = styled.div`

> img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

> h2 {
  color: #3e3f5e;

  /* MOBILE */
  @media (max-width: 480px) {
    font-size: 16px;
  }
}

> p {
  color: #adafca;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 1;
  margin-top: 1rem;
}

> main {

  > span {
    //letter-spacing: -40px;
    font-weight: 900;
    line-height: 1;
    font-size: 180px;
    text-align: center;
    color: #3e3f5e;
    letter-spacing: -8px;

    /* MOBILE */
    @media (max-width: 480px) {
      font-size: 100px;
      letter-spacing: -6px;
    }
  }
}
`;

export const ContentGrid2 = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 320px auto;

  /* TABLET */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  /* MOBILE */
  @media (max-width: 480px) {

  }

  > section:nth-child(1) {
    display: grid;
    grid-template-rows: auto 200px;

    > div:nth-child(1) {
      position: relative;
      padding: 28px;
      border-radius: 12px;
      background: linear-gradient(45deg, #615dfa, #8d7aff);
      box-shadow: ${props => props.theme.colors.shadowBox};
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      margin-bottom: 1rem;
      animation: pulse 2s infinite;

      > aside {
        display: flex;
        align-items: center;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: column;

          > h4 {
            line-height: 1;
            color: #fff;
            text-align: center;
          }

          > p {
            line-height: 1;
            color: #fff;
            font-size: .7rem;
            font-weight: 700;
            text-align: center;
            margin-bottom: 1rem;
          }
        }
      }

      > main {
        margin-top: 1rem;

        > p {
          line-height: 1;
          color: #fff;
          text-align: justify;
          margin-bottom: .5rem;
          font-size: 14px;
        }
      }
    }

    > div:nth-child(2) {
      position: relative;
      padding: 28px;
      border-radius: 12px;
      background: ${props => props.theme.colors.bgBox};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > h4 {
        color: #adafca;
        padding-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        letter-spacing: 4px;
      }

      > p {
        text-align:center;
        font-size: 70px;
        font-weight: bold;
        line-height: 1;
        color: ${props => props.theme.colors.textColor};
      }

      > footer {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-right: .5rem;
          color: ${props => props.theme.colors.textColor};
          font-weight: bold;
        }

        > div {
          position: relative;
        }

      }
    }
  }

  > section:nth-child(2) {
    position: relative;
    padding: 28px;
    border-radius: 12px;
    background: ${props => props.theme.colors.bgBox};
    box-shadow: ${props => props.theme.colors.shadowBox};

    > h4 {
      line-height: 1;
      color: ${props => props.theme.colors.textColor};
      margin-bottom: 1rem;
    }

    > main {

      > table {
        color: ${props => props.theme.colors.textColor};

        .competencia {
            width: 45%;
          }

          .nota {
          text-align: center;
          width: 15%;
          font-size: 24px;
          font-weight: bold;
          color: ${props => props.theme.colors.textColor};
          vertical-align: inherit;

          /* MOBILE */
          @media (max-width: 480px) {
            font-size: 20px;
          }
        }

        .comentario {
            width: 40%;
            font-size: 14px;
            padding-top: .5rem;
          }

        .text-center {
          text-align: center;
        }

        > thead {
          /* MOBILE */
          @media (max-width: 480px) {
            font-size: 12px;
          }

          > tr {
            text-align: left;

            > th {
              color: #adafca;
              padding-bottom: 20px;
              text-transform: uppercase;
            }

            > td {
              vertical-align: top;
              padding-bottom: 30px;
            }
          }
        }

        > tbody {

          > tr {
            text-align: left;

            > th {
              color: #adafca;
              padding-bottom: 20px;
              text-transform: uppercase;
            }

            > td {
              vertical-align: top;
              padding-bottom: 30px;

              > h3 {
                font-size: 14px;

                > span {
                  border-radius: 4px;
                  padding: 1px 8px;
                  color: #fff;

                  /* MOBILE */
                  @media (max-width: 480px) {
                    font-size: 10px;
                    padding: 1px 4px;
                  }
                }
              }

              > p {
                font-size: 14px;
                padding-left: 4px;
                padding-top: .5rem;
              }
            }
          }
        }
      }
    }
  }
  `;

export const AvatarContent = styled.div`
  margin-top: -0.5rem;
  margin: -0.5rem 0;

  .user-avatar-border {
    display: none;
  }
  `;

export const ConfirmDiv = styled.div`

  > footer {
    display: flex;
    justify-content: center;
  }
`;

export const BlockVotation = styled.main`
  background: transparent;
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
`;
