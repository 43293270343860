import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  //box-shadow: ${props => props.theme.colors.shadowBox};

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    transition: all .3s ease-in-out;
    cursor: pointer;
    background-image: linear-gradient(to bottom, transparent, #6e61c6);
    opacity: 1;

    > button {
      border: 2px solid #ffffff;
      color: #ffffff;
      border-radius: 8px;
      padding: 12px 18px;
      background: #7b6fca;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      opacity: 1;
    }
  }

  > img {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

interface IModalProps {
  isOpen: boolean;
  status: string;
}

export const Modal = styled.section<IModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  overflow: scroll;
  left: 0;
  top: 0;
  background: ${props => props.theme.colors.primary};
  background: linear-gradient(45deg, #615dfa, #8d7aff);
  width: 100%;
  height: 100%;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  z-index: 99999999999999999;

  > div {
    position: fixed;
    z-index: 99999999999999999;
    background: #fff;
    width: 40px;
    height: 40px;
    top: 2rem;
    right: 2rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  > main {
    zoom: 0.35;

    @media (max-width: 1030px) {
      zoom: .65;
    }
    @media (max-width: 650px) {
      zoom: .55;
    }
    @media (max-width: 550px) {
      zoom: .45;
    }
    @media (max-width: 450px) {
      zoom: .35;
    }

    > div {
      width: 890px;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: ${props => props.theme.colors.shadowBox};
    }
  }

  > p {
    text-align: center;
    margin-top: 1rem;
    line-height: 1;
    font-size: 11px;
    padding: 0 2rem;
  }
`;
