import React, { useCallback, useState } from 'react';

import api from '../../../../services/api';

import Swal from 'sweetalert2';

import { Container } from './styles';

const ModalSuggestion: React.FC = () => {

  const [inputSuggest, setInputSuggest] = useState('');

  const handleSubmitSuggestForm = useCallback(async (e, suggest: string) => {
    e.preventDefault();
    console.log('suggest: ', suggest);

    try {
      //setLoading(true);
      const response = await api.post(`/themes/suggest`, {
        "name": suggest
      });

      console.log(response.data);
    } catch(error) {
      console.log(error);
    } finally {
      //setLoading(false);
      Swal.close();
      Swal.fire({
        title: 'Pronto!',
        text: 'Sua sugestão foi enviada com sucesso!',
        icon: 'success',
        background: '#6e61c6',
        backdrop: 'rgba(110, 97, 198, .1)',
        confirmButtonColor: '#03bb85',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
      });
    }

  }, []);

  return (
    <Container>
      <h1>Gostaria de sugerir um tema? 😊</h1>
      <p>Informe no campo abaixo o título que você deseja sugerir</p>
      <div>
        <form onSubmit={(e) => {handleSubmitSuggestForm(e, inputSuggest)}}>
          <input
            type="suggest"
            autoComplete="off"
            defaultValue={inputSuggest}
            onChange={(e) => setInputSuggest(e.target.value)}
            maxLength={255}
            required
          />
          <label htmlFor="suggest">Sugestão de tema</label>
          <button disabled={inputSuggest === '' ? true : false}>Enviar sugestão de tema!</button>
        </form>
      </div>
    </Container>
  );
}

export default ModalSuggestion;
