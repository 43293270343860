import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  margin: 1rem 0;

  /* TABLET */
  @media (max-width: 768px) {
    flex-direction: column;
  }

  //SECTION 1
  > section:nth-child(1) {
    color: ${props => props.theme.colors.textColor};
    position: relative;

    > div {
      position: sticky;
      top: calc(80px + 1rem);

      > aside {
        margin-bottom: 1rem;
      }

      > div {
        margin-bottom: 1rem;

        /* TABLET */
        @media (max-width: 1000px) {
         // display: none;
        }
      }
    }
  }

  //SECTION 2
  > section:nth-child(2) {

    .line {
      position: relative;
    }

    .line::before {
      position: absolute;
      width: 2px;
      height: 100%;
      background: ${props => props.theme.colors.timelineColor};
      content: "";
    }

    .line .content {
      position: relative;
      padding-left: 30px;
    }

    .line .content + .content {
      margin-top: 40px;
    }

    .line .content p {
      font-size: 14px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.textColor};

      > img {
        width: 14px;
        margin-right: 5px;
      }
    }

    .line .content::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background: ${props => props.theme.colors.timelineColor};
      left: -38px;
      top: 43px;
      border-radius: 50%;
    }
  }
`;

export const Timeline = styled.div`
  display: flex;
  padding: 0 0 0 1rem;
  border-radius: 18px;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {
    padding: 0;
  }
  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const TabsCrid = styled.div`
  margin: 2rem 0;

  ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;
  }

  li {
    font-size: .875rem;
    font-weight: 700;
    cursor: pointer;
    background: none;
    padding-right: 1rem;
    border-radius: 0px;
    color: ${props => props.theme.colors.textColor};

    > button {
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-weight: 700;
      color: ${props => props.theme.colors.textColor};
      background: none;
      opacity: .5;
      transition: opacity .3s ease-in-out 0s;

      &.active {
        opacity: 1;
        border-bottom: 4px solid #6e61c6;
      }
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9;
  width: 100%;

  > div {
    width: 100%;

    @media (max-width: 768px) {
      min-height: 400px !important;
    }

    @media (max-width: 480px) {
      min-height: 200px !important;
    }
  }
`;

export const VideoContent = styled.div`
  position: relative;
  background: ${props => props.theme.colors.bgBox} !important;
  padding: 2rem;
  margin-left: 2rem;
  border-radius: 1rem;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.textColor};
  }

  iframe {
    border-radius: 1rem;
    overflow: hidden;
  }
`;


export const SectionMaterials = styled.a`
  position: relative;
  background: ${props => props.theme.colors.bgBox} !important;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 12px;
  width: 210px;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};

  @media (max-width: 768px) {
    width: 100%;

    &.pulse {
      animation: none !important;
    }
  }

  &.pulse {
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.051);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  > header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff20;
    width: fit-content;
    padding: 1rem;
    border-radius: 50%;

    > span {
      position: relative;
      left: -5px;
      top: 2px;
    }
  }

  > footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;

    > p {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
`;
