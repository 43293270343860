import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;

  > ol {

    > li {
      color: ${props => props.theme.colors.textColor};
      display: inline;
      font-weight: 500;
      font-size: 13px;
      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #adafca;

      > a {
        text-decoration: none;
        transition: all .3s ease-in-out;
        color: #adafca;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }

      & + li::before {
        content: "/";
        margin: 0 6px;
      }
    }
  }
`;
