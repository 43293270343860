import React, { useCallback, useEffect, useRef } from 'react';

// import { Container } from './styles';

interface ICropPointsDelimitersProps {
  previewDims: {
    ratio: number;
    width: number;
    height: number;
  };
  cropPoints: {
    'left-top': {
      x: number;
      y: number
    };
    'right-top': {
      x: number;
      y: number
    };
    'right-bottom': {
      x: number;
      y: number
    };
    'left-bottom': {
      x: number;
      y: number
    };
  };
  lineColor: string;
  lineWidth: number;
  pointSize: number;
}

const CropPointsDelimiters: React.FC<ICropPointsDelimitersProps> = ({
  cropPoints,
  previewDims,
  lineWidth = 3,
  lineColor = '#3cabe2',
  pointSize
}) => {
  const canvas = useRef<any>();

  const clearCanvas = useCallback(() => {
    const ctx = canvas.current?.getContext('2d');
    ctx.clearRect(0, 0, previewDims.width, previewDims.height);
  }, [previewDims]);

  const sortPoints = useCallback(() => {
    const sortOrder = ['left-top', 'right-top', 'right-bottom', 'left-bottom'];

    return sortOrder.reduce((acc: string[], pointPos) =>
      //@ts-ignore
      [...acc, cropPoints[pointPos]],
    [])}, [cropPoints]);

    const drawShape = useCallback(
      ([point1, point2, point3, point4]) => {
        const ctx = canvas.current.getContext('2d')
        ctx.lineWidth = lineWidth
        ctx.strokeStyle = lineColor

        ctx.beginPath()
        ctx.moveTo(point1.x + pointSize / 2, point1.y)
        ctx.lineTo(point2.x - pointSize / 2, point2.y)

        ctx.moveTo(point2.x, point2.y + pointSize / 2)
        ctx.lineTo(point3.x, point3.y - pointSize / 2)

        ctx.moveTo(point3.x - pointSize / 2, point3.y)
        ctx.lineTo(point4.x + pointSize / 2, point4.y)

        ctx.moveTo(point4.x, point4.y - pointSize / 2)
        ctx.lineTo(point1.x, point1.y + pointSize / 2)
        ctx.closePath()
        ctx.stroke()
      },[lineColor, lineWidth, pointSize]);

      useEffect(() => {
        if (cropPoints && canvas.current) {
          clearCanvas()
          const sortedPoints = sortPoints()
          drawShape(sortedPoints)
        }
      }, [cropPoints, clearCanvas, drawShape, sortPoints]);

  return (
    <canvas
      ref={canvas}
      style={{
        position: 'absolute',
        zIndex: 5
      }}
      width={previewDims.width}
      height={previewDims.height}
    />
  );
}

export default CropPointsDelimiters;
