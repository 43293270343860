import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {}

  /* MOBILE */
  @media (max-width: 480px) {}
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 1.5fr 5fr;
  border-radius: 12px;
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadow};

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      position: sticky;
      top: calc(80px + 1rem);
      width: 100%;

      > footer {
        width: 100%;
        background: ${props => props.theme.colors.bgBox};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        border-radius: 0 0 12px 12px;

        > button {
          display: inline-block;
          width: 80%;
          padding: 1rem .4rem;
          border-radius: 10px;
          font-size: .875rem;
          font-weight: 700;
          text-align: center;
          transition: all .2s ease-in-out;
          box-shadow: 3px 5px 10px 0 rgb(62 63 94 / 20%);
        }
      }
    }
  }

  > section:nth-child(2) {

    > header {
      display: grid;
      grid-gap: 1rem;
      height: 140px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 1rem;

      /* TABLET */
      @media (max-width: 768px) {
        display: none;
      }

      > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        padding: 0 28px;
        background: ${props => props.theme.colors.bgBox};
        box-shadow: ${props => props.theme.colors.shadowBox};
        overflow: hidden;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:not(:first-child):hover {
            transform: translateY(-.5rem);
          }

        > header {
          background: linear-gradient(90deg, #615dfa, #8d7aff);
          position: absolute;
          width: 100%;
          height: 50%;
          display: flex;
          align-items: start;
          justify-content: start;
          top: 0;
        }

        > div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          > h4 {
            margin-top: 15px;
            font-size: .875rem;
            font-weight: 700;
            line-height: 1;
          }

          > p {
            margin-top: 4px;
            color: #8f91ac;
            font-size: .75rem;
            font-weight: 500;
          }
        }

      }
    }

    > section {

      > h1 {
        font-size: 1rem;
        font-weight: 700;
      }

      > main {
        display: grid;
        grid-gap: 1rem;
        margin-top: 2rem;
        grid-template-columns: repeat(2, 1fr);

        /* TABLET */
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
        /* MOBILE */
        @media (max-width: 480px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
`;

export const SimpleInput = styled.div`
  position: relative;
  margin-bottom: 1.75rem;
  display: flex;
  justify-content: center;

  > input[type="text"], textarea, select {
    background-color: transparent;
    border: 1px solid #8f91ac70;
    width: 100%;
    color: ${props => props.theme.colors.textColor};
    border-radius: 12px;
    font-size: .875rem;
    font-weight: 700;
    padding: .8rem 1.5rem;
    resize: none;
    transition: all .3s ease-in-out;

    ::placeholder {
      color: #8f91ac70;
    }

    &:focus {
      border-color: #6e61c6;
    }

    &:disabled {
      color: #8f91ac;
      cursor: no-drop;
    }
  }

  > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    &::-ms-expand {
      display: none;
    }
  }

  >  span {
    color: #9098a9;
    font-size: 10px;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 5px);
    transform: rotate(90deg);
  }

  > label {
    color: ${props => props.theme.colors.textColor};
    font-size: .75rem;
    font-weight: 600;
    position: absolute;
    top: 12.5px;
    left: .75rem;
    padding: 0 5px;
    pointer-events: none;
    background: ${props => props.theme.colors.bgBox};
    transition: .3s ease;
    transform:translateY(-1.5rem);
  }
`;

export const PreferencesContent = styled.div`

  > main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    > div {

      > h3 {
        font-size: .875rem;
        font-weight: 700;
      }
      > p {
        margin-top: 4px;
        color: #8f91ac;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.4285714286em;
      }
    }

  }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 2rem 0;
  min-height: 150px;
  grid-template-columns: repeat(2, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > section {

    > h4 {
      margin: 0 0 .5rem .7rem;
      color: #adafca;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
    }
  }
`;

export const ContentGrid3 = styled.div`
  position: relative;

  > p {
    color: #9aa4bf95;
    text-align: center;
  }

  > footer {
    display: flex;
    justify-content: center;

    > button {
      display: flex;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      padding: 1rem 0rem;
      margin-top: 1rem;
      font-size: .8rem;
      width: 190px;
      height: 54px;

      &:disabled {
        background: #f7f7f7;
        border: 1px solid #d2d7e3;
        color: #b3bbcf;
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }

`;

export const Card = styled(Link)`
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  border-radius: 12px;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  margin-bottom: 1rem;
  text-decoration: none;
  transition: all .2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: .875rem;
    font-weight: 500;
    line-height: 1rem;
    margin-left: .7rem;
    flex: 1;

    > span:first-child {
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
    }

    > span:last-child {
      margin-top: 10px;
      color: #adafca; /* AQUI */
      font-size: .75rem;
      font-weight: 500;
    }
  }

  > span {
    margin-left: 20px;
    font-size: 22px;
  }
`;
