import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useCredit } from '../../../hooks/credit';

import api from '../../../services/api';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { isAfter, parseISO } from 'date-fns/esm';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Image } from 'react-image-and-background-image-fade';
import ButtonStatus from '../ButtonStatus';
import ButtonCancelSend from '../ButtonCancelSend';
import ExpBadge from '../../../components/ExpBadge';

import { Container, CorrectionInfoPreview, ListNotes, Notes, ButtonsActionsContainer, CancelWordingModalContent } from './styles';
import { IProfileWordingsProps } from '..';

interface IListCorrectionPreviewProps {
  id: string;
  imgCover: string;
  tema: string;
  date: string;
  exp: number;
  nota: number;
  correction_deadline: string;
  is_photo: boolean;
  created_at: string;
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
  setWordings: React.Dispatch<React.SetStateAction<IProfileWordingsProps>>;
}

const ListCorrectionPreview: React.FC<IListCorrectionPreviewProps> = ({id, imgCover, tema, date, exp, nota, correction_deadline, is_photo, created_at, status, setWordings}) => {
  const { credits, addCredit } = useCredit();

    //load data
    const handleCancelWording = useCallback(async () => {
      try {
        //setLoading(true);
        const response = await api.delete(`/wordings/${id}`, {
          data: { cancellation_reason: 'was_requested' }
        });

        console.log('WORDING STATUS = CANCELED! >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setWordings(oldWordings => ({
          ...oldWordings,
          records: oldWordings.records.map(record => record.id === response.data.id ? response.data : record),
        }));

        Swal.fire({
          title: 'Pronto!',
          text: 'Sua redação foi cancelada com sucesso!',
          icon: 'success',
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          confirmButtonColor: '#03bb85',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });

        addCredit();

      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }, [id, setWordings, addCredit]);


  const cancelSendConfirm = useCallback((imgCover, tema) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      html: (
        <CancelWordingModalContent>
          <header>
            <div>
              <img src={imgCover} alt={tema} />
            </div>
          </header>
          <main>
            <h1>Deseja cancelar a redação "{tema}"?</h1>
            <p><span>ATENÇÃO! Esta ação é irreversível e não poderá ser desfeita!</span></p>
          </main>
        </CancelWordingModalContent>
      ),
      background: '#6e61c6',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#03bb85',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim, eu quero!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('opration confirmed');
        handleCancelWording();
      }
      else {
        console.log('opration canceled');
      }
    });
  }, [handleCancelWording]);

  return (
    <Container>
      <Image src={imgCover} width="104px" height="70px" lazyLoad={true} />
      <CorrectionInfoPreview status={status}>
        <aside>
          <Link to={status === 'canceled' ? '#' : `/visualizar-redacao/${is_photo ? `foto` : `texto`}/${id}`}>{tema}</Link>
          <h6>{format(parseISO(date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</h6>
          {status !== 'corrected' && status !== 'canceled' && <h4>Sua correção será <br /> entregue até {format(parseISO(correction_deadline), "dd'/'MM'/'yyyy", { locale: ptBR })}</h4>}
        </aside>
        <main>
          {status !== 'canceled' && <ExpBadge icon="icon-icon-plus" exp={exp} position="unset" padding="0 10px" border="1px solid #6e61c6" background="none" />}
        </main>
        <ListNotes>
          {status !== 'canceled' &&
            (<Notes>
              {status === 'corrected' ?
              <>
                <h4>{nota}</h4>
                <p>Sua nota</p>
              </>
              :
                <>
                  <h4>{format(parseISO(correction_deadline), "dd'/'MM'/'yyyy", { locale: ptBR })}</h4>
                  <p>Sua correção será<br />entregue até</p>
                </>
              }
            </Notes>)}
        </ListNotes>
        <ButtonsActionsContainer>
          <ButtonStatus status={status} id={id} is_photo={is_photo}></ButtonStatus>
          {isAfter(parseISO(created_at), parseISO(credits.initialPeriod)) && <ButtonCancelSend status={status} onClick={() => cancelSendConfirm(imgCover, tema)} />}
        </ButtonsActionsContainer>
      </CorrectionInfoPreview>
    </Container>
  );
}

export default ListCorrectionPreview;
