import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

import WidgetPostBox from '../../../components/WidgetPostBox';

interface ILastMaterialProps {
  id: string;
  exam_name: string;
  name: string;
  description: string;
  thumbnail: string;
  thumbnail_url: string;
  created_at: string;
  category: {
    name: string;
  };
}

const LastMaterialCard: React.FC = () => {
  const [lastMaterial, setLastMaterial] = useState<ILastMaterialProps>({} as ILastMaterialProps);

    // List last MATERIAL uploaded: default ENEM
    useEffect(() => {
      async function loadData() {
        try {
          const response = await api.get('/materials/last',
            {params: {exam_name: 'enem'}}
          );

          console.log('LAST MATERIAL >>>>>>>>>>>>>>>>>>>');
          console.log(response.data);

          setLastMaterial(response.data);
        } catch (error) {
          console.log(error);
        } finally {

        }
      }

      loadData();
    }, []);

  return (
    <WidgetPostBox
      id={lastMaterial.id}
      thumbnail_url={lastMaterial.thumbnail_url}
      description={lastMaterial.description}
      name={lastMaterial.name}
      exam_name={lastMaterial.exam_name}
      category={lastMaterial.category}
      created_at={lastMaterial.created_at}
      isThumbnailLarge
    />
  );
}

export default LastMaterialCard;
