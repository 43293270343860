import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const Container = styled.div`
  grid-area: MH;
  background: #6e61c6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 99999999;
`;

export const LogoContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;

  > a {
    margin: 0;
    padding: 0;
    display: flex;
    text-decoration: none;
  }
`;

export const LogoImg = styled.img`
  width: 50px;
  height: 54px;
`;

export const HeaderActions = styled.div`
  margin: 0 2rem;
  text-align: center;


  @media screen and (max-width: 680px) {
    margin: 0 1.5rem;
  }
`;

export const MenuTrigger = styled.button`
  color: #ffffff60;
  background: none;
  text-decoration: none;
  font-size: 20px;
  transition: .4s;

  &:hover {
    color: #fff;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const NextLevelContainer = styled.div`
  min-width: 150px;
  margin: 0 1.5rem;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const QuestLevelContainer = styled.div`
  width: 100%;

  > p {
    color: #444563;
  }
`;

export const BarProgressInfo = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
  font-size: .7rem;
  margin-bottom: .2rem;
  font-weight: 700;
  text-transform: uppercase;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: ${props => props.theme.colors.textColor};

    > div {

      > h4{
        margin-top: 2px;
        font-size: .75rem;
        font-weight: 500;
        color: #adafca;
        line-height: .4rem;
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

export const ActionList = styled.ul`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: "";
    width: 1px;
    height: 32px;
    background-color: #ffffff35;
    position: relative;
  }

  &:after {
    content: "";
    width: 1px;
    height: 32px;
    background-color: #ffffff35;
    position: relative;
  }

  @media screen and (max-width: 1215px) {
    display: none;
  }
`;

export const MenuItem = styled.li`
  display: inline-block;
  padding: 0 1rem;

  &:first-child {
    margin: 0 0 0 1rem;
  }

  &:last-child {
    margin: 0 1rem 0 0;
  }
`;

export const ProfileSettings = styled.div`
  margin: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 680px) {
    margin: 0 1.5rem;
  }
`;

export const DropdownBoxHeader = styled.div`
  display:flex;
  justify-content: space-between;
  padding: 20px 28px 0 28px;
  align-items: center;


  > h3 {
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    align-items: center;
    margin-left: 0px;
    line-height: 1;
    color: ${props => props.theme.colors.textColor};

    > span {
      color: #00c7d9;
      margin-left: .4rem;
    }
  }

  > a {
    display: flex;
    text-decoration: none;
    color: ${props => props.theme.colors.textColor};

    > h3 {
      display: flex;
      font-size: 1rem;
      font-weight: 700;
      align-items: center;
      margin-left: 5px;
      line-height: 1;

      > span {
        color: #00c7d9;
      }
    }
  }


  > span {
    display: block;
  }

  > p {
    color: #8f91ac;
    font-size: .75rem;
    font-weight: 700;
    opacity: .6;
    cursor: pointer;
    line-height: .9rem;
    text-align: right;
  }
`;

export const DropdownBoxContent = styled.div`
  padding: .5rem 28px;

  > h4 {
    margin: 1rem 0 .7rem 0;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: .875rem;
    font-weight: 500;
    line-height: 1rem;
    margin: .5rem 0;

    > a {
      color: ${props => props.theme.colors.textColor};
      text-decoration: none;
      font-weight: 600;
    }
  }

  > a {
    padding: 0 0 .4rem 0;
    font-size: .75rem;
    font-weight: 700;
    text-decoration: none;
    color: ${props => props.theme.colors.textColor};
    transition: padding .2s ease-in-out,color .2s ease-in-out;
    display: flex;
    padding: 1rem 0;

    &:hover {
      padding-left: 4px;
      color: #6e61c6; /* AQUI */
    }
    .off {
      background: #df514e;
      color: #fff;
      border-radius: 52px;
      font-size: 8px;
      padding: 0 4px;
      margin-left: 4px;
      align-items: center;
    }

  }
`;

export const DropdownBoxContent2 = styled.div`
  padding: .5rem 28px;

  > h4 {
    margin: 1rem 0 .7rem 0;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: .875rem;
    font-weight: 500;
    line-height: 1rem;
    margin: .5rem 0;

    > a {
      color: ${props => props.theme.colors.textColor};
      text-decoration: none;
      font-weight: 600;
    }
  }

  > a {
    display: block;
    padding: 0 0 .4rem 0;
    font-size: .75rem;
    font-weight: 700;
    text-decoration: none;
    color: ${props => props.theme.colors.textColor};
    transition: padding .2s ease-in-out,color .2s ease-in-out;

    &:hover {
      padding-left: 4px;
      color: #6e61c6; /* AQUI */
    }

    .off {
      background: #df514e;
      color: #fff;
      border-radius: 52px;
      font-size: 8px;
      padding: 0 4px;
      margin-left: 4px;
      align-items: center;
    }

  }
`;

export const DropdownBoxFooter = styled.div`

  > h4 {
    margin: 1rem 0 .7rem 0;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  > Button {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: .7rem 0;
    margin-top: 1.5rem;
    font-size: .8rem;
  }
`;

export const DropdownBoxFooterProfile = styled.div`
  padding: 0 28px 28px 28px;

  > h4 {
    margin: 1rem 0 .7rem 0;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  > Button {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: .7rem 0;
    margin-top: 1.5rem;
    font-size: .8rem;
  }
`;

export const ToggleContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 25%;
`;

export const DropdownNotification = styled(Link)`
  display: flex;
  padding: .7rem 0;
  align-items: center;
  transition: all .2s ease-in-out;
  cursor: pointer;

  &:hover {
    padding-left: 4px;
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: .875rem;
    font-weight: 500;
    line-height: 1rem;
    margin-left: .7rem;
    flex: 1;

    > span:first-child {
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
    }

    > span:last-child {
      margin-top: 10px;
      color: #adafca; /* AQUI */
      font-size: .75rem;
      font-weight: 500;
    }
  }

  > span {
    margin-left: 20px;
    font-size: 22px;
  }
`;

export const FotterActionButton = styled.div`

  > a {
    width: 100%;
    display: block;
    height: 60px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #6e61c6; /* AQUI */
    color: #fff;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    line-height: 60px;
    text-decoration: none;
    transition: background-color .3s ease-in-out;

    &:hover {
      background: #7750f8;
    }
  }
`;

export const SendPreview = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.gray}25;
  padding: 1rem 0;

  > div figure img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-right: .75rem;
  }

  > div {
    color: ${props => props.theme.colors.textColor};
    font-weight: 700;
    line-height: 1rem;
    text-decoration: none;
    font-size: .875rem;
    justify-content: space-between;

    > span {
      margin-top: 2px;
      font-size: .75rem;
      font-weight: 500;
      color: #adafca; /* AQUI */
      line-height: .4rem;
    }
  }

  > div:last-child{
    margin-left: .75rem;
    text-align: center;
    text-transform: uppercase;
  }

`;

export const QuestProgress = styled.div`
  margin: 1rem 0 -1rem 0;
  padding: 1rem 0;

  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.gray}25;

  > a figure img {
    width: 35px;
    margin-right: .75rem;
  }

  &:last-child {
    margin-bottom: .05rem;
  }
`;

export const XpAdd = styled.div`
  height: 20px;
  padding: 0 8px;
  border-radius: 200px;
  font-size: .65rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 9px;
  min-width: 60px;
  background: ${props => props.theme.colors.bgBoxMenu};
  color: ${props => props.theme.colors.textColor};
  box-shadow: 3px 5px 20px 0 rgba(94,92,154,.12);
`;

export const ArrowBack = styled.button`
  position: absolute;
  height: 15px;
  width: 15px;
  font-size: 14px;
  top: 1.2rem;
  right: 1.2rem;
  border: none;
  background: none;
  color: ${props => props.theme.colors.textColor};
`;

export const AsideMenuMobile = styled.div`
  background: ${props => props.theme.colors.bgBox};
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  z-index: 99999999999999;
  padding: 2rem 1rem;
  transition: .5s ease-in-out;
  left: -300px;

  &.sidebar.active {
    left: 0;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 4px;
    height: 4px;
  }

  &:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    opacity: 1;
    transition: all .3s ease-in-out;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: #cccccc80;
    border-radius: 10px;
  }
  &:hover::-webkit-scrollbar-thumb:hover{

  }
  &:hover::-webkit-scrollbar-track{
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #cccccc10;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 1rem;

    > a {
      margin-left: .5rem;
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
      line-height: 1;
      text-decoration: none;

      > span {
        margin-top: 4px;
        color: #8f91ac;
        font-size: .75rem;
        font-weight: 500;
      }
    }
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(110, 97, 198, .5);
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
    transition: all .3s ease-in-out;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h4 {
    padding: 0 0 5px 7px;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
  }

  > div {
    margin: .25rem 0;
    width: 100%;
    padding: 12px 1rem;
    display: flex;
    align-items: center;

    &.active {
      border-radius: 12px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.primary};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > a, span {
        color: #fff;
      }
    }

    > a, svg {
      text-decoration: none;
      transition: all .2s ease-in-out;
      font-weight: 700;
      text-align: left;
      display: flex;
      align-items: center;
      color: ${props =>props.theme.colors.textColor};
      fill: ${props =>props.theme.colors.textColor};

      > span {
        padding-right: 1.5rem;
        font-size: 20px;
        color: ${props =>props.theme.colors.primary};

        > svg {
          fill: ${props =>props.theme.colors.primary};
          width: 20px;
          height: 20px;
          transition: all .2s ease-in-out;
          scale: 1.3;
        }
      }
    }
  }
`;

export const ModalPlan = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;
  margin-top: -25px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    padding: 1rem;
    width: 100%;

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff3b;
      padding: 5px 10px;
      backdrop-filter: blur(5px);
      border-radius: 15px;

      > input {
        margin-right: 8px;
      }
    }

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      font-size: 27px;
      background: rgb(106 85 157 / 22%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transition: all .4s;

      &::before {
        position: absolute;
        content: '×';
        top: 7px;
      }
    }
  }

  > a {
    text-decoration: none;

    > img {
      width: 100%;
      border-radius: 12px;
      cursor: pointer;
    }
  }

`;
