import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import Chart from "react-apexcharts";

import api from '../../services/api';

import LazyLoad from 'react-lazyload';

import ContentGrid from "../../components/ContentGrid";
import SectionBanner from "../../components/SectionBanner";
import HeaderInfo from "../../components/HeaderInfo";
import LevelProgressBox from "../../components/LevelProgressBox";
import StatsBoxSmall from "../../components/StatsBoxSmall";
import RankingBox from '../../components/RankingBox';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import NoticeHomeModal from '../../components/NoticeHomeModal';

import TopMonthRanking from './TopMonthRanking';
import PlayerLastVideo from './PlayerLastVideo';
import LastThemeSuggest from './LastThemeSuggest';
import HallOfFameCard from './HallOfFameCard';
import LastMaterialCard from './LastMaterialCard';
import LastAchievementsCards from './LastAchievementsCards';


import {
  Container,
  GridHeader,
  StatsContent,
  SliderLine,
  GridContentMain,
  GridContentMain2,
  GridContentMain3,
  ChartContainer,
  Live,
  BannerEmBreve
} from './styles';

import bannerindexIconImg from '../../assets/newsfeed-icon.png';
interface IStatsBoxSmallProps {
  id: string;
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
  created_at: string;
  icon: 'up' | 'down' | 'none';
  is_photo: boolean;
  corrections: {
    final_grade?: number;
  }[]
  theme: {
    name: string;
    thumbnail_url: string;
  }
  status_info: {
    name: string;
  }
}

interface IPerformanceGraphProps {
  categories?: string[];
  series: any[];
}

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [lastCorrections, setLastCorrections] = useState<IStatsBoxSmallProps[]>([]);
  const [performanceGraph, setPerformanceGraph] = useState<IPerformanceGraphProps>();

  const SHOW_MODAL_ALERT = true;

  // List 4 wordings in cards
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<IStatsBoxSmallProps[]>('/wordings/last');
        console.log(response.data);

        const correctionsData = response.data.map((data, index) => {
          let icon: 'up' | 'down' | 'none' = 'up';

          if (index < response.data.length - 1) {
            const nextCorrection = response.data[index + 1];

            if (!nextCorrection.corrections.length || !data.corrections.length) {
              icon = 'none';
              return {
                ...data,
                icon,
              }
            }

            const nextGrade = nextCorrection.corrections[0].final_grade;
            const currentGrade = data.corrections[0].final_grade;

            icon = Number(currentGrade) > Number(nextGrade) ? 'up' : 'down';
          }

          return {
            ...data,
            icon,
          }
        })

        setLastCorrections(correctionsData.slice(0, 4));
        console.log(correctionsData);

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  // GET PERFORMANCE GRAPH STATISCS
  /* useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/wordings/statistics');

        console.log('LIST PERFORMANCE GRAPH STATISTCS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setPerformanceGraph(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []); */

  const ApexOptions = useMemo(() => ({
    chart: {
      id: "grafico-de-desempenho",
      foreColor: '#adafca',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          customIcons: []
        },
      },
    },
    xaxis: {
      categories: performanceGraph ? performanceGraph.categories : [],
      axisBorder: {
        show: false,
        color: '#adafca50',
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      //max: 1000,
      //min: minDataValue - 100,
      tickAmount: 4,
      //forceNiceScale: true,
      axisBorder: {
        show: false,
      },
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    tooltip: {
      theme: 'dark',
    },
    colors: ['#6e61c6',],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.1,
        stops: [0, 100],
        colorStops: []
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: "#00000020",
      clipMarkers: true,
      yaxis: {
        lines: {
          show: true
        },
      },
      xaxis: {
        lines: {
          show: true
        }
      },
    },
  }), [performanceGraph]);

  return (
    <Container>
      {(SHOW_MODAL_ALERT) && (user?.courses?.length > 0) && <NoticeHomeModal showDoNotShowAgain={true} />}
      {loading && <Loader isFixed={true} zIndex={99999999} />}
      <SectionBanner icon={bannerindexIconImg} title={`Olá, ${user.show_name}!`} subTitle="Você está na melhor plataforma de redação do mundo!" />
      <HeaderInfo supTitle="Página Inicial" title="Dashboard" margin="2rem 0 1rem 0" />
      <StatsContent>
        {lastCorrections.map((correction, index) => (
          <StatsBoxSmall
            key={index}
            id={correction.id}
            nota={correction.corrections[0]?.final_grade}
            levelFlag={correction.icon}
            status={correction.status}
            width="100%"
            theme={correction.theme.name}
            bg={index % 2 === 0 ? 'bg1' : 'bg2'}
            is_photo={correction.is_photo}
          />))}
      </StatsContent>
      <BannerEmBreve to="/redacao-espacial" />
      <GridContentMain>
        <LazyLoad style={{ position: 'relative', }} once={true}>
          <PlayerLastVideo />
        </LazyLoad>
        <LazyLoad style={{ position: 'relative', minHeight: 461 }} once={true}>
          <LastThemeSuggest />
        </LazyLoad>
      </GridContentMain>

      <ContentGrid>
        <GridHeader>
          <LevelProgressBox />
        </GridHeader>

        {/* <ChartContainer>
          <h4>Gráfico de Desempenho</h4>
          {performanceGraph && <Chart options={ApexOptions} series={[performanceGraph.series[0]]} type="area" height={350} />}
        </ChartContainer> */}

        {user.is_public && (
          <SliderLine>
            <LazyLoad style={{ position: 'relative', minHeight: 228 }} once={true} placeholder={<Loader isFixed={false} />}>
              <HallOfFameCard />
            </LazyLoad>
          </SliderLine>)}

        <LazyLoad style={{ position: 'relative', minHeight: 259 }} once={true} placeholder={<Loader isFixed={false} />}>
          <GridContentMain2>
            <TopMonthRanking />
          </GridContentMain2>
        </LazyLoad>

        <HeaderInfo supTitle="Sistema de" title="Gamificação" margin="2rem 0 1rem 0" />
        <LazyLoad style={{ position: 'relative', minHeight: 142 }} once={true} placeholder={<Loader isFixed={false} />}>
          <GridContentMain3>
            <LastAchievementsCards />
          </GridContentMain3>
        </LazyLoad>
        <GridContentMain3>
          <aside>
            <LazyLoad style={{ position: 'relative', }} once={true} placeholder={<Loader isFixed={false} />}>
              <LastMaterialCard />
            </LazyLoad>
          </aside>
          <aside>
            <LazyLoad style={{ position: 'relative', minHeight: 626 }} once={true} placeholder={<Loader isFixed={false} />}>
              <RankingBox />
            </LazyLoad>
          </aside>
        </GridContentMain3>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Dashboard;
