import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import ProgressBar from '@ramonak/react-progress-bar';
import UserAvatar from '../../components/UserAvatar';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import confetti from 'canvas-confetti';
import CountUp from 'react-countup';
import ReactStars from 'react-stars';

import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseISO } from 'date-fns/esm';

import HeaderInfo from '../../components/HeaderInfo';
import SmallStatisticsBox from '../../components/SmallStatisticsBox';
import ThemeCardInstructions from '../../components/ThemeCardInstructions';
import Loader from '../../components/Loader';
import BeforeSkipPage from '../../components/BeforeSkipPage';
import ModalRatingCorrection from '../../components/ModalRatingCorrection';
import Footer from '../../components/Footer';
import InCorrectionAlert from '../../components/InCorrectionAlert';
import WordingContent from './WordingContent';
import ModalOpenCorrection from './ModalOpenCorrection';

import { Mark, MarkPhotoProvider } from '../../hooks/mark-photo';

import { Container, ContentGrid0, ContentGrid1, ContentGrid2, Modal900Content, AvatarContent, ConfirmDiv, BlockVotation } from './styles';

import corrector1 from '../../assets/support_avatar2.png';
import competences from '../../utils/getCompetences';

interface IWordingProps {
  id: string;
  student_id: string;
  theme_id: string;
  user_id?: string;
  redemption_date?: string;
  end_time_in_seconds: number;
  photo?: null;
  text: string;
  in_review: boolean;
  status: string;
  cancellation_reason?: string;
  created_at: string;
  updated_at: string;
  student: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  theme: {
    id: string;
    exam_name: string;
    name: string;
    material: string;
    thumbnail: string;
    new: boolean;
    material_url: string;
    thumbnail_url: string;
  };
  corrections?: {
    id: string;
    user_id: string;
    wording_id: string;
    exam_name: string;
    note: string;
    status: boolean;
    disapprove_reason?: boolean;
    final_grade: number;
    corrected_in: number;
    rating?: number;
    rating_reason?: string;
    created_at: string;
    updated_at: string;
    user: {
      id: string;
      first_name: string;
      last_name: string;
      avatar: string;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    };
  }[];
  correction_deadline: string;
  is_photo: boolean;
  status_info: {
    name: string;
    color: string;
  };
  photo_url: string;
}
interface IThemeProps {
  id: string;
  category_id: string;
  exam_name: string;
  student_id?: string;
  name: string;
  material: string;
  thumbnail: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  category: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  }
  student?: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  difficulty: 'easy' | 'medium' | 'hard';
  new: boolean;
  material_url: string;
  thumbnail_url: string;
}

interface IThemeStatistics {
  average_grade: number;
  highest_grade: number;
  all_submitted_wordings: number;
  user_submitted_wordings: number;
}

interface ICorrectionProps {
  id: string;
  user_id: string;
  wording_id: string;
  exam_name: string;
  note: string;
  status: boolean;
  disapprove_reason?: string;
  final_grade: number;
  corrected_in: number;
  rating?: number;
  rating_reason?: string;
  created_at: string;
  updated_at: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
}

interface IGradesProps {
  id: string;
  correction_id: string;
  name: string;
  value: number;
  created_at: string;
  updated_at: string;
}

const VisualizarRedacaoFoto: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { wording_id } = useParams<{ wording_id: string }>();

  const [loading, setLoading] = useState(false);
  const [wording, setWording] = useState<IWordingProps>({} as IWordingProps);
  const [theme, setTheme] = useState<IThemeProps>({} as IThemeProps);
  const [correction, setCorrection] = useState<ICorrectionProps>();
  const [marks, setMarks] = useState<Mark[]>([]);
  const [grades, setGrades] = useState<IGradesProps[]>([]);
  const [contentWidth, setContentWidth] = useState(890);

  const [statistics, setStatistics] = useState<IThemeStatistics>();

  const wordingContentRef = useRef<HTMLElement>(null);

  // Get WORDING
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(`/wordings/${wording_id}`);

        console.log('WORDING >>>>>>>>>>>>');
        console.log(response.data);
        setWording(response.data);

        console.log('THEME >>>>>>>>>>>>');
        setTheme(response.data.theme);
        console.log(response.data.theme);

        console.log('THEME STATISTICS >>>>>>>>>>>>');
        const response2 = await api.get<IThemeStatistics>(`/themes/${response.data.theme.id}/statistics`);
        setStatistics(response2.data);
        console.log(response2.data);

        if (response.data.corrections.length) {
          const lastCorrection = response.data.corrections[0];
          setCorrection(lastCorrection);

          console.log('MARKS >>>>>>>>>>>>>>>>>');
          const response3 = await api.get(`/corrections/${lastCorrection.id}/marks`);
          setMarks(response3.data);
          console.log(response3.data);

          console.log('GRADES (NOTAS) >>>>>>>>>>>>>>>>>');
          const response4 = await api.get(`/corrections/${lastCorrection.id}/grades`);
          setGrades(response4.data);
          console.log(response4.data);
        }


      } catch (err) {
        console.log(err);

        toast.error('😢 Poxa, parece que a redação que você tentou acessar não existe!', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        history.push('/minhas-redacoes');
      }
      finally {
        setLoading(false);
      }
    }

    loadData();
  }, [wording_id, history]);

  // open modal if grade >= 900 pts
  useEffect(() => {
    function loadConfetti() {
      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 0);

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 1000);

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 2000);

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 3000);

      setTimeout(() => {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
          }
        });
      }, 4000);
    }

    const lastCorrection = wording.corrections?.length && wording.corrections[0];

    if (wording.status === 'corrected' && lastCorrection && lastCorrection.final_grade >= 900) {
      console.log('entrou');
      const Modal900 = withReactContent(Swal);

      Modal900.fire({
        html: (
          <Modal900Content>
            <img src={wording.student.avatar_url} alt={wording.student.show_name} />
            <h2>Parabéns, {wording.student.show_name}!</h2>
            <p>{wording.student.id === user.id ? 'Sua nota' : 'Nota'}</p>
            <main>
              <CountUp end={lastCorrection.final_grade} duration={3} />
            </main>
          </Modal900Content>
        ),
        confirmButtonText: 'VISUALIZAR CORREÇÃO',
        showCloseButton: false,
        background: '#fff',
        backdrop: '#03bb85',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('opration confirmed');
          loadConfetti();
        }
        else {
          console.log('opration canceled');
        }
      });
    }


  }, [wording, user.id]);

  const handleRatingCorrection = useCallback((new_rating: number) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      html: (
        <ConfirmDiv>
          <h2>Confirmar avaliação?</h2>
          <p>Você está avaliando a correção com {new_rating} estrelas!</p>
          <footer>
            <ReactStars
              key={0}
              color1="#adafca"
              color2="#ffbf00"
              value={new_rating}
              count={5}
              size={50}
              half={false}
              edit={false}
              onChange={(new_rating: number) => {
                console.log('voto alterado: ' + (new_rating * 10));
              }}
            />
          </footer>
        </ConfirmDiv>
      ),
      confirmButtonText: 'Confirmar',
      showCloseButton: true,
    }).then((result) => {

      async function loadData() {
        try {
          const response = await api.post(`/corrections/${correction?.id}/rate`, {
            rating: new_rating * 10
          });

          setCorrection(oldValue => {
            if(!oldValue) {
              return;
            }

            return {
              ...oldValue,
              rating: new_rating * 10,
            };
          });

          console.log(response.data);
        } catch (error) {
          console.log(error);
        } finally {

        }
      }


      if (result.isConfirmed) {
        loadData();
        Swal.fire({
          title: 'Pronto!',
          text: 'Sua avaliação foi computada com sucesso!',
          icon: 'success',
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          confirmButtonColor: '#03bb85',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 2500,
          timerProgressBar: true,
        });
        console.log('voto confirmado: '+ (new_rating * 10));
      }
      else {
        console.log('opration canceled');
      }
    });
  }, [correction?.id]);

  useEffect(() => {
    function handleResize() {
      const wordingContentWidth = wordingContentRef.current?.clientWidth || 890;
      setContentWidth(wordingContentWidth);
      console.log('contentWidth: ', wordingContentWidth);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Before skip rating correction
  const modalRatingCorretion = useCallback(() => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      html: (
        correction && <ModalRatingCorrection correction_id={correction.id} history={history} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
  }, [correction, history]);

  return (
    <>
      {(user.id === wording.student_id && correction && wording.status === 'corrected' && !correction.rating) && <BeforeSkipPage beforeSkip={modalRatingCorretion} />}
      <Container>
        <HeaderInfo supTitle={theme.exam_name} title={theme.name} margin="0 0 1rem 0" showSendButton={false} />
          {statistics &&
            <ContentGrid0>
            <SmallStatisticsBox icon="icon-icon-send-message" title="Redações Enviadas" bgColorIcon="#9181fa" value={statistics.all_submitted_wordings} />
            <SmallStatisticsBox icon="icon-icon-overview" title="Nota Média geral" bgColorIcon="#9181fa" value={statistics.average_grade} />
            <SmallStatisticsBox icon="icon-icon-quests" title="Maior nota geral" bgColorIcon="#9181fa" value={statistics.highest_grade} />
            <SmallStatisticsBox icon="icon-icon-messages-1" title="Seus envios para este tema" bgColorIcon="#9181fa" value={statistics.user_submitted_wordings} />
          </ContentGrid0>}
          <ContentGrid1>
            <main ref={wordingContentRef}>
              {contentWidth >= 890 ?
                <MarkPhotoProvider>
                  <WordingContent photo_url={wording.photo_url} initialMarks={wording.status === 'corrected' ? marks : []} />
                </MarkPhotoProvider>
              :
                <ModalOpenCorrection photo_url={wording.photo_url} initialMarks={wording.status === 'corrected' ? marks : []} status={wording.status} />
              }
            </main>
            <section>
              <main>
                {loading && <Loader isFixed={false} />}
                {theme && <ThemeCardInstructions
                  id={theme.id}
                  title={theme.name}
                  thumbnail_url={theme.thumbnail_url}
                  exp={30}
                  student={theme?.student}
                  course={theme.exam_name}
                  link={theme?.material_url}
                  themeSuggestion={true}
                  difficulty={theme.difficulty}
                />}
              </main>
              {wording.status === 'corrected' && <footer>
                <h4>Desempenho por competência</h4>
                {grades.map((grade, index) => {
                    const competences_colors = ['#ffce56','#36a2eb','#008d3d','#e86d6c','#9966ff'];

                    return (
                      <div>
                        <header>
                          <h6>Competência {index + 1}</h6>
                          <h6>{grade.value}/200</h6>
                        </header>
                        <ProgressBar completed={grade.value / 2} height="10px" borderRadius="4px" isLabelVisible={false} baseBgColor="#adafca60" bgColor={competences_colors[index]} />
                      </div>
                    );
                })}
                <footer>
                  {correction && <h4>Nota total: {correction.final_grade}</h4>}
                </footer>
              </footer>}
            </section>
          </ContentGrid1>

          {wording.status === 'corrected' && (
            <ContentGrid2>
              <section>
                <div>
                  <aside>
                    <AvatarContent>
                      <UserAvatar size="md" totalExp={9900} picture={corrector1} showOnlyHehaxon={true} />
                    </AvatarContent>
                    <div>
                      <h4>Corretor</h4>
                      {correction && <p>{formatDistance(parseISO(correction.created_at), new Date(), { locale: ptBR })}</p>}
                    </div>
                  </aside>
                  <main>
                    <p>
                      {correction?.note}
                    </p>
                  </main>
                </div>

                <div>
                  <h4>Nota total</h4>
                  <p>{correction?.final_grade}</p>
                  <footer>
                    <p>{user.id !== wording.student_id ? 'Avaliação da correção:' : 'Avaliar correção?'}</p>
                    <div>
                    {user.id !== wording.student_id && wording.status === 'corrected' && <BlockVotation />}
                    {correction?.rating && <BlockVotation />}
                      {correction && <ReactStars
                        key={1}
                        count={5}
                        size={20}
                        color1="#adafca"
                        color2="#b9993a"
                        half={false}
                        edit={!correction?.rating}
                        value={correction.rating ?  correction.rating / 10 : 0} //puxar do banco aqui
                        onChange={(new_rating: number) => {
                          handleRatingCorrection(new_rating);
                        }}
                      />}
                    </div>
                  </footer>
                </div>
              </section>
              <section>
                <main>
                  <table className="correcoes">
                    <thead>
                      <tr>
                        <th>
                            Competência
                        </th>
                        <th className="text-center">
                            Nota
                        </th>
                        <th>
                            Comentário
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {competences.map((competence, index) => {
                          if (!grades[index]) {
                            return null;
                          }

                          const competenceIndex = Math.trunc(grades[index].value / 40);
                          return (
                          <tr>
                            <td className="competencia">
                                <h3>
                                  <span style={{background: competence.color}}>
                                    {competence.name}
                                  </span>
                                </h3>
                                <p>{competence.description}</p>
                            </td>
                            <td className="nota">
                                {grades[index].value}
                            </td>
                            <td className="comentario">
                                {competence.comments[competenceIndex]}
                            </td>
                          </tr>
                        )})
                      }
                    </tbody>
                  </table>
                </main>
              </section>
            </ContentGrid2>
          )}
        <Footer />
        {user && user.id === wording.student?.id && wording.status !== 'corrected' && wording.status !== 'canceled' && (
          <InCorrectionAlert>Sua redação ainda está em correção! 😊</InCorrectionAlert>
        )}
      </Container>
    </>
  );
}

export default VisualizarRedacaoFoto;
