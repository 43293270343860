import styled from 'styled-components';

export const Container = styled.div`
  grid-area: AS;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.primary};
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  height: 100%;
  z-index: 99999999;

  /* TABLET */
  @media (max-width: 768px) {
    display: none;
  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const Fixed = styled.div`
  position: fixed;
  height: 100%;
  background: ${props => props.theme.colors.bgBox};
  width: 80px;

  /* TABLET */
  @media (max-width: 768px) {
  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: center;
  margin-bottom: 30px;
`;

export const MenuContainer = styled.nav`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0;
  transition: transform .35s ease-in-out,-webkit-transform .35s ease-in-out;
`;

export const LogoImg = styled.img`
  height: 50px;
`;

export const Menu = styled.ul`
  list-style: none;

  > li {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    &.new {
      position: relative;

      &::before {
        content: 'Novo';
        position: absolute;
        z-index: 99999;
        border-radius: 12px;
        text-align: center;
        box-sizing: border-box;
        background: #df514e;
        height: 16px;
        width: 34px;
        font-size: 11px;
        right: 0px;
        bottom: 6px;
        color: #fff;
        font-weight: 600;
      }
    }

    > a {
      position: relative;
      background-color: transparent;
      color: #adafca90; /* ${props => props.theme.colors.primary} */;
      text-decoration: none;
      margin: .3rem 0;
      display: flex;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      transition: all .2s ease-in-out;
      padding: .9rem;

      &:hover {
        background-color: ${props => props.theme.colors.bgBoxMenu};
        box-shadow: ${props => props.theme.colors.shadowMenu};
        color: ${props => props.theme.colors.info};
        position: relative;
        transition: all 0.3s ease-in-out 0s;
      }

      &:after {
        position: absolute;
        z-index: 99999;
        content: attr(data-tooltip);
        border-radius: 1rem;
        text-align: center;
        box-sizing: border-box;
        box-shadow: ${props => props.theme.colors.shadowBox};
        padding: 0 12px;
        background: ${props => props.theme.colors.darkGray};
        color: #fff;
        font-size: .75rem;
        font-weight: 700;
        white-space: nowrap;
        line-height: 24px;
        margin-top: 0;
        margin-left: 3.3rem;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
      }

      &:hover::after {
        opacity: 1;
        visibility: visible;
        transform: translateX(-0.5rem);
      }

      &.active {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};

        > span {

          > svg {
            fill: ${props => props.theme.colors.white};
          }
        }

        &:hover {

          > svg {
            background: ${props => props.theme.colors.primary};
            color: ${props => props.theme.colors.white};
          }
        }
      }

      > span {
        font-size: 20px;
        text-align: center;
        align-items: center;
        fill: ${props => props.theme.colors.primary};
        width: 20px;
        display: flex;

        > svg {
          width: 20px;
          height: 20px;
          fill: #adafca90;
          transition: all .2s ease-in-out;
          scale: 1.3;

          &:hover {
            fill: ${props => props.theme.colors.info};
          }
        }
      }
    }
  }
`;

export const MenuItem = styled.li`

`;

export const MenuIntemLink = styled.a`

`;

export const Title = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  margin-left: 10px;
`;
