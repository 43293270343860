import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  background: #ea4242;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px 0;
  z-index: 999;

  > p {
    color: #fff;
    font-weight: bold;
  }
`;
