import React from 'react';

import { Container } from './styles';

import UserAvatar from '../UserAvatar';

interface IFeaturedProfileBoxProps {
  title: string;
  timeAgo: string;
  nota: string | number;
  subTitle: string;
  FeacturedType: 'topAvg' | 'topDuvidas' | 'topConquistas' | 'topReact';
  user: {
    id: string;
    first_name: string;
    last_name: string;
    nickname?: null;
    avatar?: null;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
}

const FeaturedProfileBox: React.FC<IFeaturedProfileBoxProps> = ({ title, timeAgo, nota, subTitle, FeacturedType, user }) => {
  return (
    <Container FeacturedType={FeacturedType}>
      <header>
        <h3>{title}</h3>
        <p>{user.show_name}</p>
      </header>
      <aside>
        <UserAvatar
          size="sm"
          totalExp={user.exp}
          picture={user.avatar_url}
          showOnlyHehaxon={false}
        />
        <h4>{nota < 10 ? `0` + nota : nota}</h4>
        <h6>{subTitle}</h6>
        <p>{timeAgo}</p>
      </aside>
    </Container>
  );
}

export default FeaturedProfileBox;
