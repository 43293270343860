import React from 'react';
import { useAuth } from '../../hooks/auth';

import CountUp from 'react-countup';
import ProgressBar from "@ramonak/react-progress-bar";

import {
  LevelProgressBadge,
  LevelProgressTitle,
  LevelProgressNumber,
  ProgressStat,
  BarProgressInfo,
  ProgressStatInfo,
  ProgressBarContent
} from './styles';

import BoxContainer  from "../../components/BoxContainer";

const LevelProgressBox: React.FC = () => {

  const { user } = useAuth();

  return (
    <BoxContainer display="flex" padding="22px 28px">
      <LevelProgressBadge>
        <LevelProgressTitle>Level</LevelProgressTitle>
        <LevelProgressNumber>{user.level}</LevelProgressNumber>
      </LevelProgressBadge>

      <ProgressStat>
        <BarProgressInfo>
          <span><CountUp end={(100 - user.current_exp) || 0} duration={5} prefix="+" suffix=" EXP" /><span></span></span><span>para alcançar o próximo nível</span>
        </BarProgressInfo>
        <ProgressStatInfo>{(user.exp || 0).toLocaleString(['pt-BR'])} <br />total de pontos acumulados</ProgressStatInfo>
        <ProgressBarContent>
          <ProgressBar completed={user.current_exp || 0} height="7px" isLabelVisible={false} baseBgColor="#adafca70" bgColor="#6e61c6" />
        </ProgressBarContent>
      </ProgressStat>
    </BoxContainer>
  );
}

export default LevelProgressBox;
