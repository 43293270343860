import styled from 'styled-components';

import levelBadgeImg from '../../assets/level-badge.png';

export const LevelProgressBadge = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 28px;
  width: 88px;
  height: 86px;
  background: url(${levelBadgeImg}) no-repeat 50%;

  /* TABLET */
  @media (max-width: 768px) {

  }
  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const LevelProgressTitle = styled.p`
  font-size: .6875rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  margin-top: -5px;
`;

export const LevelProgressNumber = styled.p`
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  line-height: 1;
  margin-top: -5px;
`;

export const ProgressStat = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const BarProgressInfo = styled.p`
  justify-content: flex-start;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 50%;
  color: ${props => props.theme.colors.textColor};

  > span:nth-child(1) {
    margin-right: 4px;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
  }
  > span:nth-child(2) {
    font-size: 1rem;
    font-weight: 500;
    text-transform: none;
    line-height: 1;

    margin-right: 4px;
    color: ${props => props.theme.colors.gray};
  }

  /* TABLET */
  @media (max-width: 768px) {
    width: 100%;
    > span:nth-child(1) {
      font-size: 1rem;
    }
  }
  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ProgressBarContent = styled.div`
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ProgressStatInfo = styled.p`
  color: ${props => props.theme.colors.gray};
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 50%;
  text-align: right;
  justify-content: flex-end;

  /* TABLET */
  @media (max-width: 768px) {
    display: none;
  }
  /* MOBILE */
  @media (max-width: 480px) {
  }
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  width: 100%;
`;
