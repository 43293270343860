import styled from 'styled-components';

interface IForumCategoryCardProps {
  background: string;
}

export const Container = styled.div<IForumCategoryCardProps>`
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  border-radius: 12px;
  margin-bottom: 1rem;

  > header {
    height: 145px;
    width: 100%;
    background: ${props => props.background};
    border-radius: 12px 12px 0 0;
    background-size: cover;
    position: relative;

     > div {
       display: flex;
       justify-content: center;

      > img {
        margin-top: -15px;
      }
    }

    > div:nth-child(2) {
      position: absolute;
      text-align: center;
      top: 105px;
      left: 85px;
      width: 48px;
      border-radius: 12px;
      overflow: hidden;

      > aside:nth-child(1) {
        width: 100%;
        padding: .15rem 0;
        font-size: 1.25rem;
        font-weight: 700;
        color: ${props => props.theme.colors.textColor};
        background: ${props => props.theme.colors.bgBox};
        box-shadow: ${props => props.theme.colors.shadowBox};
      }

      > aside:nth-child(2) {
        width: 100%;
        padding: .15rem 0;
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        background: ${props => props.theme.colors.primary};
      }
    }
  }

  > main {
    justify-content: space-between;
    padding: 28px;

    > h1 {
      font-size: 1rem;
      font-weight: 700;
      color: ${props => props.theme.colors.textColor};
      text-decoration: none;
      height: 48px;
    }

    > h4 {
      margin-top: .5rem;
      font-size: .7rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #8f91ac;

      > span {
        font-weight: 500;
      }
    }

    > p {
      color: #9aa4bf;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.4285714286em;
      height: 80px;
    }

    > div {
      font-size: .875rem;
      font-weight: 600;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      min-height: 21px;

      > a {
        display: flex;
        align-items: center;
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
        word-break:break-all;
        height: 43px;
      }

      > span {
        color: ${props => props.theme.colors.primary};
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }

  > footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 28px 32px 28px;

    > a {
      width: 100%;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #9aa4bf !important;
      color: #9aa4bf;
      box-shadow: none;
      padding: .9rem .4rem;
      font-size: .875rem;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      border-radius: 12px;
      text-transform: uppercase;
      transition: background-color .2s ease-in-out,color .2s ease-in-out, border-color .2s ease-in-out,box-shadow .2s ease-in-out;

      &:hover {
        background: ${props => props.theme.colors.primary};
        color: #fff;
        border-color: #fff !important;
      }
    }
  }
`;
