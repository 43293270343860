import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 99;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-weight: bold;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: .8;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    }
`;
