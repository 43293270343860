import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 890px 354px; // FOLHA = 890px / GRID-GAP = 16px / TOTAL 1260px

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    // grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    //box-shadow: ${props => props.theme.colors.shadowBox};
  }

  > section:nth-child(2) {
    position: relative;

    > main {
      position: sticky;
      top: calc(80px + 1rem);
      width: 100%;

      > button {
        width: 100%;
        height: 48px;
        padding: 0 1.2rem;
        border-radius: 8px;
        color: #ffffff;
        font-size: .875rem;
        font-weight: 700;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        text-decoration: none;
        transition: all .2s ease-in-out;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        &:disabled {
          background: transparent;
          border: 1px solid #d2d7e3;
          color: #b3bbcf;
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      > p {
        color: #9aa4bf80;
        text-align: center;
        margin-top: 1rem;
        font-size: 10px;
      }
    }
  }
`;
