import React, { useCallback, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseISO } from 'date-fns/esm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserAvatar from '../UserAvatar';
import SmallLoader from '../SmallLoader';

import { Container } from './styles';

import truncate from '../../utils/truncateStrings';
import profilePic from '../../assets/poxa-lulu-avatar.png';

interface IWidgetPostBoxProps {
  id: string;
  exam_name: string;
  name: string;
  description: string;
  truncate_description_size?: number;
  thumbnail_url: string;
  isThumbnailLarge?: boolean;
  created_at: string;
  category: {
    name: string;
  }
}

const WidgetPostBox: React.FC<IWidgetPostBoxProps> = ({id, name, thumbnail_url, description, truncate_description_size, category, created_at, isThumbnailLarge}) => {
  const { user } = useAuth();
  const [loadingMaterial, setLoadingMaterial] = useState(false);

  const handleOpenMaterial = useCallback(async () => {
    try {
      // verifica se o usuário tem um plano ativo
      if(user?.courses?.length > 0) {
        setLoadingMaterial(true);
        const response = await api.get(`/materials/files/${id}`, {
          responseType: 'arraybuffer',
        });
        const file = new Blob([response.data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.setAttribute('target', '_self');
        a.click();
      } else {
        toast.error('😢 Poxa, você precisa ter um plano ativo para visualizar este material', {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingMaterial(false);
      toast.error('😢 Poxa, Algo deu errado ao tentar gerar o seu material!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } finally {
      setLoadingMaterial(false);
    }
  }, [id, user?.courses?.length]);

  return (
    <>
      {loadingMaterial && (<SmallLoader />)}
      <Container thumbnail_url={thumbnail_url} isThumbnailLarge={isThumbnailLarge}>
        <nav>
          <div>
            <UserAvatar size="sm" totalExp={10000} picture={profilePic} showOnlyHehaxon={false}  />
            <aside>
              <Link to="/poxalulu">Poxalulu</Link>
            </aside>
          </div>
        </nav>
        <main>
          <div onClick={handleOpenMaterial}>
            <figure />
            <div>
              <p>Materiais didáticos / {category?.name}</p>
              <p>{name}</p>
              <p>{truncate(description, truncate_description_size? truncate_description_size : 155)}</p>
            </div>
          </div>
        </main>
        <footer>
          <h4>Categoria</h4>
          <p>{category?.name}</p>
        </footer>
      </Container>
    </>
  );
}

export default WidgetPostBox;
