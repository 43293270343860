import React, { useCallback, useEffect, useRef, useState } from 'react';

import api from '../../services/api';

import Icon from '../Icon';

import { Container } from './styles';

import loveReactIcon from '../../assets/reaction-icons/love.png';
import likeReactIcon from '../../assets/reaction-icons/like.png';
import funnyReactIcon from '../../assets/reaction-icons/funny.png';
import happyReactIcon from '../../assets/reaction-icons/happy.png';
import sadReactIcon from '../../assets/reaction-icons/sad.png';

type Reaction = 'like' | 'love' | 'funny' | 'happy' | 'sad';

interface IReactionProps {
  type: string;
  funny: number;
  happy: number;
  like: number;
  love: number;
  sad: number;
  reactions_total: number;
}

interface IButtonFeedReactionProps {
  react?: Reaction;
  post_id: string;
  post_type: 'topics' | 'answers';
  setPost(reactions: IReactionProps): void;
}


const ButtonFeedReaction: React.FC<IButtonFeedReactionProps> = ({react, post_id, post_type, setPost}) => {

  const reactionTypes = {
    love: loveReactIcon,
    like: likeReactIcon,
    funny: funnyReactIcon,
    happy: happyReactIcon,
    sad: sadReactIcon,
  };

  const reactions = {
    love: 'Amei',
    like: 'Gostei',
    funny: 'Haha',
    happy: 'Feliz',
    sad: 'Poxa',
  }

  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [reactText, setReactText] = useState<Reaction | undefined>(react);
  const [reactIcon, setReactIcon] = useState(react ? reactionTypes[react] : '');

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!dropdownButtonRef.current || !event.target) {
        return;
      }

      if (dropdownButtonRef.current.contains(event.target as HTMLDivElement)) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {

  }, [isOpen]);

  const handleReactState = (reactText: Reaction, icon: string) => {
    setReactText(reactText);
    console.log(reactText)
    setReactIcon(icon);
    setIsOpen(false);

    handleReaction(reactText);
  }

  const handleReaction = useCallback((type: string) => {
    async function loadData() {
      try {
        //setLoading(true);

        const response = await api.post(`/${post_type}/${post_id}/reactions`, {
          "type": type,
        });

        console.log('Reaction >>>>>>>', response.data);
        setPost(response.data);
      } catch (error) {
        console.log(error);
      }
      finally {
        //setLoading(false);
      }
    }

    loadData();
  }, [post_id, post_type, setPost]);


  return (
    <Container isOpen={isOpen} ref={dropdownButtonRef}>
      {!reactIcon && <span><Icon icon="icon-icon-thumbs-up" size="20px" margin="0 .8rem 0 0" color="#23d2e2" /></span>}
      {reactIcon && <span><img src={reactIcon} alt={reactText} /></span>}
      <p>{reactText ? reactions[reactText] : 'Reagir'}!</p>
      <div>
        <span data-reaction="curti" onClick={() => handleReactState('like', likeReactIcon)}><img src={likeReactIcon} alt="curti"/></span>
        <span data-reaction="amei" onClick={() => handleReactState('love', loveReactIcon)}><img src={loveReactIcon} alt="amei"/></span>
        <span data-reaction="haha" onClick={() => handleReactState('funny', funnyReactIcon)}><img src={funnyReactIcon} alt="haha"/></span>
        <span data-reaction="feliz" onClick={() => handleReactState('happy', happyReactIcon)}><img src={happyReactIcon} alt="feliz"/></span>
        <span data-reaction="poxa" onClick={() => handleReactState('sad', sadReactIcon)}><img src={sadReactIcon} alt="poxa"/></span>
      </div>
    </Container>
  );
}

export default ButtonFeedReaction;
