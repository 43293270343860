import styled from 'styled-components';

import bgBannerImg from '../../assets/banner-bg-light2.png';

interface ISectionBannerProps {
  title: string;
  subTitle: string;
  background?: string;
}

export const Container = styled.div<ISectionBannerProps>`
  min-height: 160px;
  padding: 0 0 0 200px;
  border-radius: 12px;
  ${props => props.background ? `background: url(${props.background})` : `background: url(${bgBannerImg})`};
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > img {
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 960px) {
      height: 100px;
    }
  }

  > h1 {
    display: block;
    line-height: 1;
  }

  > p {
    display: block;
    margin-right: 1rem;
    margin-top: .4rem;
  }

  @media screen and (max-width: 960px) {
    height: 86px;
    padding: 0 0 0 120px;
    min-height: auto;

    > h1 {
      font-size: 1.5rem;
    }
    > p {
      font-size: 0.9rem;
      line-height: .9rem;
    }
  }

  @media (max-width: 768px) {

  }
  @media (max-width: 480px) {
    > h1 {
      font-size: 1rem;
    }
    > p {
      display: block;
    }
  }

`;
