import React, { useCallback, useRef } from 'react';

import { useMark } from '../../../hooks/mark-photo';

import { Container } from './styles';

interface MarkProps {
  id: string;
  background: string;
  color: string;
  parent?: React.RefObject<HTMLElement>;
}

const Mark: React.FC<MarkProps> = ({ id, background, color, children, parent }) => {
  const markRef = useRef<HTMLDivElement>(null);

  const { showMark, hideMark, isActive, getCoordinates } = useMark();

  const { height, left, width, top } = getCoordinates(id);

  const handleClick = useCallback(() => {
    const currentMark = markRef.current;

    if (!currentMark) {
      return;
    }

    if (!isActive(id)) {
      const {
        left: cLeft,
        top: cTop,
        width: cWidth,
      } = currentMark.getBoundingClientRect();

      /* if (parent?.current) {
        parent.current.scrollLeft = (cLeft + cWidth / 2);
      } */

      const scrollY = parent?.current ? parent.current.scrollTop : window.scrollY;
      const scrollX = parent?.current ? parent.current.scrollLeft : 0;

      const offsetX = (cLeft + cWidth / 2) + scrollX;
      const offsetY = cTop + scrollY - 14;

      showMark({ id, offsetX, offsetY });
    } else {
      hideMark(id);
    }
  }, [id, isActive, showMark, hideMark, parent]);

  return (
    <Container
      ref={markRef}
      background={background}
      color={color}
      onMouseOver={handleClick}
      onMouseLeave={() => hideMark(id)}
      style={{
        width,
        height,
        top,
        left,
      }}
    >
      {children}
    </Container>
  );
};

export default Mark;
