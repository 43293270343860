import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import FeaturedProfileBox from '../../../components/FeaturedProfileBox';

interface ITop30LastDaysProps {
  [key: string]: {
    user: {
      id: string;
      first_name: string;
      last_name: string;
      nickname?: null;
      avatar?: null;
      exp: number;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    };
    value: number;
  };
}

const TopMonthRanking: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [top30LastDays, setTop30LastDays] = useState<ITop30LastDaysProps>({} as ITop30LastDaysProps);

   // LIST TOPS FOR LAST 30 DAYS
   useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/dashboard/statistics');

        console.log('LIST TOP LAST 30 DAYS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setTop30LastDays(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return (
    <>
      {top30LastDays.highestGrade && (
        <FeaturedProfileBox
        title="#Top 1"
        subTitle="Maior nota média"
        timeAgo="Dos últimos 30 dias"
        nota={top30LastDays.highestGrade.value}
        user={top30LastDays.highestGrade.user}
        FeacturedType="topAvg"
        />)
      }
      {top30LastDays.highestAnswers && (
      <FeaturedProfileBox
        title="#Top 1"
        subTitle="Mais dúvidas respondidas"
        timeAgo="Dos últimos 30 dias"
        nota={top30LastDays.highestAnswers.value}
        user={top30LastDays.highestAnswers.user}
        FeacturedType="topDuvidas"
        />)
      }

      {top30LastDays.highestAchievements && <FeaturedProfileBox
        title="#Top 1"
        subTitle="Mais conquistas desbloqueadas"
        timeAgo="Nos últimos 30 dias"
        nota={top30LastDays.highestAchievements.value}
        user={top30LastDays.highestAchievements.user}
        FeacturedType="topConquistas"
      />}

      {top30LastDays.highestReactions && (
        <FeaturedProfileBox
          title="#Top 1"
          subTitle="Maior número de reações"
          timeAgo="Dos últimos 30 dias"
          nota={top30LastDays.highestReactions.value}
          user={top30LastDays.highestReactions.user}
          FeacturedType="topReact"
      />)}
    </>
  );
}

export default TopMonthRanking;
