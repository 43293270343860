import styled, { css } from 'styled-components';

import checkIcon from '../../assets/check-icon.svg';
import blockIcon from '../../assets/block-icon.svg';

interface ICardQuestionProps {
  status: 'corrected' | 'wrong' | 'blank';
  current: boolean;
  blank: boolean;
  onClick(): void;
}

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const SliderInnerAlert = styled.div`

> div {
  > footer {
    > p {
      > button {
        margin: 0 .5rem;
        border: none;
        border-radius: 7px;
        padding: 1rem 1rem;
        min-width: 170px;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
        font-size: .75rem;
        background: #03bb85;
        color: #fff;
        margin-top: 2rem;
      }
    }
  }
}
  .outline {
    padding: 14px 27px;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 1rem 0 1rem 0;
    background: #fff;
    color: #6e61c6;
    font-weight: 700;
    border-radius: 50px;
    transition: .3s all;

    &:hover {
      background: #31495e;
      color: #fff;
    }
  }

  > div div {
    > h1 {
      color: #fff;
    }

    > p {
      color: #fff;
      font-weight: 500;
      margin-top: .5rem;
      display: flex;
      justify-content: center;

      > a {
        color : purple;
        padding-top: 1rem;
      }
    }
    > footer {
      margin-top: 2rem;
      display: flex;
      width: 100%;
      justify-content: space-between;

      > p {
        > a {
          color: #fff;
          font-weight: 600;
        }

        > button {
          background: none;
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    > aside {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      width: 100%;

      > button {

      }
    }
  }


  /* BUTONS PREV AND NEXT SLIDER */
  button[data-action='stop'],
  button[data-action='start'] {
    display: none;
  }

  #customize-controls {
    list-style: none;
    border: none;
    color: #6e61c6;
    cursor: pointer;

    > li:first-child {
      transform: rotate(180deg);

      position: absolute;
      z-index: 999;
      left: 0;
      top: calc(50% - 6px);
      padding: 0 .7rem;
    }
    > li:last-child {
      position: absolute;
      z-index: 999;
      right: 0;
      top: calc(50% - 6px);
      padding: 0 .7rem;
    }
  }

  #customize-toggle {
    visibility: hidden;
  }

`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 2fr 1fr;

  /* TABLET */
  @media (max-width: 1250px) {
    display: flex;
    flex-direction: column-reverse;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    position: relative;
    border-radius: 12px;
    padding: 34px 28px !important;
    background: ${props => props.theme.colors.bgBox};
    color: ${props => props.theme.colors.textColor};
    box-shadow: ${props => props.theme.colors.shadow};
  }

  > section:nth-child(2) {
    position: sticky;
    top: calc(80px + 1rem);
    height: 0;

    /* TABLET */
    @media (max-width: 1250px) {
      position: relative;
      height: auto;
      top: 0;
    }

    > main {
      background: ${props => props.theme.colors.bgBox};
      position: relative;
      padding: 32px 28px 28px 28px;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(10, 1fr);
      border-radius: 12px;

      /* MOBILE */
      @media (max-width: 580px) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
`;

const buttonColorVariations = {
  corrected: css`
    background: #03bb85;
    color: #fff;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: #03bb85de;
    }
  `,
  wrong: css`
    background: #e74c3c;
    color: #fff;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: #e74c3ccc;
    }
  `,
  blank: css`
    background: ${props => props.theme.colors.bgBox};
    color: #adafca !important;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: ${props => props.theme.colors.bgBox}80;
    }
  `,
};

export const CardQuestion = styled.div<ICardQuestionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  border-radius: 4px;
  ${props => buttonColorVariations[props.status]};
  background: ${props => props.status === 'blank' && props.theme.colors.bgContent};
  color: #fff;
  font-weight: bold;
  animation: ${props => props.current ? 'pulseAnimationCss 2s infinite' : 'unset' };

  @keyframes pulseAnimationCss {
    0% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.primary};
      box-shadow: 0 0 0 0 ${props => props.theme.colors.primary};
    }
    70% {
        -moz-box-shadow: 0 0 0 5px ${props => props.theme.colors.primary}10;
        box-shadow: 0 0 0 5px ${props => props.theme.colors.primary}10;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.primary}10;
        box-shadow: 0 0 0 0 ${props => props.theme.colors.primary}10;
    }
  }
`;

export const Question = styled.div`
  margin-bottom: 1rem;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    > div:nth-child(1) {
        > h1 {
        text-align: left;

        > span {
          font-size: 13px;
        }
      }
    }
    > div:nth-child(2) {
      > p {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #adafca;
      }
    }
  }

  > main {
    margin-bottom: 1rem;

    > div {

      > p {
        font-size: 14px;
        margin-bottom: .5rem;
        font-weight: 600;

        > img {
          width: auto !important;
          height: auto !important;
          background: #fff;
          padding: .5rem;
          border-radius: 8px;
          max-width: 100%;
        }
      }
    }
  }

  > footer {
    font-weight: 600;

    > p {
      font-weight: 600;
    }
  }
`;

interface IInputGroupProps {
  readOnly: boolean;
  correctAnswer: string;
  checkedAnswer: string;
  value: string;
}

export const InputGroup = styled.div<IInputGroupProps>`
    display: block;
    margin: 10px 0;
    position: relative;
    color: ${({ theme }) => theme.colors.textColor};

    label {
      ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ? 'background: #03bb85; color: #fff;' : (props.checkedAnswer === props.value && 'background: #e74c3c;')};
      `}
      padding: 12px 50px;
      width: 100%;
      display: block;
      text-align: left;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid #5562eb26;
      ${props => props.readOnly && css`
        cursor: not-allowed;
      `}
      ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ? 'border: 1px solid #03bb85;' : (props.checkedAnswer === props.value && 'border: 1px solid ##e74c3c;')};
      `}

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #5562eb;
        ${props => props.readOnly && css`
          ${props.correctAnswer === props.value ? 'background: #03bb85' : (props.checkedAnswer === props.value && 'background: #e74c3c')};
        `}
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 1rem;
        height: 1rem;
        content: '';
        border: 2px solid #e6e8fc;
        background: url(${checkIcon});

        ${props => props.readOnly && css`
          ${props.correctAnswer === props.value ?
            `background: url(${checkIcon}) #189570;border: 2px solid #289777;`
            :
            null
          };
        `}
        ${props => props.readOnly && css`
          ${props.correctAnswer !== props.value ?
            `background: url(${blockIcon}) #fff`
            :
            null
          };
        `}
        background-repeat: no-repeat;
        background-position: 1px 1px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        ${props => props.readOnly && css`
          cursor: not-allowed;
        `}
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
        width: 100%;
      }

      &:after {
        background-color: #54E0C7;
        border-color: #54E0C7;

        ${props => props.readOnly && css`
          ${props.correctAnswer === props.value ? 'background-color: #189570; border-color: #289777' : null };
        `}

       ${props => props.readOnly && css`
          ${props.correctAnswer !== props.value ? 'background-color: #c92413; border-color: #bf3d2f' : null };
        `}
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
`;

export const FooterMenu = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #615dfa;
  z-index: 9;
  width: 100vw;
  height: 80px;
  left: 0;
  bottom: 0;
  padding: 0 86px;
  z-index: 999999999999999999999999;

  > section:nth-child(1) {
    text-align: center;
    margin: 0 .5rem;

    > p {
      color: #fff;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
    }

    > span {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  > section:nth-child(2) {
    text-align: center;
    margin: 0 .5rem;

    > p {
      color: #fff;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
    }

    > span {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  > section:nth-child(3) {
    text-align: center;
    margin: 0 .5rem;

    > button {
      margin: 0 .5rem;
      border: none;
      border-radius: 7px;
      padding: 1rem 1rem;
      min-width: 170px;
      text-align: center;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
      font-size: .75rem;
    }
  }
`;

export const ModalFinishForTime = styled.div`


`;
