import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 9999999999999;
  bottom: 2rem;
  right: 3rem;


  .nav--checkbox {
    display: none;
  }
  .nav--button {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60px;
    width: 60px;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 50%;
    z-index: 666;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 2s infinite;
    cursor: pointer;
  }

  .nav--button span {
    display: flex;
  }

  .nav--small {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: all 500ms cubic-bezier(0.6, -0.5, 0.4, 1.5);
    z-index: 1;
  }
  .nav--small button {
    position: relative;
    display: block;
    height: inherit;
    width: inherit;
    border-radius: inherit;
    text-decoration: none;
    color: white;

    &:after {
      position: absolute;
      z-index: 99999;
      content: attr(data-tooltip);
      border-radius: 1rem;
      text-align: center;
      box-sizing: border-box;
      box-shadow: ${props => props.theme.colors.shadowBox};
      padding: 0 12px;
      background: ${props => props.theme.colors.darkGray};
      color: #fff;
      font-size: .75rem;
      font-weight: 700;
      white-space: nowrap;
      line-height: 24px;
      margin-top: 0;
      margin-left: -25px;
      opacity: 0;
      visibility: hidden;
      transition: .4s;
    }

    &:hover {
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover::after {
      visibility: visible;
      opacity: 1;
      transform: translateX(-5.7rem);
    }
  }
  .nav--small button i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 19px;
  }
  .nav--btn-1 {
      > button {
        background-color: #b649d2;
      }
    }

  .nav--btn-3 {
    > button {
      background-color: #741077;
      transition-delay: 100ms;
    }
  }
  .nav--checkbox:checked ~ .nav--btn-1 {
      bottom: 85px;
  }

  .nav--checkbox:checked ~ .nav--btn-3 {
    right: 85px;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(97,93,250, 0.50);
      box-shadow: 0 0 0 0 rgba(97,93,250, 0.50);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(255,255,255, 0);
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }
`;

export const ModalCorrectionType = styled.div`

> header {

    > h4 {
      color:  #e2e3ea;
      letter-spacing: 5px;
    }

    > h2 {
      margin-bottom: 1rem;
    }
  }

  > main {

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 1rem 0;

    /* TABLET */
    @media (max-width: 768px) {}

    /* MOBILE */
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    > section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #3e3f5e;
      cursor: pointer;
      padding: 32px 28px;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
      transition: all .2s ease-in-out;

      &:hover {
        transform: translateY(-.5rem);
      }

      > span {
        margin-bottom: .5rem;
      }

      > p {
        margin-top: .25rem;
        line-height: 1;
      }
    }
  }

  > footer {

    > span {
      padding: 1px 11px;
      border-radius: 40px;
    }
  }
  `;
