import React from 'react';

import LazyLoad from 'react-lazyload';


import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import SectionBanner from '../../components/SectionBanner';

import bannerindexIconImg from '../../assets/streams-icon.png';

import { Container, ContentGrid1, Timeline, TabsCrid, VideoContainer, VideoContent, SectionMaterials } from './styles';
import Icon from '../../components/Icon';
import PlayerAdapter from '../../components/PlayerAdapter';

const RedacoesNota1000: React.FC = () => {

  const lessons = [
    {
      id: 1,
      title: 'Aula 01 - Curso Redação ENEM Nota 1000 🔥',
      duration: '03:59',
      video_url: '3383bd46-cd58-4128-aa1f-23b187697b4f',
    },
    {
      id: 2,
      title: 'Aula 02 - Entenda as 5 competências da redação do ENEM',
      duration: '09:09',
      video_url: '02bb16ae-5d7d-4591-8d75-2579fec9b894',
    },
    {
      id: 3,
      title: 'Aula 03 - O que as introduções nota 1000 têm em comum?',
      duration: '51:56',
      video_url: '8db4f386-093e-4b9d-b9ca-02a6609ef139',
    },
    {
      id: 4,
      title: 'Aula 04 - O que os parágrafos de desenvolvimento 1 têm em comum?',
      duration: '58:19',
      video_url: 'aa8ff6ef-0ac3-4944-bb3d-3f9d4dbbf590',
    },
    {
      id: 5,
      title: 'Aula 05 - O que os parágrafos de desenvolvimento 2 têm em comum?',
      duration: '38:42',
      video_url: '25342852-e32f-4b88-a8fd-49c2e7f8f774',
    },
    {
      id: 6,
      title: 'Aula 06 - O que as conclusões nota 1000 têm em comum?',
      duration: '53:31',
      video_url: 'adfb262e-fdff-495e-b810-fe55e549bc51',
    },
    {
      id: 7,
      title: 'Aula 07- Análise da Planilha Completa ✅',
      duration: '07:25',
      video_url: '4b3603d9-33ae-4fd3-bc08-46abfcdc499b',
    },
    {
      id: 8,
      title: 'Aula 08 - Repertórios e argumentos que servem para qualquer tema',
      duration: '06:45',
      video_url: '89cc9dab-71e4-4c96-9196-121399fd622c',
    },
    {
      id: 9,
      title: 'Aula 09 - Conectivos e agentes interventivos mais usados nas redações nota 1000',
      duration: '06:07',
      video_url: '0b6a2090-7be0-4bae-8a4f-f204cde382d1',
    },
    {
      id: 10,
      title: 'Aula 10 - Checklist com base nas redações nota 1000 ✅',
      duration: '09:52',
      video_url: '7bf72b7a-955d-44af-8d31-14b789653e04',
    },
  ];

  const onReady = (player: any) => {
    console.log('Player is ready: ', player);
  }

  const onPlay = (player: any) => {
    console.log('Video is playing: ', player);
  }

  const onPause = (player: any) => {
    console.log('Video is paused: ', player);
  }

  const onProgress = (player: any) => {
    console.log('Video progress: ', player);
  }

  const onEnded = (id: number) => {
    console.log('Video ended: ', id);
  }

  return (
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Curso de análises e comparações de redações nota 1000" subTitle="Curso pago disponível no Youtube Learning e está sendo disponibilizado com BÔNUS para vocês" />
      <TabsCrid>
        <ContentGrid1>
          <section>
            <div>
              <SectionMaterials href="https://planilha.poxalulu.com.br" target="_blank" rel="noreferrer" className='pulse'>
                <header>
                  <Icon icon="icon-icon-send-message" size="48px" />
                </header>
                <footer>
                  <p>Clique para acessar a Planilha Interativa de Redações Nota 1000</p>
                </footer>
              </SectionMaterials>

            </div>
          </section>
          <section style={{ width: '100%' }}>
            <Timeline>
              <div className="line">
                <div className="line-content">
                  {lessons.map((lesson, index) => {
                    return (
                      <>
                        <VideoContent className="content" key={index}>
                          <LazyLoad style={{ position: 'relative', minHeight: 100 }} once={true} placeholder={<Loader isFixed={false} />}>
                            <h1>{lesson.title}</h1>
                            <VideoContainer>
                              <PlayerAdapter
                                provider="panda"
                                video_url={lesson.video_url}
                                thumbnail={''}
                                video={lesson.video_url}
                                onReady={onReady}
                                onPlay={onPlay}
                                onPause={onPause}
                                onEnded={() => onEnded(lesson.id)}
                                onProgress={onProgress}
                              />
                            </VideoContainer>
                          </LazyLoad>
                        </VideoContent>
                      </>
                    )
                  })}
                </div>
              </div>
            </Timeline>
          </section>
        </ContentGrid1>
      </TabsCrid>
      <Footer />
    </Container>
  );
}

export default RedacoesNota1000;
