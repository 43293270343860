import React, { useCallback, useRef } from 'react';

import { useMark } from '../../../hooks/mark';

import { Container } from './styles';

interface MarkProps {
  id: string;
  background: string;
  color: string;
}

const Mark: React.FC<MarkProps> = ({ id, background, color, children }) => {
  const markRef = useRef<HTMLDivElement>(null);

  const { showMark, hideMark, isActive } = useMark();

  const handleMouseOver = useCallback(() => {
    const currentMark = markRef.current;

    if (!currentMark) {
      return;
    }

    if (!isActive(id)) {
      const parent = currentMark.parentElement as HTMLElement;
      const grandParent = parent.parentElement as HTMLElement;
      const paddingLeft = grandParent.clientWidth > 844 ? 50 : 20;
      const paddingRight = grandParent.clientWidth > 844 ? 5 : 20;

      const offsetLeft = (paddingLeft + currentMark.offsetWidth + paddingRight) >= grandParent.clientWidth ? paddingLeft : currentMark.offsetLeft;
      const offsetX = offsetLeft + currentMark.offsetWidth / 2;
      const offsetY = currentMark.offsetTop - 14;

      showMark({ id, offsetX, offsetY });
    }
  }, [id, isActive, showMark]);

  return (
    <Container
      ref={markRef}
      background={background}
      color={color}
      isActive={isActive(id)}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => hideMark(id)}
    >
      {children}
    </Container>
  );
};

export default Mark;
