import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useCredit } from '../../hooks/credit';

import api from '../../services/api';

import CountUp from 'react-countup';
import { debounce } from 'lodash';
import { format, isBefore } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { parseISO } from 'date-fns/esm';

import ListCorrectionPreview  from "./ListCorrectionPreview";
import ContentGrid  from "../../components/ContentGrid";
import SectionBanner from '../../components/SectionBanner';
import BoxContainer  from "../../components/BoxContainer";
import InputAnimate from '../../components/InputAnimate';
import Icon from '../../components/Icon';
import NewWordingFloatButton from '../../components/NewWordingFloatButton';
import Pagination from '../../components/Pagination';
import SmallStatisticsBox from '../../components/SmallStatisticsBox';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/sends-icon.png';

import { Container, SearchButton, SendStatiscs, RenoveBox, ContentGrid0, TabsCrid, ContentGrid3, Showing, NotFound } from './styles';

export interface IProfileStatisticsProps {
  submitted_wordings: number;
  average_grade: number;
  highest_grade: number;
  times_in_hall: number;
  topics_count: number;
  tickets_count: number;
  achievements_count: number;
}

export interface IProfileWordingsProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records: {
    id: string;
    student_id: string;
    theme_id: string;
    user_id: string;
    redemption_date: string;
    end_time_in_seconds: number;
    photo?: string;
    is_photo: boolean;
    text: string;
    status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
    cancellation_reason?: string;
    created_at: string;
    updated_at: string;
    theme: {
      id: string;
      name: string;
      thumbnail: string;
      new: boolean;
      material_url?: string;
      thumbnail_url: string;
    };
    corrections: {
      id: string;
      final_grade: number;
      created_at: string;
    }[];
    correction_deadline: string;
    status_info: {
      name: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
      color: string;
    };
  }[];
}


const MinhasRedacoes: React.FC = () => {
  const { user } = useAuth();
  const { credits } = useCredit();

  const [loading, setLoading] = useState(true);

  const [wordings, setWordings] = useState<IProfileWordingsProps>({} as IProfileWordingsProps);
  const [personalStatistics, setPersonalStatistics] = useState<IProfileStatisticsProps>({} as IProfileStatisticsProps);

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');


  // impede que a página seja redirecionada ao submeter o input de materiais
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    return
  }, []);

  const delayedSearch = useMemo(() =>
    debounce(() => {
      if (searchTerm.length > 3) {
        setSearch(searchTerm);
      } else {
        setSearch('');
      }
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  // List PERSONAL WORDING STATISTICS
  useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get<IProfileStatisticsProps>(`/profile/wordings/statistics/${user.id}`,
          {params: {exam_name: 'enem'}}
        );

        console.log('LIST PERSONAL WORDINGS STATISTICS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setPersonalStatistics(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  // List WORDINGS: default ENEM
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        window.scrollTo(0, 0);

        const response = await api.get<IProfileWordingsProps>('/profile/wordings',
          {params: {exam_name: 'enem', search: search, pageIndex: currentPage - 1, pageSize: 8,}}
        );

        console.log('LIST WORDINGS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

        setWordings(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [search, currentPage]);

  return(
    <Container>
      <NewWordingFloatButton to={'/temas'} icon="icon-icon-send-message" background="#9181fa" tooltip="Escrever Redação!" />
      <ContentGrid>
        <SectionBanner icon={bannerindexIconImg} title="Minhas Redações" subTitle="As redações tem prazo máximo de 3 dias úteis para serem corrigidas." />
        <TabsCrid>
          <ContentGrid0>
            <SmallStatisticsBox icon="icon-icon-send-message" title="Redações Enviadas" bgColorIcon="#9181fa" value={personalStatistics?.submitted_wordings} />
            <SmallStatisticsBox icon="icon-icon-overview" title="Nota Média" bgColorIcon="#9181fa" value={personalStatistics?.average_grade} />
            <SmallStatisticsBox icon="icon-icon-badges" title="Sua Maior Nota" bgColorIcon="#9181fa" value={personalStatistics?.highest_grade} />
            <SmallStatisticsBox icon="icon-icon-trophy" title="Vezes no Hall da Fama" bgColorIcon="#9181fa" value={personalStatistics?.times_in_hall} />
          </ContentGrid0>

          <BoxContainer margin="1rem 0 1rem 0" padding="34px 28px" display="flex" position="relative">
            <RenoveBox>
              <span>Renovação dos créditos: {credits.finalPeriod && format(new Date(credits?.finalPeriod), "dd'/'MM'/'Y", {locale: ptBR})}</span>
            </RenoveBox>
            <form onSubmit={e => handleSubmit(e)}>
              <InputAnimate
                label="Pesquise por uma redação"
                type="search"
                name="user"
                onChange={(event) => {setCurrentPage(1); setSearchTerm(event.target.value);}}
                margin="0"
                required
                autoComplete="off"
              />
              <SearchButton>
                <Icon icon="icon-icon-magnifying-glass" />
              </SearchButton>
            </form>
            <SendStatiscs>
              <p>
                <CountUp end={credits.wordingsRemaining + credits.extraCredits || 0} duration={5} />
              </p>
              <h5>Créditos disponíveis</h5>
            </SendStatiscs>
          </BoxContainer>
          <section>
            {loading && <Loader isFixed={false} />}
            {wordings && wordings?.records?.map((wording) => (
              <ListCorrectionPreview
                key={wording.id}
                id={wording.id}
                imgCover={wording.theme.thumbnail_url}
                date={wording.created_at}
                exp={wording.status === 'corrected' ? 30 + Math.round(wording?.corrections[0] && wording?.corrections[0].final_grade / 10) : 30}
                tema={wording.theme.name}
                nota={wording.corrections[0]?.final_grade}
                correction_deadline={wording.correction_deadline}
                is_photo={wording.is_photo}
                created_at={wording.created_at}
                status={wording.status}
                setWordings={setWordings}
              />))}
            {(totalRecords === 0 && !!!loading) && <NotFound>Poxa, não encontramos nenhuma redação até o momento. 😢<br /> <Link to="/temas"><Icon icon="icon-icon-send-message" margin="0 6px 0 0" /> ESCREVER REDAÇÃO</Link></NotFound>}
          </section>
        </TabsCrid>
      </ContentGrid>
      <ContentGrid3>
        <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} redações enviadas`}</Showing>
        {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      </ContentGrid3>
      <Footer />
    </Container>
  );
}

export default MinhasRedacoes;
