import React from 'react';

import { Container } from './styles';

const InCorrectionAlert: React.FC = () => {
  return (
    <Container>
      Sua redação ainda está em correção! 😊
    </Container>
  );
}

export default InCorrectionAlert;
