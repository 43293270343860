import React from 'react';

import { Image } from 'react-image-and-background-image-fade';

import Icon from '../../../components/Icon';
import { Container } from './styles';

interface IBadgeItemCardProps {
  badge_icon: string;
  title: string;
  description: string;
  exp_total_value: number | string;
  status: 'pending' | 'concluded';
  is_hidden: boolean;
  users: {
    id: string;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  }[];
}

const BadgeItemCard: React.FC<IBadgeItemCardProps> = ({badge_icon, title, description, exp_total_value, status, is_hidden}) => {


  console.log('STATUSSS', status)

  return (
    <Container status={status}>
      <div>
        <Icon icon="icon-icon-plus-small" color={status === 'concluded' ? '#00c7d9' : ''} size="8px" margin="2px 6px 0 0" /> {exp_total_value} Exp
      </div>
      <header>
        <Image src={badge_icon} lazyLoad={true} />
      </header>
      <main>
        <h1>{title} {(is_hidden && status === 'concluded') && '(Conquista misteriosa)'}</h1>

        <p>{description}</p>
      </main>
      <footer>
        <p><span>{status === 'concluded' ? 'Conquista desbloqueada' : 'Você ainda não desbloqueou esta conquista'}</span></p>
      </footer>
    </Container>
  );
}

export default BadgeItemCard;
