import React, {useRef, useCallback, useEffect, useState} from 'react';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web'
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import WysiwygInnerModal from '../../../components/WysiwygInnerModal';
import LoaderPureCss from '../../../components/LoaderPureCss';
import InputAnimate2 from '../../../components/InputAnimate2';

import { SingleEvent } from './styles';

import firstCharapterCaptalized from '../../../utils/firstCharapterCaptalized';

export interface IAddTopicDTO {
  title: string;
  content: string;
}

interface ICreateEventProps {
  id: string;
  title?: string;
  content: string;
  slug: string;
  category: string;
  setTopic(data: any): void;
}

const ModalEditTopic: React.FC<ICreateEventProps> = ({ id, title, content, slug, category, setTopic }) => {
  const formRef = useRef<FormHandles>(null);
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formRef.current?.setData({ title, content })
  }, [title, content])

  const handleSubmit = useCallback(async (data: IAddTopicDTO) => {
    console.log('data aqui');
    console.log(data);

    try {
      setDisabled(true);
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        title: Yup.string().min(15, 'O título deve conter pelo menos 15 caracteres.').required('Informe o título do seu tópico.'),
        content: Yup.string().min(15, 'O conteúdo deve conter pelo menos 15 caracteres.').required('Informe a descrição de sua dúvida.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(`/forums/${slug}/${category}/topics/${id}`, {
        title: firstCharapterCaptalized(data.title),
        content: data.content,
      });

      setTopic(response.data);

      console.log(response.data);

      Swal.close();
    } catch (err) {
      setDisabled(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      console.log(err);
    }
    finally {
      setLoading(false);
    }

  }, [id, category, slug, setTopic]);

  return (
    <SingleEvent>
      <section>
        <h1>Editar tópico</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <InputAnimate2 name="title" label="Título da postagem" type="text" />
          </div>
          <WysiwygInnerModal name="content" placeholder="Desenvolva a sua pergunta aqui..." maxLength={800} />
          <footer>
            <button type="submit" color="primary" disabled={disabled}>
              Editar Tópico
            </button>
          </footer>
        </Form>
        {loading && <LoaderPureCss isFixed={false} />}
      </section>
    </SingleEvent>
  );
}

export default ModalEditTopic;
