import React, { ButtonHTMLAttributes } from 'react';

import Icon from '../../../components/Icon';

import { Container } from './styles';

type IButtonCancelSendProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
}

const ButtonCancelSend: React.FC<IButtonCancelSendProps> = ({status, id, ...rest}) => {
  return (
    <Container
      status={status}
      data-tooltip="Cancelar redação"
      {...rest}>
      <Icon icon="icon-icon-delete" />
      <footer>cancelar redação?</footer>
    </Container>
  );
}

export default ButtonCancelSend;
