const getGernders =  [
  {
    value: 'male',
    label: 'Masculino',
  },
  {
    value: 'female',
    label: 'Feminino',
  },
  {
    value: 'non-binary',
    label: 'Não-binário',
  },
];

export default getGernders;
