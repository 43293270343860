import styled from 'styled-components';

export const Container = styled.div`
  max-width: 890px;
  margin: 0 auto;
  position: relative;
`;

export const WordingPhotoContainer = styled.div`
  user-select: none;

  > img {
    display: block;
    max-width: 890px;
    width: 100%;
  }
`;
