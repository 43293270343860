import styled from 'styled-components';

interface IListCorrectionPreviewProps {
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 107px;
  padding-left: 28px;
  position: relative;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.bgBox};
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  margin-bottom: 1rem;

  > div {
    margin-right: 1rem;

    > img {
      border-radius: 12px;
    }
  }


   /* TABLET */
   @media (max-width: 768px) {
    padding-left: 1.8rem;

    > div:first-child {
      display: none;
    }

    aside {

      > a {
        font-size: .7rem !important;
      }

      > h6 {
        display: none;
      }
    }

    main {
      display: none !important;
    }

    button:first-child {
      margin-left: 0;
      transform: scale(.8);
      margin-right: .5rem;
    }
  }

  /* MOBILE */
  @media (max-width: 480px) {

    button:first-child {
      margin-left: 0;
      transform: scale(.7);
      margin-right: 0;
    }
  }
`;

export const CorrectionInfoPreview = styled.div<IListCorrectionPreviewProps>`
  width: 100%;
  padding: 0 28px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > aside {
    line-height: 1;
    position: relative;
    width: 35%; /* AQUI */

    > a {
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
      font-size: .9rem;
      line-height: 1;
      text-decoration: none;
      text-align: left;
      cursor: ${props => props.status === 'canceled' ? 'not-allowed' : 'pointer'};
    }

    > h6 {
      color: #adafca;
      position: relative;
      padding-left: 0px;
      margin-top: 8px;
      font-weight: 600;
    }

    > h4 {
      color: #adafca;
      position: relative;
      padding-left: 0px;
      margin-top: 8px;
      font-weight: 600;
      font-size: 10px;
      display: none;

      @media (max-width: 480px) {
        display: block;
      }
    }

    /* TABLET */
    @media (max-width: 768px) {

    }

    /* MOBILE */
    @media (max-width: 480px) {
      width: auto;
    }
  }

  > main  {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  /* TABLET */
  @media (max-width: 768px) {
    padding: 0;
  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ListNotes = styled.div`
  list-style: none;
  border: none;
  display: inline-flex;
  text-align: center;
  line-height: 1;
  width: 145px;
  justify-content: center;

  /* MOBILE */
  @media (max-width: 480px) {
    display: none;
  }
`;

export const Notes = styled.div`
  position: relative;
  text-align: center;
  padding: 0 24px;

  &:nth-child(2)::before {
    content: "";
    width: 1px;
    height: 20px;
    background-color: #eaeaf580;
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    box-sizing: border-box;
  }

    > h4 {
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      color: #adafca;
      margin-top: 10px;
      font-size: .6875rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
    }

    > span {
      display: flex;
      align-items: center;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      display: flex;
      box-shadow: 3px 5px 30px 0 rgb(94 92 154 / 12%);
      border-radius: 6px;
      padding: .45rem .75rem;
      color: ${props => props.theme.colors.textColor};
      background-color: ${props => props.theme.colors.bgBox};

      > span {
        font-size: 1.875rem;
        font-weight: 700;
        transition: all .25s ease-in-out;
        margin: 0 .35rem;
      }

    }

    /* TABLET */
    @media (max-width: 768px) {

    }

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }
`;

export const ButtonsActionsContainer = styled.div`
  display: flex;

  > a {
    align-items: center;
    background: ${props => props.theme.colors.primary};
    box-shadow: 4px 7px 12px 0 rgba(97,93,250,.2);
    padding: 12px 15px;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    font-size: .75rem;
    font-weight: 700;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;

    &:hover {
      background: #53489f;
    }
  }

  /* TABLET */
  @media (max-width: 768px) {
    flex-direction: column;
  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ButtonCancelSend = styled.div`
  color: #adafca;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  opacity: .4;
  cursor: pointer;
  transition: opacity .2s ease-in-out,fill .2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const CancelWordingModalContent = styled.div`

  > header {
    position: relative;
    overflow: hidden;
    height: 150px;
    width: auto;
    margin: -22px -40px;
    border-radius: 10px 10px 0 0;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      background: linear-gradient(to bottom,rgba(0,0,0,0), #6e61c6);
      position: absolute;
    }

    > div {
      position: absolute;
      z-index: -1;

      > img {
        width: 100%;
        margin-top: -20px;
      }
    }
  }

  > main {
    position: relative;
    z-index: 999;

    > h1 {
      line-height: 1;
      margin-bottom: 1rem;
    }
  }

`;
