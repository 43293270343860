import styled from 'styled-components';

interface ContainerProps {
  background: string;
  color: string;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  border-radius: 4px;
  background: ${({ background }) => background}80;
  color: ${({ color }) => color};
  cursor: pointer;
`;
