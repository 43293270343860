import React from 'react';

import { Container} from './styles';

const HallDaFama: React.FC = () => {
  return(
    <Container>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
      <div>1</div>
    </Container>
  );
}

export default HallDaFama;
