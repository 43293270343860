import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  position: relative;

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    > h4 {
      color: ${props => props.theme.colors.textColor};
      line-height: 1em;
      margin-left: .55rem;
    }
  }

  main {
    position: relative;

    > ul {
      position: relative;
      list-style: none;
      max-height: 508px;
      overflow-y: scroll;
      list-style-type: none;

      > li {
        text-align: left;
        opacity: 1;
        padding: .2rem 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #9aa4bf40;
        justify-content: space-between;
        cursor: unset;

        > p {
          min-width: 32px;
          text-align: center;
        }

        &:last-child {
          border-bottom: none;
        }

        > aside {
          width: 52px;
          margin-right: 7px;
        }

        > div {
          flex-direction: column;
          width: 100%;

          > h3 {
            font-size: 1rem;
            color: ${props => props.theme.colors.textColor};
            font-weight: 700;
            line-height: 1;
          }

          > p {
            font-size: .75rem;
            font-weight: 500;
            color: ${props => props.theme.colors.textColor};

            /* MOBILE */
            @media (max-width: 480px) {
              font-size: .6rem;
              font-weight: 500;
            }
          }
        }
      }

      &::-webkit-scrollbar {
        visibility: hidden;
        width: 4px;
        height: 4px;
      }

      &:hover::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        opacity: 1;
        transition: all .3s ease-in-out;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: #cccccc80;
        border-radius: 10px;
      }
      &:hover::-webkit-scrollbar-thumb:hover{

      }
      &:hover::-webkit-scrollbar-track{
        border-radius: 10px;
        box-shadow: inset 7px 10px 12px #cccccc10;
      }
    }

    > div {
      display: flex;
      align-items: center;
      margin: 0 -28px;
      padding: 5px 28px;
      border-radius: 0 0 12px 12px;
      color: ${props => props.theme.colors.textColor};
      background: ${props => props.theme.colors.bgContent}70;

      > p {
        min-width: 32px;
        text-align: center;
        font-size: .875rem;
        font-weight: 700;
      }

      > div {
        > h3 {
          font-size: 1rem;
          font-weight: 700;
          line-height: 1;
        }
        > p {
          font-size: .75rem;
          font-weight: 500;

          /* MOBILE */
          @media (max-width: 480px) {
            font-size: .6rem;
            font-weight: 500;
          }
        }
      }

      > aside {
          width: 52px;
          margin-right: 7px;
      }

      > main {
        display: flex;
        align-items: end;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
`;
