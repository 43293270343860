import styled, { css } from 'styled-components';

import bgHeadAvg from '../../assets/banner-reaction-4.jpg';
import bgHeadDuvidas from '../../assets/banner-reaction-1.png';
import bgHeadViews from '../../assets/banner-reaction-12.png';
import bgHeadReact from '../../assets/banner-reaction-11.png';

interface IFeaturedProfileBoxProps {
  FeacturedType: 'topAvg' | 'topDuvidas' | 'topConquistas' | 'topReact';
}

const bgHeaderVariations = {
  topAvg: css`
    background: url(${bgHeadAvg});
  `,
  topDuvidas: css`
    background: url(${bgHeadDuvidas}), linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  `,
  topConquistas: css`
    background: url(${bgHeadViews}), linear-gradient(90deg, #635ef9 0%, #8e7aff 100%);
  `,
  topReact: css`
    background: url(${bgHeadReact}), linear-gradient(90deg, #36D1DC 0%, #5B86E5 100%);
  `,
}

export const Container = styled.div<IFeaturedProfileBoxProps>`
  width: 100%;
  border-radius: 12px;
  text-align: center;
  line-height: 1;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};

  > header {
    height: 120px;
    padding-top: 32px;
    border-radius: 12px 12px 0 0;
    display: block;
    //background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    ${props => bgHeaderVariations[props.FeacturedType]};

    > h3 {
      text-transform: uppercase;
    }

    > p {
      font-size: 14px;
      margin-top: .4rem;
    }
  }

  > aside {
    margin-top: -25px;
    padding-bottom: 28px;

    > h4 {
      margin-top: 8px;
      font-size: 1.375rem;
      font-weight: 700;
      color: ${props => props.theme.colors.textColor};
    }

    > h6 {
      margin-top: 14px;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      margin-top: 4px;
      color: #adafca;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
`;
