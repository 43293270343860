import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 80px;
  left: 80px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;

  > div {
    width: 200px;
    position: relative;
    z-index: 99999;
    justify-content: center;
    box-sizing: border-box;
    color: ${props => props.theme.colors.textColor};
    border-width: .5px;
    border-style: solid;
    border-image:
      linear-gradient(to bottom, rgb(173 175 202), rgba(0, 0, 0, 0)) 1 100%;


    &::before {
      /* content: '';
      position: absolute;
      height: 100vh;
      width: 1px;
      background: rgba(255,255,255,0.7);
      background: linear-gradient(to bottom, rgb(173, 175, 202), ${props => props.theme.colors.bgBox}10);
      margin-top: -100vh;
      bottom: 0;
      left: 100%; */
    }
  }
`;
