import React, { useEffect, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import htmlParser from 'react-html-parser';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ProfileHeader from '../../components/ProfileHeader';
import Accordion from '../../components/Accordion';
import HeaderInfo from '../../components/HeaderInfo';
import Loader from '../../components/Loader';
import UserAvatar from '../../components/UserAvatar';
import Icon from '../../components/Icon';

import { Container, ContentGrid1, ContentGrid2, ContentGrid3, Card } from './styles';

import poxaluluProfilePic from '../../assets/poxa-lulu-avatar.png';
import Button from '../../components/Button';


interface IPlatformNotificationsProps {
  category: 'reaction' | 'answer' | 'lesson' | 'general';
  content: string;
  created_at: string;
  id: string;
  link: string;
  recipient_id: string;
  type: string;
  updated_at: string;
  user_avatar: string;
  user_exp: number;
  user_level: number;
}

const MinhasNotificacoes: React.FC = () => {
  const {user} = useAuth();

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [platformNotifications, setPlatformNotifications] = useState<IPlatformNotificationsProps[]>([]);
  const [forumNotifications, setForumNotifications] = useState<IPlatformNotificationsProps[]>([]);

  // GET NOTIFICATION PLATFORM
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/notifications', {
          params: { recipient_id: user.id, page, perPage: 4, type: 'platform' },
        });

        setLastPage(response.data.lastPage);
        setPlatformNotifications(oldNotifications => [
          ...oldNotifications,
          ...response.data.records,
        ]);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.id, page]);

  // GET NOTIFICATION FORUM
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/notifications', {
          params: { recipient_id: user.id, page, perPage: 4, type: 'forum' },
        });

        setLastPage(response.data.lastPage);
        setForumNotifications(oldNotifications => [
          ...oldNotifications,
          ...response.data.records,
        ]);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.id, page]);

  return(
    <Container>
        <ProfileHeader />
        <ContentGrid1>
          <section>
            <div>
              <Accordion />
              <footer>
                {/* <Button color="success" onClick={() => handleSaveChanges()} >Salvar mudanças!</Button> */}
              </footer>
            </div>
          </section>
          <section>
            <HeaderInfo supTitle="Não perca nenhum aviso" title="Notificações" showSendButton={false} margin="0 0 1.5rem 0" />
            <section>
              <ContentGrid2>
                {loading && <Loader isFixed={false} />}
                <section>
                  <h4>Plataforma</h4>
                  {loading && <Loader isFixed={false} />}
                  {platformNotifications && platformNotifications.length > 0 ? platformNotifications.map((notification, index) => (
                    <Card key={index} to={notification.link}>
                      <UserAvatar size="sm" totalExp={10000} picture={poxaluluProfilePic} />
                      <p>
                        {htmlParser(notification.content)} <br/>
                        <span>{formatDistance(parseISO(notification.created_at), new Date(), { locale: ptBR })} atrás</span>
                      </p>
                      <Icon icon="icon-icon-thumbs-up" color="#9aa4bf40" />
                    </Card>
                  ))
                  :
                  <p>Nenhuma notificação disponível 😢</p>
                }
                </section>
                <section>
                  <h4>Fórum</h4>
                  {loading && <Loader isFixed={false} />}
                  {forumNotifications && forumNotifications.length > 0 ? forumNotifications?.map((notification, index) => (
                    <Card key={index} to={notification.link}>
                      <UserAvatar size="sm" totalExp={notification.user_exp} picture={notification.user_avatar} />
                      <p>
                        {htmlParser(notification.content)} <br/>
                        <span>há {formatDistance(parseISO(notification.created_at), new Date(), { locale: ptBR })} atrás</span>
                      </p>
                      {notification.category === 'reaction' && <Icon icon={notification.category === 'reaction' ? 'icon-icon-thumbs-up' : 'icon-icon-comment'} color="#9aa4bf40" />}
                      {notification.category === 'answer' && <Icon icon={notification.category === 'answer' ? 'icon-icon-comment' : 'icon-icon-comment'} color="#9aa4bf40" />}
                      {notification.category === 'lesson' && <Icon icon={notification.category === 'lesson' ? 'icon-icon-streams' : 'icon-icon-comment'} color="#9aa4bf40" />}
                      {notification.category === 'general' && <Icon icon={notification.category === 'general' ? 'icon-icon-thumbs-up' : 'icon-icon-comment'} color="#9aa4bf40" />}
                    </Card>
                  ))
                  :
                  <p>Nenhuma notificação disponível 😢</p>
                }
                </section>
              </ContentGrid2>
              <ContentGrid3>
              <footer>
                <Button color="primary" onClick={() => {setPage(oldPage => oldPage + 1)}} disabled={lastPage <= page} loading={loading}>
                   {lastPage <= page ? 'Não há mais registros' : 'Carregar mais'}
                </Button>
              </footer>
              </ContentGrid3>
            </section>
          </section>
        </ContentGrid1>
    </Container>
  );
}

export default MinhasNotificacoes;
