import styled from 'styled-components';

interface IWidgetPostBoxProps {
  thumbnail_url: string;
  isThumbnailLarge?: boolean;
}

export const Container = styled.div<IWidgetPostBoxProps>`
  padding: 1rem 28px 1.5rem 28px;
  border-radius: 12px;
  position: relative;
  background-color: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};

  > header {
    display: flex;
    align-items: center;
    padding: 5px 16px;
    line-height: 44px;
    position: absolute;
    top: -8px;
    right: 28px;

    border-radius: 8px;
    font-weight: 700;
    line-height: 32px;
    background-color: ${props => props.theme.colors.bgBox};
    box-shadow: 3px 5px 20px 0 rgba(94,92,154, 0.07);
    color: ${props => props.theme.colors.textColor};

    > span:nth-child(2) {
      margin-left: 7px;
      font-size: .8rem;
      text-transform: uppercase;
    }
  }

  > nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    transition: opacity .2s ease-in-out,fill .2s ease-in-out;
    color: ${props => props.theme.colors.textColor};

    > div {
      display: flex;
      align-items: center;

      > aside {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        > a {
          font-weight: 700;
          font-size: 1rem;
          text-decoration: none;
          line-height: 1;
          color: ${props => props.theme.colors.textColor};
        }
        > p {
          margin-top: 2px;
          font-size: .75rem;
          font-weight: 500;
          line-height: 1;
          color: ${props => props.theme.colors.textColor};
        }
      }
    }

    > main {

      &:hover {
        position: relative;
        transition: all 0.3s ease-in-out 0s;
      }

      &:after {
        position: absolute;
        z-index: 99999;
        content: attr(data-tooltip);
        border-radius: 1rem;
        text-align: center;
        box-sizing: border-box;
        box-shadow: ${props => props.theme.colors.shadowBox};
        padding: 10px 12px;
        background: ${props => props.theme.colors.bgBox};
        color: ${props => props.theme.colors.textColor};
        font-size: .6rem;
        min-width: 132px;
        font-weight: 700;
        white-space: nowrap;
        margin-top: -45px;
        margin-left: 3.3rem;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
      }

      &:hover::after {
        opacity: 1;
        visibility: visible;
        transform: translateX(-8rem);
      }
    }
  }

  > main {
    position: relative;
    margin-bottom: 1rem;

    > aside {
      margin-top: 0px;
      height: 72px;
      width: 100%;
      overflow:hidden;

      > p {
        font-size: .875rem;
        line-height: 1.7142857143em;
        font-weight: 500;
        display: block;
        text-overflow:ellipsis;
        color: ${props => props.theme.colors.textColor};

        &::after {
          content: '[...]';
          position: absolute;
          right: 7px;
          top: 24px;
          background: red; /* AQUI */
        }
      }
    }

    > div {
      margin-top: 24px;
      display: block;
      border-radius: 12px;
      text-decoration: none;
      border: none;
      color: ${props => props.theme.colors.textColor};
      box-shadow: ${props => props.theme.colors.shadowBox};
      cursor: pointer;

      > figure {
        background: url(${props => props.thumbnail_url}) no-repeat center center;
        background-size: cover;
        height: ${props => props.isThumbnailLarge ? '300px' : '187px'};
        width: 100%;
        border-radius: 12px 12px 0 0;

        /* MOBILE */
        @media screen and (max-width: 480px) {
          height: 177px;
        }

        > div {
          min-height: 100px;

          > img {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            max-height: 187px;
          }
        }
      }

      > div {
        padding: 22px 28px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        line-height: 1;
        margin-top: -6px;
        background: ${props => props.theme.colors.bgContent};
        box-shadow: ${props => props.theme.colors.shadowAlternative};

        > p:nth-child(1) {
          color: #adafca;
          font-size: .6875rem;
          font-weight: 700;
          text-transform: uppercase;
        }
        > p:nth-child(2) {
          margin-top: 12px;
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: 1rem;
          height: 36px;
        }
        > p:nth-child(3) {
          margin-top: 2px;
          color: #8f91ac;
          font-size: .875rem;
          line-height: 1.7142857143em;
          font-weight: 500;
          height: 48px;
          text-align: justify;
        }
      }
    }
  }

  > footer {

    > a, p {
      margin: 12px 12px 0 0;
      display: inline-flex;
      height: 24px;
      padding: 0 12px;
      border-radius: 200px;
      color: #fff;
      font-size: .75rem;
      font-weight: 700;
      line-height: 24px;
      background-color: ${props => props.theme.colors.primary};
      text-decoration: none;
      transition: background-color .2s ease-in-out;
      text-transform: capitalize;

      &:hover {
        background-color: ${props => props.theme.colors.info};
      }
    }

    > h4 {
        color: #adafca;
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
      }
  }
`;
