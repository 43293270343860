import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { debounce } from 'lodash';

import InputAnimate from '../../components/InputAnimate';
import SectionBanner from '../../components/SectionBanner';
import SelectInput from '../../components/SelectInput';
import Icon from '../../components/Icon';
import WidgetPostBox from '../../components/WidgetPostBox';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/materials-icon.png';

import { Container, TabsCrid, ContentGrid1, SearchButton, ContentGrid2, ContentGrid3, Showing, NotFound } from './styles';
import Pagination from '../../components/Pagination';

interface IMaterialProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    category_id: string;
    exam_name: string;
    name: string;
    description: string;
    thumbnail: string;
    created_at: string;
    updated_at: string;
    thumbnail_url: string;
    category: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    };
  }[];
}

interface ICategoryProps {
  value: string | number;
  label: string | number;
}

const Materiais: React.FC = () => {

  const [loading, setLoading] = useState(true);

  const [materials, setMaterials] = useState<IMaterialProps>({} as IMaterialProps);
  const [categories, setCategories] = useState<ICategoryProps[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const delayedSearch = useMemo(
    () => debounce(() => {
      if (searchTerm.length > 3) {
        setSearch(searchTerm);
      } else {
        setSearch('');
      }
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);


  // List MATERIALS
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        window.scrollTo(0, 0);

        const response = await api.get<IMaterialProps>('/materials/active',
          {params: {exam_name: 'enem', search: search, category: category, pageIndex: currentPage - 1, pageSize: 9,}}
        );

        console.log('LIST MATERIAIS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

        setMaterials(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [search, category, currentPage]);

   // List CATEGORIES MATERIALS
   useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get<ICategoryProps[]>('/materials/categories/list',{
          params: { exam_name: 'enem', }
        });

        console.log('LIST CATEGORIES >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setCategories(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, []);

  return(
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Materiais Didáticos" subTitle="Muitos e-books para alavancar os seus estudos. Aproveite!" />
      <TabsCrid>
        <ContentGrid1>
          <div>
            <SelectInput
              options={categories}
              onChange={event => setCategory(event.target.value)}
              labelText="Categoria"
              placeHolder="Exibir todas"
              showLabel={true}
              showPlaceholder={true}
            />
            <form onSubmit={e => e.preventDefault()}>
              <InputAnimate
                label="Pesquisar..."
                type="text"
                name="material"
                onChange={(event) => {setCurrentPage(1); setSearchTerm(event.target.value);}}
                margin="0"
                required
              />
              <SearchButton>
                <Icon icon="icon-icon-magnifying-glass" />
              </SearchButton>
            </form>
          </div>
        </ContentGrid1>
        {(totalRecords === 0 && !!!loading) && <NotFound>Poxa, não encontramos nenhum material para a sua pesquisa. 😢</NotFound>}
        <ContentGrid2 style={loading ? {maxHeight: '200px', overflow: 'hidden', position: 'relative'} : {}}>
          {materials && materials?.records?.map((material, index) => (
            <div key={index}>
            <WidgetPostBox
              id={material.id}
              thumbnail_url={material.thumbnail_url}
              description={material.description}
              truncate_description_size={85}
              name={material.name}
              exam_name={material.exam_name}
              category={material.category}
              created_at={material.created_at}
            />
          </div>))}
          {loading && <Loader isFixed={false} />}
        </ContentGrid2>
        <ContentGrid3>
          <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} materiais disponíveis`}</Showing>
          {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
        </ContentGrid3>
      </TabsCrid>
      <Footer />
    </Container>
  );
}

export default Materiais;
