import React from 'react';
import {Link} from 'react-router-dom';

import { Container } from './styles';

interface IAchievementBoxProps {
  background: 'bg1' | 'bg2' | 'bg3' | 'bg4';
  icon: string;
  title: string;
  description: string;
  buttonTextValue: string;
  href: string;
  pulseAnimation?: boolean;
}

const AchievementBox: React.FC<IAchievementBoxProps> = ({
  background,
  icon,
  title,
  description,
  buttonTextValue,
  href,
  pulseAnimation
}) => {
  return (
    <Container background={background} pulseAnimation={pulseAnimation}>
      <aside>
        <figure>
          <img src={icon} alt={title} />
        </figure>
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </aside>
      <Link to={href}>{buttonTextValue}</Link>
    </Container>
  );
}

export default AchievementBox;
