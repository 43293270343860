import React, { TextareaHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container, Label, Error } from './styles';

interface IInputTextareaValidteProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
  placeholder: string;
  name: string;
}

const InputTextareaValidte: React.FC<IInputTextareaValidteProps> = ({label, name, padding, margin, onlyWhite, placeholder, autoFocus, ...rest}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value: string) {
        if (value) {
          ref.setInputValue(value);
        }
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container padding={padding} margin={margin} onlyWhite={onlyWhite}>
        <textarea {...rest} defaultValue={defaultValue} placeholder={placeholder} ref={inputRef} />
        <Label onlyWhite={onlyWhite}>{label}</Label>
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
}

export default InputTextareaValidte;
