import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

import { uuid } from 'uuidv4';

import Mark from '../Mark';

import { useMark } from '../../../hooks/mark';

import { Main } from './styles';

interface IWordingContentProps {
  text: string;
  initialMarks?: any;
  fontSize: string;
}

interface IMarkCategory {
  value: string;
  label: string;
  competence: 1 | 2 | 3 | 4 | 5;
}

interface ICorrectionMark {
  id: string;
  category_id?: string;
  type: 'negative' | 'positive';
  background: string;
  color: string;
  title: string;
  description: string;
  from_index: number;
  to_index: number;
}

interface IWordingContent {
  content: string;
  mark?: ICorrectionMark;
}

interface Wording {
  id: string;
  contents: IWordingContent[];
}

const WordingContent: React.FC<IWordingContentProps> = ({text, initialMarks, fontSize}) => {
  const { marks, setInitialMarks } = useMark();

  const [loading, setLoading] = useState(false);

  const [wording, setWording] = useState<Wording[]>([]);

  //const [markPosition, setMarkPosition] = useState<IMarkPosition>({} as IMarkPosition);

  const [markCategories, setMarkCategories] = useState<IMarkCategory[]>([]);

  useEffect(() => {
    setInitialMarks(initialMarks);
  }, [initialMarks, setInitialMarks]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get('/marks/list');

        setMarkCategories(response.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);

        // colocar aviso de erro

        // redirecionar, talvez?
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    if (text) {
      const tempWording: Wording[] = [];
      const tempMarks = marks;

      const splitByParagraph = text.split('\n');
      const removeSpaces = splitByParagraph.map(p => p.trim());

      const newText = removeSpaces.join('\n');

      const tempTexts = [newText];

      let provisoryText: string[] = [];

      tempMarks.forEach(tempMark => {
        let realStartIndex = 0;
        let indexPosition = 0;

        tempTexts.every((tempText, index) => {
          if (tempText.startsWith('[mark=') || tempText === '[/mark]') {
            return true;
          }

          if (realStartIndex + tempText.length > tempMark.from_index) {
            const startIndex = tempMark.from_index - realStartIndex;
            const endIndex =
              startIndex + (tempMark.to_index - tempMark.from_index);

            indexPosition = index;

            provisoryText = [
              tempText.slice(0, startIndex),
              `[mark=${tempMark.id}]`,
              tempText.slice(startIndex, endIndex),
              `[/mark]`,
              tempText.slice(endIndex),
            ];

            return false;
          }

          realStartIndex += tempText.length;
          return true;
        });

        tempTexts.splice(indexPosition, 1, ...provisoryText);
      });

      const paragraphs = tempTexts.join('').split('\n');

      paragraphs.forEach(paragraph => {
        const splittedParagraph = paragraph.split(
          /(\[mark=.+?\].+?\[\/mark])/gi,
        );

        const contents: IWordingContent[] = [];

        splittedParagraph.forEach(part => {
          const tempContent: IWordingContent = {
            content: part,
          };

          const splittedParts = part.split(/\[mark=(.+?)\](.+?)\[\/mark]/);

          if (splittedParts.length > 1) {
            const [id, text] = splittedParts.filter(item => item);

            tempContent.content = text;

            const findMark = marks.find(mark => mark.id === id);

            if (findMark) {
              tempContent.mark = findMark;
            }
          }

          contents.push(tempContent);
        });

        tempWording.push({
          id: uuid(),
          contents,
        });
      });

      setWording(tempWording);
    }
  }, [text, marks]);

  return (
    <Main fontSize={fontSize}>
      {wording.map(paragraph => (
          <div key={paragraph.id} style={{ whiteSpace: 'pre-wrap' }}>
            {paragraph.contents.map(content => {
              if (content.mark) {
                return (
                  <Mark
                    key={content.mark.id}
                    id={content.mark.id}
                    background={content.mark.background}
                    color={content.mark.color}
                  >
                    {content.content}
                  </Mark>
                );
              }

              return content.content;
            })}
          </div>
        ))}
    </Main>
  );
}

export default WordingContent;
