import React, { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

import api from '../../services/api';

import { debounce } from 'lodash';

import { SearchContainer, Container, SearchResult } from './styles';
import { Link } from 'react-router-dom';

type IInputSearchHeaderProps = InputHTMLAttributes<HTMLInputElement>;

interface ISearchProps {
  topics?: {
    id: string;
    title: string;
    created_at: string;
    category: {
      slug: string;
      forum: {
        slug: string;
      };
    };
  }[];
  materials?: {
    id: string;
    name: string;
  }[];
  lessons?: {
    id: string;
    title: string;
    slug: string;
    created_at: string;
    cluster: {
      slug: string;
      course: {
        slug: string;
      };
    };
  }[];
  articles?: {
    id: string;
    section_id: string;
    title: string;
    slug: string;
    is_promoted: boolean;
    abstract: string;
    vote_count: number;
    vote_sum: number;
    created_at: string;
    updated_at: string;
    section: {
      slug: string;
    };
  points: number;
  }[];
}


const InputSearchHeader: React.FC<IInputSearchHeaderProps> = ({...rest}) => {

  const inputEl = useRef<HTMLInputElement>(null);

  const [isActive, setIsActive] = useState(false);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<ISearchProps>({} as ISearchProps);
  const [searchLoading, setSearchLoading] = useState(false);

  const delayedSearch = useMemo(() =>
    debounce(() => {
      setSearch(searchTerm);
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  // SEARCH
  useEffect(() => {
    async function loadData() {
      try {
        if (search.length > 3) {
          const response = await api.get('/dashboard/search',
            {params: { search: search, exam_name: 'enem' }}
          );

          console.log('SEARCH LIST >>>>>>>>>>>>')
          console.log(response.data);
          setSearchResult(response.data);
        } else {
          setSearchResult({});
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        setSearchLoading(false);
      }
    }

    loadData();
  }, [search]);

  return(
    <SearchContainer>
      <Container>
        <input
          {...rest}
          type="text"
          autoComplete="off"
          ref={inputEl}
          onChange={event => {
            setSearchLoading(true);
            setSearchTerm(event.target.value);
          }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        />
      </Container>
      <SearchResult isActive={isActive}>
        <ul>
        {!searchResult.lessons?.length && !searchResult.articles?.length && !searchResult.materials?.length && !searchResult.topics?.length && (
            <li>
              {searchLoading && <p>Carregando...</p>}
              {!searchLoading && search.length <= 3 && <p>Busque por palavras-chave...</p>}
              {!searchLoading && search.length > 3 && <p>Poxa, não encontramos a sua busca! 😢</p>}
            </li>
          )}
          <main>
            {(searchResult.lessons && searchResult.lessons?.length > 0) && <div>
              <h5>Vídeos</h5>
              {searchResult.lessons?.map((search, index) => (
                <li key={index}>
                  <Link onClick={() => {if (!inputEl.current) return; inputEl.current.value = ''; setSearchTerm('')}} to={`/player/${search.cluster.course.slug}/${search?.cluster.slug}/${search.slug}/`}>• {search.title}</Link>
                </li>
              ))}
            </div>}

            {(searchResult.materials && searchResult.materials?.length > 0) && <div>
              <h5>Materiais</h5>
              {searchResult.materials?.map((search, index) => (
                <li key={index}>
                  <Link onClick={() => {if (!inputEl.current) return;}} to={`/materiais/download/${search.id}`} target="_blank">• {search.name}</Link>
                </li>
              ))}
            </div>}

            {(searchResult.articles && searchResult.articles?.length > 0) && <div>
              <h5>Artigos</h5>
              {searchResult.articles?.map((search, index) => (
                <li key={index}>
                  <Link onClick={() => {if (!inputEl.current) return; inputEl.current.value = ''; setSearchTerm('')}} to={`/suporte/artigos/${search?.section?.slug}/${search.slug}/`}>• {search.title}</Link>
                </li>
              ))}
            </div>}

            {(searchResult.topics && searchResult.topics?.length > 0) && <div>
              <h5>Tópicos do fórum</h5>
              {searchResult.topics?.map((search, index) => (
                <li key={index}>
                  <Link onClick={() => {if (!inputEl.current) return; inputEl.current.value = ''; setSearchTerm('')}} to={`/forum/${search.category.forum.slug}/${search.category.slug}/topicos/${search.id}`}>• {search.title}</Link>
                </li>
              ))}
            </div>}
          </main>
        </ul>
      </SearchResult>
    </SearchContainer>
  );
}

export default InputSearchHeader;
