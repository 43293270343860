import React from 'react';
import GlobalStyle from './styles/GlobalStyle';
import './styles/poxalulu-icons.css';
import { ToastContainer } from 'react-toastify';

import { ModalProvider } from './hooks/modal';
import { AuthProvider } from './hooks/auth';
import { CreditProvider } from './hooks/credit';

//@ts-ignore
import { OpenCvProvider } from 'opencv-react';

import Routes from './routes';

const App: React.FC = () => {

  return(
    <AuthProvider>
      <CreditProvider>
        <OpenCvProvider>
          <ToastContainer />
          <ModalProvider>
            <GlobalStyle />
            <Routes />
          </ModalProvider>
        </OpenCvProvider>
      </CreditProvider>
    </AuthProvider>
  );
}

export default App;
