import React, { useState } from 'react';

import ReactStars from 'react-stars';
import 'react-toastify/dist/ReactToastify.css';

import NoSignalPlayer from './NoSignalPlayer';
import NextVideoTumbnailsLive from '../../components/NextVideoTumbnailsLive';
import Footer from '../../components/Footer';

import viewIcon from '../../assets/view.svg';

import { Container, ContentGrid1, YoutubeVideoContainer } from './styles';

const PlayerLives: React.FC = () => {

  const [signal, setSignal] = useState(true);

  const liveLink = 'R6yYPANoPGM';

  return(
    <Container>
        <h6>Lives exclusivas</h6>
        <h1>#LIVE - TUDO sobre a REDAÇÃO DO ENEM 2024!</h1>
        <ContentGrid1>
          <aside>
            <main>
              {signal ?
              <YoutubeVideoContainer>
                <a href={`https://youtube.com/watch?v=${liveLink}`} target="_blank" rel="noreferrer">
                  <p>Clique para interagir!</p>
                </a>
                <iframe
                  src={`https://www.youtube.com/embed/${liveLink}?autoplay=1`}
                  frameBorder="0"
                  title="Live"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </YoutubeVideoContainer>
              :
              <YoutubeVideoContainer>
                <NoSignalPlayer />
              </YoutubeVideoContainer>
            }
            </main>
            <footer>
              <aside>
                {signal ?
                <div>
                  <img src={viewIcon} alt="Visualizações"/>
                  <span>Clique para participar pelo Youtube</span>
                </div>
                :
                <div>
                  <img src={viewIcon} alt="Visualizações"/>
                  <span>Poxinha, verifique em seu calendário a data da próxima live</span>
                </div>
                }
              </aside>
              <aside>
                <main  />
                <ReactStars
                  key={1}
                  count={5}
                  size={20}
                  color1="#adafca"
                  color2="#b9993a"
                  half={false}
                  value={5} //puxar do banco aqui
                  onChange={(new_rating: number) => {
                    //
                  }}
                />
              </aside>
            </footer>
          </aside>
          <aside>
            <NextVideoTumbnailsLive
              key={1111111111}
              lessonOrder={1}
              thumbnailUrl={`https://i.ytimg.com/vi/tgzwTi7M9Ac/mqdefault.jpg`}
              lessonTitle={'[LIVE] Tirando dúvidas dos poxalunos'}
              lessonDuration={'01:11:56'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/-0ufyr5VNvQ?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={2222222222}
              lessonOrder={2}
              thumbnailUrl={`https://i.ytimg.com/vi/eqjHGW4JkFs/mqdefault.jpg`}
              lessonTitle={'[LIVE] Super LIVE de revisão'}
              lessonDuration={'02:21:31'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/eqjHGW4JkFs?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={3333333}
              lessonOrder={3}
              thumbnailUrl={`https://i.ytimg.com/vi/6GTRwVXukb4/mqdefault.jpg`}
              lessonTitle={'[LIVE] Primeira LIVE do ano'}
              lessonDuration={'01:00:00'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/l0k1Ajp8DjU?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={444444}
              lessonOrder={4}
              thumbnailUrl={`https://i.ytimg.com/vi/SvW19oXdtOY/mqdefault.jpg`}
              lessonTitle={'[LIVE] SUPER revisão Enem 2022'}
              lessonDuration={'00:45:00'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/SvW19oXdtOY?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={555555}
              lessonOrder={5}
              thumbnailUrl={`https://i.ytimg.com/vi/gyYULpJVaSk/mqdefault.jpg`}
              lessonTitle={'[LIVE] ANÁLISE DE REDAÇÃO NOTA 1000'}
              lessonDuration={'02:01:42'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/gyYULpJVaSk?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={666666}
              lessonOrder={6}
              thumbnailUrl={`https://i.ytimg.com/vi/XG4FQGmb1rY/mqdefault.jpg`}
              lessonTitle={'[LIVE] Como começar a estudar para REDAÇÃO do ENEM agora?'}
              lessonDuration={'01:02:30'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/XG4FQGmb1rY?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={777777}
              lessonOrder={7}
              thumbnailUrl={`https://i.ytimg.com/vi/L8YQfOHwCsQ/mqdefault.jpg`}
              lessonTitle={'[LIVE] TUDO sobre o desenvolvimento na redação do enem'}
              lessonDuration={'01:02:30'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/L8YQfOHwCsQ?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={88888}
              lessonOrder={8}
              thumbnailUrl={`https://i.ytimg.com/vi/GK7u4-xnyYk/mqdefault.jpg`}
              lessonTitle={'[LIVE] Como Fazer Introdução Nota 1000'}
              lessonDuration={'01:02:30'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/GK7u4-xnyYk?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={999999}
              lessonOrder={9}
              thumbnailUrl={`https://i.ytimg.com/vi/eh2k0Xy6ors/mqdefault.jpg`}
              lessonTitle={'[LIVE] TUDO sobre a CONCLUSÃO da REDAÇÃO do Enem!'}
              lessonDuration={'01:02:30'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/eh2k0Xy6ors?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={10000}
              lessonOrder={10}
              thumbnailUrl={`https://i.ytimg.com/vi/0g6RUBXjRW4/mqdefault.jpg`}
              lessonTitle={'[LIVE] Conclusão pronta para QUALQUER tema Enem'}
              lessonDuration={'01:02:30'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/0g6RUBXjRW4?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={10000}
              lessonOrder={10}
              thumbnailUrl={`https://i.ytimg.com/vi/T0xXHc5DNak/mqdefault.jpg`}
              lessonTitle={'[LIVE] TUDO sobre a REDAÇÃO DO ENEM 2024!!!'}
              lessonDuration={'00:52:40'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/T0xXHc5DNak?autoplay=1`}
            />
            <NextVideoTumbnailsLive
              key={10000}
              lessonOrder={10}
              thumbnailUrl={`https://i.ytimg.com/vi/R6yYPANoPGM/mqdefault.jpg`}
              lessonTitle={'[LIVE] Como começar a estudar para a REDAÇÃO DO ENEM agora?'}
              lessonDuration={'00:54:45'}
              progress={100}
              current={true}
              href={`https://www.youtube.com/embed/R6yYPANoPGM?autoplay=1`}
            />
          </aside>
        </ContentGrid1>
        <Footer />
    </Container>
  );
}

export default PlayerLives;
