import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import ProgressBar from "@ramonak/react-progress-bar";
import CountUp from "react-countup";
import htmlParser from "react-html-parser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { format, formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { parseISO } from "date-fns/esm";

import { useTheme } from "../../hooks/theme";
import { useAuth } from "../../hooks/auth";
import { useCredit } from "../../hooks/credit";

import api from "../../services/api";

import InputSearchHeader from "../InputSearchHeader";
import MenuTrigger from "../MenuTrigger";
import DropdownButton from "../DropdownButton";
import Toggle from "../Toggle";
import UserAvatar from "../UserAvatar";
import Button from "../Button";
import Icon from "../Icon";

import srcImgLogo from "../../assets/logo-poxalulu-vertical.svg";
import poxaluluProfilePic from "../../assets/poxa-lulu-avatar.png";

import menuLinks from "../../utils/menuLinks";

import {
  Container,
  LogoContainer,
  LogoImg,
  HeaderActions,
  InputContainer,
  NextLevelContainer,
  BarProgressInfo,
  ActionsContainer,
  ActionList,
  MenuItem,
  ProfileSettings,
  DropdownBoxHeader,
  DropdownBoxContent,
  DropdownBoxFooter,
  DropdownBoxFooterProfile,
  DropdownNotification,
  FotterActionButton,
  SendPreview,
  QuestProgress,
  XpAdd,
  QuestLevelContainer,
  ArrowBack,
  AsideMenuMobile,
  Menu,
  Backdrop,
  DropdownBoxContent2,
  ModalPlan,
} from "./styles";

export interface IStatsBoxSmallProps {
  id: string;
  status:
  | "canceled"
  | "pending"
  | "in_correction"
  | "in_confirmation"
  | "corrected";
  created_at: string;
  is_photo: boolean;
  corrections: {
    final_grade?: number;
  }[];
  theme: {
    name: string;
    thumbnail_url: string;
  };
  status_info: {
    name: string;
  };
}

interface IAchievementsProps {
  achievements?: {
    id: string;
    icon: string;
    title: string;
    exp: number;
    description: string;
    requirement: string;
    acquired_at: string;
    icon_url: string;
  }[];
  total: number;
}

interface IPlatformNotificationsProps {
  page: number;
  lastPage: number;
  total: number;
  records: {
    category: string;
    content: string;
    created_at: string;
    id: string;
    link: string;
    recipient_id: string;
    type: string;
    updated_at: string;
    user_avatar: string;
    user_exp: number;
    user_level: number;
  }[];
}

const MainHeader: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname.split("/")[1];

  // SideBar Menu Open
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const { logout, user } = useAuth();
  const { credits, countCredits } = useCredit();

  const { toggleTheme, theme } = useTheme();

  const [lastCorrections, setLastCorrections] =
    useState<IStatsBoxSmallProps[]>();
  const [achievements, setAchievements] = useState<IAchievementsProps>(
    {} as IAchievementsProps
  );
  const [platformNotifications, setPlatformNotifications] =
    useState<IPlatformNotificationsProps>({} as IPlatformNotificationsProps);
  const [forumNotifications, setForumNotifications] =
    useState<IPlatformNotificationsProps>({} as IPlatformNotificationsProps);

  // List 4 wordings in cards
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<IStatsBoxSmallProps[]>("/wordings/last");
        console.log(response.data);

        setLastCorrections(response.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    }

    loadData();
  }, []);

  // GET LAST ACHIVEMENTS BADGES
  useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get("/achievements/last", {
          params: { quantity: 4 },
        });

        console.log("LIST ACHIVEMENTS >>>>>>>>>>>>>>>>>>>");
        console.log(response.data);

        setAchievements(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, []);

  // GET LAST *PLATFORM* NOTIFICATIONS
  useEffect(() => {
    async function loadData() {
      if (!user.id) {
        return;
      }
      try {
        //setLoading(true);
        const response = await api.get("/notifications", {
          params: {
            page: 1,
            perPage: 4,
            recipient_id: user.id,
            type: "platform",
          },
        });

        console.log("LIST *PLATFORM* NOTIFICATIONS >>>>>>>>>>>>>>>>>>>");
        console.log(response.data);

        setPlatformNotifications(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  // GET LAST *FORUM* NOTIFICATIONS
  useEffect(() => {
    async function loadData() {
      if (!user.id) {
        return;
      }
      try {
        //setLoading(true);
        const response = await api.get("/notifications", {
          params: { page: 1, perPage: 4, recipient_id: user.id, type: "forum" },
        });

        console.log("LIST *FORUM* NOTIFICATIONS >>>>>>>>>>>>>>>>>>>");
        console.log(response.data);

        setForumNotifications(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  const handleChangeTheme = () => {
    //setLightTheme(!lightTheme);
    toggleTheme();
  };

  // modal plans
  const openModalExtendsPlan = useCallback((email: string) => {
    const modal = withReactContent(Swal);
    modal.fire({
      html: (
        <ModalPlan>
          <div>
            <div onClick={() => Swal.close()}></div>
          </div>
          <a
            href={`${process.env.REACT_APP_RENEW_DISCOUNT_LINK}&email=${email}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://poxalulu-api.s3.sa-east-1.amazonaws.com/geral/promo22.jpg"
              alt="Aviso Plataforma"
            />
          </a>
        </ModalPlan>
      ),
      background: "transparent",
      backdrop: "rgba(110, 97, 198, .1)",
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: false,
      width: "550px",
    });
  }, []);

  return (
    <>
      <Container>
        <LogoContainer>
          <Link to="/dashboard">
            <LogoImg src={srcImgLogo} alt="Plataforma Poxalulu" />
          </Link>
          <HeaderActions>
            <MenuTrigger disabledd={true} onClick={showSidebar}>
              <span className="icon-icon-grid"></span>
            </MenuTrigger>
          </HeaderActions>
        </LogoContainer>
        <InputContainer>
          <InputSearchHeader placeholder="Busque por vídeos, materiais, artigos ou tópicos do fórum..." />
        </InputContainer>
        <NextLevelContainer>
          <BarProgressInfo>
            Nível {user.level}{" "}
            <span>
              {" "}
              <CountUp end={user.exp || 0} duration={5} suffix=" EXP" />
            </span>
          </BarProgressInfo>
          <ProgressBar
            completed={user.current_exp}
            height="4px"
            isLabelVisible={false}
            baseBgColor="#6257b2"
            bgColor="#ff6961"
          />
        </NextLevelContainer>
        <ActionsContainer>
          <ActionList>
            <MenuItem>
              <DropdownButton
                icon="icon-icon-wallet"
                disabledd={false}
                badge={countCredits()}
              >
                <DropdownBoxHeader>
                  <h3>
                    Créditos disponíveis:{" "}
                    <span>
                      {credits.wordingsRemaining}{" "}
                      {credits.extraCredits > 0 &&
                        "+ " + credits.extraCredits + " extra"}
                      {credits.extraCredits > 1 && "s"}
                    </span>
                  </h3>
                  <p>
                    validade:
                    <br />{" "}
                    {credits.finalPeriod &&
                      format(new Date(credits.finalPeriod), "dd'/'MM'/'Y", {
                        locale: ptBR,
                      })}
                  </p>
                </DropdownBoxHeader>
                <DropdownBoxContent>
                  <p>
                    Todo dia{" "}
                    {credits.initialPeriod &&
                      format(new Date(credits.initialPeriod), "dd", {
                        locale: ptBR,
                      })}{" "}
                    de cada mês, seus envios de redação são renovados
                    automaticamente.
                  </p>
                  <h4>Últimos envios</h4>
                  {lastCorrections && lastCorrections.length > 0 ? (
                    lastCorrections.slice(0, 3).map((wording, index) => (
                      <SendPreview
                        to={`/visualizar-redacao/${wording.is_photo ? "foto" : "texto"
                          }/${wording.id}`}
                        key={index}
                        style={{
                          display:
                            wording.status === "canceled" ? "none" : "flex",
                        }}
                      >
                        <div>
                          <figure>
                            <img
                              src={wording.theme.thumbnail_url}
                              alt={wording.theme.name}
                            />
                          </figure>
                        </div>
                        <div>
                          {wording.theme.name} <br />
                          <span>
                            há{" "}
                            {formatDistance(
                              parseISO(wording.created_at),
                              new Date(),
                              { locale: ptBR }
                            )}
                          </span>
                        </div>
                        {wording.corrections.length &&
                          wording.status === "corrected" ? (
                          <div>
                            <span>NOTA</span>
                            <br />
                            {
                              wording.corrections[
                                wording.corrections.length - 1
                              ].final_grade
                            }
                          </div>
                        ) : (
                          <div>
                            <span>NOTA</span>
                            <br />-
                          </div>
                        )}
                      </SendPreview>
                    ))
                  ) : (
                    <p style={{ marginTop: "2rem" }}>
                      Poxa, você ainda não fez nenhuma redação 😢
                    </p>
                  )}
                </DropdownBoxContent>
                <DropdownBoxFooter>
                  <FotterActionButton>
                    <Link to="/minhas-redacoes/">
                      Visualizar todas as redações
                    </Link>
                  </FotterActionButton>
                </DropdownBoxFooter>
              </DropdownButton>
            </MenuItem>

            <MenuItem>
              <DropdownButton
                icon="icon-icon-trophy"
                disabledd={false}
                notify={true}
              >
                <DropdownBoxHeader>
                  <h3>Conquistas</h3>
                </DropdownBoxHeader>
                <DropdownBoxContent>
                  <p>
                    Realize os desafios, acumule experiência (EXP) e some pontos
                    para o ranking!
                  </p>
                  {achievements.achievements &&
                    achievements.achievements?.length > 0 ? (
                    <h4>Últimas conquistas</h4>
                  ) : (
                    <p style={{ marginTop: "2rem" }}>
                      Você ainda não desbloqueou nenhuma conquista! 😢
                    </p>
                  )}

                  {achievements.achievements?.map((achievement, index) => (
                    <QuestProgress key={index}>
                      <Link to="/conquistas">
                        <figure>
                          <img
                            src={achievement.icon_url}
                            alt={achievement.title}
                          />
                        </figure>
                      </Link>
                      <QuestLevelContainer>
                        <BarProgressInfo>
                          <header>
                            <div>
                              <h3>{achievement.title}</h3>
                              <h4>{achievement.requirement}</h4>
                            </div>
                            <XpAdd>
                              <Icon
                                icon="icon-icon-plus-small"
                                color="#00c7d9"
                                size="7px"
                                margin="0 4px 0 0"
                              />{" "}
                              {achievement.exp} Exp
                            </XpAdd>
                          </header>
                        </BarProgressInfo>
                      </QuestLevelContainer>
                    </QuestProgress>
                  ))}
                </DropdownBoxContent>
                <DropdownBoxFooter>
                  <FotterActionButton>
                    <Link to="/conquistas">Visualizar todas as conquistas</Link>
                  </FotterActionButton>
                </DropdownBoxFooter>
              </DropdownButton>
            </MenuItem>
            <MenuItem>
              <DropdownButton
                icon="icon-icon-messages-1"
                disabledd={false}
                notify={true}
              >
                <DropdownBoxHeader>
                  <h3>Fórum de dúvidas</h3>
                  <p>Marcar todas como lidas</p>
                </DropdownBoxHeader>
                <DropdownBoxContent>
                  {forumNotifications.records &&
                    forumNotifications.records.length > 0 ? (
                    forumNotifications.records?.map((notification, index) => (
                      <DropdownNotification key={index} to={notification.link}>
                        <UserAvatar
                          size="sm"
                          totalExp={notification.user_exp}
                          picture={notification.user_avatar}
                        />
                        <p>
                          {htmlParser(notification.content)} <br />
                          <span>
                            {formatDistance(
                              parseISO(notification.created_at),
                              new Date(),
                              { locale: ptBR }
                            )}{" "}
                            atrás
                          </span>
                        </p>
                        <Icon icon="icon-icon-thumbs-up" color="#9aa4bf40" />
                      </DropdownNotification>
                    ))
                  ) : (
                    <p>Nenhuma notificação disponível!</p>
                  )}
                </DropdownBoxContent>
                {forumNotifications.records &&
                  forumNotifications.records.length > 0 && (
                    <DropdownBoxFooter>
                      <FotterActionButton>
                        <Link to="/notificacoes">
                          Visualizar todas as notificações
                        </Link>
                      </FotterActionButton>
                    </DropdownBoxFooter>
                  )}
              </DropdownButton>
            </MenuItem>
            <MenuItem>
              <DropdownButton
                icon="icon-icon-notification"
                disabledd={false}
                notify={true}
              >
                <DropdownBoxHeader>
                  <h3>Notificações</h3>
                  <p>Marcar todas como lidas</p>
                </DropdownBoxHeader>
                <DropdownBoxContent>
                  {platformNotifications.records &&
                    platformNotifications.records.length > 0 ? (
                    platformNotifications.records.map((notification, index) => (
                      <DropdownNotification key={index} to={notification.link}>
                        <UserAvatar
                          size="sm"
                          totalExp={10000}
                          picture={poxaluluProfilePic}
                        />
                        <p>
                          {htmlParser(notification.content)}
                          <br />
                          {notification.created_at && (
                            <span>
                              {formatDistance(
                                parseISO(notification.created_at),
                                new Date(),
                                { locale: ptBR }
                              )}{" "}
                              atrás
                            </span>
                          )}
                        </p>
                        <Icon icon="icon-icon-blog-posts" color="#9aa4bf40" />
                      </DropdownNotification>
                    ))
                  ) : (
                    <p>Nenhuma notificação disponível!</p>
                  )}
                </DropdownBoxContent>
                {platformNotifications.records &&
                  platformNotifications.records.length > 0 && (
                    <DropdownBoxFooter>
                      <FotterActionButton>
                        <Link to="/notificacoes">
                          Visualizar todas as notificações
                        </Link>
                      </FotterActionButton>
                    </DropdownBoxFooter>
                  )}
              </DropdownButton>
            </MenuItem>
          </ActionList>
          <ProfileSettings>
            <DropdownButton icon="icon-icon-members-1" width="220px">
              <DropdownBoxHeader style={{ justifyContent: "start" }}>
                <Link to="/perfil">
                  <UserAvatar
                    size="sm"
                    totalExp={user.exp}
                    picture={user.avatar_url}
                  />
                  <h3>Olá, {user.show_name}!</h3>
                </Link>
              </DropdownBoxHeader>
              <DropdownBoxContent2>
                <h4>Meu perfil</h4>
                <Link to="/perfil">Visualizar perfil</Link>
                <Link to="/minhas-conquistas">Minhas conquistas</Link>
                {/* <Link to="/preferencias">Preferências</Link> AQUI 777 */}

                <h4>Segurança</h4>
                <Link to="/alterar-senha">Alterar senha</Link>
                <Link to="/historico-de-acessos">Histórico de acessos</Link>

                <h4>Plano</h4>
                <Link to="#">
                  <span onClick={() => openModalExtendsPlan(user.email)}>
                    Estender Plano 💜{" "}
                    {/* <span className="off">em breve</span> */}{" "}
                  </span>
                </Link>
              </DropdownBoxContent2>
              <DropdownBoxFooterProfile>
                <h4>Alterar tema</h4>
                <Toggle
                  labelLeft="Dark"
                  labelRight="Light"
                  checked={theme.title === "light"}
                  onChange={handleChangeTheme}
                />
                <Button onClick={logout} color="success">
                  Sair
                </Button>
              </DropdownBoxFooterProfile>
            </DropdownButton>
          </ProfileSettings>
        </ActionsContainer>
      </Container>
      <AsideMenuMobile className={sidebar ? "sidebar active" : "sidebar"}>
        <ArrowBack onClick={showSidebar}>
          <span className="icon-icon-back-arrow"></span>
        </ArrowBack>
        <div>
          <UserAvatar size="sm" totalExp={user.exp} picture={user.avatar_url} />
          <Link to="/perfil" onClick={showSidebar}>
            Olá, {user.show_name}! <br /> <span>Bons estudos, poxinha! 💖</span>
          </Link>
        </div>
        <Menu>
          <h4>Menu</h4>
          {menuLinks.map((item, index) => (
            <>
              <div
                key={index}
                className={
                  localtionSplited === item.location ||
                    (localtionSplited === "" && index === 0)
                    ? "active"
                    : ""
                }
              >
                <Link
                  data-tooltip={item.tooltip}
                  to={"/" + item.location}
                  onClick={showSidebar}
                >
                  {item.customIcon ? (<span dangerouslySetInnerHTML={{__html: item.customIcon}}></span>) : <span className={`${item.icon}`}></span>}
                  {item.tooltip}
                </Link>
              </div>
            </>
          ))}
        </Menu>
      </AsideMenuMobile>
      <Backdrop
        className={sidebar ? "active" : "no-active"}
        onClick={showSidebar}
      />
    </>
  );
};

export default MainHeader;
