import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useCredit } from '../../hooks/credit';
import 'react-toastify/dist/ReactToastify.css';

import LinedPaper from '../../components/LinedPaper';
import HeaderInfo from '../../components/HeaderInfo';
import ThemeCardInstructions from '../../components/ThemeCardInstructions';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import FooterAlert from '../../components/FooterAlert';
import Footer from '../../components/Footer';

import { Container, ContentGrid1 } from './styles';

interface IThemeProps {
  id: string;
  category_id: string;
  exam_name: string;
  student_id?: null;
  name: string;
  material: string;
  thumbnail: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  category: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  };
  student: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  difficulty: 'easy' | 'medium' | 'hard';
  new: boolean;
  material_url: string;
  thumbnail_url: string;
}

const EnviarRedacaoCelular: React.FC = () => {
  const history = useHistory();
  const { subCredit, haveCredits } = useCredit();

  const { theme_id } = useParams<{ theme_id: string }>();

  const windowDimensions = getWindowDimensions();

  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState<IThemeProps>({} as IThemeProps);

  const [textareaValue, setTextareaValue] = useState('');
  const [textAreaLineCount, setTextAreaLineCount] = useState(1);

  const [seconds, setSeconds] = useState(0);

  // Get window dimensions
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  // verifica a dimensão do dispositivo e redireciona
  useEffect(() => {
    getWindowDimensions();
    if (windowDimensions.width > 1345) {
      history.push(`/enviar-redacao/texto/${theme_id}`);
      console.log(windowDimensions);
    }
  }, [windowDimensions, history, theme_id]);

  // Get theme
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(`/themes/${theme_id}`);

        console.log('theme >>>>>>>>>>>>');
        console.log(response.data);
        setTheme(response.data);
      } catch (error) {
        console.log(error);
        // setConnectionFailed(true)
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [theme_id]);

  // verifica se existe um rascunho
  useEffect(() => {
    const wordingSketchStorage = localStorage.getItem(
      `@poxalulu-sketch-${theme_id}`,
    );
    console.log('rascunho? ', wordingSketchStorage);
    if (wordingSketchStorage) {
      const modalRascunho = withReactContent(Swal);
      modalRascunho
        .fire({
          html: (
            <div>
              <h2>Você tem um rascunho dispoível para esse tema</h2>
              <p>Gostaria de cotinuar escrevendo de onde parou? 😊</p>
            </div>
          ),
          background: '#6c60c3',
          backdrop: 'rgba(110, 97, 198, .1)',
          cancelButtonColor: '#d2194d',
          cancelButtonText: 'Não!',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          showConfirmButton: true,
          showCloseButton: true,
        })
        .then(result => {
          if (result.isConfirmed) {
            Swal.close();
            setTextareaValue(wordingSketchStorage);
            console.log('foi>>> ', wordingSketchStorage);
          } else {
            console.log('opration canceled');
          }
        });
    }
  }, [theme_id]);

  // seta um rascunho no storage
  useEffect(() => {
    textareaValue &&
      localStorage.setItem(`@poxalulu-sketch-${theme_id}`, textareaValue);
  }, [textareaValue, theme_id]);

  // count time for send wording
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(oldSeconds => oldSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSendCorrention = useCallback(() => {
    const modal = withReactContent(Swal);
    modal
      .fire({
        html: (
          <div>
            <h2>Tem certeza que deseja enviar sua redação?</h2>
            <p>Sua correção será entregue em até 3 dias úteis! 😊</p>
          </div>
        ),
        background: '#6c60c3',
        backdrop: 'rgba(110, 97, 198, .1)',
        cancelButtonColor: '#d2194d',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        showConfirmButton: true,
        showCloseButton: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          loadData();
          console.log('opration confirmed');
        } else {
          console.log('opration canceled');
        }
      });

    async function loadData() {
      try {
        // setLoading(true);
        const response = await api.post(`/wordings`, {
          theme_id,
          end_time_in_seconds: seconds,
          type: 'text',
          text: textareaValue,
          font_size: '18.6',
        });

        console.log('Redação enviada com sucesso >>>>> ', response.data);
        localStorage.removeItem(`@poxalulu-sketch-${theme_id}`);

        Swal.fire({
          title: 'Pronto!',
          text: 'Sua redação foi enviada com sucesso!',
          icon: 'success',
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          confirmButtonColor: '#03bb85',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });

        subCredit();
        history.push('/minhas-redacoes');
      } catch (err) {
        console.log('erro: >>>>>>', err);
        toast.error('🤷‍♂️ Ops! Algo deu errado ao enviar sua redação!', {
          position: 'top-right',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        // setLoading(false);
      }
    }
  }, [theme_id, seconds, textareaValue, history, subCredit]);

  return (
    <>
      {!haveCredits() && <FooterAlert />}
      <Container>
        <HeaderInfo
          supTitle={theme.exam_name}
          title={theme.name}
          margin="0 0 1rem 0"
          showSendButton={false}
        />
        <ContentGrid1>
          <section>
            {loading && <Loader />}
            <main>
              <ThemeCardInstructions
                id={theme.id}
                title={theme.name}
                thumbnail_url={theme.thumbnail_url}
                exp={777}
                student={theme?.student}
                course={theme.exam_name}
                link={theme.material_url}
                themeSuggestion={true}
                difficulty={theme.difficulty}
              />
            </main>
          </section>
          <section>
            <main>
              <LinedPaper
                fontSize="18.6"
                placeholder="Clique para escrever sua redação..."
                resize="none"
                width="100%"
                isMobile={true}
                countLines={false}
                showLines={true}
                textareaValue={textareaValue}
                setTextareaValue={text => setTextareaValue(text)}
                setTextAreaLineCount={count => setTextAreaLineCount(count)}
              />
            </main>
          </section>
        </ContentGrid1>
        <footer>
          {haveCredits() ? (
            <Button color="success" onClick={handleSendCorrention}>
              <Icon icon="icon-icon-send-message" margin="0 6px 0 0" />
              enviar para correção
            </Button>
          ) : (
            <Button color="success" className="pulse" disabled>
              Você não tem créditos disponíveis 😢
            </Button>
          )}
        </footer>
        <Footer />
      </Container>
    </>
  );
};

export default EnviarRedacaoCelular;
