import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import TextareaAutosize from 'react-textarea-autosize';

import { Container, Enumerate, ButtonsFontSizeChange } from './styles';

interface ILinedPaperProps {
  resize?: 'vertical' | 'horizontal' | 'unset' | 'block' | 'none';
  placeholder?: string;
  initialText?: string;
  width?: string;
  countLines?: boolean;
  showLines?: boolean;
  textareaValue: string;
  isMobile?: boolean;
  fontSize: string;
  setFontSize?(value: string): void;
  setTextareaValue(value: string): void;
  setTextAreaLineCount(value: number): void;
}

const LinedPaper: React.FC<ILinedPaperProps> = ({
  initialText,
  placeholder,
  width,
  countLines,
  showLines,
  textareaValue,
  fontSize,
  setTextareaValue,
  setTextAreaLineCount,
  setFontSize,
  isMobile = false,
}) => {
  const [min] = useState(15);
  const [max] = useState(22);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const lines = [];
  for (let i = 1; i <= 30; i++) {
    lines.push(i);
  }

  useEffect(() => {
    initialText && setTextareaValue(initialText);
  }, [initialText, setTextareaValue]);

  const handleLineCount = useCallback(() => {
    const heightPaper = textAreaRef.current?.style.height;
    const integerHeiht = Number(heightPaper?.substr(0, heightPaper.length - 2));
    const countLines = (integerHeiht - 60) / 30 + 1;

    return Math.trunc(countLines);
  }, []);

  const handleChange = useCallback(
    e => {
      const before = String(textareaValue);

      const lines = handleLineCount();
      setTextAreaLineCount(lines);

      console.log('lines', lines);

      if (lines > 30 && !isMobile) {
        e.preventDefault();
        console.log('brecar');
        const currentAreaRef = textAreaRef.current;

        if (!currentAreaRef) {
          return;
        }

        setTextareaValue(before);
        currentAreaRef.value = before;

        e.preventDefault();
        return;
      }

      setTextareaValue(e.target.value);
    },
    [
      textareaValue,
      handleLineCount,
      setTextareaValue,
      setTextAreaLineCount,
      isMobile,
    ],
  );

  const handleFontSizeChange = useCallback(
    (size: string) => {
      if (setFontSize) {
        setFontSize(size);

        localStorage.setItem('@poxalulu-font-size', size);
      }
    },
    [setFontSize],
  );

  const position = useMemo(
    () => ((Number(fontSize) - min) / (max - min)) * 100,
    [fontSize, min, max],
  );

  const iptSliderRange = useRef<HTMLSpanElement>(null);

  return (
    <>
      {countLines && (
        <ButtonsFontSizeChange>
          <div className="range">
            <div className="center">
              <div className="sliderValue">
                <div>
                  <span
                    ref={iptSliderRange}
                    id="iptSliderRange"
                    style={{ left: `calc(${position}%)` }}
                  >
                    {fontSize}
                  </span>
                </div>
              </div>
              <div className="field">
                <div className="value">15px</div>
                <div className="input-content">
                  <input
                    type="range"
                    defaultValue={fontSize}
                    min={min}
                    max={max}
                    step={0.2}
                    onChange={e => {
                      setFontSize && setFontSize(e.target.value);
                      handleFontSizeChange(e.target?.value);
                    }}
                    onMouseMove={() => {
                      iptSliderRange.current?.classList.add('show');
                    }}
                    onMouseOut={() => {
                      iptSliderRange.current?.classList.remove('show');
                    }}
                  />
                </div>
                <div className="value">22px</div>
              </div>
            </div>
          </div>
        </ButtonsFontSizeChange>
      )}
      <Container
        fontSize={fontSize}
        width={width}
        countLines={countLines}
        showLines={showLines}
      >
        {countLines && (
          <Enumerate>
            {lines.map(index => (
              <div key={index}>{index}</div>
            ))}
          </Enumerate>
        )}
        <TextareaAutosize
          spellCheck={false}
          ref={textAreaRef}
          placeholder={placeholder}
          defaultValue={textareaValue}
          onChange={handleChange}
          // @ts-ignore
          // onKeyDown={handleKeyDown}
          // @ts-ignore
          // onKeyUp={handleKeyUp}
        />
      </Container>
    </>
  );
};

export default LinedPaper;
