import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import UserAvatar from '../../components/UserAvatar';
import Icon from '../../components/Icon';
import ExpBadge from '../../components/ExpBadge';
import Button from '../../components/Button';

import poxaluluProfilePic from '../../assets/poxa-lulu-avatar.png';

import { Container, ModalCorrectionType } from './styles';
import BadgeDificulty from '../../Pages/Temas/ThemeCard/BadgeDificulty';
interface IThemeCardSuggestProps {
  id: string;
  exp: number;
  themeSuggestion: boolean;
  title: string;
  thumbnail_url: string;
  difficulty: 'easy' | 'medium' | 'hard';
  student?: {
    id: string;
    show_name: string;
    exp: number;
    avatar_url: string;
  };
  course: string;
}

const ThemeCardSuggest: React.FC<IThemeCardSuggestProps> = ({id, student, exp, themeSuggestion, title, thumbnail_url, course, difficulty }) => {
  const history = useHistory();

  const handleSendCorrectionType = useCallback((id) => {
    const swal = withReactContent(Swal)

    swal.fire({
      html: (
        <ModalCorrectionType>
          <main>
            <section onClick={() => {history.push(`/enviar-redacao/texto/${id}`); swal.close();}}>
                <Icon icon="icon-icon-blog-posts" size="20px" color="#6e61c6" />
                <h4>Enviar redação digitada</h4>
                <p>máximo de 30 linhas</p>
            </section>
            <section onClick={() => {history.push(`/enviar-redacao/foto/${id}`); swal.close();}}>
                <Icon icon="icon-icon-camera" size="20px" color="#6e61c6" />
                <h4>Enviar redação por foto</h4>
                <p>tamanho máximo de 3mb</p>
            </section>
          </main>
          <footer>
            <p>Escolha uma das opções de envio 😊</p>
          </footer>
        </ModalCorrectionType>
      ),
      background: '#6e61c6',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, [history]);

  return (
    <Container photo={thumbnail_url}>
      <figure />
      <main>
        <ExpBadge icon="icon-icon-plus" exp={exp} />
        <div>
          <p>{themeSuggestion && 'Sugestão de tema'}</p>
          <h2>{title}</h2>
        </div>
        <Button color="primary" onClick={() => handleSendCorrectionType(id)}>
          <Icon icon="icon-icon-send-message" color="#ffffff" size="14px" margin="0 8px 0 0" />
          ESCREVER REDAÇÃO
        </Button>
      </main>
      <footer>
        {student ?
        <div>
          <UserAvatar size="sm" totalExp={student.exp} picture={student.avatar_url} />
          <div>
            <p><span>Sugerido por</span><br />{student.show_name}</p>
          </div>
        </div>
        :
        <div>
          <UserAvatar size="sm" totalExp={10000} picture={poxaluluProfilePic} />
          <div>
            <p><span>Publicado por</span><br />Poxalulu</p>
          </div>
        </div>}
        <div>
          <BadgeDificulty dificulty={difficulty} />
        </div>
      </footer>
    </Container>
  );
}

export default ThemeCardSuggest;
