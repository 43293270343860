import { Link } from 'react-router-dom';
import styled from 'styled-components';
interface IProfileHeaderProps {
  background?: string;
}

export const Container = styled.div<IProfileHeaderProps>`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  input[type="file"] {
    display: none;
  }

  > div figure {
    position: relative;
    height: 300px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    > footer {
      margin-bottom: -6rem;
      z-index: 999;
      text-align: center;

      > h2 {
        color: ${props => props.theme.colors.textColor};
      }

      > p {
        color: ${props => props.theme.colors.textColor};
        line-height: 1;
      }
    }
  }

  > footer {
    position: relative;
    display: flex;
    align-items: center;
    height: 126px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: ${props => props.theme.colors.bgBox};

    /* TABLET */
    @media (max-width: 768px) {
      padding: 5rem 0 6rem 0;

      > div section {
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
    }

    /* MOBILE */
    @media (max-width: 480px) {

    }
  }
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding: 0 38px;
  width: 100%;
  color: ${props => props.theme.colors.textColor};

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > section {
    margin: 1rem 0;
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      margin-top: -3rem;
      z-index: 99;
    }

    >  div:nth-child(2) {
        margin-left: .75rem;

        /* TABLET */
        @media (max-width: 768px) {
          margin-left: 0;
          margin-bottom: 3rem;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

      h2 {
        line-height: 1;
      }

      > p {
        line-height: 1;
        margin-top: .15rem;
        color: #adafca;
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      > footer {
        display: flex;
        align-items: center;
        margin-top: .55rem;
        line-height: 1;

        /* MOBILE */
        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
          flex-direction: row;
        }

        > div {

          > a {
            text-decoration: none;

            > img {
              width: 30px;
              height: 33px;
              margin: 0 .2rem;
            }
          }


          > p {
            background: ${props => props.theme.colors.bgBox};
            box-shadow: ${props => props.theme.colors.shadowBox};
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            letter-spacing: 0px;
            font-weight: 700;
            font-size: 11px;
          }
        }
      }
    }

    > aside {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 60px;
      display: flex;
      justify-content: space-between;
      padding: 0 1.5rem;

      &::after {
        content: none;
      }

      &:not(:last-child):after {
        content: '';
        width: 1px;
        height: 25px;
        background-color: #adafca40;
        position: absolute;
        top: 25px;
        right: 0;
      }

      > div {
        //margin-bottom: 1rem;
      }

      > p {
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.375rem;
      }

      > h4 {
        color: #adafca;
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  > section:nth-child(2) {
    /* TABLET */
    @media (max-width: 768px) {
      display: none;
    }

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }
  }

`;

export const ModalPrivateContent = styled.div`

  > h1 {
    text-align: left;
  }

  > p {
    text-align: left;
    margin: 1rem 0;
  }

  > ul {
    text-align: left;
    padding-left: 1rem;
  }
`;

export const ViewAll = styled(Link)`
  margin-left: .5rem;
  color: ${props => props.theme.colors.textColor};
  text-decoration: none;
  font-size: 12px;
`;

export const FloatValidation = styled.div`
  position: absolute;
  z-index: 99999;
  top: 1rem;
  left: 1rem;
  padding: 7px 12px;
  backdrop-filter: blur(5px);
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
  border: 2px solid #ffffff45
`;
