import React, { useCallback, useState } from 'react';

import api from '../../services/api';

import ReactStars from 'react-stars';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConfirmDiv } from './styles';
import Swal from 'sweetalert2';

interface IModalRatingCorrectionProps {
  correction_id: string;
  history: any;
}

const ModalRatingCorrection: React.FC<IModalRatingCorrectionProps> = ({correction_id, history}) => {
  const [modalSkipRatingVote, setModalSkipRatingVote] = useState(0);

  const handleConfirmVote = useCallback(() => {
    async function loadData() {
      try {
        const response = await api.post(`/corrections/${correction_id}/rate`, {
          rating: modalSkipRatingVote * 10
        });

        console.log(response.data);
        toast.success('😊 Poxinha, muito obrigado por ajudar a melhorar a nossa Plataforma!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        Swal.close();
        history.push('/minhas-redacoes');
      } catch (error) {
        console.log(error);
        toast.error('😢 Ops! Algo deu errado ao avaliar a correção.', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        Swal.close();
      } finally {

      }
    }

    loadData();


  }, [correction_id, modalSkipRatingVote, history]);

  return (
    <ConfirmDiv>
      <h1>Como você avalia o seu corretor?</h1>
      <p>Considere uma escala onde zero significa muito ruim e cinco significa excelente.</p>
      <footer>
        <ReactStars
          key={99999999}
          color1="#adafca"
          color2="#ffbf00"
          count={5}
          size={50}
          value={modalSkipRatingVote}
          half={false}
          onChange={(new_rating: number) => {
            console.log('voto alterado: ' + (new_rating * 10));
            setModalSkipRatingVote(new_rating);
          }}
        />
      </footer>
      <button disabled={modalSkipRatingVote === 0} onClick={handleConfirmVote}>Confirmar avaliação</button>
    </ConfirmDiv>
  );
}

export default ModalRatingCorrection;
