import React from 'react';

import { Container } from './styles';

import easyIcon from '../../../../assets/easy-dificulty.svg';
import mediumIcon from '../../../../assets/medium-dificulty.svg';
import hardIcon from '../../../../assets/hard-dificulty.svg';

interface IBasgeDificultyProps {
  dificulty: 'easy' | 'medium' | 'hard';
}

const colorVariations = {
  easy: easyIcon,
  medium: mediumIcon,
  hard: hardIcon,
};

const BadgeDificulty: React.FC<IBasgeDificultyProps> = ({ dificulty }) => {
  return (
    <Container dificulty={dificulty}>
      <img src={colorVariations[dificulty]} alt="Dificuldade do tema" />
      {dificulty === 'easy' && 'Fácil'}
      {dificulty === 'medium' && 'Média'}
      {dificulty === 'hard' && 'Difícil'}
    </Container>
  );
}

export default BadgeDificulty;
