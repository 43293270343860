import React, { useState } from 'react';

import ReactStars from 'react-stars';
import 'react-toastify/dist/ReactToastify.css';

import Footer from '../../components/Footer';

import viewIcon from '../../assets/view.svg';

import { Container, ContentGrid1, YoutubeVideoContainer } from './styles';
import NextVideoTumbnailVision from '../../components/NextVideoTumbnailVision';
import PlayerAdapter from '../../components/PlayerAdapter';

const PlayerVision: React.FC = () => {


  const lessons = [
    {
      id: 1,
      title: 'Correção espacial: uma nova era',
      duration: '00:01:19',
      src: 'b2ca4b8e-ab23-403e-b4d8-85d614092530',
    },
    {
      id: 2,
      title: 'Desvendando uma introdução com realidade aumentada',
      duration: '00:06:04',
      src: '6c2c25de-be9c-4a1a-9dc1-f9566bb5871e',
    },
    {
      id: 3,
      title: 'Navegando pelo parágrafo de desenvolvimento I',
      duration: '00:03:59',
      src: 'c3e83737-8577-4529-8ff1-60a24851fc55',
    },
    {
      id: 4,
      title: 'Analisando o parágrafo de desenvolvimento II',
      duration: '00:07:15',
      src: '6f9ab308-2dba-45fd-b9da-609ca7b82ae3',
    },
    {
      id: 5,
      title: 'Dominando a conclusão de uma redação ENEM de forma imersiva',
      duration: '00:06:02',
      src: '160e2fcc-afc3-48e8-b03c-f19908fba633',
    }
  ];

  const onReady = (player: any) => {
    console.log('Player is ready: ', player);
  }

  const onPlay = (player: any) => {
    console.log('Video is playing: ', player);
  }

  const onPause = (player: any) => {
    console.log('Video is paused: ', player);
  }

  const onProgress = (player: any) => {
    console.log('Video progress: ', player);
  }

  const onEnded = (id: number) => {
    console.log('Video ended: ', id);
  }


  const [currentLesson, setCurrentLesson] = useState(lessons[0]);

  return (
    <Container>
      <h6 style={{ marginBottom: '0.5rem' }}><span style={{ fontSize: '18px', marginRight: '0.5rem' }}>🥽</span>Redação ENEM com realidade aumentada</h6>
      <h1>AULA {currentLesson.id} - {currentLesson.title}</h1>
      <ContentGrid1>
        <aside>
          <main>
            <YoutubeVideoContainer>
              <PlayerAdapter
                provider="panda"
                video_url={currentLesson.src}
                thumbnail={''}
                video={currentLesson.src}
                onReady={onReady}
                onPlay={onPlay}
                onPause={onPause}
                onEnded={() => onEnded(currentLesson.id)}
                onProgress={onProgress}
              />
            </YoutubeVideoContainer>
          </main>
          <footer>
            <aside>
              <div>
                <img src={viewIcon} alt="Visualizações" />
                <span>Poxinha, você não precisa ter um Apple Vision para assistir a este conteúdo. Aproveite!</span>
              </div>
            </aside>
            <aside>
              <main />
              <ReactStars
                key={1}
                count={5}
                size={20}
                color1="#adafca"
                color2="#b9993a"
                half={false}
                value={5} //puxar do banco aqui
                onChange={(new_rating: number) => {
                  //
                }}
              />
            </aside>
          </footer>
        </aside>
        <aside>
          {lessons.map((lesson, index) => (
            <NextVideoTumbnailVision
              key={index}
              lessonOrder={lesson.id}
              thumbnailUrl={`https://b-vz-e7274b3b-4ac.tv.pandavideo.com.br/${lesson.src}/thumbnail.jpg`}
              lessonTitle={lesson.title}
              lessonDuration={lesson.duration}
              progress={100}
              current={currentLesson.id === lesson.id}
              onClick={() => setCurrentLesson(lesson)}
            />
          ))}

        </aside>
      </ContentGrid1>
      <Footer />
    </Container>
  );
}

export default PlayerVision;
