import React from 'react';

import { useAuth } from '../hooks/auth';

import App from './app.routs';
import Auth from './auth.routs';
import Maintenance from './maintenance.routs';

const Routes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const isAdmin = !!localStorage.getItem('@poxalulu:isAdmin');

 /*  if(process.env.REACT_APP_MAINTENANCE && !isAdmin) {

    return <Maintenance />;
  }
 */
  return (
    isAuthenticated ? <App /> : <Auth />
  );
}

export default Routes;
