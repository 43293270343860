import styled from 'styled-components';

interface ILinedPaperProps {
  fontSize?: string | number;
  resize?: 'vertical' | 'horizontal' | 'unset' | 'block' | 'none';
  width?: string;
  countLines?: boolean;
  showLines?: boolean;
}

export const ButtonsFontSizeChange = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* range classes */
  .range{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    padding: 0 65px 0 45px;
    background: ${props => props.theme.colors.primary};
    color: #fff;
  }
  .range .center {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .sliderValue{
    position: absolute;
    width: 200px;
    height: 10px;
    margin: 0 auto;
    left: calc(50% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      position: relative;
      margin: 0 10px;
      width: 100%;
    }
  }
  .sliderValue span{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 46px;
    width: 46px;
    top: -75px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 55px;
    z-index: 2;
    color: #fff;
    transform-origin: bottom;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-50%) scale(0);

    &::after {
      content: 'px';
      font-size: 11px;
      margin-left: 2px;
      font-weight: bold;
    }
  }
  .sliderValue span.show{
    transform: translateX(-50%) scale(1);
  }
  .sliderValue span:before{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: #664AFF;
    border: 3px solid #fff;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-bottom-left-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 300px;
    position: relative;
    font-weight: 700;
  }
  .input-content {
    width: 200px;
    display: flex;
  }
  .range input{
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
    border: none;
    z-index: 2222;
    cursor: pointer;
  }
  .range input::-webkit-slider-thumb{
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #664AFF;
    border: 3px solid #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .range input::-moz-range-thumb{
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #664AFF;
    border: 1px solid #664AFF;
    cursor: pointer;
  }
  .range input::-moz-range-progress{
    background: #664AFF; //this progress background is only shown on Firefox
  }
  /* range classes */

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > button {
      background: #fff;
      margin: .5rem 4px .5rem 0;
      padding: .75rem .7rem 1.2rem .75rem;
      width: 120px;
      transition: all .2s ease-in-out;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      border-radius: 12px 12px 0 0;
      box-shadow: ${props => props.theme.colors.shadowBox};

      &:nth-child(1) {
        //border-radius: 12px 0 0 12px;
        font-size: 10px;
      }

      &:nth-child(2) {
        //border-radius: 0 12px 12px 0;
        font-size: 13px;
      }

      &:nth-child(3) {
        //border-radius: 0 12px 12px 0;
        font-size: 16px;
      }

      > span {
        font-weight: 500;
        font-size: 9px;
        display: block;
        letter-spacing: 0;
        color: #adafca;
      }

      &:hover {
        transform: translateY(-.3rem);
      }

      &.active {
        color: #fff;
        background: ${props => props.theme.colors.primary};
        transform: translateY(-.3rem);

        > span {
          color: #fff;
        }
      }
    }
  }
`;

export const Container = styled.div<ILinedPaperProps>`
  position: relative;
  overflow: hidden;
  box-shadow: ${props => props.theme.colors.shadowBox};

  > textarea {
    text-align: justify;
    position: relative;
    padding: 30px 5px 0px 0;
    padding-left: ${props => !props.countLines ? '20px' : '50px'};
    box-shadow: ${props => props.theme.bgBox};
    border-radius: 0 0 12px 12px;
    background: white;
    background-image: ${props => props.showLines ? 'repeating-linear-gradient(white 0px, white 24px, steelblue 25px)' : 'none'};
    background-size: 100% 30px;
    color: #3e3f5e;
    line-height: 30px;
    font-weight: 600;
    min-height: 929px;
    overflow: hidden;
    font-size: ${props => props.fontSize ? props.fontSize + 'px' : '15px'};
    width: ${props => props.width ? props.width : '890px'};
    resize: none;
    overflow: hidden;
    max-height: 932px;

    ::placeholder {
      color: #adafca;
    }
  }

`;

export const Enumerate = styled.aside`
  position: absolute;
  left: 5px;
  top: 31px;
  line-height: 30px;
  font-size: 11px;
  color: #7f7e7e;
  z-index: 999;

  > div {
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0px;
    top: -40px;
    left: 39px;
    bottom: 2px;
    border-left: 2px solid rgba(255, 0, 0, 0.4);
    z-index: 9999;
  }
`;
