import React, { useState, useEffect } from 'react';

import { useMark } from '../../../hooks/mark-photo';

import Loader from '../../../components/Loader';

import Mark from '../Mark';

import { Container, WordingPhotoContainer } from './styles';

interface WordingPhotoContentProps {
  photo_url: string;
  initialMarks?: any;
  parent?: React.RefObject<HTMLElement>;
}

const WordingPhotoContent: React.FC<WordingPhotoContentProps> = ({
  photo_url,
  initialMarks,
  parent,
}) => {

  const { marks, setInitialMarks } = useMark();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInitialMarks(initialMarks);
  }, [initialMarks, setInitialMarks]);

  return (
    <>
      <Container>
        {loading && <Loader isFixed />}
        <WordingPhotoContainer>
          <img src={photo_url} alt="" draggable={false} />
        </WordingPhotoContainer>

        {marks.map(mark => (
          <Mark
            key={mark.id}
            id={mark.id}
            background={mark.background}
            color={mark.color}
            parent={parent}
          />
        ))}
      </Container>
    </>
  );
};

export default WordingPhotoContent;
