import React, { useCallback, useEffect, useState } from 'react';
import { Link as LinkRouter, useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from "react-scroll";
import ReactMarkdown from 'react-markdown';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SectionBanner from '../../components/SectionBanner';
import HeaderInfo from '../../components/HeaderInfo';
import Breadcrumbs from '../../components/Breadcrumbs';
import ButtonFeedReaction from '../../components/ButtonFeedReaction';
import Badge from './Badge';
import InlineReaction from './InlineReaction';
import Pagination from '../../components/Pagination';
import Wysiwyg from '../../components/Wysiwyg';
import UserAvatar from '../../components/UserAvatar';
import Loader from '../../components/Loader';
import ModalEditTopic from './ModalEditTopic';
import ModalEditAwnser from './ModalEditAwnser';
import ModalReportTopic from './ModalReportTopic';
import ModalReportAnwser from './ModalReportAnwser';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/forum-icon.png';

import { Container, ContentGrid1, ContentGrid2, Topcs, Post, Showing } from './styles';

interface ITopicProps {
  id: string;
  category_id: string;
  author_id: string;
  title: string;
  content: string;
  deleted_at?: string;
  deleted_by?: string;
  is_pinned: boolean;
  love: number;
  like: number;
  funny: number;
  happy: number;
  sad: number;
  created_at: string;
  updated_at: string;
  author: {
    id: string;
    first_name: string;
    last_name: string;
    avatar?: string;
    role: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    career?: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    }
  };
  reactions?: {
    type: "like" | "love" | "funny" | "happy" | "sad";
  }[];
  category: {
    id: string;
    title: string;
    description: string;
    thumbnail_url?: string;
  };
  is_new: boolean;
  reactions_total: number;
}

interface IAnswersProps {
  id: string;
  topic_id: string;
  author_id: string;
  author: {
    id: string;
    first_name: string;
    last_name: string;
    avatar?: string;
    role: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    career?: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    }
  };
  reactions: {
    type: "like" | "love" | "funny" | "happy" | "sad";
  }[];
  content: string;
  deleted_at?: string;
  deleted_by?: string;
  love: number;
  like: number;
  funny: number;
  happy: number;
  sad: number;
  created_at: string;
  updated_at: string;
  reactions_total: number;
}


const ForumFeed: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { slug, category, id } = useParams<{ slug: string, category: string, id:string }>();

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(true);

  const [topic, setTopic] = useState<ITopicProps>({} as ITopicProps);
  const [answers, setAnswers] = useState<IAnswersProps[]>([]);

  // List topics
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get<ITopicProps>(`/forums/${slug}/${category}/topics/${id}`);

        console.log(response.data);
        setTopic(response.data);
      } catch (err) {
        console.log(err);

        toast.error('😢 Poxa, parece que o tópico que você tentou acessar não existe!', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        history.push('/forum');

      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, category, id, history]);

  // List anwsers
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/forums/${slug}/${category}/topics/${id}/answers`,
          {params: { pageIndex: currentPage - 1, pageSize: 6 }}
        );

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

        console.log('awsers:>>>>> ', response.data);
        setAnswers(response.data.records);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [currentPage, slug, category, id]);


  // Modal edit topic
  const handleEditTopic = useCallback((id: string, title: string, content: string) => {
    let reportModal = withReactContent(Swal);

    reportModal.fire({
      html: <ModalEditTopic id={id} title={title} content={content} slug={slug} category={category} setTopic={setTopic} />,
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: 777,
    });
  }, [category, slug]);


  const handleEditAwnser = useCallback((topic_id: string, answer_id: string, content: string) => {
    let reportModal = withReactContent(Swal);

    reportModal.fire({
      html:
        <ModalEditAwnser
          topic_id={topic_id}
          answer_id={answer_id}
          content={content}
          slug={slug}
          category={category}
          setAnswer={newAnswer => setAnswers(oldAnswers => oldAnswers.map(a => a.id === answer_id ? { ...a, ...newAnswer } : a))}
        />,
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, [category, slug]);


  const handleReportAwnser = useCallback((id: string) => {
    let reportModal = withReactContent(Swal);

    reportModal.fire({
      html: <ModalReportAnwser target_id={id} />,
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, []);

  const handleReportQuestion = useCallback((id: string) => {
    let reportModal = withReactContent(Swal);

    reportModal.fire({
      html: <ModalReportTopic target_id={id} />,
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, []);

  const handleRemoveTopicOrAwnser = useCallback((topic_id: string, type: string, answer_id?: string) => {
    console.log({topic_id, type, answer_id})
    let deleteModal = withReactContent(Swal);
    deleteModal.fire({
      html: (
        <h1>Tem certeza que deseja excluir esse(a) tópico/resposta?</h1>
      ),
      background: '#df4040',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
    }).then((result) =>{
      if(result.isConfirmed) {
        if(type === 'topic') {
          const response = api.delete(`/forums/${slug}/${category}/topics/${topic_id}`);

          console.log(response);
          history.goBack();
        } else if(type === 'answer') {
          const response = api.delete(`/forums/${slug}/${category}/topics/${topic_id}/answers/${answer_id}`);
          console.log(response);
          history.goBack();
        }
      }
    });
  }, [category, slug, history]);

  return(
    <Container>
      <SectionBanner icon={bannerindexIconImg} title={topic.category?.title} subTitle="Crie tópicos e discuta com os seus colegas sobre redação e vestibulares!" />
      <Breadcrumbs>
        <ol>
          <li title="Fórum">
            <LinkRouter to={`/forum`}>Fórum</LinkRouter>
          </li>
          <li title={topic?.category?.title}>
            <LinkRouter to={`/forum/${slug}/${category}/topicos`}>{topic?.category?.title}</LinkRouter>
          </li>
          <li title={topic.title}>
            {topic.title}
          </li>
        </ol>
      </Breadcrumbs>
      <HeaderInfo supTitle="Fórum de discussões" badge={topic.is_new ? 'Novo' : ''} title={topic.title} margin="3rem 0 1rem 0" showSendButton={false} />

      <Topcs>
        <section>
          <aside><h5>Autor</h5></aside>
          <aside><h5>Postagem</h5></aside>
        </section>
      </Topcs>

      <ContentGrid1>
        <Post key={topic.id}>
          <div>
            <p>{topic.created_at && format(parseISO(topic.created_at), "EEEE, dd 'de' MMMM", {locale: ptBR})}</p>
            <div>
              {topic.author_id === user.id && <button onClick={() => handleEditTopic(topic.id, topic.title, topic.content)} style={{color: '#805cea'}}>Editar</button>}
              <Link to="wiziwig" duration={777} smooth={true}>Responder</Link>
              <button onClick={() => handleReportQuestion(topic.id)}>Denunciar</button>
              {/* ADRÍCIA ID ABAIXO 777777777777777777777777777777777777 ATENÇÃO */}
              {(user.role === 'administrator' || user.id === '9a201048-1279-42a8-bb15-4f537a927bd6') && <button onClick={() => handleRemoveTopicOrAwnser(topic.id, 'topic')}>Excluir</button>}
            </div>
          </div>
          <section>
            <aside>
              <UserAvatar size="lg" totalExp={topic.author?.exp} picture={topic.author?.avatar_url} />
              <h3>{topic.author?.show_name}</h3>
              {topic.author?.career && <p>{topic.author?.career.name}</p>}
              <Badge type="Autor" />
            </aside>
            <main>
              <h2>{topic.title}</h2>
              <ReactMarkdown disallowedElements={['a', 'link', 'anchor']}>{topic.content}</ReactMarkdown>
              <footer>
                <InlineReaction like={topic.like} love={topic.love} funny={topic.funny} sad={topic.sad} happy={topic.happy} />
                <ButtonFeedReaction react={topic.reactions && topic.reactions.length > 0 ? topic.reactions[0].type : undefined} post_id={topic.id} post_type="topics" setPost={reactions => setTopic(oldTopic => ({ ...oldTopic, ...reactions }))} />
              </footer>
            </main>
          </section>
        </Post>
        {answers.map((answer) => {

          const role = answer.author?.id === topic.author?.id ? 'Autor' : answer.author?.role_name === 'Aluno' ? 'Aluno' : answer.author?.role_name === 'Afiliado' ? 'Aluno' : 'Moderador';

          return (
            <Post key={answer.id}>
              <div>
                <p>{answer.created_at && format(parseISO(answer.created_at), "EEEE, dd 'de' MMMM", {locale: ptBR})}</p>
                <div>
                  {answer.author_id === user.id && <button onClick={() => {handleEditAwnser(topic.id, answer.id, answer.content)}} style={{color: '#805cea'}}>Editar</button>}
                  <Link to="wiziwig" duration={777} smooth={true}>Responder</Link>
                  <button onClick={() => handleReportAwnser(answer.id)}>Denunciar</button>
                  {(user.role === 'administrator' || user.role === 'coordinator') && <button onClick={() => handleRemoveTopicOrAwnser(topic.id, 'answer', answer.id)}>Excluir</button>}
                </div>
              </div>
              <section>
                <aside>
                  <UserAvatar size="md" totalExp={answer.author.exp} picture={answer.author.avatar_url} />
                  <h3>{answer.author.show_name}</h3>
                  {answer.author?.career && <p>{answer.author?.career.name}</p>}
                  <Badge type={role} />
                </aside>
                <main>
                  <ReactMarkdown>{answer.content}</ReactMarkdown>
                  <footer id="footer">
                    <InlineReaction like={answer.like} love={answer.love} funny={answer.funny} sad={answer.sad} happy={answer.happy} />
                    <ButtonFeedReaction
                      react={answer.reactions.length > 0 ? answer.reactions[0].type : undefined}
                      post_id={answer.id}
                      post_type="answers"
                      setPost={reactions => {
                        setAnswers(oldAnswers => oldAnswers.map(oldAnswer => {
                          if (oldAnswer.id === answer.id) {
                            return { ...oldAnswer, ...reactions };
                          }

                          return oldAnswer;
                        }))
                      }}
                    />
                  </footer>
                </main>
              </section>
            </Post>
          )
        })}
      {loading && <Loader isFixed={false}  />}
      </ContentGrid1>
      <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} tópicos disponíveis`}</Showing>
      {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      <ContentGrid2 id="wiziwig">
        <Wysiwyg placeholder="Escreva sua resposta aqui!" maxLength={user.role !== 'student' ? 1500 : 500} setAnswers={setAnswers} />
      </ContentGrid2>

      <Footer />
    </Container>
  );
}

export default ForumFeed;
