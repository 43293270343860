import React, { useEffect, useRef, useState } from 'react';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import { ptBR } from 'date-fns/locale';

import { Container, Label, Error } from './styles';

interface IInputDatePikerValidateProps extends Omit<ReactDatePickerProps, 'onChange'> {
  label: string;
  padding?: string;
  margin?: string;
  name: string;
}

const InputDatePikerValidate: React.FC<IInputDatePikerValidateProps> = ({ label, name, padding, margin, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      setValue: (ref: any, value: any) => {
        if (value) {
          ref.setSelected(new Date(value));
        }
      },
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container padding={padding} margin={margin}>
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={setDate}
          dateFormat="dd/MM/yyyy"
          locale={ptBR}
          {...rest}
        />
        <Label>{label}</Label>
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
}

export default InputDatePikerValidate;
