import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 1.5fr 5fr;
  border-radius: 12px;
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadow};

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      position: sticky;
      top: calc(80px + 1rem);
      width: 100%;

      > footer {
        width: 100%;
        background: ${props => props.theme.colors.bgBox};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        border-radius: 0 0 12px 12px;
      }
    }
  }

  > section:nth-child(2) {
    position: relative;

    > header {
      display: grid;
      grid-gap: 1rem;
      height: 140px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 1rem;

      /* TABLET */
      @media (max-width: 768px) {
        display: none;
      }

      > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        padding: 0 28px;
        background: ${props => props.theme.colors.bgBox};
        box-shadow: ${props => props.theme.colors.shadowBox};
        overflow: hidden;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:not(:first-child):hover {
            transform: translateY(-.5rem);
          }

        > header {
          background: linear-gradient(90deg, #615dfa, #8d7aff);
          position: absolute;
          width: 100%;
          height: 50%;
          display: flex;
          align-items: start;
          justify-content: start;
          top: 0;
        }

        > div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          > h4 {
            margin-top: 15px;
            font-size: .875rem;
            font-weight: 700;
            line-height: 1;
          }

          > p {
            margin-top: 4px;
            color: #8f91ac;
            font-size: .75rem;
            font-weight: 500;
          }
        }

      }
    }

    > section {
      position: relative;
      background: ${props => props.theme.colors.bgBox};
      padding: 32px 28px;
      border-radius: 12px;
      box-shadow: ${props => props.theme.colors.shadowBox};

      > footer {
        display: flex;
        justify-content: center;

        > button {
          display: inline-block;
          width: 100%;
          padding: 1rem .4rem;
          border-radius: 10px;
          font-size: .875rem;
          font-weight: 700;
          text-align: center;
          -webkit-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
          box-shadow: 3px 5px 10px 0 rgb(62 63 94 / 20%);
        }
      }

      > h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: .5rem;
      }

      ul {
        font-size: .8rem;
        color: #adafca;
        list-style: none;
      }

      > main {
        margin-top: 2rem;

        > div {
          overflow-x:auto;

          > table {
            border-collapse: separate;
            border-spacing: 0;
            width: 100%;
            border-spacing: 0px 8px;
            margin-top: -8px;

            > thead {

              > tr {

                > td {
                  font-size: 12px;
                  color: ${props => props.theme.colors.gray};
                  padding-left: 1rem;
                  text-transform: uppercase;
                  font-weight: 600;
                }
              }
            }

            > tbody {
              > tr {
                background: ${props => props.theme.colors.bgContent};

                > td {
                  padding: 16px;
                  font-size: 14px;
                  color: ${props => props.theme.colors.textColor};
                  font-weight: 600;
                }
              }
            }
          }
        }

        > footer {
          margin-top: 1rem;
        }
      }
    }
  }
`;

export const SimpleInput = styled.div`
  position: relative;
  margin-bottom: 1.75rem;
  display: flex;
  justify-content: center;

  > input[type="text"], input[type="password"], textarea, select {
    background-color: transparent;
    border: 1px solid #8f91ac70;
    width: 100%;
    color: ${props => props.theme.colors.textColor};
    border-radius: 12px;
    font-size: .875rem;
    font-weight: 700;
    padding: .8rem 1.5rem;
    resize: none;
    transition: all .3s ease-in-out;

    ::placeholder {
      color: #8f91ac70;
    }

    &:focus {
      border-color: #6e61c6;
    }

    &:disabled {
      color: #8f91ac;
      cursor: no-drop;
    }
  }

  > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    &::-ms-expand {
      display: none;
    }
  }

  >  span {
    color: #9098a9;
    font-size: 10px;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 5px);
    transform: rotate(90deg);
  }

  > label {
    color: ${props => props.theme.colors.textColor};
    font-size: .75rem;
    font-weight: 600;
    position: absolute;
    top: 12.5px;
    left: .75rem;
    padding: 0 5px;
    pointer-events: none;
    background: ${props => props.theme.colors.bgBox};
    transition: .3s ease;
    transform:translateY(-1.5rem);
  }
  `;

  export const Showing = styled.div`
    text-align: center;
    color: #9aa4bf80;
    margin: 1rem 0 -1rem 0;
  `;
