import styled from 'styled-components';

interface IMessageErrorValidatorProps {
  animaton?: boolean;
}

export const ErrorParagraph = styled.p<IMessageErrorValidatorProps>`
  color: #ff0000;
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
  margin-left: 6px;
  animation: ${props => props.animaton ? 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both ' : 'none'};

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;
