import React from 'react';

import { Container } from './styles';

interface IBadgePros {
  type:
    | 'Moderador'
    | 'Autor'
    | 'Aluno'
}

const Badge: React.FC<IBadgePros> = ({type}) => {
  return (
    <Container type={type}>
      {type}
    </Container>
  );
}

export default Badge;
