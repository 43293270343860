import React from 'react';

import ReactMarkdown from 'react-markdown';

import { Mark } from '../../hooks/mark';
import { Mark as MarkPhoto } from '../../hooks/mark-photo';

import { MarkPopover } from './styles';

interface MarkContainerProps {
  marks: Mark[] | MarkPhoto[];
}

const MarkContainer: React.FC<MarkContainerProps> = ({ marks, children }) => {

  return (
    <div>
      {children}
      {/* @ts-ignore */}
      {marks.map(mark => (
        <MarkPopover
          key={mark.id}
          isActive={mark.isActive}
          style={{ left: `${mark.offsetX}px`, top: `${mark.offsetY}px` }}
        >
          <header>
            {mark.title}
          </header>

          <div>
            <ReactMarkdown
              //@ts-ignore
              allowedTypes={[
                'break',
                'delete',
                'emphasis',
                'strong',
                'text',
                'paragraph',
              ]}
            >
              {mark.description}
            </ReactMarkdown>
          </div>
        </MarkPopover>
      ))}
    </div>
  );
};

export default MarkContainer;
