
import styled from 'styled-components';

interface INextVideoTumbnailsProps {
  current?: boolean;
}

export const Container = styled.div<INextVideoTumbnailsProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
  cursor: pointer;
  text-decoration: none;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .3rem .9rem .3rem .7rem;
    border-radius: 12px;
    background: rgb(110 97 198 / 12%);
    box-shadow: ${props => props.theme.colors.shadowAlternative};
    transition: all .25s ease-in-out;

    &:hover {
      background: rgb(110 97 198 / 8%);
    }

    > figure {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 6px;

      > div {
        position: absolute;
        text-align: center;
        display: flex;
        opacity: ${props => props.current ? '1' : '0'};
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background: #261e5d9c;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transition: all .3s ease-in-out;
      }

      img {
      width: 100px;
      border-radius: 6px;
      }
    }

    > main {
      padding: 1rem;
      flex: 1;

      > p {
        line-height: 1;
        text-transform: uppercase;
        color: #adafca;
        font-size: .75rem;
        font-weight: 700;
      }

      > h3 {
        display: flex;
        align-items: center;
        min-height: 28px;
        font-size: 14px;
        line-height: 1;
        color: ${props => props.theme.colors.textColor};
      }
    }


    > aside {

      p {
        font-size: 12px;
        color: ${props => props.theme.colors.textColor};
        font-weight: 700;
      }
    }
  }
`;
