import React, { useCallback, useState } from 'react';

import api from '../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ProfileHeader from '../../components/ProfileHeader';
import Accordion from '../../components/Accordion';
import HeaderInfo from '../../components/HeaderInfo';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import { Container, ContentGrid1, SimpleInput } from './styles';

const AlterarSenha: React.FC = () => {

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangePassoword = useCallback((e) => {
    e.preventDefault();
    if(passwordConfirmation.length < 6) {
      toast.error('🔒 Poxinha, sua senha deve ter no mínimo 6 caracteres!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if((password !== passwordConfirmation)) {
      toast.error('🤷‍♂️ Poxinha, as senhas escolhidas não conferem!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      loadData();
    }

    async function loadData() {
      try {
        setLoading(true);
        const response = await api.patch(`/profile/password`, {
          "old_password": oldPassword,
          "password": password,
          "password_confirmation": passwordConfirmation
        });

        console.log('senha alterada com sucesso >>>>> ', response.data);

        toast.success('👌 Senha alterada com sucesso!', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setOldPassword('');
        setPassword('');
        setPasswordConfirmation('');
        setSuccess(true);

      } catch (err) {
        console.log('erro: >>>>>>', err);
        const { error } = err.response?.data;
        console.log(error);

        let message = '';

        switch(error) {
          case 'wrong-password':
            message = '😢 Poxinha, a senha atual informada não confere!'
            break;

          default:
            message = '😢 Poxa, algo deu errado! Tente novamente mais tarde.';
        }
        console.log('aqui')
        toast.error(message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setSuccess(false);
      } finally {
        setLoading(false);
      }
    }


  }, [oldPassword, password, passwordConfirmation]);

  return(
    <Container>
        <ProfileHeader />
        <ContentGrid1>
          <section>
              <div>
                <Accordion />
                <footer>
                  {/* <Button color="success" onClick={() => handleSaveChanges()} >Salvar mudanças!</Button> */}
                </footer>
              </div>
            </section>
          <section>
            <HeaderInfo supTitle="Segurança" title="Alterar senha" showSendButton={false} margin="0 0 1.5rem 0" />
            <section>
              {loading && <Loader isFixed={false} />}
              <h1>Por favor, informe uma senha com os requisitos abaixo:</h1>
              <ul>
                <li>- mínimo de 6 caracteres</li>
              </ul>
              <main>
                <form onSubmit={(e) => handleChangePassoword(e)}>
                  <div>
                    <SimpleInput>
                      <input
                        type="password"
                        id="oldPassword"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        autoComplete="off"
                      />
                      <label>Senha atual</label>
                    </SimpleInput>
                    <SimpleInput>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                        minLength={6}
                      />
                      <label>Nova senha</label>
                    </SimpleInput>
                    <SimpleInput>
                      <input
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        autoComplete="off"
                        minLength={6}
                      />
                      <label>Confirme a nova senha</label>
                    </SimpleInput>
                  </div>
                  <footer>
                    {!success ?
                      <Button type="submit" color="primary">Alterar sua senha!</Button>
                      :
                      <Button type="submit" color="success">Senha alterada com sucesso! 😊</Button>
                    }
                  </footer>
                </form>
              </main>
            </section>
          </section>
        </ContentGrid1>
        <Footer />
    </Container>
  );
}

export default AlterarSenha;
