import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAuth } from '../../hooks/auth';

/* import Cropper from 'react-perspective-cropper'; */
import Cropper from './Cropper';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import imageCompression from 'browser-image-compression';

import Icon from '../Icon';
import Loader from '../Loader';
import Button from '../Button';
import LottieAnimation from '../../components/LottieAnimation';

import { Container, BtnReset, BtnCancel, CropInstructionsContentModal } from './styles';

import resizeAnimation from '../../assets/lottie-animations/resize.json';


interface ICropperPerspectiveProps {
  maxWidth?: number;
  placeholder: string;
  padding?: string;
  setEnvioFile(value: string | File): void;
}

const CropperPerspective: React.FC<ICropperPerspectiveProps> = ({maxWidth = 773, placeholder, padding, setEnvioFile}) => {
  const {user} = useAuth();

  const [loading, setLoading] = useState(false);
  const [imgInputed, setImgInputed] = useState(false);
  const [imgScanned, setImgScanned] = useState(false);
  const [error, setError] = useState(false);

  const [fileInputed, setFileInputed] = useState<any>();

  /* CROP PERSPECTIVE STATE VARS */
  const [cropState, setCropState] = useState<any>({} as any);
  const [img, setImg] = useState<File | null | string>('');
  const cropperRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onDragStop = useCallback((s) => setCropState(s), []);
  const onChange = useCallback((s) => setCropState(s), []);

  // Dropzone perpective
  const doSomething = useCallback(async () => {
    try {
      console.log('coordenadas: ', cropState);
      setLoading(true);
      const res = await cropperRef.current?.done({ preview: true });
      console.log('res', res)
      //const file = await resizeBlob(res, { width: 980, height: 0 });
      const compressedFile = await imageCompression(res, {maxSizeMB: 1, maxWidthOrHeight: 890, useWebWorker: true});
      console.log('compressedFile>>>>>>', compressedFile)
      const file = new File([compressedFile], 'croppedImage.jpeg', {
        lastModified: Date.now(),
        type: 'image/jpeg',
      });
      setEnvioFile(file);
      setFileInputed(file);
      console.log(cropperRef.current);
      console.log('blob: ', res);
      setImgScanned(true);
    } catch (e) {
      console.log('error', e);
      toast.error('🤷‍♂️ Ops! Algo deu errado. Contate o suporte técnico!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [cropState, setEnvioFile]);


  const onImgSelection = useCallback(async (e) => {
    setLoading(true);
    try {
      if (e.target.files && e.target.files.length > 0) {
        console.log('img inputed!');

        /*  */
      let modal = withReactContent(Swal);
      modal.fire({
        html: (
          <CropInstructionsContentModal>
            <h1>Atenção, {user.show_name}! 🚨</h1>
            <p>Na próxima etapa você precisará posicionar os quatro <strong>"Pontos de corte"</strong> para escanear sua redação, ok?</p>
            <LottieAnimation options={{animationData: resizeAnimation}}
              height={200}
              width={200}
              scale="scale(1)"
            />
          </CropInstructionsContentModal>
        ),
        background: '#664AFF',
        backdrop: 'rgba(110, 97, 198, .1)',
        showCancelButton: false,
        confirmButtonText: 'Ok!',
        showConfirmButton: true,
        showCloseButton: true,
      })
      /*  */

        setImg(e.target.files[0]);
        setImgInputed(true);
        setImgScanned(false);
      }
    } catch (e) {
      console.log('error', e);
      toast.error('🤷‍♂️ Ops! Algo deu errado. Contate o suporte técnico!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [user.show_name]);

  useEffect(() => {
    setLoading(cropState.loading);
  }, [cropState]);

  const handleResetInput = useCallback(() => {
    if(!inputRef.current) {
      return;
    }
    inputRef.current.value = ''
    setImg('');
    setImgInputed(false);
    setImgScanned(false);
    setError(false);
    setEnvioFile('');
  }, [setEnvioFile]);


  return (
    <Container id="canvasContainer" padding={padding}>
      {loading && <Loader isFixed={false} />}
      <main>
        <div style={{visibility: !loading ? 'visible' : 'hidden'}}>
          <Cropper
            ref={cropperRef}
            //@ts-ignore
            image={img}
            // @ts-ignore
            onChange={onChange}
            // @ts-ignore
            onDragStop={onDragStop}
            pointSize={16}
            lineWidth={4}
            pointBgColor="#ff0000"
            pointBorder="#ff0000"
            lineColor="#ff0000"
            maxWidth={maxWidth - 36}
          />
        </div>
        <input
          ref={inputRef}
          id="inpId"
          type="file"
          key={0}
          onChange={onImgSelection}
          accept="image/png, image/jpg, image/jpeg"
          disabled={imgInputed ? true : false}
        />
        {(!imgInputed) && <footer>
          <Icon icon="icon-icon-photos" size="40px" margin="0 0 1rem 0" />
          <p>{placeholder}</p>
        </footer>}
        {error && <p>Ops! Algo deu errado. Contate o suporte técnico! 😢</p>}
        <div style={{display: 'flex'}}>
          {((imgInputed) && !loading) && <BtnCancel><span onClick={handleResetInput}> <Icon icon="icon-icon-cross-thin" margin="0 4px 0 0" size="10px" /> Cancelar envio</span></BtnCancel>}
        </div>
      </main>
      {(imgInputed && !imgScanned) &&
        <Button color="info" type="button" onClick={doSomething}>
          <Icon icon="icon-icon-grid" margin="0 6px 0 0" />
          Escanear imagem
        </Button>}
    </Container>
  );
}

export default CropperPerspective;
