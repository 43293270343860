import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {}

  /* MOBILE */
  @media (max-width: 480px) {}

`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;

  > div {

    > p {
      color: #8f91ac;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    > h1 {
      font-size: 1.625rem;
      font-weight: 700;
      color: ${props => props.theme.colors.textColor};
    }

    > button {
      height: 48px;
      padding: 0 1.2rem;
      font-size: .875rem;
      font-weight: 700;

      &:hover span {
        padding: 0;
      }
    }
  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 1fr;

  border-radius: 12px;
  padding: 14px 28px;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadow};

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 10px;

    > p {
      display: flex;
      align-items: center;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      color: #8f91ac;
      padding-left: .5rem;

      > span {
        margin: 0 .5rem;
      }

      > a {
        text-decoration: none;
        transition: all .3s ease-in-out;
        color: ${props => props.theme.colors.textColor};
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }

        > span {
          color: ${props => props.theme.colors.primary};
        }
      }
    }

    > form {
      display: flex;
      width: 100%;
    }

    > div {
      > select {
        width: 100%;
        font-size: 1rem;
        background-color: ${props => props.theme.colors.bgBox};
        border: 1px solid #adafca30;;
        padding: 1rem;
        color: #a9a6c6;
        transition: border-color .2s ease-in-out;
        border-radius: 12px;
        font-weight: 600;
        cursor: pointer;
        font-size: 1rem;
      }
      > div, select, span {
        /* MOBILE */
        @media (max-width: 480px) {
          display: none;
        }
      }
      /* MOBILE */
      @media (max-width: 480px) {
          display: none;
      }
    }
  }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(1, 1fr);
  border-radius: 12px;
  padding: 34px 28px;
  min-height: 100px;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadow};

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > section {
    display: flex;
    display: grid;
    grid-gap: 1rem;
    margin: 0;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #9aa4bf30;
    grid-template-columns: 3fr .5fr 200px;

     /* MOBILE */
    @media (max-width: 480px) {
      grid-template-columns: 2fr .5fr;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    > aside > div > a {
      color: ${props => props.theme.colors.textColor};
      text-decoration: none;
      transition: all .25s ease-in-out;

      &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }


    > aside:nth-child(1) {
      display: flex;
      align-items: center;

      > a {

        margin-right: 1.5rem;
      }

      > img {
        vertical-align: middle;
        height: max-content;
      }

      > div {
        display: flex;
        flex-direction: column;

        > p {
          display: flex;
          font-size: .75rem;
          font-weight: 600;
          color: #8f91ac;

          /* TABLET */
          @media (max-width: 768px) {
            display: inline;
          }

          > img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0 6px;
          }

          > span {
            margin: 0 4px;
          }
        }
      }

    }
    > aside:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;

      > p {
        font-size: .875rem;
        font-weight: 700;
      }
    }
    > aside:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;

      /* MOBILE */
      @media (max-width: 480px) {
        display: none;
      }

      > div {

        > p {
          font-size: .75rem;
          font-weight: 700;
          line-height: 1;
          margin-left: 5px;

          > span {
            font-size: .625rem;
            font-weight: 500;
            line-height: 1;
          }
        }
      }
    }

    > aside:nth-child(4) {
      display: flex;
      flex-direction: column;
      align-items: start;

      /* MOBILE */
      @media (max-width: 480px) {
        display: none;
      }

      > a {
        display: block;
        font-size: .75rem;
        font-weight: 700;
        text-decoration: none;
        margin-top: .3rem;
        color: ${props => props.theme.colors.textColor};
        transition: all .25s;

        &:hover {
          color: ${props => props.theme.colors.primary};
        }
      }
    }
  }

`;

export const Topcs = styled.div`
  padding: 0 28px;

  > section {
    display: flex;
    display: grid;
    margin: 2rem 0 -7px 0;
    align-items: center;
    grid-template-columns: 3fr .6fr 200px;

    /* MOBILE */
    @media (max-width: 480px) {
      grid-template-columns: 2fr .5fr;
    }

    > aside {
      > h5 {
        font-size: .75rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #adafca;
      }
    }

    > aside:nth-child(1) {
      display: flex;
      align-items: center;
    }
    > aside:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;

      /* MOBILE */
      @media (max-width: 480px) {
      justify-content: start;
      }
    }
    > aside:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;

      /* MOBILE */
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
`;

export const SearchButton = styled.a`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 1;
  text-decoration: none;
  font-size: 1.2rem;
`;

export const Badge = styled.span`
  margin-right: .4rem;
  padding: 1px 9px;
  border-radius: 200px;
  background-color: #23d2e2;
  color: #fff;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  vertical-align: middle;
`;

export const Showing = styled.p`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;

export const SectionAdd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > div {
    display: flex;
    margin-top: 1rem;

    > button {
      padding: .75rem 1.5rem;

      &:hover {
        transition: none;
      }
    }
  }
`;

export const ModalConfirmRules = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  .swal2-styled.swal2-confirm {
    width: 100% !important;
  }

  > h1 {

  }

  > p {
    font-size: 14px;
    text-align: justify;

    > a {
      color: #fff;
      font-weight: bold;
      text-decoration: underline;
    }
  }


`;
