import styled from 'styled-components';

import arrow from '../../assets/small-arrow-icon.svg'

export const Container = styled.div`
  width: 100%;

  .rdtPicker {
    background: blue !important;
  }

  .rbc-btn-group:nth-child(1) {
    > button:nth-child(1)  {
      display: none;
    }
  }
  .rbc-btn-group:nth-child(3) {

    > button {
      display: none;
    }
  }
  .rbc-label {
    color: ${props => props.theme.colors.secundaryTextColor};
    font-weight: 600;
  }
  .rbc-toolbar {
    margin-bottom: 1.8rem;
    flex-direction: row-reverse;
  }
  .rbc-toolbar button {
    border: none;
    font-weight: 700;
    padding: 0 1rem;
    font-size: 20px;
    color: ${props => props.theme.colors.secundaryTextColor};

    &:hover, :active, :focus  {
      background: none;
      box-shadow: none;
    }
  }
  .rbc-toolbar .rbc-toolbar-label {
    font-size: 1.25rem;
    font-weight: 700;
    text-align: left;
    text-transform: capitalize;
    color: ${props => props.theme.colors.textColor};
  }
  .rbc-month-view {
    border-top:  none;
  }
  .rbc-month-header {
    color: ${props => props.theme.colors.textColor};
    color: #8f91ac;
  }
  .rbc-header {
    padding: 1.4rem 0;
    color: #8f91ac;
    font-size: 1rem;
    font-weight: 500;
    border-left: none !important;
    border-right: none !important;
    border-color: ${props => props.theme.colors.secundaryTextColor}20;

    > span {
      text-transform: capitalize;
    }
  }
  .rbc-today {
    background: none;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-color: ${props => props.theme.colors.secundaryTextColor}20;
  }
  .rbc-row-segment {
    padding: 0 4px 4px 4px;
  }
  .rbc-month-row {
    overflow: visible;
  }
  .rbc-month-row + .rbc-month-row {
    border-color: ${props => props.theme.colors.secundaryTextColor}20;
  }
  .rbc-month-view {
    border: none;
  }
  .rbc-date-cell {
    padding: 10px !important;
    font-weight: 700;
    color: ${props => props.theme.colors.textColor};
  }
  .rbc-off-range {
    > a {
      font-weight: 500 !important;
    }
  }
  .rbc-off-range-bg {
    background: ${props => props.theme.colors.bgContent};
  }
  .rbc-event {
    //background-color: ${props => props.theme.colors.primary} !important;
  }
  .rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: ${props => props.theme.colors.primary};
    border-radius: 15px;
    padding: 6px 12px;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus, :active {
      outline: none;
    }
  }
  .rbc-show-more {
    background: none;
    color: #adc1e2;
    font-weight: 500;
    text-decoration: none;
    margin: 7px 0 0 7px;
  }
  .rbc-agenda-view table.rbc-agenda-table {
    color: ${props => props.theme.colors.textColor};
  }
  .rbc-time-view, .rbc-time-header.rbc-overflowing, .rbc-time-header-content, .rbc-time-header.rbc-overflowing {
    border-color: ${props => props.theme.colors.secundaryTextColor}20;
  }
  .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: none;
  }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    visibility: hidden;
    line-height: 0;
    transition: all .2s;

    &:after {
      content: '';
      visibility: visible;
      display: flex;
      height: 1rem;
      background: url(${arrow}) no-repeat;
      transform: rotate(180deg);
    }

    &:hover {
      opacity: .5;
    }
  }
  .rbc-btn-group > button:last-child:not(:first-child) {
    visibility: hidden;
    line-height: 0;
    transition: all .2s;

    &:after {
      content: '';
      visibility: visible;
      display: flex;
      height: 1rem;
      background: url(${arrow}) no-repeat;
    }

    &:hover {
      opacity: .5;
    }
  }
  .rbc-overlay {
    background: ${props => props.theme.colors.bgBox} !important;
    border-radius: 12px;
  }
  .rbc-overlay-header {
    text-transform: capitalize;
  }
  .rbc-date-cell.rbc-now {
    display: flex;
    justify-content: flex-end;

    > a {
      position: relative;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        top: 0px;
        left: -3px;
        background-color: #7750f8;
        color: #fff;
        cursor: default;
        z-index: -9;
      }
    }
  }

  /* TABLET */
  @media (max-width: 768px) {}

  /* MOBILE */
  @media (max-width: 480px) {}
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(1, 1fr);
  position: relative;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {}

  > section:nth-child(1) {
    border-radius: 12px;
    padding: 34px;
    background-color: ${props => props.theme.colors.bgBox};
    box-shadow: ${props => props.theme.colors.shadowBox};

    /* MOBILE */
    @media (max-width: 480px) {
      padding: 1rem 0;
    }
  }
`;

export const ContentGrid2 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(1, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {}

  > section:nth-child(1) {
    display: grid;
    grid-gap: 1rem;
    margin: 1rem 0;
    grid-template-columns: repeat(4, 1fr);

    /* TABLET */
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    /* MOBILE */
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;

  > div {

    > p {
      color: #8f91ac;
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    > h1 {
      font-size: 1.625rem;
      font-weight: 700;
      line-height: 1;
      color: ${props => props.theme.colors.textColor};
    }

    > button {
      height: 48px;
      padding: 0 1.2rem;
      font-size: .875rem;
      font-weight: 700;

      &:hover span {
        padding: 0;
      }
    }
  }
`;

export const SingleEvent2 = styled.div`
  margin: -20px -41px 0 -40px;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  justify-content: start;
  text-align: left;

  > header {
    background-size: cover;
    background-repeat: no-repeat;
  }

  > main {
    display: flex;
    padding: 0 2rem;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      > span {
        color: #6e61c6;
      }

      h1 {
        font-size: 1.3rem;
        font-weight: 700;
        text-align: left;
      }

      h4 {
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        display: flex;
        align-items: center;
        line-height: 1;
      }

      p {
        font-size: .875rem;
        font-weight: 500;
        color: #9aa4bf;
        display: flex;
        align-items: center;

        > a {
          color: #9aa4bf;
          transition: all .3s ease-in-out;
          word-break:break-all;

          &:hover {
            color: #fff;
          }
        }
      }
    }

    > footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;

      > button {
        background-color: #fd4350;
        color: #fff;
        box-shadow: 4px 7px 12px 0 rgb(253 67 80 / 10%);
        display: inline-block;
        height: 48px;
        width: 200px;
        border-radius: 10px;
        font-size: .875rem;
        font-weight: 700;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
      }
    }
  }
`;
