import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseISO } from 'date-fns/esm';
import { debounce } from 'lodash';

import SectionBanner from '../../components/SectionBanner';
import Icon from '../../components/Icon';
import InputAnimate from '../../components/InputAnimate';
import Pagination from '../../components/Pagination';
import UserAvatar from '../../components/UserAvatar';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/forum-icon.png';

import CreateTopic from './CreateTopic';

import { Container, Header, ContentGrid1, ContentGrid2, Topcs, SearchButton, Badge, Showing, SectionAdd, ModalConfirmRules } from './styles';

interface ITopicsProps {
  maxPages: number;
  page: number;
  records: {
    answers: {
      author: {
        avatar_url: string;
        show_name: string;
        exp: number;
      };
    }[];
    category: {
      title: string;
    };
    author: {
      avatar_url: string;
      show_name: string;
      exp: number;
    };
    created_at: string;
    is_new: boolean;
    id: string;
    title: string;
  }[];
  length: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
}

interface ICategoryProps {
  id: string;
  forum_id: string;
  title: string;
  description: string;
  slug: string;
  thumbnail: string;
  order: number;
  created_at: string;
  updated_at: string;
  forum: {
    id: string;
    course_id: string;
    title: string;
    slug: string;
    created_at: string;
    updated_at: string;
  };
  thumbnail_url: string;
}

const ForumTopics: React.FC = () => {
  const { slug, category } = useParams<{ slug: string, category: string }>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentCategory, setCurrentCategory] = useState<ICategoryProps>({} as ICategoryProps);

  const [topics, setTopics] = useState<ITopicsProps>();

  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const delayedSearch = useMemo(() =>
    debounce(() => {
      if (searchTerm.length > 3) {
        setSearch(searchTerm);
      } else {
        setSearch('');
      }
    }, 500),
    [searchTerm],
  );

  const redirect = useCallback((id: string) => {
    history.push(`/forum/${slug}/${category}}/topicos/${id}`);
  }, [slug, category, history]);

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  const showRules = () => {
    let modal = withReactContent(Swal);
    modal.fire({
      html: (
        <ModalConfirmRules>
          <h1>🚨 Atenção!</h1>
          <p>
            A criação do tópico é para dúvidas relacionadas a categoria <strong>"{currentCategory.title}"</strong>. Se não estiver
            relacionado com o tema, seu tópico estará sujeito a ser fechado por um moderador. Caso você esteja com um
            problema técnico, por favor abra um ticket no <a href="/suporte/tickets" target="_blank">Suporte Técnico</a> ou
            envie um e-mail para <a href="mailto:suporte@poxalulu.com.br" target="_blank" rel="noreferrer">suporte@poxalulu.com.br</a>.
          </p>
        </ModalConfirmRules>
      ),
      background: '#6e61c6',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Ok, entendi!',
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      width: 500,
    }).then((result) => {
      if(result.isConfirmed){
        handleCreateTopic();
      }
    });
  }

  const handleCreateTopic = useCallback(() => {
    let modal = withReactContent(Swal);
    modal.fire({
      html: <CreateTopic slug={slug} category={category} redirect={redirect} />,
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: 777,
    });
  }, [slug, category, redirect]);

  // List topics
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get<ITopicsProps>(`/forums/${slug}/${category}/topics`,
          {params: { pageIndex: currentPage - 1, pageSize: 10, search: search }}
        );

        console.log(response.data);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
        setTopics(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, category, search, currentPage]);


  // List current category
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/forums/${slug}/categories/${category}`);

        console.log('Categoria: ', response.data);

        setCurrentCategory(response.data);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }

    loadData();
  }, [slug, category]);

   // impede que a página seja redirecionada ao submeter o input de temas
   const handleSubmit = useCallback((e) => {
    e.preventDefault();
    return
  }, []);

  return(
    <Container>
      <SectionBanner
        icon={bannerindexIconImg} title={currentCategory.title} subTitle={currentCategory.description} />
      <Header>
        <div>
          <p>Acompanhe as discussões</p>
          <h1>Tópicos</h1>
        </div>
        <div>
          <Button color="primary" onClick={showRules}>
            <Icon icon="icon-icon-forums" color="#ffffff" size="14px" margin="0 8px 0 0" />Criar tópico
          </Button>
        </div>
      </Header>
      <ContentGrid1>
        <div>
          <p>
            <Link to="/forum">Fórum</Link>
            <span>/</span>
            <Link to={`/forum/`}>{slug}</Link>
            <span>/</span>
            {currentCategory.title && currentCategory.title}
          </p>
          <form onSubmit={e => handleSubmit(e)}>
            <InputAnimate
              label="Pesquisar..."
              type="text"
              name="user"
              onChange={(event) => {setCurrentPage(1); setSearchTerm(event.target.value);}}
              margin="0"
              required
            />
            <SearchButton>
              <Icon icon="icon-icon-magnifying-glass" />
            </SearchButton>
          </form>
        </div>
      </ContentGrid1>
      <Topcs>
        <section>
          <aside><h5>Tópico</h5></aside>
          <aside><h5>Respostas</h5></aside>
          <aside><h5>Última interação</h5></aside>
        </section>
      </Topcs>
      <ContentGrid2>
        {(totalRecords === 0 && !!!loading) &&
            <SectionAdd>
              <p>Poxa, não encontramos nenhum tópico com a sua pesquisa. 😢</p>
              <div>
                <Button color="primary" onClick={showRules}>
                  <Icon icon="icon-icon-forums" color="#ffffff" size="14px" margin="0 8px 0 0" />Criar o primeiro tópico!
                </Button>
              </div>
            </SectionAdd>
          }
        {
          topics?.records.map((topic, index) => (
            <section key={index}>
              <aside>
                <div>
                  <Link to={`/forum/${slug}/${category}/topicos/${topic.id}`}>
                    <h1>
                      {topic.is_new && <Badge>Novo</Badge>}
                      {topic.title}
                    </h1>
                  </Link>
                  <p>
                    Tópico iniciado por <img src={topic.author.avatar_url} alt={topic.author.show_name} />
                    <strong>{topic.author.show_name}</strong>
                    <span> — há {formatDistance(parseISO(`${topic.created_at}`), new Date(), { locale: ptBR })}</span>
                  </p>
                </div>
              </aside>
              <aside>
                <p>{topic.answers.length}</p>
              </aside>
              {topic.answers.length ?
                <aside>
                  <UserAvatar size="sm" totalExp={topic.answers[topic.answers.length - 1].author.exp} picture={topic.answers[topic.answers.length - 1].author.avatar_url} />
                  <div>
                    <p><span>Última interação</span><br />{topic.answers[topic.answers.length - 1].author.show_name}</p>
                  </div>
                </aside>
              :
                <aside>
                  <UserAvatar size="sm" totalExp={topic.author.exp} picture={topic.author.avatar_url} />
                  <div>
                    <p><span>Última interação</span><br />{topic.author.show_name}</p>
                  </div>
                </aside>
              }
            </section>
          ))
        }
      {loading && <Loader isFixed={false}  />}
      </ContentGrid2>
      <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} tópicos disponíveis`}</Showing>
      {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      <Footer />
    </Container>
  );
}

export default ForumTopics;
