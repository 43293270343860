import styled, { css } from 'styled-components';

interface IBasgeDificultyProps {
  dificulty: 'easy' | 'medium' | 'hard';
}

const buttonColorVariations = {
  easy: css`
    background: #03bb85;
  `,
  medium: css`
    background: #f09c54;
  `,
  hard: css`
    background: #db7878;
  `
};

export const Container = styled.span<IBasgeDificultyProps>`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 4px;
  padding: 8px;
  font-size: 10px;
  color: #fff;
  height: 30px;
  ${props => buttonColorVariations[props.dificulty]}

  > img {
    width: 32px;
    margin-right: .8rem;
  }
`;
