import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TinySlider from 'tiny-slider-react';

import api from '../../../services/api';

import UserAvatar from '../../../components/UserAvatar';
import Icon from '../../../components/Icon';

import { WidgetBoxActions, TinySliderContent, SlideItem } from './styles';

interface IHallOfFameProps {
  id: string;
  exam_name: string;
  status: boolean;
  final_grade: number;
  created_at: string;
  wording: {
    id: string;
    photo: string;
    status: string;
    student: {
      id: string;
      first_name: string;
      last_name: string;
      nickname: string;
      avatar: string;
      exp: number;
      is_public: boolean;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    };
    is_photo: boolean;
    status_info: {
      name: string;
      color: string;
    };
    photo_url: string;
  };
}

const HallOfFameCard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [hallOfFame, setHallOfFame] = useState<IHallOfFameProps[]>([]);

    // List last 15 corrections of Hall of Fame
    useEffect(() => {
      async function loadData() {
        try {
          setLoading(true);
          const response = await api.get('/corrections/hall-of-fame',
            {params: {exam_name: 'enem', quantity: 15}}
          );

          console.log('LIST HALL OF FAME >>>>>>>>>>>>>>>>>>>');
          console.log(response.data);

          setHallOfFame(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }

      loadData();
    }, []);


  const sldierHallOfFameSettings = {
    lazyload: true,
    nav: false,
    mouseDrag: false,
    loop: true,
    gutter: 1,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    controlsContainer: "#customize-controls",
    responsive: {
      450: {
          items: 1,
      },
      640: {
          items: 3,
      },
      768: {
          items: 3,
      },
      1200: {
          items: 6,
      }
    }
  };

  return (
    <>
      <WidgetBoxActions>
        <h4>Hall da Fama</h4>
        <div>
          <p>Últimas notas superiores a 900 pontos</p>
        </div>
      </WidgetBoxActions>
      <TinySliderContent>
        <TinySlider settings={sldierHallOfFameSettings}>
          {hallOfFame.map((correction, index) => (
            <SlideItem key={index} className="tns-item tns-slide-active" style={{ position: "relative" }}>
              <Link key={index} to={`/visualizar-redacao/${correction.wording.is_photo ? `foto` : `texto`}/${correction.wording.id}`}>
                <UserAvatar size="md" totalExp={correction.wording.student.exp} picture={correction.wording.student.avatar_url} showOnlyHehaxon={false} />
                <h6>{correction.final_grade}</h6>
                <p>{correction.wording.student.show_name}</p>
              </Link>
            </SlideItem>
          ))}
        </TinySlider>
      </TinySliderContent>
      <ul className="controls" id="customize-controls" aria-label="Carousel Navigation">
        <li className="prev" data-controls="prev" aria-controls="customize">
          <Icon icon="icon-icon-small-arrow" size="8px" />
        </li>
        <li className="next" data-controls="next" aria-controls="customize">
          <Icon icon="icon-icon-small-arrow" size="8px" />
        </li>
      </ul>
    </>
  );
}

export default HallOfFameCard;
