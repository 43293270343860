import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(110,97,198,.5);
  backdrop-filter: blur(4px);
  left: 0;
  top: 0;
  z-index: 99999999999;


  > main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 150px;
    background: #fff;
    border-radius: 12px;

    > div{
      display: block;
      height: 48px;
      width: 48px;
      -webkit-animation: loader-2-1 3s linear infinite;
      animation: loader-2-1 3s linear infinite;

      > span {
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 48px;
        width: 48px;
        clip: rect(16px, 48px, 48px, 0);
        -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1)
          infinite;
        animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 48px;
          width: 48px;
          border: 3px solid transparent;
          border-top: 3px solid ${props => props.theme.colors.primary};
          border-radius: 50%;
          -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1)
            infinite;
          animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
      }
    }
    @keyframes loader-2-1 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes loader-2-2 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes loader-2-3 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
