import React from 'react';

import { Link, useLocation } from  'react-router-dom';

import { useAuth } from '../../hooks/auth';

import UserAvatar from '../UserAvatar';

import menuLinks from '../../utils/menuLinks';

import { Container, MenuContainer, Menu, Fixed } from './styles';

const Aside: React.FC = () => {
    const {user} = useAuth();

    const location = useLocation();
    const localtionSplited = location.pathname.split('/')[1];

    return(
        <Container>
          <Fixed>
            <Link to="/perfil">
              <UserAvatar size="sm" totalExp={user.exp} picture={user.avatar_url} showOnlyHehaxon={false} />
            </Link>
            <MenuContainer>
              <Menu>
                {menuLinks.map((item, index) => (
                  <>
                    <li key={index} className={`${item.badge ? 'new' : ''}`}>
                      <Link
                        to={'/' + item.location}
                        className={localtionSplited === item.location || (localtionSplited === '' && index === 0)  ? 'active' : ''}
                        data-tooltip={item.tooltip}
                      >

                        {item.customIcon ? (<span dangerouslySetInnerHTML={{__html: item.customIcon}}></span>) : <span className={`${item.icon}`}></span>}
                      </Link>
                    </li>
                  </>
                ))}
              </Menu>
            </MenuContainer>
          </Fixed>
        </Container>
    );
}

export default Aside;
