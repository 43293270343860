import React, {
  ElementRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

// @ts-ignore
import { useOpenCv } from 'opencv-react';

import { calcDims, readFile } from './utils';
import CropPoints from './CropPoints';
import { applyFilter, transform } from './imgManipulation';
import CropPointsDelimiters from './CropPointsDelimiters';

interface ICanvasProps {
  image: any;
  onDragStop(value: any): void;
  onChange(value: any): void;
  cropperRef: any;
  /* cropperRef: ElementRef<any> {
    current: {
      done(value: any): void;
      backToCrop(): void;
    }
  }; */
  pointSize: number;
  lineWidth: number;
  pointBgColor: string;
  pointBorder: string;
  lineColor: string;
  maxWidth: number;
  maxHeight?: number;
}

interface IContourCoordinatesProps {
  [key: string]: {
    x: number;
    y: number;
  }
}

const buildImgContainerStyle = (previewDims: any) => ({
  width: previewDims.width,
  height: previewDims.height
});

const imageDimensions = { width: 0, height: 0 }
let imageResizeRatio: number;

const Cropper: React.FC<ICanvasProps> = ({
  image,
  onDragStop,
  onChange,
  cropperRef,
  pointSize = 30,
  lineWidth,
  pointBgColor,
  pointBorder,
  lineColor,
  maxWidth,
  maxHeight
}) => {

  const { loaded: cvLoaded, cv } = useOpenCv();
  const canvasRef = useRef<any>(null);
  const previewCanvasRef = useRef<any>(null);
  const magnifierCanvasRef = useRef<any>(null);
  const [previewDims, setPreviewDims] = useState<any>();
  const [cropPoints, setCropPoints] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('crop');


  const showPreview = useCallback((value?: any) => {
    const src = value || cv.imread(canvasRef.current)
    const dst = new cv.Mat()
    const dsize = new cv.Size(0, 0)

    cv.resize(
      src,
      dst,
      dsize,
      imageResizeRatio,
      imageResizeRatio,
      cv.INTER_AREA,
    )
    cv.imshow(previewCanvasRef.current, dst)
    src.delete()
    dst.delete()
  }, [cv]);

  useImperativeHandle(cropperRef, () => ({
    backToCrop: () => {
      setMode('crop')
    },
    done: async (opts: any = {}) => {
      console.log('opts', opts)
      return new Promise((resolve) => {
        setLoading(true)
        transform(
          cv,
          canvasRef.current,
          cropPoints,
          imageResizeRatio,
          setPreviewPaneDimensions
        )
        //applyFilter(cv, canvasRef.current, opts.filterCvParams)
        if (opts.preview) {
          setMode('preview')
        }
        canvasRef.current?.toBlob((blob: any) => {
          blob.name = image.name
          resolve(blob)
          setLoading(false)
        }, image.type)
      })
    }
  }));

  useEffect(() => {
    if (mode === 'preview') {
      showPreview()
    }
  }, [mode, showPreview])

  const setPreviewPaneDimensions = useCallback(() => {
    // set preview pane dimensions
    const newPreviewDims = calcDims(
      canvasRef.current.width,
      canvasRef.current.height,
      maxWidth,
      maxHeight
    )
    setPreviewDims(newPreviewDims);

    if(!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.width = newPreviewDims.width
    previewCanvasRef.current.height = newPreviewDims.height

    imageResizeRatio = newPreviewDims.width / canvasRef.current.width
  }, [maxHeight, maxWidth]);

  const createCanvas = useCallback((src) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')
      img.onload = async () => {
        // set edited image canvas and dimensions
        canvasRef.current = document.createElement('canvas')
        canvasRef.current.width = img.width
        canvasRef.current.height = img.height
        const ctx = canvasRef.current.getContext('2d')
        ctx.drawImage(img, 0, 0)
        imageDimensions.width = canvasRef.current.width
        imageDimensions.height = canvasRef.current.height
        setPreviewPaneDimensions()
        // @ts-ignore
        resolve()
      }
      img.src = src
    })
   }, [setPreviewPaneDimensions]);

  const detectContours = useCallback(() => {
    const dst = cv.imread(canvasRef.current)
    const ksize = new cv.Size(5, 5)
    //convert the image to grayscale, blur it, and find edges in the image
    cv.cvtColor(dst, dst, cv.COLOR_RGBA2GRAY, 0)
    //cv.GaussianBlur(dst, dst, ksize, 0, 0, cv.BORDER_DEFAULT)
    //cv.Canny(dst, dst, 75, 200)
    // find contours
    //cv.threshold(dst, dst, 120, 200, cv.THRESH_BINARY)
    const contours = new cv.MatVector()
    const hierarchy = new cv.Mat()
    cv.findContours(
      dst,
      contours,
      hierarchy,
      cv.RETR_CCOMP,
      cv.CHAIN_APPROX_SIMPLE
    )
    const rect = cv.boundingRect(dst)
    dst.delete()
    hierarchy.delete()
    contours.delete()
    // transform the rectangle into a set of points
    Object.keys(rect).forEach((key) => {
      rect[key] = rect[key] * imageResizeRatio
    })

    const contourCoordinates: IContourCoordinatesProps = {
      'left-top': { x: rect.x, y: rect.y },
      'right-top': { x: rect.x + rect.width, y: rect.y },
      'right-bottom': {
        x: rect.x + rect.width,
        y: rect.y + rect.height
      },
      'left-bottom': { x: rect.x, y: rect.y + rect.height }
    }

    setCropPoints(contourCoordinates)
  }, [cv]);

  const clearMagnifier = () => {
    const magnCtx = magnifierCanvasRef.current?.getContext('2d')
    magnCtx.clearRect(
      0,
      0,
      magnifierCanvasRef.current.width,
      magnifierCanvasRef.current.height
    )
  }

  useEffect(() => {
    if (onChange) {
      onChange({ ...cropPoints, loading })
    }
  }, [cropPoints, loading, onChange])

  useEffect(() => {
    const bootstrap = async () => {
      const src = await readFile(image)
      await createCanvas(src)
      showPreview()
      console.log('image: ', image)
      detectContours()
      setLoading(false)
    }

    if (image && previewCanvasRef.current && cvLoaded && mode === 'crop') {
      bootstrap()
    } else {
      setLoading(true)
    }
  }, [image, cvLoaded, mode, createCanvas, showPreview, detectContours]);

  const onDrag = useCallback((position, area) => {
    const { x, y } = position

    const magnCtx = magnifierCanvasRef.current.getContext('2d')
    clearMagnifier()

    // TODO we should make those 5, 10 and 20 values proportionate
    // to the point size
    magnCtx.drawImage(
      previewCanvasRef.current,
      x - (pointSize - 10),
      y - (pointSize - 10),
      pointSize + 5,
      pointSize + 5,
      x + 10,
      y - 90,
      pointSize + 20,
      pointSize + 20
    )

    setCropPoints((cPs: any) => ({ ...cPs, [area]: { x, y } }))
  }, [pointSize]);

  const onStop = useCallback((position, area, cropPoints) => {
    const { x, y } = position
    clearMagnifier()
    setCropPoints((cPs: any) => ({ ...cPs, [area]: { x, y } }))
    if (onDragStop) {
      onDragStop({ ...cropPoints, [area]: { x, y } })
    }
  }, [onDragStop]);

  return (
    <div
      style={{
        position: 'relative',
        ...(previewDims && buildImgContainerStyle(previewDims))
      }}
    >
      {previewDims && mode === 'crop' && cropPoints && (
        <>
          <CropPoints
            pointSize={pointSize}
            pointBgColor={pointBgColor}
            pointBorder={pointBorder}
            cropPoints={cropPoints}
            previewDims={previewDims}
            onDrag={onDrag}
            onStop={onStop}
            bounds={{
              left: previewCanvasRef?.current?.offsetLeft - pointSize / 2,
              top: previewCanvasRef?.current?.offsetTop - pointSize / 2,
              right:
                previewCanvasRef?.current?.offsetLeft -
                pointSize / 2 +
                previewCanvasRef?.current?.offsetWidth,
              bottom:
                previewCanvasRef?.current?.offsetTop -
                pointSize / 2 +
                previewCanvasRef?.current?.offsetHeight
            }}
          />
          <CropPointsDelimiters
            previewDims={previewDims}
            cropPoints={cropPoints}
            lineWidth={lineWidth}
            lineColor={lineColor}
            pointSize={pointSize}
          />
          <canvas
            style={{
              position: 'absolute',
              zIndex: 5,
              pointerEvents: 'none'
            }}
            width={previewDims.width}
            height={previewDims.height}
            ref={magnifierCanvasRef}
          />
        </>
      )}

      <canvas
        style={{ zIndex: 5, pointerEvents: 'none' }}
        ref={previewCanvasRef}
      />
    </div>
  );
}

export default Cropper;
