import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format, subYears } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import ProfileHeader from '../../components/ProfileHeader';
import Accordion from '../../components/Accordion';
import HeaderInfo from '../../components/HeaderInfo';
import InputValidate from '../../components/InputValidate';
import InputValidateMask from '../../components/InputValidateMask';
import InputTextareaValidate from '../../components/InputTextareaValidate';
import InputSelectValidate from '../../components/InputSelectValidate';
import InputDatePikerValidate from '../../components/InputDatePikerValidate';
import ModalCropImage from '../../components/ModalCropImage';
import UserAvatar from '../../components/UserAvatar';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import getStates from '../../utils/getStates';
import getGenders from '../../utils/getGenders';
import toBase64 from '../../utils/toBase64';

import { Container, ContentGrid1, SimpleInput, SectionFakeMobileBug } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

interface IProfileProps {
  id: string;
  first_name: string;
  last_name: string;
  cpf: string;
  email: string;
  birthday?: string;
  avatar: string;
  cover?: string;
  about?: string;
  role: string;
  price_per_wording?: string;
  bank?: string;
  agency?: string;
  account?: string;
  account_type?: string;
  active: boolean;
  exp: number;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  created_at: string;
  updated_at: string;
  full_name: string;
  role_name: string;
  show_name: string;
  level: number;
  current_exp: number;
  avatar_url: string;
  cover_url: string;
}

interface IUniversitiesProps {
  value: string | number;
  label: string | number;
}

interface ICareersProps {
  value: string | number;
  label: string | number;
}

interface IStudentFormData {
  university_id: string;
  career_id: string;
  first_name: string;
  last_name: string;
  email: string;
  about: string;
  cpf: string;
  password: string;
  gender: string;
  nickname: string;
  phone_number: string;
  birthday: Date;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
}

const Perfil: React.FC = () => {
  const { user, updateUser } = useAuth();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);

  const userAvatarInput = useRef<HTMLInputElement>({} as HTMLInputElement);
  const userCoverInput = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [profile, setProfile] = useState<IProfileProps>({} as IProfileProps);
  const [universities, setUniversities] = useState<IUniversitiesProps[]>([]);
  const [careers, setCareers] = useState<ICareersProps[]>([]);

  const [profilePic, setProfilePic] = useState('');
  const [profileCover, setProfileCover] = useState('');

  const [modalAvatar, setModalAvatar] = useState(false);
  const [modalCover, setModalCover] = useState(false);


  // Get PROFILE INFOS
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const [profileResponse, universityResponse, careersResponse] = await Promise.all([
          api.get(`/profile`),
          api.get(`/universities/list`),
          api.get(`/careers/list`),
        ]);

        console.log('PROFILE INFO: >>>>>>>>>>>>');
        console.log(profileResponse.data);

        let birthday;
        if (profileResponse.data.birthday) {
          birthday = format(new Date(profileResponse.data.birthday), "dd/MM/yyyy", { locale: ptBR });
        }

        console.log('UNIVERSITIES LIST: >>>>>>>>>>>>');
        console.log(universityResponse.data);
        setUniversities(universityResponse.data);

        console.log('CARRIER LIST: >>>>>>>>>>>>');
        console.log(careersResponse.data);
        setCareers(careersResponse.data);


        formRef.current?.setData({
          state: profileResponse.data.state,
          cep: profileResponse.data.cep,
          birthday: profileResponse.data.birthday,
          gender: profileResponse.data.gender,
          career_id: profileResponse.data.career_id,
          phone_number: profileResponse.data.phone_number,
          university_id: profileResponse.data.university_id,
        });

        setProfile({ ...profileResponse.data, birthday });

      } catch (error) {
        console.log(error);
        //setConnectionFailed(true)
      }
      finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  const handleSaveChanges = useCallback(async(data: IStudentFormData) => {
    setLoading(true);

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        university_id: Yup.string().nullable(),
        career_id: Yup.string().nullable(),
        about: Yup.string(),
        birthday: Yup.date()
          .max(subYears(new Date(), 14), 'Mínimo de 14 anos')
          .nullable(),
        gender: Yup.string().nullable(),
        nickname: Yup.string().max(20).matches(/^[a-z ,.'-]*$/i, "Apenas letras do alfabeto são permitidas.").nullable(true),
        phone_number: Yup.string()
          .min(14, 'Telefone inválido')
          .max(15, 'Telefone inválido')
          .required('Telefone obrigatório'),
        cep: Yup.string(),
        street: Yup.string(),
        number: Yup.string(),
        complement: Yup.string(),
        neighborhood: Yup.string(),
        city: Yup.string(),
        state: Yup.string().nullable(),
      });

      await schema.validate(data, { abortEarly: false });

      await api.put(`/profile`, {
        university_id: data.university_id,
        career_id: data.career_id,
        about: data.about,
        gender: data.gender,
        nickname: data.nickname,
        phone_number: data.phone_number,
        birthday: data.birthday,
        cep: data.cep,
        street: data.street,
        number: data.number,
        complement: data.complement,
        neighborhood: data.neighborhood,
        city: data.city,
        state: data.state,
      });

      toast.success('😎 Perfil atualizado com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        toast.error(`😢 Poxa, algo deu errado. Verifique os campos e tente novamente!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return;
      }

      toast.error('😢 Poxa, algo deu errado!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmitAvatar = useCallback(async (file: File) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('avatar', file);

      const response = await api.patch('/profile/avatar', formData);
      console.log(response.data);

      updateUser({ ...user, avatar_url: response.data.avatar_url });

    } catch(err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  }, [updateUser, user]);

  const handleSubmitCover = useCallback(async (file: File) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('cover', file);

      const response = await api.patch('/profile/cover', formData);
      console.log(response.data);

      updateUser({ ...user, cover_url: response.data.cover_url });

    } catch(err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  }, [updateUser, user]);

  const handleChangeAvatar = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setLoading(true);
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfilePic(convertedFile);
    setModalAvatar(true);
    setLoading(false);
  }, []);

  const handleChangeCover = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setLoading(true);
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfileCover(convertedFile);
    setModalCover(true);
    setLoading(false);
  }, []);

  return(
    <Container>
      <ModalCropImage
        image={profilePic}
        cropSize={{width: 200, height: 200}}
        isOpen={modalAvatar}
        setIsOpen={(value) => setModalAvatar(value)}
        onSubmit={handleSubmitAvatar}
        cropShape="round"
      />
      <ModalCropImage
        image={profileCover}
        cropSize={{width: 1260, height: 350}}
        isOpen={modalCover}
        setIsOpen={(value) => setModalCover(value)}
        onSubmit={handleSubmitCover}
      />
        <ProfileHeader />
        <ContentGrid1>
          <section>
            <div>
              <Accordion />
              <footer>
                {/* <Button color="success" >Salvar mudanças!</Button> */}
              </footer>
            </div>
          </section>
          <section>
            {loading && <Loader isFixed={false} />}
            <HeaderInfo supTitle="Informações pessoais" title="Meu perfil" showSendButton={false} margin="0 0 1.5rem 0" />
              <header>
                <div onClick={() => userAvatarInput.current.click()}>
                  <UserAvatar size="md" totalExp={user.exp} picture={user.avatar_url} />
                  <header />
                </div>
              <div onClick={() => userAvatarInput.current.click()}>
                <div>
                  <input type="file" name="useravatar" ref={userAvatarInput} onChange={handleChangeAvatar} accept="image/png, image/jpeg" />
                  <Icon icon="icon-icon-members-1" size="20px" color="#6e61c6" />
                  <h4>Alterar foto de perfil</h4>
                  <p>150x150px tamanho recomendado</p>
                </div>
              </div>
              <div onClick={() => userCoverInput.current.click()}>
                <div>
                  <input type="file" name="usercover" ref={userCoverInput} onChange={handleChangeCover} accept="image/png, image/jpeg" />
                  <Icon icon="icon-icon-photos" size="20px" color="#6e61c6" />
                  <h4>Alterar foto de capa</h4>
                  <p>1260x350px tamanho recomendado</p>
                </div>
              </div>
            </header>
            <section>
              <h1>Informações pessoais</h1>
              <main>
              <Form ref={formRef} initialData={profile} onSubmit={handleSaveChanges} noValidate>
                <section>
                  <SimpleInput>
                    <InputValidate type="text" label="Como você gostaria de ser chamado?" name="nickname" maxLength={20} />
                  </SimpleInput>
                  <SimpleInput>
                    <InputValidate type="text" label="Nome completo" name="full_name" disabled/>
                  </SimpleInput>
                  <SimpleInput>
                    <InputTextareaValidate placeholder="Conte um pouco sobre você" label="Breve descrição" name="about" rows={7} />
                  </SimpleInput>
                </section>
                <section>
                  <SimpleInput>
                    <InputSelectValidate
                      placeholder="Prefiro não responder"
                      labelText="Gênero"
                      label="Prefiro não responder"
                      name="gender"
                      options={getGenders}
                      showLabel
                    />
                  </SimpleInput>
                  <SimpleInput style={{ display: 'none' }}>
                    <InputValidate type="text" label="CPF" name="cpf" disabled />
                  </SimpleInput>
                  <SimpleInput style={{ display: 'none' }}>
                    <InputDatePikerValidate
                      label="Data de nascimento"
                      name="birthday"
                      maxDate={subYears(new Date(), 14)} />
                  </SimpleInput>
                  <SimpleInput>
                    <InputValidate type="email" label="E-mail" name="email" disabled />
                  </SimpleInput>
                  <SimpleInput style={{ display: 'none' }}>
                    <InputValidateMask label="Telefone" name="phone_number" mask="(99) 99999-9999" />
                  </SimpleInput>
                  <SimpleInput>
                    {careers && <InputSelectValidate
                      placeholder="Objetivo"
                      labelText="Objetivo"
                      label="Objetivo"
                      name="career_id"
                      options={careers}
                      showLabel
                    />}
                  </SimpleInput>
                  <SimpleInput>
                    {universities && <InputSelectValidate
                      placeholder="Sonho em ingressar na"
                      labelText="Sonho em ingressar na"
                      label="Sonho em ingressar na"
                      name="university_id"
                      options={universities}
                      showLabel
                    />}
                  </SimpleInput>
                </section>
                <section>
                  <h1>Endereço</h1>
                  <SimpleInput>
                    <InputValidateMask label="CEP" name="cep" mask="99999-999" />
                  </SimpleInput>
                  <SimpleInput>
                    <InputValidate type="text" label="Logradouro" name="street" />
                  </SimpleInput>
                  <SimpleInput>
                    <InputValidate type="text" label="Número" name="number" />
                  </SimpleInput>
                  <SimpleInput>
                    <InputValidate type="text" label="Complemento" name="complement" />
                  </SimpleInput>
                </section>
                <SectionFakeMobileBug>
                  <SimpleInput>
                    <InputValidate type="text" label="Bairro" name="neighborhood" />
                  </SimpleInput>
                  <SimpleInput>
                    <InputValidate type="text" label="Cidade" name="city" />
                  </SimpleInput>
                  <SimpleInput>
                    <InputSelectValidate placeholder="Selecione um Estado" labelText="Estado" label="Estado" name="state" options={getStates} showLabel/>
                  </SimpleInput>
                </SectionFakeMobileBug>
                <footer>
                  <Button type="submit" color="success">Salvar mudanças!</Button>
                </footer>
              </Form>
              </main>
            </section>
          </section>
        </ContentGrid1>
        <Footer />
    </Container>
  );
}

export default Perfil;
