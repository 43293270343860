import React from 'react';

import { ErrorParagraph } from './styles';

interface IMessageErrorValidatorProps {
  animaton?: boolean;
}

const MessageErrorValidator: React.FC<IMessageErrorValidatorProps> = ({children, animaton = true}) => {
  return (
    <ErrorParagraph animaton={animaton}>
      {children}
    </ErrorParagraph>
  );
}

export default MessageErrorValidator;