import React from 'react';

import LottieAnimation from '../LottieAnimation';

import { Container } from './styles';

import connectionAnimation from '../../assets/lottie-animations/connection.json';

const ConnectionFailed: React.FC = () => {
  return (
    <Container>
      <LottieAnimation options={{animationData: connectionAnimation}}
        height={200}
        width={200}
        scale="scale(1.5)"
      />
      <h2>Poxa, estamos com problemas de conexão.</h2>
      <p>por favor, tente novamente mais tarde.</p>
    </Container>
  );
}

export default ConnectionFailed;
