import { Area } from 'react-easy-crop/types';

const createImage = (url: string) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
  image.src = url;
});

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {string} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

interface IResizeProps {
  width: number;
  height: number;
}

export default async function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0, resize: IResizeProps): Promise<any> {
  const image = await createImage(imageSrc) as OffscreenCanvas;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  image.width = image.width / 2;
  image.height = image.height / 2;

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea; // 480
  canvas.height = safeArea; // 480

  if (!ctx) {
    //setLoading(false);
    return null;
  }

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width,
    safeArea / 2 - image.height,
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height =  pixelCrop.height;

  console.log({ pixelCrop })

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width - pixelCrop.x,
    0 - safeArea / 2 + image.height - pixelCrop.y,
  );

  if (resize) {
    const oc = document.createElement('canvas')
    const octx = oc.getContext('2d');
    oc.width = resize.width
    oc.height = resize.height
    if(!octx) {
      return;
    }
    octx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resize.width, resize.height);

    return new Promise((resolve) => {
      oc.toBlob((blobFile) => {
        if (!blobFile) {
          return;
        }

        const file = new File([blobFile], 'croppedImage.jpeg', {
          lastModified: Date.now(),
          type: 'image/jpeg',
        });

        resolve(file);
      }, 'image/jpeg');
    });
  } else {
    return new Promise((resolve) => {
      canvas.toBlob((blobFile) => {
        if (!blobFile) {
          return;
        }

        const file = new File([blobFile], 'croppedImage.jpeg', {
          lastModified: Date.now(),
          type: 'image/jpeg',
        });

        resolve(file);
      }, 'image/jpeg');
    });
  }

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  /* return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  }); */
}
