import styled from 'styled-components';

interface ISmallStatisticsBoxProps {
  bgColorIcon: string;
}

export const Container = styled.div<ISmallStatisticsBoxProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  padding: 0 28px;
  border-radius: 12px;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  justify-content: start;

  > aside {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    > div {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 20px;
      background: ${props => props.bgColorIcon ? props.bgColorIcon : props.theme.colors.primary};

      > span {
        color: #fff;
      }
    }

  }

  > main {
    position: relative;

    > h3 {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1;
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      margin-top: 2px;
      color: #adafca;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
    }
  }
`;
