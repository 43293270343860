import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #161b28;
  color: #fff;
  height: 100vh;

  .lbl {
    background: #1d2333;
    transform: translate(17px, -9px) !important;
    position: absolute;
    left: 0;
    padding: 0 5px;
    color: #adafca;
    font-size: .8rem;
    font-weight: 600;
  }

  .lbk {
    padding: 1rem 1.5rem;
  }

  input, label, select {
    background: rgb(29, 35, 51);
    font-size: 14px;
    font-weight: 600;
  }


  input, select {
    color: #fff;
    padding: 0.85rem 1.5rem;
    border: 1px solid #adafca30;
    border-radius: 12px;
    transition: all .2s ease-in-out;
    width: 100%;
    height: 50px;

    &:focus {
      border-color: #615dfa;
    }
  }

  label {
    position: absolute;
    left: 0;
    font-size: .8rem;
    transform: translate(20px,-10px) !important;
    background: rgb(29,35,51);
    background: linear-gradient(180deg, rgba(22,27,40,1) 0%, rgba(29,35,51,1) 70%);
    padding: 0 5px;
    color: #adafca;
    font-weight: 600;
  }

  select {
    width: 98%;
  }

  option {
    color: #fff !important;
    padding: 1rem;
  }

  h4 {
    margin: 3rem 0 2rem 0;
  }

  /* TABLET */
  @media (max-width: 768px) {
    section {
      display: flex;
      flex-direction: column;
    }
  }

  /* MOBILE */
  @media (max-width: 480px) {

  }

  > section {
    width: 100%;
    max-width: 70rem;
    margin: 0px auto;
    padding: 3.5rem 1.5rem;

    > header {
      text-align: center;

      > img {
        width: 220px;
        margin-bottom: 1rem;
      }
    }

    > main {

      #lblll {
        background: rgb(29, 35, 51);
      }


      > form {

        > div {
          position: relative;
          display: flex;
          margin-bottom: 1.5rem;

          > button {
            width: 100%;
            padding: 1rem 0.4rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
`;

export const BankContianer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1.5fr 0.8fr 1fr;
  margin-bottom: 1rem;
`;

export const SocialNetworksContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
`;

export const NameContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr 0.7fr;
  margin-bottom: 2rem;
`;

export const SecondContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1fr 1fr;
  margin-bottom: 1rem;
`;

export const PasswordContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
`;

export const FormControl = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const SuccessContainerModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  color: #3e3f5e;

  > h1 {
    margin-bottom: 1rem;
  }

  > p {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  > a {
    width: 100%;
    padding: 1rem 0.4rem;
    margin-top: 1rem;
    background: #3a3fb9;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    text-decoration: none;

    border: none;
    border-radius: 10px;
    font-weight: bold;
    align-items: center;
    box-shadow: 4px 7px 12px 0 rgb(97 93 250 / 20%);
    font-size: 1rem;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
    background: #6e61c6;
    color: #fff;
  }
  `;

export const FooterConainer = styled.footer`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;

  > input[type='checkbox'] {
    width: 18px;
    border: none !important;
    cursor: pointer;
  }

  > p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    > a {
      color: #6f6df7;
      margin: 0 4px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
