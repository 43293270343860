import styled from 'styled-components';

interface Props {
  visible: boolean;
}

export const Container = styled.div`
  width: 100%;
`;

export const ContentGrid0 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 2fr 1fr;

  /* LAPTOP */
  @media (max-width: 1350px) {
    display: flex;
    flex-direction: column-reverse;
  }
  /* TABLET */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    position: relative;
    display: flex;
    justify-content: center;
    background: blue;
    padding: 28px;
    border-radius: 12px;
    background: ${props => props.theme.colors.bgBox};
    box-shadow: ${props => props.theme.colors.shadowBox};
    color: ${props => props.theme.colors.textColor};
    overflow: hidden;

    > main:nth-child(1) {
      width: 100%;
      display: flex;
      flex-direction: column;

      > form {

        > h1 {
          margin-bottom: 1rem;
        }

        > footer {

          > button {
            width: 100%;
            height: 48px;
            padding: 0 1.2rem;
            border-radius: 8px;
            color: #ffffff;
            font-size: .875rem;
            font-weight: 700;
            text-align: center;
            line-height: 48px;
            cursor: pointer;
            text-decoration: none;
            transition: all .2s ease-in-out;
            align-items: center;
            justify-content: center;

            &:hover {
              padding: 0 1.2rem;
            }
          }

          > p {
              text-align: center;
              margin-top: 2rem;
              color: #8f91ac;
              font-weight: 600;
              font-size: .75rem;
          }
        }
      }

    }
  }

  > section:nth-child(2) {
    display: flex;
    position: relative;

    > main {
      position: sticky;
      top: calc(80px + 1rem);
      width: 100%;

      > div {
        position: sticky;
        top: calc(80px + 1rem);
        padding: 2px 0;
      }
    }
  }
`;

export const Loader = styled.div<Props>`
  //background: ${props => props.theme.colors.bgAnimation};
  background: red;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

export const FakeDropZone = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 420px;
  padding: 1rem;
  border-style: dashed;
  color: #adafca;
  border-color: #adafca;
  border-width: 2px;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all .3s ease-in-out;

  > p {
    text-align: center;
  }
`;
