import React, {useEffect, useState} from 'react';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import UserAvatar from '../../components/UserAvatar';
import SelectInput from '../../components/SelectInput';
import Loader from '../../components/Loader';


import { Container } from './styles';

interface IRankingProps {
  ranking?: {
    user: {
      id: string;
      first_name: string;
      last_name: string;
      nickname: string;
      avatar: string;
      exp: number;
      is_public: boolean;
      full_name: string;
      role_name: string;
      show_name: string;
      level: number;
      current_exp: number;
      avatar_url: string;
      cover_url: string;
    };
    points: number;
  }[];
  userPosition?: {
    position: number;
    points: number;
  };
}

const RankingBox: React.FC = () => {
  const { user } = useAuth();

  const [ranking, setRanking] = useState<IRankingProps>({} as IRankingProps);
  const [rankingFilter, setRankingFilter] = useState('year');
  const [loading, setLoading] = useState(false);


  // List ranked users
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.post('/ranking',
          {filter: rankingFilter}
        );

        console.log('LIST RANKING >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setRanking(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [rankingFilter]);

  const rankingFilterValues = [
    {label: 'Este ano', value: 'year'},
    {label: 'Este mês', value: 'month'},
  ];

  return (
      <Container>
        <header>
          <h4>Ranking</h4>
          <SelectInput options={rankingFilterValues} onChange={(e) => {setRankingFilter(e.target.value)}} showLabel={true} selected={rankingFilter} defaultValue={rankingFilter} />
        </header>
        <main>
          <ul>
          {loading && <Loader isFixed={false} />}
            {ranking && ranking.ranking?.map((user, index) => (
                <li key={index}>
                  <p>#{index + 1}</p>
                  <aside>
                    <UserAvatar size="sm" totalExp={user.user?.exp} picture={user.user?.avatar_url} showOnlyHehaxon={false} />
                  </aside>
                  <div>
                    <h3>{user.user?.show_name}</h3>
                    <p>{(user.points).toLocaleString(['pt-BR'])} pontos acumulados</p>
                  </div>
                </li>
              ))
            }
          </ul>
          <div>
            <p>{ranking.userPosition ? '#' + ranking.userPosition.position: ''}</p>
            <aside>
              <UserAvatar size="sm" totalExp={user.exp} picture={user.avatar_url} showOnlyHehaxon={false}  />
            </aside>
            <div>
              <h3>{ranking.userPosition ? 'Você' : 'Você ainda não pontuou'}</h3>
              <p>{ranking.userPosition ? (ranking.userPosition.points || 0).toLocaleString(['pt-BR']) + ' pontos acumulados' : 'Realize os desafios e some pontos para o ranking'}</p>
            </div>
          </div>
        </main>
      </Container>
  );
}

export default RankingBox;
