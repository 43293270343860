import React from 'react';

import { Container } from './styles';

interface LoaderProps {
  isFixed?: boolean;
  scale?: string;
}

const LoaderPureCss: React.FC<LoaderProps> = ({ isFixed = false, scale}) => {
  return (
    <Container isFixed={isFixed} scale={scale}>
      <div>
        <span />
      </div>
    </Container>
  );
};

export default LoaderPureCss;
