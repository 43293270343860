import React, { useState, useEffect } from 'react';

import api from '../../services/api';

import { useField } from '@unform/core'
import { convertToRaw, EditorState, ContentState, convertFromRaw } from 'draft-js';
import { mdToDraftjs } from "draftjs-md-converter";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// @ts-ignore
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Icon from '../Icon';

import { Container, Erro } from './styles';

interface IWysiwygProps {
  name: string;
  placeholder?: string;
  maxLength: number;
}

const WysiwygInnerModal: React.FC<IWysiwygProps> = ({
  name,
  placeholder,
  maxLength,
}) => {
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [editor, setEditor] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    registerField({
      name: fieldName,
      setValue(_: any, value: string) {
        const editorState = EditorState.createWithContent(
          convertFromRaw(mdToDraftjs(value)),
        );

        setEditor(editorState);
      },
      getValue(): string {
        if (!editor) {
          return '';
        }

        const content = draftToMarkdown(convertToRaw(editor.getCurrentContent()));

        return content;
      },
      clearValue(): void {
        setEditor(EditorState.createEmpty());
      },
    })
  }, [fieldName, registerField, editor]);

  async function uploadImageCallBack(file: File) {
    try {
      if(file.size > 1024 * 1024 * 3) {
        throw new Error('exceeded-size-limit');
      }
      const data = new FormData();
      data.append("image", file);

      const response = await api.patch('/tickets', data);

      return { data: { link: response.data } };
    } catch(err) {
      console.log(err);

      if(err.message === 'exceeded-size-limit') {
        toast.error('😢 Tamanho da imagem excedido. Máximo de 3MB!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const handleEditorStateChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const oldContent = editor.getCurrentContent();

    if(contentState === oldContent || contentState.getPlainText().length <= maxLength) {
        setEditor(editorState);
    } else {
        const editorState = EditorState.moveFocusToEnd(
            EditorState.push(
                editor,
                ContentState.createFromText(oldContent.getPlainText()),
                'undo',
            )
        );
      setEditor(editorState);
    }
  }

  return (
    <>
      <Container>
        <Editor
          editorState={editor}
          stripPastedStyles={true}
          onEditorStateChange={(editorState: EditorState) => {handleEditorStateChange(editorState)}}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          handlePastedText={() => false}
          localization={{
            locale: 'pt',
          }}
          /*
          handlePastedText={(text, html, _, onChange) => {
            console.log('text', text);
            console.log('html', html);
            const editorState = EditorState.createWithContent(
              convertFromRaw(mdToDraftjs(text.replace(/ +(?= )/g,''))),
            );

            setEditor(editorState);

            return true;
          }}
          */
          placeholder={placeholder ? placeholder : ''}
          toolbar={{
            options: ['inline', 'list', 'emoji', 'image'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'],
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: false,
              defaultTargetOption: '_blank',
              options: [],
              linkCallback: () => {
                return { title: 'outro', target: 'outro', targetOption: '_self' };
              },
            },
            emoji: {
              className: 'add',
              component: undefined,
              popupClassName: undefined,
              emojis: [
                '💜','😀','😃','😄','😁','😆','😅','😂','🤣','😊','😇','🙂','🙃','😉','😌','😍','🥰','😘',
                '😗','😙','😚','😋','😛','😝','😜','🤪','🤨','🧐','🤓','😎','🤩','🥳','😏',
                '😱','🤗','🤭','😬','😲','🤠','👋','🤚','🖐','✋','🖖','👌','✌️','🤞','🤟','🤘','🤙',
                '👈','👉','👆','👇','☝️','👍','👎','👊','🤛','🤜','👏','🙌','🤝','🙏',
                '✍️','💅','💪','🧠','👀','🏆','🥇','🥈','🥉','🏅','🖊','🖋','✒️','🖌','🖍','📝','✏️',
              ],
            },
            image: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              urlEnabled: false,
              uploadEnabled: true,
              alignmentEnabled: false,
              uploadCallback: uploadImageCallBack,
              previewImage: true,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
              alt: { present: false, mandatory: false },
              defaultSize: {
                width: '100%',
              },
            },
          }}
        />
      </Container>
      {error && <Erro><Icon icon="icon-icon-info" /> {error}</Erro>}
    </>
  );
}

export default WysiwygInnerModal;
