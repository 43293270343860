import styled from 'styled-components';

interface IThemeCardProps {
  thumbnail_url: string;
}

export const Container = styled.div<IThemeCardProps>`
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.theme.colors.shadowBox};
  border-radius: 12px;
  height: 100%;

  > div {
      overflow: hidden;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

    > figure {
      height: 210px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      overflow: hidden;
      background: url(${props => props.thumbnail_url}) center center / cover no-repeat;
    }
  }

  > main {
    position: relative;
    padding: 28px 28px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 91.6666666667%;
    padding: 28px;
    margin: -48px auto 0;
    border-radius: 12px;
    background-color: ${props => props.theme.colors.bgContent};
    box-shadow: ${props => props.theme.colors.shadowAlternative};

    > button {
      background-color: ${props => props.theme.colors.primary};
      display: flex;
      height: 48px;
      border-radius: 10px;
      color: ${props => props.theme.colors.white};
      font-size: .875rem;
      font-weight: 700;
      text-align: center;
      line-height: 48px;
      cursor: pointer;
      text-decoration: none;
      transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
      align-items: center;
      justify-content: center;
      margin: 1rem 0 0 0;

      &:hover {
        background-color: ${props => props.theme.colors.info};
      }
    }

    > div > p {
      color: #adafca;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;

      > a {
        color: #adafca;
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      > button {
        color: #adafca;
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }

      > span {
        margin: 0 .25rem;
      }
    }

    > div > h2 {
      margin-top: 10px;
      font-size: 1.25rem;
      font-weight: 700;
      min-height: 76px;
      line-height: 1.2em;
      color: ${props => props.theme.colors.textColor};

      /* TABLET */
      @media (max-width: 768px) {}

      /* MOBILE */
      @media (max-width: 480px) {
        min-height: unset;
      }
    }

    > div > h2 {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2em;
    }

    > p {
      font-size: .875rem;
      line-height: 1.7142857143em;
      font-weight: 500;
      color: ${props => props.theme.colors.textColor};
    }
  }

  > footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 65px;
    margin: .5rem auto;

    > div:nth-child(1) {
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.textColor};

      > div:nth-child(1) {
        transform: scale(.8);
      }
      > div:nth-child(2) {
        margin-left: .2rem;
      }
    }

    > div:nth-child(2) {
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.textColor};
    }

    > div p {
      font-size: .75rem;
      font-weight: 700;
      line-height: 1;
    }

    > div p span {
      font-size: .625rem;
      font-weight: 500;
      line-height: 1;
    }
  }
`;

export const ModalCorrectionType = styled.div`

  > main {

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 1rem 0;

    /* TABLET */
    @media (max-width: 768px) {}

    /* MOBILE */
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    > section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #3e3f5e;
      cursor: pointer;
      padding: 32px 28px;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
      transition: all .2s ease-in-out;

      &:hover {
        transform: translateY(-.5rem);
      }

      > span {
        margin-bottom: .5rem;
      }

      > p {
        margin-top: .25rem;
        line-height: 1;
      }
    }
  }

  > footer {

    > span {
      padding: 1px 11px;
      border-radius: 40px;
    }
  }
`;
