import React, { useEffect, useState } from 'react';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import SectionBanner from '../../components/SectionBanner';
import HeaderInfo from '../../components/HeaderInfo';
import ForumCategoryCard from './ForumCategoryCard';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/forum-icon.png';

import { Container, TabsCrid, ContentGrid2 } from './styles';

interface IForumProps {
  title: string;
  description: string;
  order?: number;
  slug?: string;
  thumbnail_url: string;
  categories: {
    created_at: string;
    description: string;
    forum_id: string;
    id: string;
    order: number;
    slug: string;
    background: string;
    thumbnail: string;
    thumbnail_url: string;
    title: string;
    updated_at: string;
  }[]
}

const Forum: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const [forumData, setForumData] = useState<IForumProps>();

  // List foruns
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const forum_id = user.forums[0]?.id;
        const response = await api.get<IForumProps>(`/forums/${forum_id}`);

        console.log(response.data);
        setForumData(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.forums]);

  return(
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Fórum de discussão" subTitle="Crie tópicos e discuta com seus os colegas sobre redação e vestibulares!" />
      <HeaderInfo supTitle="Bem vindo a" title="Comunidade" margin="2rem 0 1rem 0" showSendButton={false} />
      <TabsCrid>
        <ContentGrid2>
          {
            forumData?.categories.map((category) =>
              <ForumCategoryCard
                key={category.id}
                background={category.background}
                title={category.title}
                description={category.description}
                thumbnail_url={category.thumbnail_url}
                to={`/forum/${forumData.slug}/${category.slug}/topicos`}
              />
            )}
          {loading && <Loader isFixed={false}  />}
        </ContentGrid2>
      </TabsCrid>
      <Footer />
    </Container>
  );
}

export default Forum;
