import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format, addDays, isPast, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { BackgroundImage } from 'react-image-and-background-image-fade';

import UserAvatar from '../UserAvatar';
import Toggle from '../Toggle';

import { Container, ContentGrid0, ModalPrivateContent, ViewAll, FloatValidation } from './styles';

import addZeroBefore from '../../utils/addZeroBefore';

interface IAchievementsProps {
  achievements: {
    id: string;
    icon: string;
    title: string;
    exp: number;
    description: string;
    requirement: string;
    acquired_at: string;
    icon_url: string;
  }[];
  total: number;
}

interface IProfileStatisticsProps {
  submitted_wordings: number;
  average_grade: number;
  highest_grade: number;
  times_in_hall: number;
  topics_count: number;
  tickets_count: number;
  achievements_count: number;
}

const ProfileHeader: React.FC = () => {
  const { user, updateUser } = useAuth();

  const location = useLocation();
  const localtionSplited = location.pathname.split('/')[1];

  const [achievements, setAchievements] = useState<IAchievementsProps>({} as IAchievementsProps);
  const [personalStatistics, setPersonalStatistics] = useState<IProfileStatisticsProps>({} as IProfileStatisticsProps);

  // List PERSONAL WORDING STATISTICS
  useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get<IProfileStatisticsProps>(`/profile/wordings/statistics/${user.id}`,
          {params: {exam_name: 'enem'}}
        );

        console.log('LIST PERSONAL WORDINGS STATISTICS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setPersonalStatistics(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, [user.id]);

  // Modal block change privacy
  const handleBlockChanglePrivacy = useCallback(() => {
    let MySwal = withReactContent(Swal);

    MySwal.fire({
      html: (
        <ModalPrivateContent>
          <h3 style={{textAlign: 'center', marginTop: '1rem'}}>Ops! Você alterou a visibilidade do seu perfil há menos de 15 dias.</h3>
          <p>Por enquanto, você não pode:</p>
          <ul>
            <li>Exibir suas redações no Hall da Fama</li>
            <li>Visualizar o Hall da Fama</li>
            <li>Participar do Ranking</li>
            <li>Visualizar as redações dos colegas de Plataforma</li>
          </ul>
        </ModalPrivateContent>
      ),
      background: '#6c60c3',
      backdrop: 'rgba(110, 97, 198, .1)',
      confirmButtonText: 'Ok!',
      showConfirmButton: true,
      showCloseButton: true,
    });
  }, []);

  // Modal Confirm privated profile
  const handlePrivateProfile = useCallback(() => {
    let MySwal = withReactContent(Swal);

    MySwal.fire({
      html: (
        <ModalPrivateContent>
          <h1>Visibilidade do perfil 🔓</h1>
          <p>Você está alterando a visibilidade do seu perfil. Veja abaixo o que muda:</p>
          <ul>
            <li>Suas redações não serão exibidas no Hall da Fama</li>
            <li>Você não aparecerá no Ranking</li>
            <li>Você só poderá visualizar suas redações</li>
            <li>Você não poderá visualizar o Hall da Fama</li>
            <li>Você não poderá visualizar as redações dos outros alunos da Plataforma</li>
          </ul>
          <p><strong>Ao confirmar, esta ação só poderá ser revertida após 15 dias.</strong></p>
        </ModalPrivateContent>
      ),
      background: '#6c60c3',
      backdrop: 'rgba(110, 97, 198, .1)',
      cancelButtonColor: '#d2194d',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showConfirmButton: true,
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Pronto!',
          text: 'A visibilidade do seu perfil foi alterada!',
          icon: 'success',
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          confirmButtonColor: '#03bb85',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });
        console.log('opration confirmed');
        try {
          //setLoading(true);
          const response = await api.patch(`/profile/privacy`);

          console.log('PROFILE PRIVACY >>>>>>>>>>>>');
          console.log(response.data);
          updateUser({...user,
            is_public: response.data.is_public,
            last_privacy_update: response.data.last_privacy_update,
          });

        } catch (error) {
          console.log(error);
          //setConnectionFailed(true);
        }
        finally {
          //setLoading(false);
        }
      }
      else {
        console.log('opration canceled');
      }
    });
  }, [updateUser, user]);

  // GET LAST ACHIVEMENTS BADGES
  useEffect(() => {
    async function loadData() {
      try {
        //setLoading(true);
        const response = await api.get('/achievements/last',{
          params: { quantity: 4 }
        });

        console.log('LIST ACHIVEMENTS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setAchievements(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
    }

    loadData();
  }, []);

  return (
    <Container background={user.cover_url}>
      <BackgroundImage isResponsive={false} height="300px" src={user.cover_url} lazyLoad={true} element="figure" />
      {user?.courses?.length > 0 ? (
        <FloatValidation style={{background: '#14ff4694'}}>Plano válido até {user?.courses?.length > 0 && format(parseISO(user.courses[0].end_date), "dd'/'MM'/'Y", {locale: ptBR})}</FloatValidation>
      )
        :
      (
        <FloatValidation style={{background: '#ff141494'}}>Você não tem um plano ativo</FloatValidation>
      )}
      <footer>
        <ContentGrid0>
          <section>
            <div>
              <UserAvatar size="lg" totalExp={user.exp} picture={user.avatar_url} />
            </div>
            <div>
              <h2>{user.show_name} {!user.is_public && '🔒'}</h2>
              <p>{user.career?.name}</p>
              <footer>
                {achievements && achievements.achievements?.map((achievement, index) => (
                 <div key={index}>
                  <Link to="/conquistas">
                    <img src={achievement.icon_url} alt="Últimas conquistas desbloqueadas" title="Últimas conquistas desbloqueadas" />
                  </Link>
                  </div>
                ))}
                {(achievements && achievements?.achievements?.length > 0) && <ViewAll to="/minhas-conquistas">{localtionSplited !== 'minhas-conquistas' && 'ver todas'}</ViewAll>}
              </footer>
            </div>
          </section>
          <section>
            <aside>
              {<Toggle
                labelLeft=""
                labelRight=""
                checked={user.is_public}
                onChange={!user.last_privacy_update || isPast(addDays(parseISO(user.last_privacy_update), 15)) ? handlePrivateProfile : handleBlockChanglePrivacy}
              />}
              <h4>Perfil<br />{user.is_public ? 'Público' : 'Privado'}</h4>
            </aside>
            <aside>
              <p>{addZeroBefore(personalStatistics.submitted_wordings)}</p>
              <h4>Redações<br />enviadas</h4>
            </aside>
            <aside>
              <p>{personalStatistics.average_grade && addZeroBefore(personalStatistics.average_grade.toFixed(0))}</p>
              <h4>Nota<br />média</h4>
            </aside>
            <aside>
              <p>{addZeroBefore(personalStatistics.topics_count)}</p>
              <h4>Interações<br />no fórum</h4>
            </aside>
            <aside>
              <p>{addZeroBefore(personalStatistics.times_in_hall)}</p>
              <h4>vezes no<br />Hall da Fama</h4>
            </aside>
          </section>
        </ContentGrid0>
      </footer>
    </Container>
  );
}

export default ProfileHeader;
