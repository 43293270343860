import styled from 'styled-components';

interface IWordingContentProps {
  fontSize: string;
}

export const Main = styled.main<IWordingContentProps>`
  box-shadow: ${props => props.theme.colors.shadowBox};
  text-align: justify;
  position: relative;
  padding: 30px 5px 0px 50px;
  box-shadow: ${props => props.theme.bgBox};
  border-radius: 12px;
  background: white;
  background-image: repeating-linear-gradient(white 0px, white 24px, steelblue 25px);
  background-size: 100% 30px;
  color: #3e3f5e;
  line-height: 30px;
  font-weight: 600;
  min-height: 929px;
  font-size: ${props => props.fontSize}px;
  width:890px;
  resize: vertical;

  ::placeholder {
    color: #adafca;
  }

  /* TABLET */
  @media (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 999px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* > div {
    text-indent: 28px;
  } */
`;
