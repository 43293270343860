import styled from 'styled-components';

interface ContainerProps {
  background: string;
  color: string;
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: inline;
  position: relative;
  border-radius: 4px;
  background: ${({ isActive, background }) =>
    isActive ? background : background}80;
  color: ${({ color }) => color};
  cursor: pointer;
`;
