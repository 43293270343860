import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {}

  /* MOBILE */
  @media (max-width: 480px) {}

`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: 1fr;

  border-radius: 12px;
  padding: 0 0 34px 0;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadow};

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Topcs = styled.div`

  > section {
    display: flex;
    display: grid;
    margin: 2rem 0 -7px 0;
    align-items: center;
    grid-template-columns: 200px auto;

    /* MOBILE */
    @media (max-width: 480px) {
      display: none;
    }

    > aside {
      > h5 {
        font-size: .75rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #adafca;
      }
    }

    > aside:nth-child(1) {
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }

    > aside:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 2rem;

      /* MOBILE */
      @media (max-width: 480px) {
        justify-content: start;
      }
    }
  }
`;

export const ContentGrid2 = styled.div`

`;

export const Post = styled.div`

  > div {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    line-height: 1;
    background: ${props => props.theme.colors.bgContent}90;

    > p {
      color: #8f91ac;
      font-size: .875rem;
      font-weight: 500;
    }

    > div {
      display: flex;
      justify-content: end;
      align-items: center;

      > button {
        background: none;
        transition: all .3s ease-in-out;
        font-weight: 700;
        margin-left: 1rem;
        color: #da3d3d;

        &:hover {
          color: #a54a4a;
        }

        /* SMALL MOBILE */
        @media (max-width: 480px) {
         font-size: 10px;
        }
      }

      > a {
        font-size: .75rem;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        transition: all .3s ease-in-out;
        color: ${props => props.theme.colors.textColor};
        margin-left: 1rem;

        &:hover {
          color: ${props => props.theme.colors.primary};
        }

        /* SMALL MOBILE */
        @media (max-width: 480px) {
         font-size: 10px;
        }
      }
    }
  }

  > section {
    display: grid;
    grid-gap: 2rem;
    margin: 1rem 0 0 0;
    grid-template-columns: 200px auto;

    /* MOBILE */
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }

    > aside {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      padding: 0 .5rem;

      > h3 {
        color: ${props => props.theme.colors.textColor};
        font-size: .875rem;
        font-weight: 700;
      }

      > p {
        font-size: .75rem;
        color: #8f91ac;
        font-weight: 500;
      }

      > ol, ul {
        padding-left: 1rem;
      }
    }

    > main {
      padding: 0 28px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      /* MOBILE */
      @media (max-width: 480px) {
        padding: 0 28px;
      }

      > h2 {
        margin-bottom: 1rem;
      }

      > p {
        font-size: 1rem;
        line-height: 1.7142857143em;
        font-weight: 500;
        margin-bottom: 1rem;

        > img {
          display: block;
          margin: 24px 0;
          width: 70%;
          height: auto;
          border-radius: 12px;

          /* MOBILE */
          @media (max-width: 480px) {
            width: 100%;
          }
        }
      }

      > ol, ul {
        padding-left: 1rem;
      }

      > footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0 0 0;
        border-top: 1px solid #9aa4bf25;
        margin-top: 1rem;
      }
    }
  }
`;

export const Showing = styled.p`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;

