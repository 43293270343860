import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container, Label, Error } from './styles';
import Icon from '../Icon';

interface IInputTextareaValidteProps {
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
  name: string;
  showLabel?: boolean;
  selected?: boolean;
  showPlaceholder?: boolean;
  labelText?: string;
  placeholder?: string;
  options: {
    value: string | number;
    label: string | number;
  }[];
}

const InputTextareaValidte: React.FC<IInputTextareaValidteProps> = ({options, showLabel, labelText, label, name, padding, margin, onlyWhite, placeholder, selected, ...rest}) => {

  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {
        if (!ref.value) {
          return null;
        }

        return ref.value;
      },
      setValue: (ref: any, value: any) => {
        ref.value = value || '';
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container padding={padding} margin={margin} onlyWhite={onlyWhite}>
        <select name={name} defaultValue={defaultValue} ref={inputRef} {...rest}>
          {showLabel && <option className="default" defaultValue={labelText} value="" key="777777">{placeholder ? placeholder : labelText}...</option>}
          {options.map((option, index) => (
            <option key={index} value={option.value} selected={selected}>{option.label}</option>
          ))}
        </select>
        <Icon icon="icon-icon-big-arrow" />
        {showLabel && <Label>{labelText}</Label>}
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
}

export default InputTextareaValidte;
