import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.bgBox};
  position: absolute;
  height: 100%;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  > h2 {
    color: ${props => props.theme.colors.textColor};
    margin-top: 3rem;
  }

  > p {
    color: #8f91ac;
  }
`;
