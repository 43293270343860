import React, { useEffect, useState } from 'react';

import { useTheme } from '../../hooks/theme';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import api from '../../services/api';

import DateExtension from '@joi/date';
import * as JoiImport from 'joi';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { BankContianer, Container, FooterConainer, FormControl, NameContainer, PasswordContainer, SecondContainer, SocialNetworksContainer, SuccessContainerModal } from './styles';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import MessageErrorValidator from '../../components/MessageErrorValidator';

import cpfMask from '../../utils/cpfMask';
import dateMask from '../../utils/dateMask';
import phoneMask from '../../utils/phoneMask';

import logo from '../../assets/logo-poxalulu.svg';

export interface IBanksProps {
  ispb: string;
  name: string;
  code?: number;
  fullName: string;
}

interface opts {
  value: string | number;
  label: string | number;
}

// Validation Schema
const Joi = JoiImport.extend(DateExtension);

const CPF_RGX = RegExp(`([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})`);

const schema = Joi.object({
  first_name: Joi.string().required().messages({ '*': 'Informe um nome válido.' }),
  last_name: Joi.string().required().messages({ '*': 'Informe um sobrenome válido.' }),
  birthday: Joi.date().format("DD/MM/YYYY").utc().required().required().messages({ '*': 'Informe uma data válida.' }),
  email: Joi.string().email({ tlds: { allow: false } }).messages({ '*': 'Informe um e-mail válido.' }),
  cpf: Joi.string().pattern(CPF_RGX).required().messages({ '*': 'Informe um CPF válido.' }),
  phone_number: Joi.string().length(15).required().messages({ '*': 'Informe uma telefone válido.' }),
  password: Joi.string().min(3).max(15).required().messages({ '*': 'As senhas informadas precisam ser identicas' }),
  password_confirmation: Joi.any().valid(Joi.ref('password')).required().messages({ '*': 'As senhas informadas precisam ser identicas' }),
  bank: Joi.string().min(3).required().messages({ '*': 'Informe o seu banco' }),
  account_type: Joi.string().required().messages({ '*': 'Informe um tipo válido.' }),
  agency: Joi.string().min(3).required().messages({ '*': 'Informe uma agência válida' }),
  account: Joi.string().min(3).required().messages({ '*': 'Informe uma conta válida' }),
  instagram: Joi.string().min(0).messages({ '*': 'Informe uma conta válida' }),
  tiktok: Joi.string().min(0).messages({ '*': 'Informe uma conta válida' }),
  youtube: Joi.string().min(0).messages({ '*': 'Informe uma conta válida' }),
  terms: Joi.boolean().required().invalid(false).messages({ '*': 'Por favor, aceite os termos para prosseguir com o cadastro' }),
});


const CadastroAfiliados: React.FC = () => {
  const { toggleTheme, theme } = useTheme();

  const [cpfMasked, setCpfmasked] = useState('');
  const [dateMasked, setDatemasked] = useState('');
  const [phoneMasked, setPhonemasked] = useState('');


  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  useEffect(() => {
    const htmll = document.querySelector('html');
    if (htmll instanceof HTMLElement) htmll.style.background = '#161b28';

    if (theme.title === 'light') {
      toggleTheme()
    }
  }, [theme.title, toggleTheme]);


  const [bankOptions, setBankOptions] = useState<opts[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get<IBanksProps[]>('https://brasilapi.com.br/api/banks/v1');
        console.log(response.data);

        const dataBanks: IBanksProps[] = response.data;

        setBankOptions(dataBanks.map((bank) => {
          return {
            value: bank.code ? `${bank.code} - ${bank.name}` : `${bank.name}`,
            label: bank.code ? `${bank.code} - ${bank.name}` : `${bank.name}`,
          }
        }));

      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await api.post('/affiliates/subscribe', {
        first_name: data.first_name,
        last_name: data.last_name,
        birthday: data.birthday,
        email: data.email,
        cpf: data.cpf,
        phone_number: data.phone_number,
        password: data.password,
        bank: data.bank,
        agency: data.agency,
        account: data.account,
        instagram: data.instagram,
        tiktok: data.tiktok,
        youtube: data.youtube,
      });

      let modal = withReactContent(Swal);
      modal.fire({
        html: (
          <SuccessContainerModal>
            <h1>Prontinho, {data.first_name}! 😊</h1>
            <p>
             <strong>Agora é tudo com a gente, ok?</strong>
            </p>
            <p>
              Seu cadastro está sendo validado pelo time de vendas. Assim que aprovado, <strong>entraremos em contato via Whatsapp</strong> para alinhar os próximos passos e liberar o seu acesso à plataforma de vendas, ok?
            </p>

            <a href="https://poxalulu.com.br" rel="noreferrer">Ok, entendi!</a>
          </SuccessContainerModal>
        ),
        background: '#fff',
        backdrop: 'rgba(110, 97, 198, .1)',
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })

      console.log(response.data);
    } catch (error) {
      console.log(error);

      toast.error('🤷‍♂️ Ops! Algo deu errado. Contate o suporte técnico!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  });

  return (
    <Container>
      <section>
        <header>
          <img src={logo} alt="" />
          <p>
            Seja bem-vindo ao pré-cadastro para o processo seletivo da plataforma de vendas da Plataforma Poxalulu.
            <br />
            Preencha com seus dados e enviaremos um e-mail com a confirmação do cadastro.
          </p>
        </header>
        <main>
          <h4><Icon icon="icon-icon-members-1" margin="0 6px 0 0" /> Insira seus dados abaixo: *</h4>

          <form onSubmit={onSubmit}>
            <NameContainer>
              <FormControl>
                <input {...register("first_name")} type="text" />
                <label>Nome *</label>
                {errors.first_name && <MessageErrorValidator>{errors.first_name.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("last_name")} type="text" />
                <label>Sobrenome completo *</label>
                {errors.last_name && <MessageErrorValidator>{errors.last_name.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("birthday")}
                  type="text"
                  onChange={(event) => setDatemasked(dateMask(event.target.value))}
                  value={dateMasked}
                />
                <label>Data de nascimento *</label>
                {errors.birthday && <MessageErrorValidator>{errors.birthday.message}</MessageErrorValidator>}
              </FormControl>
            </NameContainer>
            <SecondContainer>
              <FormControl>
                <input {...register("email")} type="email" />
                <label>E-mail *</label>
                {errors.email && <MessageErrorValidator>{errors.email.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("cpf")}
                  type="text"
                  onChange={(event) => setCpfmasked(cpfMask(event.target.value))}
                  value={cpfMasked}
                />
                <label>CPF *</label>
                {errors.cpf && <MessageErrorValidator>{errors.cpf.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("phone_number")}
                  type="text"
                  onChange={(event) => setPhonemasked(phoneMask(event.target.value))}
                  value={phoneMasked}
                />
                <label>Telefone (WhatsApp) *</label>
                {errors.phone_number && <MessageErrorValidator>{errors.phone_number.message}</MessageErrorValidator>}
              </FormControl>
            </SecondContainer>

            <h4><Icon icon="icon-icon-private" margin="0 6px 0 0" /> Defina uma senha para acessar a plataforma: *</h4>

            <PasswordContainer>
              <FormControl>
                <input {...register("password")} type="password" />
                <label>Senha*</label>
                {errors.password && <MessageErrorValidator>{errors.password.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("password_confirmation")} type="password" />
                <label>Confrimar senha *</label>
                {errors.password_confirmation && <MessageErrorValidator>{errors.password_confirmation.message}</MessageErrorValidator>}
              </FormControl>
            </PasswordContainer>

            <h4><Icon icon="icon-icon-wallet" margin="0 6px 0 0" /> Dados bancários para recebimento das comissões: *</h4>

            <BankContianer>
              <FormControl style={{ position: 'relative' }}>
                <input {...register("bank")} list="banks" />
                <datalist id="banks">
                  {bankOptions && bankOptions.map((bank, index) => (
                    <option key={index} value={bank.label} />
                  ))}
                </datalist>
                <label>Banco *</label>
                {errors.bank && <MessageErrorValidator>{errors.bank.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <select {...register("account_type")}>
                  <option value="Conta Corrente" selected>Conta corrente</option>
                </select>
                <label>Tipo de conta *</label>
                {errors.account_type && <MessageErrorValidator>{errors.account_type.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("agency")} type="text" />
                <label>Agência*</label>
                {errors.agency && <MessageErrorValidator>{errors.agency.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("account")} type="text" />
                <label>Número da conta *</label>
                {errors.account && <MessageErrorValidator>{errors.account.message}</MessageErrorValidator>}
              </FormControl>
            </BankContianer>

            <h4><Icon icon="icon-icon-thumbs-up" margin="0 6px 0 0" /> Redes sociais:</h4>

            <SocialNetworksContainer>
              <FormControl>
                <input {...register("instagram")} type="text" />
                <label>Instagram</label>
                {errors.instagram && <MessageErrorValidator>{errors.instagram.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("tiktok")} type="text" />
                <label>Tiktok</label>
                {errors.tiktok && <MessageErrorValidator>{errors.tiktok.message}</MessageErrorValidator>}
              </FormControl>
              <FormControl>
                <input {...register("youtube")} type="text" />
                <label>Youtube</label>
                {errors.youtube && <MessageErrorValidator>{errors.youtube.message}</MessageErrorValidator>}
              </FormControl>
            </SocialNetworksContainer>

            <FooterConainer>
              <input type="checkbox" {...register("terms")} />
              <p>Li, estou ciente e aceito os <a href="https://poxalulu.com.br/termos-programa-de-afiliados.html" target="_blank" rel="noreferrer">Termos e Condições de Uso</a> do programa de Afiliado da Plataforma Poxalulu.</p>
            </FooterConainer>
            {errors.terms && <MessageErrorValidator>⛔ {errors.terms.message}</MessageErrorValidator>}

            <div>
              <Button type="submit" color="primary">Quero ser afiliado</Button>
            </div>
          </form>
        </main>
      </section>
    </Container >
  );
}

export default CadastroAfiliados;
