import styled from 'styled-components';
interface IBadgeItemCardProps {
  status: 'pending' | 'concluded';
}

export const Container = styled.div<IBadgeItemCardProps>`
  position: relative;
  align-items: center;
  border-radius: 12px;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: ${props => props.status === 'concluded' ? props => props.theme.colors.shadowBox : 'none'};
  border: ${props => props.status === 'concluded' ? 'none' : '2px dashed #8f91ac30'};
  color: ${props => props.status === 'concluded' ? props => props.theme.colors.textColor : props => props.theme.colors.textColor + 50};


  > div {
    display: flex;
    position: absolute;
    background: red;
    font-size: 14px;
    right: -7px;
    top: 1rem;
    font-weight: bold;
    align-items: center;
    padding: 13px 16px;
    border-radius: 3rem;
    line-height: 0;
    background: ${props => props.theme.colors.bgBox};
    box-shadow: 3px 5px 20px 0 rgba(94,92,154,.12);
    font-size: .875rem;
  }

  > header {

    > div {
      width: 82px;
      height: 90px;

      img {
        filter: ${props => props.status === 'concluded' ? 'none' : 'opacity(0.2) saturate(0)'};
      }
    }
  }

  > main {

    > h1 {
      margin-top: 2rem;
      font-size: 1.125rem;
      font-weight: 700;
      text-align: center;
      min-height: 54px;

      /* MOBILE */
      @media (max-width: 480px) {
        min-height: auto;
      }
    }

    > p {
      display: flex;
      justify-content: center;
      margin: 16px auto 0;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.4285714286em;
      text-align: center;
      height: 80px;

      /* MOBILE */
      @media (max-width: 480px) {
        height: auto;
      }
    }
  }

  > footer {
    margin-top: 2rem;

    > p {

      > span {
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 1.6rem;
        color: ${props => props.status === 'concluded' ? 'none' : props => props.theme.colors.textColor}40;
        background: ${props => props.theme.colors.bgContent}80;
        border: 1px solid ${props => props.theme.colors.bgContent};
        padding: 4px 10px;
        border-radius: 25px;
      }
    }
  }
`;
