import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import api from '../../services/api';

import ReactMarkdown from 'react-markdown';

import Icon from '../../components/Icon';
import Breadcrumbs from '../../components/Breadcrumbs';
import UserAvatar from '../../components/UserAvatar';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import profilePic from '../../assets/poxa-lulu-avatar.png';

import { Container, ContentGrid2, AbsoluteHeader, SocialBtn, VoteButton } from './styles';

export interface IArticleCategoryProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
  created_at: string;
  updated_at: string;
}


interface IArticleProps {
  id: string;
  section_id: string;
  title: string;
  body: string;
  slug: string;
  is_promoted: boolean;
  abstract: string;
  vote_count: number;
  vote_sum: number;
  created_at: string;
  updated_at: string;
  reading_time: string;
}

const SuportePost: React.FC = () => {
  const { slug, article_slug } = useParams<{ slug: string, article_slug: string }>();

  const [loading, setLoading] = useState(true);
  const [userVote, setUserVote] = useState<'upvote' | 'downvote'>();

  const [articleCategory, setArticleCategory] = useState<IArticleCategoryProps>({} as IArticleCategoryProps);
  const [article, setArticle] = useState<IArticleProps>({} as IArticleProps);
  const [releatedArticle, setReleatedArticle] = useState<IArticleProps[]>([]);

  // List ARTICLE CATEGORY FOR BREADCUBE
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/sections/${slug}`);

        console.log('LIST CATEGORY  >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setArticleCategory(response.data);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }

    loadData();
  }, [slug, article_slug]);

  // List SINGLE ARTICLE
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/sections/${slug}/articles/${article_slug}`);

        console.log('LIST ARTICLE >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setArticle(response.data);

        if(response.data.userVote) {
          setUserVote(response.data.userVote.type);
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, article_slug]);

  // List RELEATED ARTICLES
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/sections/${slug}/articles/related`,
          {params: {limit: 5}}
        );

        console.log('LIST RELEATED ARTICLES  >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setReleatedArticle(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, article_slug]);


  const handleUtilArticle = useCallback(async (type: 'upvote' | 'downvote') => {
    try {
      const response = await api.post(`/sections/${slug}/articles/${article_slug}/vote`, {
        "type": type
      });

      console.log(response.data);
      setUserVote(type);
    } catch(error) {
      console.log(error);
    } finally {
      //
    }
  }, [article_slug, slug]);

  return(
    <>
      <AbsoluteHeader />
      <Container>
        <ContentGrid2>
          <section>
            {loading && <Loader isFixed={false}  />}
            <h2><span>{article.reading_time}</span></h2>

            <h1>{article.title}</h1>
            <Breadcrumbs>
              <ol>
                  <li title="Central de Ajuda">
                    <Link to={`/suporte`}>Central de ajuda</Link>
                  </li>
                  <li title={articleCategory.name}>
                    <Link to={`/suporte/artigos/${articleCategory.slug}`}>{articleCategory.name}</Link>
                  </li>
                  <li title={article.title}>
                    {article.title}
                  </li>
              </ol>
            </Breadcrumbs>
            <main>
              <div>
                <UserAvatar size="sm" totalExp={10000} picture={profilePic} showOnlyHehaxon={false} />
                <SocialBtn>
                  <a href="https://youtube.com/poxalulu" target="_blank" rel="noreferrer" style={{color: '#f00'}}>
                    <Icon icon="icon-icon-youtube" />
                  </a>
                </SocialBtn>
                <SocialBtn>
                  <a href="https://instagram.com/poxalulu" target="_blank" rel="noreferrer" style={{color: '#ed4956'}}>
                    <Icon icon="icon-icon-instagram" />
                  </a>
                </SocialBtn>
                <SocialBtn>
                  <a href="https://t.me/poxaluluredacao" target="_blank" rel="noreferrer" style={{color: '#1abcff'}}>
                    <Icon icon="icon-icon-send-message" />
                  </a>
                </SocialBtn>
              </div>
              <div>
                <ReactMarkdown>{article.body}</ReactMarkdown>
              </div>
            </main>
            <footer>
              <h4>Esse artigo foi útil?</h4>
              <div>
                <div>
                  <VoteButton onClick={() => handleUtilArticle('upvote')} isActive={userVote === 'upvote'} disabled={!!userVote}>Sim 👍</VoteButton>
                  <VoteButton onClick={() => handleUtilArticle('downvote')} isActive={userVote === 'downvote'} disabled={!!userVote}>Não 👎</VoteButton>
                </div>
                <div>
                  {userVote && <p>obrigado pelo seu feedback!</p>}
                </div>
              </div>
              <p>Tem mais dúvidas? <Link to="/suporte/tickets">Envie uma solicitação!</Link></p>
            </footer>
          </section>
          <section>
            <h2>Artigos nessa seção</h2>
            <section>
              {releatedArticle.map((article, index) => (
                <Link to={`/suporte/artigos/${slug}/${article.slug}/`} key={index} className={article.slug === article_slug ? 'active' : ''}>
                  <h2>{index + 1}.</h2>
                  <h1>{article.title}</h1>
                  <p>{article.abstract}</p>
                  {
                    article.slug === article_slug ?
                    <h6>você está lendo este artigo</h6>
                    :
                    <>
                      <Link to={`/suporte/artigos/${slug}/${article.slug}/`}> <Icon icon="icon-icon-magnifying-glass" margin="0 6px 0 0" /> visualizar artigo</Link>
                    </>
                  }
                </Link>
              ))}
            </section>
          </section>
        </ContentGrid2>
        <Footer />
      </Container>
    </>
  );
}

export default SuportePost;
