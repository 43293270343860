import styled, { css } from 'styled-components';

interface ContainerProps {
  cancel_reason?: string;
  status:
    | 'canceled'
    | 'pending'
    | 'in_correction'
    | 'in_confirmation'
    | 'corrected'
    | 'none';
}

const buttonColorVariations = {
  canceled: css`
    background: #ff0000;
    color: #fff;
    top: 0;
    cursor: not-allowed;

    &:hover {
      background: #ff0000;
    }
  `,
  pending: css`
    background: #de8020;
    color: #fff;
    cursor: help;

    &:after {
      position: absolute;
      content: 'Sua redação tem até 3 dias úteis para ser corrigida!';
      background: ${props => props.theme.colors.bgBox};
      padding: .25rem .8rem;
      color: ${props => props.theme.colors.textColor};
      box-shadow: ${props => props.theme.colors.shadowBox};
      border-radius: 20px;
      opacity: 0;
      top: -10px;
      transition: all .2s ease-in-out;
    }

    &:hover {
      background: #de8020;

      &:after {
        opacity: 1;
      }
    }
  `,
  in_correction: css`
    background: #de8020;
    color: #fff;
    cursor: help;

    &:after {
      position: absolute;
      content: 'Sua redação tem até 3 dias úteis para ser corrigida!';
      background: ${props => props.theme.colors.bgBox};
      padding: .25rem .8rem;
      color: ${props => props.theme.colors.textColor};
      box-shadow: ${props => props.theme.colors.shadowBox};
      border-radius: 20px;
      opacity: 0;
      top: -10px;
      transition: all .2s ease-in-out;
    }

    &:hover {
      background: #de8020;

      &:after {
        opacity: 1;
      }
    }
  `,
  in_confirmation: css`
    background: #de8020;
    color: #fff;
    cursor: help;

    &:after {
      position: absolute;
      content: 'Sua redação tem até 3 dias úteis para ser corrigida!';
      background: ${props => props.theme.colors.bgBox};
      padding: .25rem .8rem;
      color: ${props => props.theme.colors.textColor};
      box-shadow: ${props => props.theme.colors.shadowBox};
      border-radius: 20px;
      opacity: 0;
      top: -10px;
      transition: all .2s ease-in-out;
    }

    &:hover {
      background: #de8020;

      &:after {
        opacity: 1;
      }
    }
  `,
  corrected: css`
    background: ${props => props.theme.colors.success};
    color: #fff;
    &:hover {
      background: ${props => props.theme.colors.success};
    }
  `,
  none: css`
    background: none;
    color: ${props => props.theme.title};
    &:hover {
      opacity: 0.9;
    }
  `,
};

export const Container = styled.div<ContainerProps>`

  > a {
    display: inline-flex;
    border: none;
    border-radius: 10px;
    width: 155px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    padding: 0.8rem 0;
    align-items: center;
    box-shadow: ${props => props.theme.colors.shadowBox};
    font-size: .75rem;
    text-decoration: none;
    ${props => buttonColorVariations[props.status]};

    /* MOBILE */
    @media (max-width: 480px) {
      transform: scale(0.75);
    }
  }

  svg {
    margin-right: 8px;
  }
`;
