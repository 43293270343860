import styled from 'styled-components';

import boldIcon from '../../assets/wysiwyg-icons/bold.svg';
import italicIcon from '../../assets/wysiwyg-icons/italic.svg';
import underlineIcon from '../../assets/wysiwyg-icons/underline.svg';
import listIcon from '../../assets/wysiwyg-icons/list.svg';
import numberListIcon from '../../assets/wysiwyg-icons/list-1.svg';
import linkIcon from '../../assets/wysiwyg-icons/link.svg';
import unLinkIcon from '../../assets/wysiwyg-icons/unlink.svg';
import emojiIcon from '../../assets/wysiwyg-icons/emoji.svg';
import imageIcon from '../../assets/wysiwyg-icons/image.svg';

export const Container = styled.div`
  border-radius: 12px;
  background: ${props => props.theme.colors.bgBox};
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  margin-top: 60px;

  .rdw-image-modal-size {
    display: none;
  }

  .rdw-image-modal-upload-option-label {
    text-align: center;
  }

  .rdw-image-modal-header-option {
    width: 100%;
    text-transform: uppercase;
  }

  .rdw-image-modal-header-label-highlighted {
    background: none;
  }

  .rdw-image-modal-btn-section {
    display: flex;
    margin: .5rem 0 0 0;
    justify-content: center;
  }

  .rdw-image-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 1.15rem .4rem;
    font-weight: bold;
    align-items: center;
    //box-shadow: 4px 7px 12px 0 rgb(97 93 250 / 20%);
    font-size: 1rem;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
    width: 100%;
    border-color: none;

    &:hover {
      box-shadow: none;
    }
  }

  .rdw-image-modal-btn:nth-child(1) {
    background: #6e61c6;
    color: #fff;
  }

  .rdw-image-modal-btn:nth-child(2) {
    background: transparent;
    color: ${props => props.theme.colors.textColor};
    border: 1px solid #3e3f5e;
  }

  .rdw-image-modal-btn:disabled {
    background: #ccc;
    color: #fff;
    border: 1px solid #ccc;
    cursor: not-allowed;
  }

  .rdw-image-modal-upload-option-label {
    color: ${props => props.theme.colors.textColor};
    width: 100%;
    font-weight: 700;
  }

  .rdw-image-modal-header-option {
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .public-DraftEditor-content[contenteditable=true] {
    color: ${props => props.theme.colors.textColor};
  }
  .rdw-image-modal {
    background: ${props => props.theme.colors.bgBox};
    color: ${props => props.theme.colors.textColor};
  }

  .rdw-image-modal-upload-option {
    background: ${props => props.theme.colors.bgBox};
    outline: 2px dashed #adafca;
  }

  .public-DraftEditorPlaceholder-root {
    color: #adafca;
    font-weight: 600;
    position: absolute;
    z-index: 0;
  }
  .DraftEditor-root {
    padding: 1.5rem 1.5rem;
    min-height: 200px;
    overflow-y: scroll;
    max-height: 200px;
    cursor: text;

    &::-webkit-scrollbar {
      visibility: hidden;
      width: 4px;
      height: 4px;
    }

    &:hover::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #cccccc80;
      border-radius: 10px;
    }
    &:hover::-webkit-scrollbar-thumb:hover{

    }
    &:hover::-webkit-scrollbar-track{
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #cccccc10;
    }
  }
  .DraftEditor-editorContainer {
    margin-top: -1rem;
    font-weight: 600;
  }

  .public-DraftEditor-content[contenteditable=true] {
    color: ${props => props.theme.colors.textColor};
  }

  .rdw-editor-toolbar {
    border: none;
  }

  .rdw-option-wrapper {
    border: none;
    padding: 10px;
    border-radius: 50%;
    margin: 0 4px;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* background: #c0c2d6; */
    background: transparent;
    text-transform: capitalize;
    transition: all .3s ease-in-out;

    &:hover {
      background: #c0c2d6;
      box-shadow: none;
    }
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }

  .public-DraftEditorPlaceholder-inner {
    margin-top: -1rem !important;
  }

  .editorClassName, .toolbarClassName {
    background: ${props => props.theme.colors.textAreaWizColor};
    margin-bottom: 0;
    padding-top: 8px;
  }

  .rdw-emoji-modal, .rdw-link-modal, .rdw-image-modal {
    border: none;
    top: 42px;
    border-radius: 8px;
    justify-content: space-between;
    background: ${props => props.theme.colors.bgBox};
    box-shadow: ${props => props.theme.colors.shadowBox};

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &:hover::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #cccccc80;
      border-radius: 10px;
    }
    &:hover::-webkit-scrollbar-thumb:hover{

    }
    &:hover::-webkit-scrollbar-track{
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #cccccc10;
    }
  }

  .rdw-inline-wrapper {
    > div {
      color: ${props => props.theme.colors.textColor};

     > img {
        display: none;
        border: none;
      }

      &:nth-of-type(1) {
        &:after {
            /* Bold */
            content: url(${boldIcon});
            height: 16px;
            width: 16px;
            filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%);
        }
      }
      &:nth-of-type(2) {
        &:after {
            /* Italic */
            content: url(${italicIcon});
            height: 16px;
            width: 16px;
            filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%);
        }
      }
      &:nth-of-type(3) {
        &:after {
            /* underline */
            content: url(${underlineIcon});
            height: 16px;
            width: 16px;
            filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%);
        }
      }
    }
  }

  .rdw-list-wrapper {

    > div {
      color: ${props => props.theme.colors.textColor};

     > img {
        display: none;
        border: none;
      }

      &:nth-of-type(1) {
        &:after {
          /* list nao numerada */
          content: url(${listIcon});
          height: 16px;
          width: 16px;
          filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%)
        }
      }
      &:nth-of-type(2) {
        &:after {
          /* list numerada */
          content: url(${numberListIcon});
          height: 16px;
          width: 16px;
          filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%)
        }
      }
    }
  }

  .rdw-link-wrapper {

    > div {
      color: ${props => props.theme.colors.textColor};

     > img {
        display: none;
        border: none;
      }

      &:nth-of-type(1) {
        &:after {
          /* list nao numerada */
          content: url(${linkIcon});
          height: 16px;
          width: 16px;
          filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%)
        }
      }
      &:nth-of-type(2) {
        &:after {
          /* list numerada */
          content: url(${unLinkIcon});
          height: 16px;
          width: 16px;
          filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%)
        }
      }
    }
  }

  .rdw-emoji-wrapper {

    > div {
      color: ${props => props.theme.colors.textColor};

     > img {
        display: none;
        border: none;
      }

      &:nth-of-type(1) {
        &:after {
          /* list nao numerada */
          content: url(${emojiIcon});
          height: 16px;
          width: 16px;
          filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%)
        }
      }
    }
  }

  .rdw-image-wrapper {

    > div {
      color: ${props => props.theme.colors.textColor};

     > img {
        display: none;
        border: none;
      }

      &:nth-of-type(1) {
        &:after {
          /* list nao numerada */
          content: url(${imageIcon});
          height: 16px;
          width: 16px;
          filter: invert(24%) sepia(29%) saturate(558%) hue-rotate(199deg) brightness(97%) contrast(95%)
        }
      }
    }
  }


  > header {
    display: flex;
    align-items: center;
    min-height: 82px;
    padding-left: 28px;
    border-bottom: 1px solid #adafca50;

    > p {
      color: ${props => props.theme.colors.textColor};
      line-height: 1em;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  > footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px;
    border-top: 1px solid #adafca50;
    color: ${props => props.theme.colors.textColor};

    div {

      > p {
        font-weight: 600;
        color: #adafca;
        font-size: 12px;
      }

      > button {
        font-size: .875rem;
        padding: .75rem 2rem;
        border: none;
        box-shadow: none;

        &:disabled {
          background: none;
          border: 1px solid #d2d7e3;
          color: #b3bbcf;
          box-shadow: none;
          cursor: not-allowed;
        }
      }
    }
  }
`;
