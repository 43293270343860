import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { addDays, format, isPast } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import SectionBanner from '../../components/SectionBanner';
import VideoCategoryBox from '../../components/VideoCategoryBox';
import VideoTumbnail from '../../components/VideoTumbnail';
import AchievementBox from '../../components/AchievementBox';
import Loader from '../../components/Loader';

import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/streams-icon.png';

import badgeLives from '../../assets/badge-lives.png';
import badgeAfiliates from '../../assets/badge-conquistas.png';

import { Container, ContentGrid1, Timeline, TabsCrid, VisionModule } from './styles';

export interface ILastVideoSuggest {
  id: string;
  slug: string;
  cluster_id: string;
  title: string;
  description: string;
  download: string;
  thumbnail: string;
  video: string;
  duration: number;
  order: number;
  views: number;
  created_at: string;
  updated_at: string;
  cluster: {
    id: string;
    slug: string;
    course_id: string;
    title: string;
    description: string;
    thumbnail: string;
    order: number;
    background: string;
    created_at: string;
    updated_at: string;
    course: {
      id: string;
      slug: string;
      exam_name: string;
      title: string;
      description: string;
      active: boolean;
      created_at: string;
      updated_at: string;
    };
    thumbnail_url: string;
  };
  formatted_duration: string;
  download_url: string;
  thumbnail_url: string;
  progress: {
    id: string;
    user_id: string;
    lesson_id: string;
    position: number;
    created_at: string;
    updated_at: string;
  };
}
interface IClusterProps {
  id: string;
  slug: string;
  course_id: string;
  course: {
    slug: string;
  }
  order: number;
  title: string;
  description: string;
  thumbnail_url: string;
  total_lessons: number;
  background: string;
}

const Videos: React.FC = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);

  const [lastVideo, setLastVideo] = useState<ILastVideoSuggest>();
  const [clusters, setClusters] = useState<IClusterProps[]>();


  // List last Video
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<ILastVideoSuggest>('/lessons/last');

        console.log(response.data);
        setLastVideo(response.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    }

    loadData();
  }, []);

  // List cluester
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const course_slug = user.courses[0].slug;
        const response = await api.get<IClusterProps[]>(`/courses/${course_slug}/clusters`);

        console.log(response.data);
        setClusters(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [user.courses]);

  return (
    <Container>
      <SectionBanner icon={bannerindexIconImg} title="Vídeos Exclusivos" subTitle="Curso completo do zero ao avançado." />
      <TabsCrid>
        <ContentGrid1>
          <section>
            <div>
              <aside>
                <Link to={`/player/${lastVideo?.cluster.course.slug}/${lastVideo?.cluster.slug}/${lastVideo?.slug}`}>
                  {lastVideo &&
                    <VideoTumbnail
                      videoTumbnailUrl={lastVideo.thumbnail ? `https://thumbs.tv.pandavideo.com.br/vz-e7274b3b-4ac/${lastVideo.thumbnail}.png` : `https://b-vz-e7274b3b-4ac.tv.pandavideo.com.br/${lastVideo.video}/thumbnail.jpg `}
                      dateView={lastVideo.progress && lastVideo.progress.updated_at}
                      videoTitle={lastVideo.title}
                      percentView={lastVideo.progress && lastVideo.progress.position * 100 / lastVideo.duration}
                      minHeight="450px"
                    />
                  }
                </Link>
              </aside>
              <div>
                <AchievementBox
                  background="bg3"
                  icon={badgeLives}
                  title="Lives exclusivas"
                  description="Assista as LIVES exclusivas e tire suas dúvidas!"
                  buttonTextValue="Entrar"
                  href="/lives" // /lives
                  pulseAnimation={true}
                />
              </div>
              <div>
                <AchievementBox
                  background="bg4"
                  icon={badgeAfiliates}
                  title="Programa de afiliados"
                  description="Que tal ganhar uma renda extra indicando a plataforma  amigos?"
                  buttonTextValue="Saiba mais"
                  href="/afiliados"
                  pulseAnimation={false}
                />
              </div>
            </div>
          </section>
          <section>
            <Timeline>
              <div className="line">
                <div className="line-content">
                 {/*  <VisionModule to={'/redacao-espacial'} className='content'>
                    <div>
                      <aside>
                        <h1>Redação Espacial</h1>
                        <h2>A nova era chegou: tenha acesso, em primeira mão, a conteúdos feitos com realidade aumentada</h2>
                        <p>Novo</p>
                      </aside>
                      <main>
                        <img src="https://poxalulu-api.s3.sa-east-1.amazonaws.com/clusters/poxalulu-vision.png" alt="Vision" />
                      </main>
                    </div>
                  </VisionModule> */}
                  {clusters && clusters.map((cluster, index) => {
                    const inWarranty = !isPast(parseISO(user.courses[0].warranty_date));

                    return (
                      <div className="content" key={index}>
                        <h1>Módulo {cluster.order}</h1>
                        <p>
                          {
                            index >= 1 && inWarranty ?
                              `Este módulo será liberado dia ${format(addDays(parseISO(user.courses[0].warranty_date), 1), "dd'/'MM'/'Y", { locale: ptBR })} 🔒`
                              :
                              ''
                          }
                        </p>
                        <VideoCategoryBox
                          href={`/player/${cluster.course.slug}/${cluster.slug}`}
                          background={cluster.background}
                          bgImg={cluster.thumbnail_url}
                          title={cluster.title}
                          subTitle={cluster.description}
                          videoQuantity={cluster.total_lessons}
                          isLocked={index >= 1 && inWarranty}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </Timeline>
          </section>
          {loading && <Loader isFixed={false} />}
        </ContentGrid1>
      </TabsCrid>
      <Footer />
    </Container>
  );
}

export default Videos;
