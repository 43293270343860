import styled from 'styled-components';

interface IBadgePros {
  type:
    | 'Moderador'
    | 'Autor'
    | 'Aluno'
}

export const Container = styled.div<IBadgePros>`
    height: 20px;
    margin-top: 12px;
    padding: 0 8px;
    border-radius: 200px;
    background-color: ${props => props.type === 'Autor' ? props.theme.colors.primary : props.type === 'Aluno' ? '#ff9800' : '#7cb77f'};
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
`;
