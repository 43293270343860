import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }

  > h1 {
    font-size: 2rem;
    line-height: 1;
    color: ${props => props.theme.colors.textColor};
  }

  > h6 {
    font-size: 1rem;
    line-height: 1;
    text-transform: uppercase;
    color: #adafca;
    font-size: .75rem;
  }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 2rem 0 1rem 0;
  grid-template-columns: 6fr 3fr;
  min-height: 200px;

  /* TABLET */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > aside:nth-child(1) {
    border-radius: 12px;
    overflow: hidden;

    > main {
      position: relative;
      min-height: 466px;
      width: 100%;
    }

    > footer {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      background: rgb(110 97 198 / 12%);
      border-radius: 0 0 12px 12px;
      justify-content: space-between;

      > aside:nth-child(1){
        display: flex;

        div:nth-child(1) {
          display: flex;
          align-items: center;

          > span {
            font-size: 12px;
            font-weight: 700;
            color: #adafca;
            margin-left: 10px;
            line-height: 1;
          }

          > img {
            width: 20px;
          }
        }

        div:nth-child(2) {
          margin-left: 1rem;
          display: flex;
          align-items: center;

          > ul {
            list-style: none;
            display: flex;
            align-items: center;

            > li {
              display: inline-flex;
              align-items: center;
              margin-right: 5px;

              > img {
                width: 18px;
              }

              > a {
                font-size: 12px;
                font-weight: 700;
                color: #adafca;
                margin-left: 5px;
                line-height: 1;
                text-transform: uppercase;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      > aside:nth-child(2) {
        display: flex;
        position: relative;

        > main {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index:999;
        }
      }
    }

  }

  > aside:nth-child(2) {
    display: flex;
    flex-direction: column;
    max-height: 529px;
    overflow-y: scroll;
    padding-right: .5rem;

    &::-webkit-scrollbar {
      visibility: hidden;
      opacity: 0;
      width: 4px;
      height: 4px;
      transition: all 4s ease-in-out;
    }

    &:hover::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      opacity: 1;
      transition: all .3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #cccccc80;
      border-radius: 10px;
    }
    &:hover::-webkit-scrollbar-thumb:hover{

    }
    &:hover::-webkit-scrollbar-track{
      border-radius: 10px;
      box-shadow: inset 7px 10px 12px #cccccc10;
    }

    /* TABLET */
    @media (max-width: 1200px) {
      display: grid;
      grid-gap: .5rem;
      grid-template-columns: repeat(2, 2fr);
      max-height: unset;
      overflow: visible;
      padding: 0;

      > div {
        margin: 0;
      }
    }
    /* MOBILE */
    @media (max-width: 1000px) {
      display: grid;
      grid-gap: .5rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const ContentGrid2 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(3, 3fr);

  /* TABLET */
  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* MOBILE */
  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ConfirmDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    color: #fff;
    font-size: 1.85rem;
    line-height: 1;
    margin: 2rem 0 0rem 0;
  }
  p {
    font-size: .8rem;
    margin-top: .2rem;
  }
`;
