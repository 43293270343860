import styled from 'styled-components';

export const TopBar = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  width: 100%;
  height: 5px;
  z-index: 999999999999999;

  /* laptop */
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const LeftBar = styled.div`
  position: fixed;
  left: 80px;
  top: 0;
  background: transparent;
  width: 5px;
  height: 100%;
  z-index: 999999999999999;

  /* laptop */
  @media (max-width: 1200px) {
    display: none;
  }
`;
