import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) {

  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  padding-top: 1rem;
  min-height: 150px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > section {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 12px;
    padding: 34px 28px;
    background: ${props => props.theme.colors.bgBox};
    color: ${props => props.theme.colors.textColor};
    box-shadow: ${props => props.theme.colors.shadowBox};

    > aside {

    }

  }

`;

export const TabsCrid = styled.div`
  margin: 2rem 0;

  ul {
    display: inline-flex;
    list-style: none;
    margin: 0 0 2rem 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #adafca40;
  }

  li {
    font-size: .875rem;
    font-weight: 700;
    cursor: pointer;
    background: none;
    padding-right: 1rem;
    border-radius: 0px;
    color: ${props => props.theme.colors.textColor};

    > button {
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-weight: 700;
      color: ${props => props.theme.colors.textColor};
      background: none;
      opacity: .5;
      transition: opacity .3s ease-in-out 0s;

      &.active {
        opacity: 1;
        border-bottom: 4px solid #6e61c6;
      }
    }
  }

`;
