import React from 'react';

import ProfileHeader from '../../components/ProfileHeader';
import Accordion from '../../components/Accordion';
import HeaderInfo from '../../components/HeaderInfo';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import { Container, ContentGrid1, PreferencesContent } from './styles';
import Toggle from '../../components/Toggle';

const Preferencias: React.FC = () => {

  return(
    <Container>
        <ProfileHeader />
        <ContentGrid1>
          <section>
            <div>
              <Accordion />
              <footer>
                {/* <Button color="success" onClick={() => handleSaveChanges()} >Salvar mudanças!</Button> */}
              </footer>
            </div>
          </section>
          <section>
            <HeaderInfo supTitle="Informações pessoais" title="Preferências" showSendButton={false} margin="0 0 1.5rem 0" />
            <section>
              <PreferencesContent>
                <main>
                  <div>
                    <h3>Hall da Fama</h3>
                    <p>Concordo em ter minhas redaçoes expostas no "Hall da Fama" (mural de redações com notas superiores a 900 pontos).</p>
                  </div>
                  <div>
                    <Toggle labelLeft="" labelRight="" checked={true} onChange={() => alert('a')}  />
                  </div>
                </main>
                <main>
                  <div>
                    <h3>Revista Hall of Fame</h3>
                    <p>You'll be notified when someone comments on your posts and/or replies to your comments</p>
                  </div>
                  <div>
                    <Toggle labelLeft="" labelRight="" checked={true} onChange={() => alert('a')}  />
                  </div>
                </main>
                <main>
                  <div>
                    <h3>Recebimento de E-mails</h3>
                    <p>You'll be notified when someone comments on your posts and/or replies to your comments</p>
                  </div>
                  <div>
                    <Toggle labelLeft="" labelRight="" checked={true} onChange={() => alert('a')}  />
                  </div>
                </main>
              </PreferencesContent>
            </section>
          </section>
        </ContentGrid1>
        <Footer />
    </Container>
  );
}

export default Preferencias;
