import React, { useEffect, useState } from 'react';
import { FakeThumbnail } from './styles';
import Icon from '../../../Icon';

interface IPandaPlayerProviderProps {
  video: string;
  thumbnail: string;
  style?: React.CSSProperties;
  onEnded?: (ref: any | null) => void;
}

const PandaPlayerProvider: React.FC<IPandaPlayerProviderProps> = ({
  video,
  thumbnail,
  style,
  onEnded,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const PANDA_PREFIX = 'https://player-vz-e7274b3b-4ac.tv.pandavideo.com.br';

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.pandavideo.com.br/api.v2.js';
    script.async = true;

    document.body.appendChild(script);

    console.log('script', script);

    return () => {
      document.body.removeChild(script);
    };

  }, []);

  useEffect(() => {
    console.log({isLoaded, isOpen})
    if (isLoaded || !isOpen) return;

    console.log('PANDA PLAYERrerrrrrrr');

    // @ts-ignore
    window.pandascripttag = window.pandascripttag || [];
    // @ts-ignore
    window.pandascripttag.push(function () {
      // @ts-ignore
      const player = new PandaPlayer(`panda-${video}`, {
        onReady: () => {
          setIsLoaded(() => true);
          console.log('PLAYER LOADED')

          player.onEvent((event: any) => {
            //console.log('EVENT', event);

            if (event.message === 'panda_ended') {
              console.log('ENDED');
              onEnded && onEnded(player);
            }
          });
        },
      });
    });
  }, [video, onEnded, isLoaded, isOpen]);

  const handlePlay = () => {
    setIsOpen(true);
  }

  return (
    <>
      {!isOpen ? (
        <FakeThumbnail
          thumbnail={thumbnail ? `https://thumbs.tv.pandavideo.com.br/vz-e7274b3b-4ac/${thumbnail}.png` : `https://b-vz-e7274b3b-4ac.tv.pandavideo.com.br/${video}/thumbnail.jpg `}
          video={video}
          onClick={handlePlay}
        >
          <div>
            <Icon icon='icon-icon-play' size="48px" color="#fff" />
          </div>
        </FakeThumbnail>
      ) : (
      <div
        style={{ minHeight: 'auto', position: 'relative', paddingTop: '56.25%', zIndex: 9, }}>
        <iframe
          title="video"
          id={`panda-${video}`}
          src={`${PANDA_PREFIX}/embed/?v=${video}`}
          style={style ? style : { border: 'none', position: 'absolute', top: 0, left: 0, }}
          allowFullScreen={true}
          width="100%"
          height="100%"
        />
      </div>
      )}
    </>
  );
}

export default PandaPlayerProvider;
