import React from 'react';

import { useAuth } from '../../hooks/auth';

import 'react-circular-progressbar/dist/styles.css';

import UserAvatar from '../UserAvatar';
import Icon from '../Icon';

import { Container, ProfileStatsCover, ProfileStatsInfo, FeaturedStatList, FeaturedStat } from './styles';

interface IProfileStatisticsProps {
  submitted_wordings: number;
  highest_grade: number;
}

const ProfileStats: React.FC<IProfileStatisticsProps> = ({submitted_wordings, highest_grade}) => {

const { user } = useAuth();

return (
  <Container>
    <ProfileStatsCover>
      <h4>Olá, {user.show_name}!</h4>
      <p>Bons estudos, poxinha!</p>
    </ProfileStatsCover>
    <ProfileStatsInfo>
      <UserAvatar size="md" totalExp={user.exp} picture={user.avatar_url} />
      <FeaturedStatList>
        <FeaturedStat>
          <div>
            <Icon icon="icon-icon-send-message" size="20px" />
            <p>{submitted_wordings}</p>
            <h6>Redações<br />Enviadas</h6>
          </div>
        </FeaturedStat>
        <FeaturedStat>
          <div>
            <Icon icon="icon-icon-overview" size="20px" />
            <p>{highest_grade}</p>
            <h6>Maior nota</h6>
          </div>
        </FeaturedStat>
      </FeaturedStatList>
    </ProfileStatsInfo>
  </Container>
  );
}

export default ProfileStats;
