import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import Icon from '../Icon';

import { Container } from './styles';

import accordionMenuLinks from '../../utils/accordionMenuLinks';

const Accordion: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname.split('/')[1];

  return (
    <Container>
      <details open={accordionMenuLinks.find(link => link.location === localtionSplited && link.section === 1) ? true : false}>
        <summary>
          <Icon icon="icon-icon-profile" color="#6e61c6" size="20px" />
          Meu perfil
          <p>Altere sua foto de perfil, foto de capa e muito mais!</p>
        </summary>
        <div>
          <ul>
            {accordionMenuLinks.map((link, index) => (
              (link.section === 1 &&
                ((link.target === "_blank") ?
                  <li key={index}>
                    <a href={`${link.location}`} target={link.target}>{link.linkName}</a>
                  </li>
                  :
                  <li key={index}>
                    <Link to={`/${link.location}`} target={link.target} className={link.location === localtionSplited ? 'active' : ''}>{link.linkName}</Link>
                  </li>
                ))
            ))}
          </ul>
        </div>
      </details>
      <details open={accordionMenuLinks.find(link => link.location === localtionSplited && link.section === 2) ? true : false}>
        <summary>
          <Icon icon="icon-icon-notification" size="20px" />
          Notificações
          <p>Não perca nenhum aviso da Plataforma.</p>
        </summary>
        <div>
          <ul>
            {accordionMenuLinks.map((link, index) => (
              (link.section === 2 &&
                ((link.target === "_blank") ?
                  <li key={index}>
                    <a href={`${link.location}`} target={link.target}>{link.linkName}</a>
                  </li>
                  :
                  <li key={index}>
                    <Link to={`/${link.location}`} target={link.target} className={link.location === localtionSplited ? 'active' : ''}>{link.linkName}</Link>
                  </li>
                ))
            ))}
          </ul>
        </div>
      </details>
      <details open={accordionMenuLinks.find(link => link.location === localtionSplited && link.section === 3) ? true : false}>
        <summary>
          <Icon icon="icon-icon-settings" color="#6e61c6" size="20px" />
          Segurança
          <p>Altere sua senha e outras preferências mais.</p>
        </summary>
        <div>
          <ul>
            {accordionMenuLinks.map((link, index) => (
                (link.section === 3 &&
                ((link.target === "_blank") ?
                <li key={index}>
                  <a href={`${link.location}`} target={link.target}>{link.linkName}</a>
                </li>
                :
                <li key={index}>
                  <Link to={`/${link.location}`} target={link.target} className={link.location === localtionSplited ? 'active' : ''}>{link.linkName}</Link>
                </li>
                ))
            ))}
          </ul>
        </div>
      </details>
    </Container>
  );
}

export default Accordion;
