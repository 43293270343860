import React from 'react';

import ReactPlayer from 'react-player';

interface IReactPlayerProviderProps {
  video_url: string;
  onReady?: (ref: ReactPlayer | null) => void;
  onPlay?: (ref: ReactPlayer | null) => void;
  onPause?: (ref: ReactPlayer | null) => void;
  onEnded?: () => void;
  style?: React.CSSProperties;
  onProgress?: (
    played: number,
    playedSeconds: number,
    loaded: number,
    loadedSeconds: number,
  ) => void;
}

const ReactPlayerProvider: React.FC<IReactPlayerProviderProps> = ({ 
  video_url, 
  style,
  onReady,
  onPlay,
  onPause,
  onEnded,
  onProgress,
 }) => {

  const playerRef = React.useRef<ReactPlayer>(null);

  return (
    <ReactPlayer
      ref={playerRef}
      url={video_url}
      autoPlay={false}
      loop={false}
      muted={false}
      background={'transparent'}
      controls={true}
      className="react-player"
      playing={true}
      width="100%"
      height="100%"
      style={style}
      onReady={() => onReady && onReady(playerRef.current)}
      onPlay={() => onPlay && onPlay(playerRef.current)}
      onPause={() => onPause && onPause(playerRef.current)}
      onEnded={() => onEnded && onEnded()}
      onProgress={({
        played,
        playedSeconds,
        loaded,
        loadedSeconds,
      }) =>
        onProgress && 
        onProgress(
          played,
          playedSeconds,
          loaded,
          loadedSeconds,
        )
      }
    />
  );
}

export default ReactPlayerProvider;