import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Icon from '../../../components/Icon';
import LoaderPureCss from '../../../components/LoaderPureCss';
import ModalSuggestion from './ModalSuggestion';

import { Container, ModalCorrectionType } from './styles';

interface IBubbleMenuProps {
  exam_name: string;
}

const BubbleMenuContext: React.FC<IBubbleMenuProps> = ({exam_name}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSortNewTheme = useCallback(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get('/themes/random', {
          params: { exam_name: exam_name }
        });

        console.log('LIST RANDOM THEME >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        const swal = withReactContent(Swal);

        swal.fire({
          html: (
            <ModalCorrectionType>
              {loading && <LoaderPureCss isFixed={false} />}
              <header>
                <h4>TEMA SORTEADO</h4>
                <h2>{response.data.name}</h2>
              </header>
              <main>
                <section onClick={() => {history.push(`/enviar-redacao/texto/${response.data.id}`); swal.close();}}>
                    <Icon icon="icon-icon-blog-posts" size="20px" color="#6e61c6" />
                    <h4>Enviar redação digitada</h4>
                    <p>máximo de 30 linhas</p>
                </section>
                <section onClick={() => {history.push(`/enviar-redacao/foto/${response.data.id}`); swal.close();}}>
                    <Icon icon="icon-icon-camera" size="20px" color="#6e61c6" />
                    <h4>Enviar redação por foto</h4>
                    <p>tamanho máximo de 3mb</p>
                </section>
              </main>
              <footer>
                <p>Escolha uma das opções de envio 😊</p>
              </footer>
            </ModalCorrectionType>
            ),
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });

        //setCategories(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [exam_name, history, loading]);

  const handleSuggestTheme = useCallback(()=> {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      html: <ModalSuggestion />,
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, []);

  return (
    <Container>
      <input type="checkbox" className="nav--checkbox" id="nav-toggle" />
      <label htmlFor="nav-toggle" className="nav--button">
        <Icon icon="icon-icon-add-friend" size="20px" />
      </label>

      <div className="nav--small nav--btn-1">
        <button data-tooltip="Sugerir tema!" onClick={handleSuggestTheme}>
          <Icon icon="icon-icon-plus" />
        </button>
      </div>

      <div className="nav--small nav--btn-3">
        <button data-tooltip="Sortear tema!" onClick={handleSortNewTheme}>
          <Icon icon="icon-icon-return" />
        </button>
      </div>
    </Container>
  );
}

export default BubbleMenuContext;
