import styled from 'styled-components';

import coverImg from '../../assets/banner-profile-stats.jpg';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  min-height: 120px;
  border-radius: 12px;
  box-sizing: border-box;
  padding-bottom: 1rem;
  background: ${props => props.theme.colors.bgBox};
  color: ${props => props.theme.colors.textColor};
  box-shadow: ${props => props.theme.colors.shadowBox};

  /* TABLET */
  @media (max-width: 768px) {
    display: none;
  }

  /* MOBILE */
  @media (max-width: 480px) {
    display: block;
  }
`;

export const ProfileStatsCover = styled.div`
  height: 160px;
  padding-top: 1.8rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: url(${coverImg}) no-repeat 50%;
  background-size: cover;
  text-align: center;

  > h4 {
    font-size: 1.8rem;
    line-height: 1.3;
    color: ${props => props.theme.colors.white};
  }

  > p {
    line-height: 1;
    color: ${props => props.theme.colors.white};
    font-size: .8rem;
  }
`;

export const ProfileStatsInfo = styled.div`
  margin-top: -65px;
`;

export const FeaturedStatList = styled.div`
  display: flex;
`;

export const FeaturedStat = styled.div`
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2.8rem;

  /* TABLET */
  @media (max-width: 768px) {
    padding: 1rem 3.5rem;
  }

  /* MOBILE IPHONE 6/7/8 PLUS */
  @media (max-width: 480px) {

  }

  > div {
    text-align: center;
    color: ${props => props.theme.colors.textColor};

    > p {
      margin-top: 1rem;
      font-size: 1.375rem;
      font-weight: 700;
    }
    > h6 {
      margin-top: 6px;
      color: #adafca;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
    }
  }

  &:nth-child(1):after {
    content: "";
    width: 1px;
    height: 40px;
    background-color: ${props => props.theme.colors.secundaryTextColor}30;
    position: absolute;
    top: 52px;
    right: 0;
  }
`;
