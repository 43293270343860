import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

import ThemeCardSuggest from '../../../components/ThemeCardSuggest';

interface IThemeCardSuggestProps {
  id: string;
  category_id: string;
  exam_name: string;
  name: string;
  material: string;
  thumbnail: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  difficulty: 'easy' | 'medium' | 'hard';
  category: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  };
  student_id: string;
  student: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  new: boolean;
  material_url: string;
  thumbnail_url: string;
}

const LastThemeSuggest: React.FC = () => {
  const [lastTheme, setLastTheme] = useState<IThemeCardSuggestProps>();

   // List last suggestion theme DEFAULT: ENEM
   useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get<IThemeCardSuggestProps>('/themes/last',
          {params: {exam_name: 'enem'}}
        );

        console.log(response.data);

        setLastTheme(response.data);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }

    loadData();
  }, []);

  return (
    <>
      {lastTheme &&
        <ThemeCardSuggest
          id={lastTheme.id}
          title={lastTheme.name}
          thumbnail_url={lastTheme.thumbnail_url}
          exp={30}
          student={lastTheme?.student}
          course={lastTheme.exam_name}
          themeSuggestion={true}
          difficulty={lastTheme.difficulty}
        />
      }
    </>
  );
}

export default LastThemeSuggest;
