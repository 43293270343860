
import styled from 'styled-components';

interface MarkPopoverProps {
  isActive: boolean;
}

export const MarkPopover = styled.div<MarkPopoverProps>`
  position: absolute;
  top: -14px;
  color: ${({ theme }) => theme.textColor};
  background: white;
  color: #595959;
  border-radius: 8px;
  width: 300px;
  cursor: default;
  text-indent: 0;
  transition: visibility 0.2s, opacity 0.2s;
  box-shadow: ${({ theme }) => theme.shadowBox};
  border: 1px solid rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -100%);
  transition: visibility 0.2s, opacity 0.2s;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  z-index: 99999999999999999 !important;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, 100%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.2);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, 100%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
  }

  header {
    color: #4d4d4d;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;
    background-color: #f7f7f7;
    align-items: center;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: start;
      margin-left: 8px;

      button {
        border: none;
        background: none;
        padding: 4px;
        line-height: 1;
        color: ${({ theme }) => theme.colors.primary};
        transition: color 0.2s ease;

        &:hover {
          color: ${({ theme }) => theme.colors.primary}80;
        }

        & + button {
          margin-left: 4px;
        }
      }
    }
  }

  > div {
    font-size: 14px;
    line-height: 1.5;
    padding: 16px;
  }
`;

