import React from 'react';

import ReactMarkdown from 'react-markdown';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container } from './styles';
interface ITicketCardProps {
  status: 'pending' | 'awaiting' | 'closed' | 'canceled';
  title: string;
  category: string;
  description: string;
  created_at: string;
  className: string;
  onClick(): void;
}

const TicketCard: React.FC<ITicketCardProps> = ({ status, title, category, description, created_at, onClick, className }) => {
  return (
    <Container>
      <div onClick={onClick} className={className}>
        <h5>{category}</h5>
        <div>
          <h1>{title}</h1>
          {(status === 'pending' || status === 'awaiting') && <h2 className={status}>Pendente</h2>}
          {status === 'closed' && <h2 className={status}>Finalizado</h2>}
          {status === 'canceled' && <h2 className={status}>Cancelado</h2>}
        </div>
        <ReactMarkdown allowedElements={['p']} >{description}</ReactMarkdown>
        <div>
          <button>visualizar chamado</button>
          <p>
            {created_at && format(parseISO(created_at), "dd'/'MM'/'yyyy 'às' HH:mm", { locale: ptBR })}
          </p>
        </div>
      </div>
    </Container>
  );
}

export default TicketCard;
