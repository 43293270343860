import styled from 'styled-components';

export const Container = styled.div`

  > aside {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    div {
      margin-left: .5rem;
      font-size: .75rem;
      font-weight: 700;
    }

    > span {
      display: flex;
      margin: 0 -2.5px;
      cursor: pointer;
      position: relative;

      &:hover {
        position: relative;
        transition: all 0.3s ease-in-out;
      }

      &:after {
        content: attr(data-count);
        box-shadow: 0 0 40px 0 rgb(94 92 154 / 45%);
        background: ${props => props.theme.colors.bgBox};
        color: ${props => props.theme.colors.textColor};
        position: absolute;
        z-index: 99999;
        border-radius: 1rem;
        text-align: center;
        box-sizing: border-box;
        padding: 0px 12px;
        margin: 0 -3px;
        font-size: .75rem;
        font-weight: 700;
        white-space: nowrap;
        line-height: 24px;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
      }

      &:hover::after {
        opacity: 1;
        visibility: visible;
        transform: translateY(-2rem);
      }

      &.active {
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
      }

      > img {
        width: 25px;
        height: 25px;
        border: 2px solid ${props => props.theme.colors.bgBox};
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
`;
