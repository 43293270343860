import React from 'react';

import ProgressBar from "@ramonak/react-progress-bar";
import { Image } from 'react-image-and-background-image-fade';

import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseISO } from 'date-fns/esm';

import Icon from '../../components/Icon';

import { Container } from './styles';

interface VideoTumbnailProps {
  videoTumbnailUrl: string;
  videoTitle: string;
  dateView?: string;
  percentView?: number;
  heightPropriety?: string;
  minHeight?: string;
  borderRadius?: string;
  showOnlyThumb?: boolean;
}

const VideoTumbnail: React.FC<VideoTumbnailProps> = ({ videoTumbnailUrl, videoTitle, dateView, percentView, heightPropriety, borderRadius, minHeight, showOnlyThumb = false }) => {
  return (
    <Container
      videoTumbnailUrl={videoTumbnailUrl}
      heightPropriety={heightPropriety}
      borderRadius={borderRadius}
      minHeight={minHeight}
      showOnlyThumb={showOnlyThumb}>
      <header>
        <span>{percentView ? 'Último vídeo assistido' : 'Assista ao primeiro vídeo'}</span>
      </header>
      <div className="btn">
        <Icon icon="icon-icon-play" size="48px" margin="0 0 0 9px" />
      </div>
      <footer>
        <p>{videoTitle}</p>
        {dateView && <p>Você visualizou este vídeo há {formatDistance(parseISO(dateView), new Date(), { locale: ptBR })}</p>}

        <div>
          <ProgressBar borderRadius="0" completed={percentView || 0} height="6px" isLabelVisible={false} baseBgColor="#fff" bgColor="#ff6961" />
        </div>
      </footer>
    </Container>
  );
}

export default VideoTumbnail;
