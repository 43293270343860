import styled from 'styled-components';

interface IInputSelectValidateProps {
  padding?: string;
  margin?: string;
  onlyWhite?: boolean | false;
}

export const Container = styled.div<IInputSelectValidateProps>`
  position: relative;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  padding: ${props => props.padding ? props.padding : null};
  margin: ${props => props.margin ? props.margin : null};

  > span {
    background: ${props => props.onlyWhite ? '#ffffff' : props.theme.colors.bgBox};
    color: #9098a9;
    font-size: 10px;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 5px);
    transform: rotate(90deg);
  }

  > select {
    width: 100%;
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.colors.textColor}30;
    color: ${props => props.onlyWhite ? '#3e3f5e' : props.theme.colors.textColor};
    background: ${props => props.onlyWhite ? '#ffffff' : props.theme.colors.bgBox};
    font-size: 14px;
    font-weight: 600;
    transition: .3s ease-in-out;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';

    &::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: #615dfa;
    }

    &:valid + label {
      font-size: .8rem;
      transform: translateY(-1.7rem);
    }

    &:invalid + label {
      transform: translateY(0);
    }

    &:focus + label {
      font-size: .8rem;
      transform: translateY(-1.7rem);
    }
  }
`;

export const Label = styled.label<IInputSelectValidateProps>`
  color: #adafca;
  font-size: .8rem;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 1rem;
  padding: 0 5px;
  pointer-events: none;
  background: ${props => props.onlyWhite ? '#fff' : props.theme.colors.bgBox};
  transition: .3s ease;
  transform: translateY(-1.7rem);
`;

export const Error = styled.p`
  color: #c55050;
  font-size: 12px;
  font-weight: 600;
  margin-left: 1rem;
`;
