import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { BackgroundImage } from 'react-image-and-background-image-fade';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import UserAvatar from '../../../components/UserAvatar';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import ExpBadge from '../../../components/ExpBadge';
import BadgeDificulty from './BadgeDificulty';

import poxaluluProfilePic from '../../../assets/poxa-lulu-avatar.png';

import { Container, ModalCorrectionType } from './styles';

interface IThemeCardProps {
  id: string;
  exp: number;
  title: string;
  thumbnail_url: string;
  student: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  course: string;
  category: string;
  difficulty: 'easy' | 'medium' | 'hard';
  setCategory(category: string): void;
}

const ThemeCard: React.FC<IThemeCardProps> = ({ id, exp, student, title, thumbnail_url, course, category, difficulty, setCategory }) => {
  const history = useHistory();

  const handleSendCorrectionType = useCallback((id: string) => {
    const swal = withReactContent(Swal)

    swal.fire({
      html: (
        <ModalCorrectionType>
          <main>
            <section onClick={() => {history.push(`/enviar-redacao/texto/${id}`); swal.close();}}>
              <Icon icon="icon-icon-blog-posts" size="20px" color="#6e61c6" />
              <h4>Enviar redação digitada</h4>
              <p>máximo de 30 linhas</p>
            </section>
            <section onClick={() => {history.push(`/enviar-redacao/foto/${id}`); swal.close();}}>
              <Icon icon="icon-icon-camera" size="20px" color="#6e61c6" />
              <h4>Enviar redação por foto</h4>
              <p>tamanho máximo de 3mb</p>
            </section>
          </main>
          <footer>
            <span>Escolha uma das opções de envio 😊</span>
          </footer>
        </ModalCorrectionType>
        ),
      background: '#6e61c6',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, [history])

  return (
      <Container thumbnail_url={thumbnail_url}>
        <BackgroundImage
          element="figure"
          src={thumbnail_url}
          lazyLoad={true}
        />
        <main>
          <ExpBadge icon="icon-icon-plus" exp={exp} />
          <div>
            <p>
              {course}
              <span>/</span>
              <button onClick={() => setCategory(category)}>{category}</button>
            </p>
            <h2>{title}</h2>
          </div>
          <Button color="primary" onClick={() => handleSendCorrectionType(id)}>
            <Icon icon="icon-icon-send-message" color="#ffffff" size="14px" margin="0 8px 0 0" />
            ESCREVER REDAÇÃO
          </Button>
        </main>
        <footer>
          <div>
            {
              student ?
              <>
                <UserAvatar size="sm" totalExp={student.exp} picture={student.avatar_url} />
                <div>
                  <p><span>Sugerido por</span><br />{student.show_name}</p>
                </div>
              </>
              :
              <>
                <UserAvatar size="sm" totalExp={10000} picture={poxaluluProfilePic} />
                <div>
                  <p><span>Publicado por</span><br />Poxalulu</p>
                </div>
              </>
            }
          </div>
          <div>
            <BadgeDificulty dificulty={difficulty} />
          </div>
        </footer>
      </Container>
  );
}

export default ThemeCard;
