const toHHMMSS = (value: number) => {
  const sec_num = value/1000; // mileseconds = 1000s
  const hours   = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  const seconds = sec_num - (hours * 3600) - (minutes * 60);

  return `0${hours}`.slice(-2)+':'+`0${minutes}`.slice(-2)+':'+`0${seconds}`.slice(-2);
}

export default toHHMMSS;
