import React, {useCallback, useState} from 'react';

import { addYears } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Swal from 'sweetalert2';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SingleEvent } from './styles';

export interface IAddEventDTO {
  title: string;
  description?: string;
  start: Date;
  link?: string;
}

interface ICreateEventProps {
  addEvent(data: IAddEventDTO): void;
}

const CreateEvent: React.FC<ICreateEventProps> = ({ addEvent }) => {
  const [disabled, setDisabled] = useState(false);
  const [eventName, setEventName] = useState('');
  const [inputStartDate, setInputStartDate] = useState<Date>();
  const [eventLink, setEventLink] = useState('');
  const [eventDescription, setEventDescription] = useState('');

  const [confirm, setConfirm] = useState(false);
  const [validation, setValidation] = useState('');

  const handleCreateEvent = useCallback(() => {
    const regex = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);

    if(eventName.length <= 5) {
      setValidation('Preencha um nome válido para o evento.');
      return;
    }
    if(!!!eventName) {
      setValidation('Os campos "nome do evento" e "data" são obrigatórios.');
      return;
    }
    else if(!!!inputStartDate) {
      setValidation('Os campos "nome do evento" e "data" são obrigatórios.');
      return;
    }
    else if(eventLink !== '') {
      if(!regex.test(eventLink)) {
        console.log(regex.test(eventLink));
        setValidation('Preencha uma url válida.');
        return;
      }
      else {
        setDisabled(true);
        addEvent({
          start: inputStartDate,
          title: eventName,
          description: eventDescription,
          link: eventLink,
        });
        setConfirm(true);
        Swal.close();
        toast.success('🚀 Lembrete cadastrado com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    else {
      setDisabled(true);
      addEvent({
        start: inputStartDate,
        title: eventName,
        description: eventDescription,
        link: eventLink,
      });

      setConfirm(true);
      Swal.close();
      toast.success('🚀 Lembrete cadastrado com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [addEvent, inputStartDate, eventLink, eventName, eventDescription]);

  return (
    <SingleEvent>
      {!confirm && <section>
        <h1>+ Add Novo Lembrete</h1>
        <div>
          <input type="text" onChange={(e) => setEventName(e.target.value)} />
          <label>Nome do evento</label>
        </div>
        <div>
          <ReactDatePicker
            selected={inputStartDate}
            onChange={(date: Date) => setInputStartDate(date)}
            minDate={new Date()}
            maxDate={addYears(new Date(), 1)}
            showTimeSelect
            timeIntervals={30}
            dateFormat="dd/MM/yyyy HH:mm"
            locale={ptBR}
          />
          <label>Data e hora</label>
        </div>
        <div>
          <input type="text" onChange={(e) => setEventLink(e.target.value)} />
          <label>Link (opcional)</label>
        </div>
        <div>
          <textarea onChange={(e) => setEventDescription(e.target.value)} />
          <label>Descreva o evento (opcional)</label>
        </div>
        <footer>
          {validation && <p>{validation}</p>}
          <button type="button" onClick={handleCreateEvent} disabled={disabled}>Criar lembrete!</button>
        </footer>
      </section>}
    </SingleEvent>
  );
}

export default CreateEvent;
