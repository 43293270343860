import React from 'react';

import { TopBar, LeftBar } from './styles';

interface IBeforeSkipPageProps {
  beforeSkip(): void;
}

const BeforeSkipPage: React.FC<IBeforeSkipPageProps> = ({beforeSkip}) => {

  return (
    <>
      <TopBar onMouseOut={beforeSkip} />
      <LeftBar onMouseOut={beforeSkip} />
    </>
  );

}

export default BeforeSkipPage;
