import styled from 'styled-components';

interface VideoTumbnailProps {
  videoTumbnailUrl: string;
  heightPropriety?: string;
  borderRadius?: string;
  minHeight?: string;
  showOnlyThumb?: boolean;
}

export const Container = styled.div<VideoTumbnailProps>`
  background: url(${props => props.videoTumbnailUrl}) center center / cover no-repeat;
  border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};
  overflow: hidden;
  cursor: pointer;
  min-height: ${props => props.minHeight ? props.minHeight : '400px'};
  height: ${props => props.heightPropriety ? props.heightPropriety : '100%'};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: ${props => props.theme.colors.shadowBox};
  transition: all .5s ease-in-out;

  /* TABLET */
  @media (max-width: 768px) {
    min-height: ${props => props.minHeight ? '393px' : '247px'};
    //min-height: 393px !important;
  }
  /* MOBILE */
  @media (max-width: 480px) {
    min-height: ${props => props.minHeight ? '247px' : '247px'};
    //min-height: 247px !important;
  }

  &:hover .btn {
    background: #6e61c6;
    border: 4px solid #ffffff;
    color: #ffffff;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, transparent, #6e61c6);
    opacity: .4;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};
    transition: all .3s ease-in-out;
  }

  &:hover::before {
    opacity: 1;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};
  }

  > header {
    position: absolute;
    width: 85%;
    top: 40px;
    margin: 0 auto;
    display: ${props => !props.showOnlyThumb ? 'block' : 'none'};

    @media (max-width: 480px) {
      top: 1rem;
    }

    > span {
      background: #6e61c6;
      color: #fff;
      padding: 0 .5rem;
      font-size: 1rem;
      font-weight: 700;
      border-radius: 5px;
      text-transform: uppercase;

      /* MOBILE */
      @media (max-width: 480px) {
        font-size: .7rem;
      }
    }
  }

  > div {
    width: 128px;
    height: 128px;
    border: 4px solid #ffffff77;
    color: #ffffff77;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      background: #6e61c6;
      border: 4px solid #ffffff;
      color: #ffffff;
    }

    /* TABLET */
    @media (max-width: 768px) {
      transform: scale(.8);
    }
    /* MOBILE */
    @media (max-width: 480px) {
      transform: scale(.4);
    }
  }


  > footer {
    text-align: left;
    display: flex;
    justify-content: left;
    width: 85%;
    padding: 1.5rem 0 0 0;
    flex-direction: column;
    line-height: 1;
    position: absolute;
    bottom: 40px;
    display: ${props => !props.showOnlyThumb ? 'block' : 'none'};

    @media (max-width: 480px) {
      bottom: 20px;
    }

    > p:nth-child(1) {
      font-size: 1.8rem;
      font-weight: 700;

      color: #fff;
      opacity: 1;
      visibility: visible;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      transition: transform .3s ease-in-out,visibility .3s ease-in-out,opacity .3s ease-in-out;

      /* TABLET */
      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
      /* MOBILE */
      @media (max-width: 480px) {
        font-size: 1rem;
      }
    }

    > p:nth-child(2) {
      font-size: 1rem;
      margin-top: .4rem;
      font-weight: 500;
      color: #ededed;

      /* TABLET */
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
      /* MOBILE */
      @media (max-width: 480px) {
        font-size: 0.6rem;
        font-weight: 600;
      }
    }


    > div {
      width: 100%;
      bottom: -40px;
      left: 0;
      margin-top: 15px;
      justify-content: center;

      /* MOBILE */
      @media (max-width: 480px) {
        margin-top: .5rem;
      }
    }
  }
`;
