import React, {LinkHTMLAttributes} from 'react';

import { Container, LinkVideo } from './styles';

import blockImgIcon from '../../assets/lock.svg';

interface IVideoCategoryBoxProps extends LinkHTMLAttributes<HTMLLinkElement> {
  background: string;
  bgImg: string;
  title: string;
  subTitle: string;
  videoQuantity: number;
  isLocked?: boolean;
  href: string;
}

const VideoCategoryBox: React.FC<IVideoCategoryBoxProps> = ({background, bgImg, title, subTitle, videoQuantity, isLocked, href}) => {
  return (
    <LinkVideo to={isLocked ? '#' : href}>
      <Container background={background} bgImg={bgImg} isLocked={isLocked}>
        <aside>
          <h1>{title} <img src={blockImgIcon} alt="Conteúdo bloqueado" /></h1>
          <h2>{subTitle}</h2>
          <p>{videoQuantity} vídeo{videoQuantity !== 1 ? 's' : ''}</p>
        </aside>
        <main>
          <img src={bgImg} alt={title} />
        </main>
      </Container>
    </LinkVideo>
  );
}

export default VideoCategoryBox;
