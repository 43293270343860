import styled from 'styled-components';

interface IButtonCancelSendProps {
  status: 'canceled' | 'pending' | 'in_correction' | 'in_confirmation' | 'corrected';
}

export const Container = styled.button<IButtonCancelSendProps>`
  color: #adafca !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 21px !important;
  opacity: .4 !important;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out 0s !important;
  visibility: ${props => props.status === 'pending' ? 'visible' : 'hidden'} !important;
  margin-left: 10px;
  padding: 0 !important;

  background: none !important;
  box-shadow: none !important;

  > footer {
    display: none;
    font-size: 14px;
    color: #b2b4cc;
    opacity: 1;
    font-weight: 600;
  }

  &::after {
    position: absolute !important;
    z-index: 99999 !important;
    content: attr(data-tooltip) !important;
    border-radius: 1rem !important;
    text-align: center !important;
    box-sizing: border-box !important;
    box-shadow: 0 0 40px 0 rgba(94,92,154,.06) !important;
    padding: 0 12px !important;
    background: #293249 !important;
    color: #fff !important;
    font-size: .75rem !important;
    font-weight: 700 !important;
    white-space: nowrap !important;
    line-height: 24px !important;
    margin-top: 0 !important;
    margin-left: 3.3rem !important;
    opacity: 0 !important;
    -webkit-transition: .4s;
    transition: .4s;
    align-items: center;
    visibility: hidden;
  }

  &:hover {
    opacity: 1 !important;
  }

  &:hover::after {
    opacity: 1 !important;
    transform: translateX(3.4rem);
    visibility: visible;
  }

  /* TABLET */
  @media (max-width: 768px) {
    visibility: ${props => props.status !== 'pending' ? 'visible' : 'visible'} !important;
    display: ${props => props.status === 'pending' ? 'block' : 'none'} !important;
    opacity: 1 !important;
    margin-left: 0 !important;

    > span {
      display: none;
    }

    > footer {
      display: block;
    }
  }

  /* MOBILE */
  @media (max-width: 480px) {

  }
`;
