import React, {useRef, useCallback, useState} from 'react';

import { useAuth } from '../../../hooks/auth';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web'
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import Icon from '../../../components/Icon';
import WysiwygInnerModal from '../../../components/WysiwygInnerModal';
import InputAnimate2 from '../../../components/InputAnimate2';

import { SingleEvent } from './styles';

import firstCharapterCaptalized from '../../../utils/firstCharapterCaptalized';

export interface IAddTopicDTO {
  title: string;
  content: string;
}

interface ICreateEventProps {
  slug: string;
  category: string;
  redirect(id: string): any;
}

const CreateEvent: React.FC<ICreateEventProps> = ({ slug, category, redirect }) => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = useCallback(async (data: IAddTopicDTO) => {
    console.log(data);

    try {
      setDisabled(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        title: Yup.string().min(15, 'O título deve conter pelo menos 15 caracteres.').required('Informe o título do seu tópico.'),
        content: Yup.string().min(15, 'O conteúdo deve conter pelo menos 15 caracteres.').required('Informe a descrição de sua dúvida.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(`/forums/${slug}/${category}/topics`, {
        title: firstCharapterCaptalized(data.title),
        content: data.content,
      });


      console.log(response.data);

      redirect(response.data.id);

      Swal.close();
    } catch (err) {
      setDisabled(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      console.log(err);
    }

  }, [category, slug, redirect]);

  return (
    <SingleEvent>
      <section>
        <h1>Novo Tópico</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <InputAnimate2 name="title" label="Título da postagem" type="text" />
          </div>
          <WysiwygInnerModal name="content" placeholder="Formule melhor a sua pergunta aqui!" maxLength={user?.role && user.role !== 'student' ? 1500 : 500} />
          <footer>
            <button type="submit" color="primary" disabled={disabled}>
              <Icon icon="icon-icon-forums" color="#ffffff" size="14px" margin="0 8px 0 0" />
              Criar Tópico
            </button>
          </footer>
        </Form>
      </section>
    </SingleEvent>
  );
}

export default CreateEvent;
