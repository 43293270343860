import React, { useRef } from 'react';

import { Container } from './styles';

const NoSignalPlayer: React.FC = () => {

  const canvas = useRef<HTMLCanvasElement>(null);

  const ctx = canvas.current?.getContext('2d');

function resize() {
  if(!canvas?.current) {
    return;
  }

}
resize();
window.onresize = resize;

function noise(ctx: any) {

  if(!ctx) {
    return;
  }
  const idata = ctx.createImageData(ctx.canvas.width, ctx.canvas.height);
  const buffer32 = new Uint32Array(idata.data.buffer);
  const  len = buffer32.length;
  let i = 0;

  for(; i < len;)
      buffer32[i++] = ((255 * Math.random())|0) << 24;

  ctx.putImageData(idata, 0, 0);
}

var toggle = true;

// added toggle to get 30 FPS instead of 60 FPS
(function loop() {
    toggle = !toggle;
    if (toggle) {
        requestAnimationFrame(loop);
        return;
    }
    noise(ctx);
    requestAnimationFrame(loop);
})();



  return (
    <Container>
      <div><span>Poxa, não estamos ao vivo no momento! 😢</span></div>
      {canvas && <canvas ref={canvas}></canvas>}
    </Container>
  );
}

export default NoSignalPlayer;
