import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import api from '../../services/api';

import InputAnimateDropdown from '../../components/InputAnimateDropdown';
import SectionBanner from '../../components/SectionBanner';
import Icon from '../../components/Icon';
import HeaderInfo from '../../components/HeaderInfo';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';

import bannerindexIconImg from '../../assets/accounthub-icon.png';

import { Container, ContentGrid1, ContentGrid2, SearchButton, NotFound, FkLoadee, Breadcrumbs } from './styles';

interface ISuportArticlesProps {
  id: string;
  section_id: string;
  title: string;
  body: string;
  slug: string;
  is_promoted: boolean;
  vote_count: number;
  vote_sum: number;
  created_at: string;
  updated_at: string;
  abstract: string;
  reading_time: string;
}

interface ICategoriesProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
  created_at: string;
  updated_at: string;
}

const SuporteCategorias: React.FC = () => {

  const { slug } = useParams<{ slug: string }>();

  const [loading, setLoading] = useState(true);

  const [suporteArticles, setSuportArticles] = useState<ISuportArticlesProps[]>([]);
  const [suporteCategories, setSuportCategories] = useState<ICategoriesProps>({} as ICategoriesProps);

  // List ARTICLES
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const [sectionResponse, articlesResponse] = await Promise.all([
          api.get(`/sections/${slug}`),
          api.get(`/sections/${slug}/articles`),
        ]);

        console.log('LIST CATEGORY >>>>>>>>>>>>>>>>>>>');
        console.log(sectionResponse.data);
        setSuportCategories(sectionResponse.data);

        console.log('LIST SUPORT ARTICLES >>>>>>>>>>>>>>>>>>>');
        console.log(articlesResponse.data);
        setSuportArticles(articlesResponse.data);

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug]);

  return(
    <Container>
        <SectionBanner icon={bannerindexIconImg} title={suporteCategories.name} subTitle={suporteCategories.description} />
        <HeaderInfo supTitle="Central de ajuda" title={suporteCategories?.name} margin="2rem 0 1rem 0" showSendButton={false} />
        <ContentGrid1>
          <div>
            <form onSubmit={e => e.preventDefault()}>
              <InputAnimateDropdown
                label="Qual é a sua dúvida?"
                type="text"
                name="duvida"
                margin="0"
                required
              />
              <SearchButton>
                <Icon icon="icon-icon-magnifying-glass" />
              </SearchButton>
            </form>
          </div>
        </ContentGrid1>
        <Breadcrumbs>
          <ol>
              <li title="Central de Ajuda">
                <Link to={`/suporte`}>Central de ajuda</Link>
              </li>
              <li title={suporteCategories.name}>
                {suporteCategories.name}
              </li>
          </ol>
        </Breadcrumbs>
        <ContentGrid2>
          {suporteArticles.length ?
            suporteArticles.map((article, index) => (
              <Link to={`/suporte/artigos/${slug}/${article.slug}`} key={index}>
                <h2>{index + 1}.</h2>
                <h1>{article.title}</h1>
                <p>{article.abstract}</p>
                <Link to={`/suporte/artigos/${slug}/${article.slug}`} > <Icon icon="icon-icon-magnifying-glass" margin="0 6px 0 0" /> visualizar artigo</Link>
              </Link>
            ))
            :
            !loading && <NotFound>Poxa, não encontramos nenhum artigo para a categoria selecionada. 😢</NotFound>
          }
          {loading && <FkLoadee><Loader isFixed={false} /></FkLoadee>}
        </ContentGrid2>
      <Footer />
    </Container>
  );
}

export default SuporteCategorias;
