import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import Icon, { IIconType } from '../Icon';

import { useField } from '@unform/core';

import { Container, Label, Input, Error } from './styles';

interface IInputValidateProps extends InputHTMLAttributes<HTMLInputElement> {
  type: 'button'
   | 'checkbox'
   | 'color'
   | 'date'
   | 'datetimeocal'
   | 'email'
   | 'file'
   | 'hidden'
   | 'image'
   | 'month'
   | 'number'
   | 'password'
   | 'radio'
   | 'range'
   | 'reset'
   | 'search'
   | 'submit'
   | 'tel'
   | 'text'
   | 'time'
   | 'url'
   | 'week';
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
  icon?: IIconType;
  name: string;
}

const InputValidate: React.FC<IInputValidateProps> = ({label, name, padding, margin, onlyWhite, autoFocus, icon, ...rest}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value: string) {
        if (value) {
          ref.setInputValue(value);
        }
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container padding={padding} margin={margin}>
        {icon && <Icon icon={icon} />}
        <Input {...rest} onlyWhite={onlyWhite} defaultValue={defaultValue} ref={inputRef} />
        <Label onlyWhite={onlyWhite}>{label}</Label>
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
}

export default InputValidate;
