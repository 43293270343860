import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useCredit } from '../../hooks/credit';

import api from '../../services/api';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CropperPerspective from '../../components/CropperPerspective';
import SmallStatisticsBox from '../../components/SmallStatisticsBox';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import HeaderInfo from '../../components/HeaderInfo';
import FooterAlert from '../../components/FooterAlert';
import ThemeCardInstructions from '../../components/ThemeCardInstructions';
import Footer from '../../components/Footer';

import { Container, ContentGrid0, ContentGrid1, FakeDropZone } from './styles';

interface IThemeProps {
  id: string;
  category_id: string;
  exam_name: string;
  student_id?: null;
  name: string;
  material: string;
  thumbnail: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  category: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
  }
  student: {
    id: string;
    first_name: string;
    last_name: string;
    avatar: string;
    exp: number;
    full_name: string;
    role_name: string;
    show_name: string;
    level: number;
    current_exp: number;
    avatar_url: string;
    cover_url: string;
  };
  difficulty: 'easy' | 'medium' | 'hard';
  new: boolean;
  material_url: string;
  thumbnail_url: string;
}

interface IThemeStatistics {
  average_grade: number;
  highest_grade: number;
  all_submitted_wordings: number;
  user_submitted_wordings: number;
}

const EnviarRedacaoFoto: React.FC = () => {
  const history = useHistory();

  const { subCredit, haveCredits } = useCredit();

  const { theme_id } = useParams<{ theme_id: string }>();

  const sectionContainer = useRef<HTMLDivElement>(null);

  const [envioFile, setEnvioFile] = useState<Blob | string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [theme, setTheme] = useState<IThemeProps>({} as IThemeProps);
  const [statistics, setStatistics] = useState<IThemeStatistics>();

  //const [dimensions, setDimensions] = useState(getDimensions());

  const [seconds, setSeconds] = useState(0);

  // count time for send wording
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(oldSeconds => oldSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Get theme
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(`/themes/${theme_id}`);

        console.log('theme >>>>>>>>>>>>');
        console.log(response.data);
        setTheme(response.data);
      } catch (error) {
        console.log(error);
        // setConnectionFailed(true)
      }
      finally {
        setLoading(false);
      }
    }

    loadData();
  }, [theme_id]);


  // Get theme statistics
  useEffect(() => {
    async function loadData() {
      try {

        const response = await api.get<IThemeStatistics>(`/themes/${theme_id}/statistics`);

        console.log('theme statistics >>>>>>>>>>>>');
        console.log(response.data);
        setStatistics(response.data);
      } catch (error) {
        console.log(error);
        // setConnectionFailed(true)
      }
      finally {

      }
    }

    loadData();
  }, [theme_id]);

  const handleSubmitPhoto = useCallback((e) => {
    e.preventDefault();

    if(!envioFile) {
      toast.error('🤷‍♂️ Faltou inserir a foto da sua redação!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setError(true);
      return;
    }

    async function loadData() {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append('theme_id', theme_id);
        formData.append('end_time_in_seconds', String(seconds));
        formData.append('type', 'photo');
        formData.append('photo', envioFile);
        formData.append('font_size', '20');

        console.log(formData);

        const response = await api.post(`/wordings`, formData);
        console.log('Redação enviada com sucesso >>>>> ', response.data);
        history.push('/minhas-redacoes');

        Swal.fire({
          title: 'Pronto!',
          text: 'Sua redação foi enviada com sucesso!',
          icon: 'success',
          background: '#6e61c6',
          backdrop: 'rgba(110, 97, 198, .1)',
          confirmButtonColor: '#03bb85',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });

        subCredit();

      } catch (err) {
        console.log('erro: >>>>>>', err);
        toast.error('🤷‍♂️ Ops! Algo deu errado ao enviar sua redação!', {
          position: "top-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } finally {
        setLoading(false);
      }
    }

    /*  */
    let modal = withReactContent(Swal);
    modal.fire({
      html: (
        <div>
          <h2>Tem certeza que deseja enviar sua redação?</h2>
          <p>Sua correção será entregue em até 3 dias úteis! 😊</p>
        </div>
      ),
      background: '#6c60c3',
      backdrop: 'rgba(110, 97, 198, .1)',
      cancelButtonColor: '#d2194d',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showConfirmButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        loadData();
        console.log('opration confirmed');
      }
      else {
        console.log('opration canceled');
      }
    });
    /*  */

  }, [envioFile, history, seconds, theme_id, subCredit]);

  return (
    <>
      {!haveCredits() && <FooterAlert />}
      <Container>
        <HeaderInfo supTitle={theme.exam_name} title={theme.name} margin="0 0 1rem 0" showSendButton={false} />
        {statistics &&
        <ContentGrid0>
          <SmallStatisticsBox icon="icon-icon-send-message" title="Redações Enviadas" bgColorIcon="#9181fa" value={statistics.all_submitted_wordings} />
          <SmallStatisticsBox icon="icon-icon-overview" title="Nota Média" bgColorIcon="#9181fa" value={statistics.average_grade} />
          <SmallStatisticsBox icon="icon-icon-quests" title="Maior nota" bgColorIcon="#9181fa" value={statistics.highest_grade} />
          <SmallStatisticsBox icon="icon-icon-messages-1" title="Seus envios para este tema" bgColorIcon="#9181fa" value={statistics.user_submitted_wordings} />
        </ContentGrid0>}
        <ContentGrid1>
          {loading && <Loader isFixed={false} />}
          <section>
            {haveCredits() ? <main ref={sectionContainer}>
              <form onSubmit={(e) => handleSubmitPhoto(e)} encType="multipart/form-data">
                <HeaderInfo supTitle="Por foto" title="Enviar redação" margin="0 0 2rem 0" showSendButton={false} />
                <CropperPerspective
                  maxWidth={sectionContainer.current?.clientWidth}
                  setEnvioFile={setEnvioFile}
                  placeholder="Arraste ou clique aqui para enviar sua redação!"
                />
                <footer>
                  {envioFile &&
                  <Button color="success" type="submit">
                    <Icon icon="icon-icon-send-message" margin="0 6px 0 0" />
                    Enviar para correção
                  </Button>}
                  <p>Envie sua foto em boa resolução e qualidade.</p>
                </footer>
              </form>
            </main>
            :
            <main>
              <HeaderInfo supTitle="Por foto" title="Enviar redação" margin="0 0 2rem 0" showSendButton={false} />
              <FakeDropZone>
                <p style={{fontSize: '3rem'}}>😢</p>
                <p>Poxa, você não tem créditos disponíveis.</p>
              </FakeDropZone>
            </main>
            }
          </section>
          <section>
            {loading && <Loader />}
            <main>
              <ThemeCardInstructions
                id={theme.id}
                title={theme.name}
                thumbnail_url={theme.thumbnail_url}
                exp={30}
                student={theme?.student}
                course={theme.exam_name}
                link={theme.material_url}
                themeSuggestion={true}
                is_photo={true}
                difficulty={theme.difficulty}
              />
            </main>
          </section>
        </ContentGrid1>
        <Footer />
      </Container>
    </>
  );
}

export default EnviarRedacaoFoto;
