import React, { useCallback, useState } from 'react';
import api from '../../../services/api';

import LoaderPureCss from '../../../components/LoaderPureCss';

import { Container } from './styles';

const ForgetPasswordModal: React.FC = () => {

  const [inputForgetPassword, setInputForgetPassword] = useState('');
  const [validation, setValidation] = useState('');
  const [validationStatus, setValidationStatus] = useState<boolean>();

  const [loading, setLoading] = useState(false);

  const handleSubmitForgetPaswordForm = useCallback(async (e, email: string) => {
    e.preventDefault();
    console.log('email: ', email);

    try {
      setLoading(true);
      const response = await api.post(`/password/forgot`, {
        "email": inputForgetPassword
      });

      console.log(response.data);
      setValidation('O e-mail de redefinição foi enviado para o seu e-mail! 📩');
      setValidationStatus(true);
    } catch(error) {
      console.log(error);
      setValidation('E-mail não encontrado em nossa plataforma! 🤷‍♂️');
      setValidationStatus(false);
    } finally {
      setLoading(false);
    }

  }, [inputForgetPassword]);

  return (
    <Container>
      <h1>Esqueceu sua senha?</h1>
      <p>Informe o email que você cadastrou no ato da compra para acessar a Plataforma e enviaremos as instruções de troca para você.</p>
      <div>
        <form onSubmit={(e) => {handleSubmitForgetPaswordForm(e, inputForgetPassword)}}>
          <input name="email"
            type="email"
            autoComplete="off"
            defaultValue={inputForgetPassword}
            onChange={(e) => setInputForgetPassword(e.target.value)}
            required
          />
          <label htmlFor="email">Informe o seu e-mail</label>
          <button disabled={inputForgetPassword === '' ? true : false}>Recuperar minha senha!</button>
          {!loading && <p style={{color: validationStatus ? '#03bb85' : '#d65151' }}>{validation}</p>}
        </form>
      </div>
      {loading && <LoaderPureCss isFixed={false} scale="0.6" />}
    </Container>
  );
}

export default ForgetPasswordModal;
